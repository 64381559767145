import { ColorShades, LacsColors } from 'src/constants';

/**
 * Generates a color shader map based on the provided LacsColors and ColorShades constants.
 * The color shader map is a record that maps each color in LacsColors to a record of color shades in ColorShades.
 * Each color shade is represented by a string value.
 *
 * @returns The generated color shader map.
 */
export const ColorShaderMap: Record<
  keyof typeof LacsColors,
  Record<keyof typeof ColorShades, string>
> = (function () {
  // @ts-ignore
  const colorMap: Record<
    keyof typeof LacsColors,
    Record<keyof typeof ColorShades, string>
  > = {};
  for (const color of Object.keys(LacsColors)) {
    // @ts-ignore
    colorMap[color] = {};
    for (const shade of Object.keys(ColorShades)) {
      colorMap[color as unknown as keyof typeof LacsColors][
        shade as unknown as keyof typeof ColorShades
      ] = `${color}.${
        ColorShades[shade as unknown as keyof typeof ColorShades]
      }`;
    }
  }
  return colorMap;
})();
