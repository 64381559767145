import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  StackProps,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseEmoji, NimblePicker } from 'emoji-mart';
import data from 'emoji-mart/data/google.json';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineSend } from 'react-icons/ai';
import { FaRegSmile } from 'react-icons/fa';
import { RiImageFill } from 'react-icons/ri';
import { useMutation, UseQueryResult } from 'react-query';

import { addCommentsToPost } from 'src/apis/comment.api';
import FileInput from 'src/components/FileInput';
import { TranslationKeys } from 'src/constants/translation-keys';
import { FeedPostCommentDto } from 'src/dto/feed-post-comment.dto';
import { useUserLocation } from 'src/hoc/UserLocationContext';
import { useTranslate } from 'src/hooks/useTranslate';
import { Comment } from 'src/models/Comment.model';
import { EntityType } from 'src/models/View.model';
import useMessageWithLimit from 'src/pages/chat/hooks/useMessageWithLimit';
import { CommentDto, useCommentFormSchema } from 'src/schema';
import { ValueCallback } from 'src/types/common.type';

import FilePreviews from './AddPostForm/FilePreviews';
import useFiles from './hooks/useFiles';

interface CommentFormProps extends StackProps {
  postId: number;
  addMultipleComments: ValueCallback<Comment[]>;
  commentsData?: Comment[];
  refetchComments: UseQueryResult['refetch'];
}

export const CommentForm: React.FC<CommentFormProps> = ({
  postId,
  addMultipleComments,
  commentsData,
  refetchComments,
  ...props
}) => {
  const { translate } = useTranslate();
  const toast = useToast();
  const CommentFormSchema = useCommentFormSchema();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { message: commentContent, handleMessageChange: setMessage } =
    useMessageWithLimit();

  const {
    handleSubmit,
    setValue,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(CommentFormSchema),
    mode: 'onChange',
  });

  const {
    files: images,
    fileURIs: imageURIs,
    addFile: addImage,
    removeFile: removeImage,
  } = useFiles();

  const { locationStream } = useUserLocation();

  const handleOnEmojiSelect = (emoji: BaseEmoji) => {
    setMessage(`${commentContent}${emoji.native}`);
  };

  const handleOnTextInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    setMessage(e.currentTarget.value);
  };

  const handleEmojiIconClick = () =>
    setShowEmojiPicker(prevShowEmojiPicker => !prevShowEmojiPicker);

  const getAllFiles = (): File[] => _.concat(images);

  const addPostCommentMutation = useMutation(
    (postComment: FeedPostCommentDto) => addCommentsToPost(postComment),
  );

  const formSubmitHandle = (data: CommentDto) => {
    addPostCommentMutation.mutate(
      {
        ...locationStream,
        EntityId: postId,
        entityType: EntityType.post,
        description: data.commentContent,
        files: getAllFiles(),
      },
      {
        onSuccess: () => {
          resetForm();
          setMessage('');
          refetchComments();
          toast({
            title: translate(TranslationKeys.commentAddedSuccessfully),
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          if (commentsData) {
            addMultipleComments(commentsData);
          }
        },
      },
    );
  };

  useEffect(() => {
    if (commentContent) {
      setValue('commentContent', commentContent);
    }
  }, [commentContent]);

  return (
    <>
      <form onSubmit={handleSubmit(formSubmitHandle)}>
        <Flex w='full' bgColor='blue.50'>
          <Box
            borderColor='gray.500'
            borderWidth='1px'
            borderRadius='3'
            w='full'
            p='1'
            minW='full'
            {...props}
          >
            <HStack>
              <IconButton
                icon={<Icon as={FaRegSmile} boxSize='6' />}
                aria-label='emote picker'
                variant='ghost'
                size='6'
                p='0'
                onClick={handleEmojiIconClick}
              />
              <Input
                onChange={handleOnTextInputChange}
                value={commentContent}
                h='full'
                pl='1'
                variant='unstyled'
                placeholder={translate(TranslationKeys.addYourComment)}
                flexGrow={2}
              />
              <Flex>
                <FormControl id='add-image'>
                  <FormLabel htmlFor='add-image-input' m='0'>
                    <FileInput
                      accept='image/*'
                      id='add-image-input'
                      onFiles={addImage}
                    />
                    <Button
                      aria-label='add image button'
                      colorScheme='blue'
                      variant='ghost'
                      as='span'
                      cursor='pointer'
                      leftIcon={<Icon as={RiImageFill} boxSize='5' />}
                    />
                  </FormLabel>
                </FormControl>
              </Flex>
              {!_.isEmpty(commentContent) && (
                <Button
                  type='submit'
                  size='md'
                  colorScheme='blue'
                  isLoading={addPostCommentMutation.isLoading}
                >
                  <Icon as={AiOutlineSend} boxSize='6' />
                </Button>
              )}
            </HStack>
          </Box>
        </Flex>
      </form>
      <Box w='full' display={showEmojiPicker ? 'block' : 'none'}>
        <NimblePicker
          set='google'
          data={data}
          onSelect={handleOnEmojiSelect}
          showPreview={false}
          showSkinTones={false}
          enableFrequentEmojiSort
          title=''
          emoji=''
          autoFocus
          style={{ width: '100%' }}
        />
      </Box>
      <Box>
        <FilePreviews
          fileURIs={imageURIs}
          files={images}
          label='Image:'
          closeBtnAriaLabel='remove image'
          onFileRemove={removeImage}
        />
      </Box>
    </>
  );
};
