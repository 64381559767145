import {
  Box,
  ComponentWithAs,
  Icon,
  IconButton,
  IconProps,
} from '@chakra-ui/react';
import { FaStopCircle } from 'react-icons/fa';

interface RecordAndStopIconProps {
  icon: ComponentWithAs<'svg', IconProps>;
  iconColor: string;
  isRecording: boolean;
  stopRecording: () => void;
  startRecording: () => void;
}

export const RecordAndStopIcon: React.FC<RecordAndStopIconProps> = ({
  icon,
  iconColor,
  isRecording,
  startRecording,
  stopRecording,
}) => {
  return (
    <Box
      rounded='full'
      border='4px solid'
      p='2'
      borderColor={isRecording ? 'red.400' : iconColor}
    >
      <IconButton
        variant='ghost'
        icon={
          <Icon
            as={isRecording ? FaStopCircle : icon}
            boxSize='10'
            fill={isRecording ? 'red.400' : iconColor}
          />
        }
        aria-label='start recording'
        onClick={isRecording ? stopRecording : startRecording}
        _hover={{}}
        _active={{}}
        _focus={{}}
      />
    </Box>
  );
};
