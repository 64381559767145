import { useState, useCallback, useEffect } from 'react';

import {
  TranscribeMessage,
  joinHFOThreadRoom,
  leaveHFOThreadRoom,
  onHFOTranscribed,
  unsubscribeHFOTranscribed,
} from 'src/apis/socket.io/messages.socket.io';
import { TRANSCRIBE_MESSAGE_LENGTH } from 'src/constants/chat.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { ValueCallback } from 'src/types/common.type';

export const useTranscribeMessage = () => {
  const { currentUser: user } = useUserDetails();

  const [receivedMessage, setReceivedMessage] = useState<TranscribeMessage>();
  const [transcribeMessage, setTranscribeMessage] = useState<string>('');
  const [startTime, setStartTime] = useState<number>(0);
  const [isEndOfUtteranceDetected, setIsEndOfUtteranceDetected] =
    useState<boolean>(false);

  const handleTranscribeMessage = useCallback<ValueCallback<TranscribeMessage>>(
    newMessage => {
      if (user?.id === newMessage.userId) {
        setReceivedMessage(newMessage);
      }
    },
    [],
  );

  useEffect(() => {
    if (user) {
      joinHFOThreadRoom(user.id);
      return () => {
        leaveHFOThreadRoom(user.id);
      };
    }
  }, [user]);

  useEffect(() => {
    onHFOTranscribed(handleTranscribeMessage);

    return () => {
      unsubscribeHFOTranscribed(handleTranscribeMessage);
    };
  }, []);

  useEffect(() => {
    if (receivedMessage && startTime && receivedMessage.timestamp < startTime) {
      return;
    }
    if (receivedMessage) {
      setTranscribeMessage(receivedMessage?.text ?? '');
      setIsEndOfUtteranceDetected(receivedMessage.isEndOfUtteranceDetected);
    }
  }, [receivedMessage, startTime]);

  useEffect(() => {
    if (transcribeMessage.length >= TRANSCRIBE_MESSAGE_LENGTH) {
      setIsEndOfUtteranceDetected(true);
    }
  }, [transcribeMessage]);

  return {
    transcribeMessage,
    setTranscribeMessage,
    isEndOfUtteranceDetected,
    setStartTime,
  };
};
