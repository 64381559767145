import { createContext, useContext } from 'react';

export interface UserLocation {
  latitude: number;
  longitude: number;
  accuracy: number;
}

export interface UserLocationProps {
  locationStream?: UserLocation;
  getIsLocationPermissionGranted: () => Promise<boolean>;
}

export const UserLocationContext = createContext<UserLocationProps>(null!);

export const useUserLocation = () => {
  const context = useContext(UserLocationContext);
  if (!context) {
    throw new Error(
      'useUserLocation must be used within a UserLocationProvider',
    );
  }
  return context;
};
