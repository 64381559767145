import { Flex, Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { HOME_PAGE_ROUTE } from 'src/routes/routeList';

const NotFoundPage = () => {
  const { translate } = useTranslate();
  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      flexDir='row'
      height='100svh'
    >
      <Flex flexDir='column' mb='10rem'>
        <Heading fontSize='6xl'>404</Heading>
        <Text fontSize='2xl'>{translate(TranslationKeys.pageNotFound)}</Text>
        <Link to={HOME_PAGE_ROUTE}>
          <Text fontSize='xl' color='blue.500'>
            {translate(TranslationKeys.goToHome)}
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default NotFoundPage;
