import { Text, TextProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { PersonalCalls } from 'src/models/PersonalCall';
import { CallStatusType } from 'src/types/call.type';

interface CallConnectionStatusProps extends TextProps {
  call: PersonalCalls;
}

const CallConnectionStatus: React.FC<CallConnectionStatusProps> = ({
  call,
  ...props
}) => {
  const { translate } = useTranslate();
  const [status, setStatus] = useState<string>(
    translate(TranslationKeys.connecting),
  );

  useEffect(() => {
    if (!call) return;

    const { callStatus } = call;

    if (callStatus === CallStatusType.unreachable) {
      setStatus(translate(TranslationKeys.userUnreachable));
    }

    if (callStatus === CallStatusType.missed) {
      setStatus(translate(TranslationKeys.callMissed));
    }

    if (callStatus === CallStatusType.rejected) {
      setStatus(translate(TranslationKeys.callRejected));
    }
  }, [call]);

  return <Text {...props}>{status}</Text>;
};

export default CallConnectionStatus;
