import { extendTheme } from '@chakra-ui/react';

import { boxSize } from './theme-blocks/boxSize';
import { Colors } from './theme-blocks/color';
import { LayerStyles } from './theme-blocks/layerstyle';

export const theme = extendTheme({
  ...Colors,
  ...boxSize,
  ...LayerStyles,
});
