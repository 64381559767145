import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import {
  onSomeoneTyping,
  onStoppedTyping,
  OnTypingCallback,
  OnTypingPayload,
  unsubscribeSomeoneTyping,
  unsubscribeStoppedTyping,
} from '../../../apis/socket.io/messages.socket.io';

const useTypingObserver = () => {
  const { currentUser } = useUserDetails();
  const [usersTyping, setUsersTyping] = useState<OnTypingPayload['user'][]>([]);

  const handleSomeoneTyping = useCallback<OnTypingCallback>(
    ({ user }) => {
      if (!currentUser) {
        return;
      }

      if (currentUser?.id === user.id) {
        return;
      }

      setUsersTyping(prev => {
        return _.uniqBy([...prev, user], 'id');
      });
    },
    [currentUser],
  );

  const handleStoppedTyping = useCallback<OnTypingCallback>(({ user }) => {
    setUsersTyping(prev => prev.filter(prevUser => prevUser.id !== user.id));
  }, []);

  useEffect(() => {
    onSomeoneTyping(handleSomeoneTyping);

    return () => {
      unsubscribeSomeoneTyping(handleSomeoneTyping);
    };
  }, [handleSomeoneTyping]);

  useEffect(() => {
    onStoppedTyping(handleStoppedTyping);

    return () => {
      unsubscribeStoppedTyping(handleStoppedTyping);
    };
  }, [handleStoppedTyping]);

  return { usersTyping, isSomeoneTyping: !_.isEmpty(usersTyping) };
};

export default useTypingObserver;
