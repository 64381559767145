import { useEffect, useState } from 'react';
import { AccessToken } from '../dto/auth.dto';
import {
  getAccessToken,
  setAccessToken as setAccessTokenInStore,
  removeAccessToken as removeAccessTokenInStore,
} from '../providers/auth-store.provider';

type AccessTokenCallback = (token: AccessToken) => void;

interface AuthStore {
  currentAccessToken: AccessToken | null;
  setAccessToken: AccessTokenCallback;
  removeAccessToken: () => void;
}

type AuthStorePayload = AccessToken | null;

const useAuthStore = (): AuthStore => {
  const [currentAccessToken, setCurrentAccessToken] =
    useState<AuthStorePayload>(null);

  // This will be invoked once in the hook's lifetime to fetch the current access token from the store
  useEffect(() => {
    setCurrentAccessToken(getAccessToken());
  }, []);

  // updates both the local state and the store's record of access token
  const setAccessToken: AccessTokenCallback = token => {
    setAccessTokenInStore(token);
    setCurrentAccessToken(token);
  };

  const removeAccessToken = () => {
    setCurrentAccessToken(null);
    removeAccessTokenInStore();
  };

  return { currentAccessToken, setAccessToken, removeAccessToken };
};

export default useAuthStore;
