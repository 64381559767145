import { Flex, Text } from '@chakra-ui/react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { getUserFullAddress } from 'src/utils/get-user-full-address';

import DetailContainer from './components/detailContainer';

const AdditionalDetails: React.FC = () => {
  const { translate } = useTranslate();
  const { currentUser: user } = useUserDetails();
  const fullAddress = user && getUserFullAddress(user);

  return (
    <Flex
      direction='column'
      w='full'
      alignItems='start'
      justifyContent='center'
      px='8'
      py='4'
    >
      <Text as='u' fontSize='3xl' fontWeight='bold' mb='4'>
        {translate(TranslationKeys.additionalDetails)}
      </Text>
      <DetailContainer
        label={translate(TranslationKeys.phoneNumber)}
        value={user?.phoneNumber ? `+${user?.phoneNumber}` : ''}
      />
      <DetailContainer
        label={translate(TranslationKeys.gender)}
        value={user?.gender}
      />
      <DetailContainer
        label={translate(TranslationKeys.address)}
        value={fullAddress}
      />
    </Flex>
  );
};

export default AdditionalDetails;
