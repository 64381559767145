import { Text } from '@chakra-ui/react';

interface TypingProps {
  typingText: string;
  isTyping: boolean | undefined;
}

export const RecipientTypingStatus: React.FC<TypingProps> = ({
  typingText,
  isTyping,
}) => {
  if (!isTyping) return null;

  return (
    <Text
      maxW='full'
      isTruncated
      ml='3'
      bottom='0'
      position='absolute'
      fontSize='xs'
      color='gray.500'
    >
      {typingText}
    </Text>
  );
};
