import { VStack, Text, Image } from '@chakra-ui/react';
import { IconSectionItemProps } from './types';

export const IconSectionItem: React.FC<IconSectionItemProps> = ({
  src,
  caption,
}) => {
  return (
    <VStack>
      <Image src={src} boxSize='10' />
      <Text fontSize='md' align='center' noOfLines={2}>
        {caption}
      </Text>
    </VStack>
  );
};
