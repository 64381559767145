import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';

type FooterIcon = React.ComponentType;

interface FooterIconButtonProps
  extends Pick<IconButtonProps, 'aria-label' | 'disabled'> {
  isActive: boolean;
  activeIcon: FooterIcon;
  inactiveIcon: FooterIcon;
  onToggleClick: () => any;
}

const FooterIconButton: React.FC<FooterIconButtonProps> = ({
  activeIcon,
  inactiveIcon,
  onToggleClick,
  isActive,
  ...props
}) => {
  const renderIcon = (icon: FooterIcon) => {
    return <Icon as={icon} boxSize='8' />;
  };

  return (
    <IconButton
      size='lg'
      variant='ghost'
      colorScheme='whiteAlpha'
      color={isActive ? 'white' : 'whiteAlpha.700'}
      icon={isActive ? renderIcon(activeIcon) : renderIcon(inactiveIcon)}
      onClick={onToggleClick}
      isRound
      {...props}
    />
  );
};

export default FooterIconButton;
