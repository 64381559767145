import React from 'react';
import * as _ from 'lodash';
import {
  UnorderedList,
  ListItem,
  Text,
  Spacer,
  Checkbox,
  HStack,
} from '@chakra-ui/react';

import { User } from 'src/models';
import { getUserDisplayName } from 'src/utils/user.utils';
import UserAvatar from 'src/components/UserAvatar';
import { MessageThreadMember } from 'src/models/MessageThreadMember.model';
import { ValueCallback } from 'src/types/common.type';
import { SelectedMemberMap } from 'src/pages/peer-call/group-call/components/MemberItem';
import { useTranslate } from 'src/hooks/useTranslate';
import { TranslationKeys } from 'src/constants/translation-keys';

import PersonalVoiceCallButton from './PersonalVoiceCallButton';

interface UsersListProps {
  userListWithoutAlreadyAddedUsers: (User | undefined)[];
  shouldRenderUser?: ValueCallback<User, boolean>;
  selectedMembers: SelectedMemberMap;
  setSelectedMembers: React.Dispatch<React.SetStateAction<SelectedMemberMap>>;
  isGroupCall?: boolean;
  isSearchingUsers?: boolean;
  searchQuery?: string;
  totalMembersCount: number;
  setTotalMembersCount: React.Dispatch<React.SetStateAction<number>>;
}

export const UsersList: React.FC<UsersListProps> = ({
  userListWithoutAlreadyAddedUsers,
  shouldRenderUser,
  selectedMembers,
  setSelectedMembers,
  isGroupCall,
  isSearchingUsers,
  searchQuery,
  totalMembersCount,
  setTotalMembersCount,
}) => {
  const { translate } = useTranslate();

  const maximumMembersAdded = totalMembersCount >= 3;

  const canRenderUser: typeof shouldRenderUser = user => {
    // Always render if no predicate is provided
    return shouldRenderUser ? shouldRenderUser(user) : true;
  };

  const toggleMemberSelect = (user: User) => {
    const member: MessageThreadMember = {
      user: user,
      isAdmin: false,
      UserId: user.id,
      MessageThreadId: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    } as MessageThreadMember;

    const wasMemberSelected = !!selectedMembers[member.UserId];

    setTotalMembersCount(prevValue => {
      return wasMemberSelected ? prevValue - 1 : prevValue + 1;
    });

    setSelectedMembers(prevMember => {
      if (wasMemberSelected) {
        return _.omit(prevMember, member.UserId);
      }
      if (maximumMembersAdded) {
        return prevMember;
      }
      const selectedAt = new Date();
      return { ...prevMember, [member.UserId]: { ...member, selectedAt } };
    });
  };

  if (
    _.isEmpty(userListWithoutAlreadyAddedUsers) &&
    !isSearchingUsers &&
    searchQuery
  ) {
    return (
      <Text color='gray.500' fontSize='sm' pl='2'>
        {translate(TranslationKeys.noUsersFound)}
      </Text>
    );
  }

  if (
    _.isEmpty(userListWithoutAlreadyAddedUsers) &&
    !isSearchingUsers &&
    !searchQuery
  ) {
    return (
      <Text color='gray.500' fontSize='sm' pl='2'>
        {translate(TranslationKeys.searchUsers)}
      </Text>
    );
  }

  return (
    <UnorderedList spacing='4' w='full'>
      {_.map(userListWithoutAlreadyAddedUsers, user => (
        <React.Fragment key={user?.id}>
          {canRenderUser(user!) && (
            <ListItem
              as={HStack}
              cursor={
                !selectedMembers[user?.id!] && maximumMembersAdded
                  ? 'not-allowed'
                  : 'pointer'
              }
              spacing='4'
              w='full'
              onClick={e => {
                e.preventDefault();
                toggleMemberSelect(user!);
              }}
            >
              <UserAvatar user={user} size='sm' />
              <Text fontWeight='bold'>{getUserDisplayName(user!)}</Text>
              <Spacer />
              {isGroupCall ? (
                <Checkbox
                  colorScheme='green'
                  isChecked={!!selectedMembers[user?.id!]}
                  isDisabled={
                    !selectedMembers[user?.id!] && maximumMembersAdded
                  }
                  onClick={() => toggleMemberSelect(user!)}
                />
              ) : (
                <PersonalVoiceCallButton otherUserId={user?.id!} />
              )}
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </UnorderedList>
  );
};
