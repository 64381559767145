import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getAllPersonalCalls } from 'src/apis/personal-calls.api';
import { PersonalCallResponse, PersonalCalls } from 'src/models/PersonalCall';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kPersonalCallsQueryKey = 'personalCalls';

export const usePersonalCallQueryClient = () => {
  return useArrayDataQuery<PersonalCalls>({
    queryKey: kPersonalCallsQueryKey,
  });
};

const usePersonalCalls = (pageNumber?: number) => {
  const [personalCalls, setPersonalCalls] = useState<PersonalCalls[]>([]);

  const { data, ...queryMetadata } = useQuery<PersonalCallResponse>(
    [kPersonalCallsQueryKey, pageNumber],
    () => getAllPersonalCalls(pageNumber),
  );

  useEffect(() => {
    setPersonalCalls(prevCalls => {
      if (data) {
        const { calls } = data;
        return [...prevCalls, ...calls];
      }
      return prevCalls;
    });
  }, [data]);

  const total = data?.total ?? 0;
  const pageSize = data?.pageSize ?? 0;
  const hasMore = total - pageSize * (pageNumber ?? 0) > 0; // Determine if there are more calls to fetch

  return {
    calls: personalCalls,
    hasMore,
    queryMetadata,
  };
};

export default usePersonalCalls;
