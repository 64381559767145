import { Container, Divider, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { MdAdd } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getOneMessageThread } from 'src/apis/message-threads.api';
import FloatingActionButton from 'src/components/FloatingActionButton';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useCustomQueryClient } from 'src/hoc/query-context';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import useDialog from 'src/hooks/useDialog';
import { useTranslate } from 'src/hooks/useTranslate';
import { GroupMessageThread } from 'src/models/GroupMessageThread.model';
import { MessageThreadMember } from 'src/models/MessageThreadMember.model';
import { AppNavigationType } from 'src/types/navigation.type';
import { getUserDisplayName } from 'src/utils/user.utils';

import AddMemberDialog from './components/AddMemberDialog';
import MemberItem from './components/MemberItem';

interface MessageMembersPageUrlParams {
  messageThreadId: string;
}

const MessageMembersPage = () => {
  const { translate } = useTranslate();
  const params = useParams<MessageMembersPageUrlParams>();
  const messageThreadId = +params.messageThreadId;

  const [isAddMemberDialogOpen, openAddMemberDialog, closeAddMemberDialog] =
    useDialog();

  const { currentUser } = useUserDetails();

  const getMessageThreadQueryKey = [
    'getOneMessageThreadForMessageMemberPage',
    messageThreadId,
  ];
  const { queryClient } = useCustomQueryClient();

  const { data: messageThread } = useQuery(getMessageThreadQueryKey, () =>
    getOneMessageThread(messageThreadId),
  );

  const handleOnMembersAdded = (addedMembers: MessageThreadMember[]) => {
    closeAddMemberDialog();
    queryClient.setQueryData<typeof messageThread>(
      getMessageThreadQueryKey,
      prevMessageThread => {
        if (!prevMessageThread) {
          return prevMessageThread;
        }

        const members = _.concat(prevMessageThread.members, addedMembers);
        return { ...prevMessageThread, members: _.uniqBy(members, 'id') };
      },
    );
  };

  const handleOnMemberRemoved = (memberRemoved: MessageThreadMember) => {
    queryClient.setQueryData<typeof messageThread>(
      getMessageThreadQueryKey,
      prevMessageThread => {
        if (!prevMessageThread) {
          return prevMessageThread;
        }

        const members = _.filter(
          prevMessageThread.members,
          member => member.id !== memberRemoved.id,
        );

        return { ...prevMessageThread, members };
      },
    );
  };

  const handleOnMemberUpdated = (memberUpdated: MessageThreadMember) => {
    queryClient.setQueryData<typeof messageThread>(
      getMessageThreadQueryKey,
      prevMessageThread => {
        if (!prevMessageThread) {
          return prevMessageThread;
        }

        const updatedMembers = _.map(prevMessageThread.members, member => {
          return member.id === memberUpdated.id ? memberUpdated : member;
        });

        return { ...prevMessageThread, members: updatedMembers };
      },
    );
  };

  if (!messageThread?.isGroup) {
    return null;
  }

  const currentUserMemberRecord = _.find(
    messageThread?.members,
    member => member.user?.id === currentUser?.id,
  );

  const groupName = (messageThread as GroupMessageThread).group.name;

  return (
    <HeaderWrapper
      pageTitle={`${translate(TranslationKeys.members)}: ${groupName}`}
      navigationType={AppNavigationType.back}
    >
      <Container>
        <VStack w='full' mt='6' spacing='4'>
          {_.chain(messageThread?.members)
            .sortBy(({ user }) => {
              // return `null` for current loggedIn user to make them appear at last during sort
              // lodash keeps null/undefined at the end for ascending order
              // Source: https://github.com/lodash/lodash/issues/4169#issuecomment-457607652
              if (user?.id === currentUser?.id) {
                return null;
              }

              return user && getUserDisplayName(user);
            })
            .value()
            .map((member, index, { length: memberArrLen }) => (
              <>
                {index === memberArrLen - 1 && <Divider />}
                <MemberItem
                  key={member.id}
                  member={member}
                  currentUserMemberRecord={currentUserMemberRecord}
                  onMemberRemoved={() => handleOnMemberRemoved(member)}
                  onMemberUpdated={handleOnMemberUpdated}
                />
              </>
            ))}
        </VStack>
        {currentUserMemberRecord?.isAdmin && (
          <>
            {messageThread && (
              <>
                <FloatingActionButton
                  aria-label='add member button'
                  icon={<MdAdd />}
                  onClick={openAddMemberDialog}
                  position='absolute'
                  bottom='10'
                  right='2'
                />
                <AddMemberDialog
                  existingMembers={messageThread.members}
                  messageThreadId={messageThreadId}
                  onMembersAdded={handleOnMembersAdded}
                  isOpen={isAddMemberDialogOpen}
                  onClose={closeAddMemberDialog}
                />
              </>
            )}
          </>
        )}
      </Container>
    </HeaderWrapper>
  );
};

export default MessageMembersPage;
