import { AlertMessage } from 'src/components/Toast/alert-message';
import { showErrorAlertMessage } from 'src/utils';
import { useTranslate } from './useTranslate';

export const useToast = () => {
  const showToast = AlertMessage();
  const { translate } = useTranslate();

  const showErrorToast = (err: unknown) =>
    showErrorAlertMessage(err, showToast, translate);

  return { showToast, showErrorToast };
};
