import { Accordion, List, Text, VStack } from '@chakra-ui/react';
import * as _ from 'lodash';
import React, { MutableRefObject, useRef, useState } from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import useGroupCalls from '../../hooks/useGroupCalls';
import GroupCallItem from '../../components/GroupCallItem';
import usePagination from '../../hooks/usePagination';

interface GroupCallsPanelProps {
  pageRef: MutableRefObject<HTMLDivElement | null>;
}

const GroupCallsPanel: React.FC<GroupCallsPanelProps> = ({ pageRef }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const { translate } = useTranslate();
  const {
    calls: groupCalls,
    hasMore,
    queryMetadata,
  } = useGroupCalls(pageNumber);

  const observerRef = useRef<HTMLDivElement | null>(null);
  const isFetchingData = useRef(false);
  const observerTargetIndex = useRef(0);

  usePagination({
    calls: groupCalls,
    observerTargetIndex,
    isLoading: queryMetadata.isLoading,
    isFetched: queryMetadata.isFetched,
    isFetchingData,
    pageRef,
    observerRef,
    hasMore,
    setPageNumber,
  });

  return (
    <List spacing='4' paddingInline='0'>
      {_.isEmpty(groupCalls) && (
        <VStack mt='12'>
          <Text fontSize='lg' color='gray.500' fontWeight='semibold'>
            {translate(TranslationKeys.youDontHaveGroupCalls)}
          </Text>
        </VStack>
      )}
      <Accordion allowMultiple>
        {_.chain(groupCalls)
          .value()
          .map((call, index) => {
            const isObserverTarget =
              index === observerTargetIndex.current && hasMore;

            return (
              <GroupCallItem
                call={call}
                observerRef={isObserverTarget ? observerRef : null}
              />
            );
          })}
      </Accordion>
    </List>
  );
};

export default GroupCallsPanel;
