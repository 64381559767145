import { Checkbox, HStack, Spacer, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useState } from 'react';

import UserAvatar from 'src/components/UserAvatar';
import { MessageThreadMember } from 'src/models/MessageThreadMember.model';
import { getUserDisplayName } from 'src/utils/user.utils';

type SelectedMember = MessageThreadMember & { selectedAt: Date };
export type SelectedMemberMap = Record<string, SelectedMember>;

export const useSelectedMembers = (initialState: SelectedMemberMap = {}) =>
  useState<SelectedMemberMap>(initialState);
interface MemberItemProps {
  member: MessageThreadMember;
  selectedMembers: SelectedMemberMap;
  setSelectedMembers: React.Dispatch<React.SetStateAction<SelectedMemberMap>>;
}

const MemberItem: React.FC<MemberItemProps> = ({
  member,
  selectedMembers,
  setSelectedMembers,
}) => {
  if (!member.user) {
    return null;
  }

  const toggleMemberSelect = (member: MessageThreadMember) => {
    setSelectedMembers(prevMember => {
      const wasMemberSelected = !!prevMember[member.UserId];
      if (wasMemberSelected) {
        return _.omit(prevMember, member.UserId);
      }
      const selectedAt = new Date();
      return { ...prevMember, [member.UserId]: { ...member, selectedAt } };
    });
  };

  return (
    <HStack spacing='4' w='full' key={member?.id}>
      <UserAvatar user={member?.user} />
      <HStack>
        <Text fontWeight='bold'>{getUserDisplayName(member.user)}</Text>
      </HStack>
      <Spacer />
      <Checkbox
        onChange={() => toggleMemberSelect(member)}
        colorScheme='green'
        isChecked={!!selectedMembers[member.UserId]}
      />
    </HStack>
  );
};

export default MemberItem;
