import { createIcon } from '@chakra-ui/react';

export const MapViewIcon = createIcon({
  displayName: 'map-view-icon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_184_935)'>
        <path
          d='M23.9423 23.0189L19.7073 13.1381C19.5959 12.8787 19.3407 12.7104 19.0585 12.7104H16.3689C17.4575 10.8086 18.3527 8.70385 18.3527 6.92297C18.3527 -2.24805 5.64734 -2.35993 5.64734 6.92297C5.64734 8.70385 6.54254 10.8086 7.6311 12.7104H4.94147C4.65912 12.7104 4.40387 12.8787 4.29272 13.1381L0.0576758 23.0189C-0.141543 23.4836 0.199766 24.0027 0.706418 24.0027H23.2936C23.7991 24.0027 24.1419 23.4847 23.9423 23.0189ZM18.5929 14.1219L20.6827 18.9973C20.6173 19.0314 13.8157 22.5633 13.767 22.5911H12.2895C12.2521 22.5494 12.5696 22.8685 7.9693 18.2687L10.5245 16.961C11.0455 17.6259 11.4104 18.0483 11.4673 18.1138C11.7486 18.4376 12.2516 18.437 12.5325 18.1138C12.6506 17.978 14.0894 16.3108 15.5068 14.1219H18.5929ZM12 1.4179C14.3796 1.4179 16.9409 3.14074 16.9409 6.92297C16.9409 10.1031 13.376 14.8359 12 16.5439C10.624 14.8357 7.05908 10.1031 7.05908 6.92297C7.05908 3.12023 9.54053 1.4179 12 1.4179ZM5.40692 14.1219C8.82623 14.1219 8.43768 14.1225 8.49243 14.1208C8.88995 14.7348 9.28912 15.3077 9.66046 15.8175L3.2807 19.0826L5.40692 14.1219ZM2.41003 21.114L6.64892 18.9446L10.296 22.5911H1.77685L2.41003 21.114ZM16.8263 22.5911L21.2404 20.2983L22.2231 22.5911H16.8263Z'
          fill='black'
        />
        <path
          d='M14.1179 6.35815C14.1179 5.19067 13.1679 4.24072 12.0004 4.24072C10.8328 4.24072 9.88281 5.19067 9.88281 6.35815C9.88281 7.52564 10.8328 8.4754 12.0004 8.4754C13.1679 8.4754 14.1179 7.52564 14.1179 6.35815ZM11.2946 6.35815C11.2946 5.96887 11.6111 5.65228 12.0004 5.65228C12.3895 5.65228 12.7063 5.96887 12.7063 6.35815C12.7063 6.74725 12.3895 7.06384 12.0004 7.06384C11.6111 7.06384 11.2946 6.74725 11.2946 6.35815Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_184_935'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0 0.00244141)'
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
