import { useEffect, useState } from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import usePersonalCallSession from '../../hooks/usePersonalCallSession';

const usePersonalCallPeerName = () => {
  const { translate } = useTranslate();
  const [peerUserName, setPeerName] = useState<string>();
  const session = usePersonalCallSession();

  useEffect(() => {
    if (session) {
      setPeerName(session.otherUsers.fullName);
    } else {
      setPeerName(translate(TranslationKeys.unknownUser));
    }
  }, [session]);

  return peerUserName;
};

export default usePersonalCallPeerName;
