import { User } from '.';
import { BaseModel } from './base.model';

export interface Alert extends BaseModel {
  id: number;
  textContent: string;
  resolvedAt?: Date;
  AlertGroupId?: number;
  latitude?: string;
  longitude?: string;
  RaisedBy: number;
  raisedBy: User;
  ResolvedBy?: number;
  resolvedBy: User;
  deletedAt: Date;
  alertMedias: AlertMedia[];
}

export interface AlertMedia extends BaseModel {
  AlertId: number;
  caption: string;
  mediaUrl: string;
  type: AlertMediaType;
}

export enum AlertMediaType {
  image = 'image/png',
  audio = 'audio/webm',
  video = 'video/webm',
}

export const AlertMediaVideoType = {
  videoWebm: 'video/webm', // supported in Chrome, Firefox, brave
  videoMp4: 'video/mp4', //safari
};

export const AlertMediaAudioType = {
  audioWebm: 'audio/webm', // supported in Chrome, Firefox, brave
  audioMp4: 'audio/mp4', //safari
};
