import { Button, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { SOS_ALERT_DATE_FORMAT } from 'src/constants/sos-alert.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import { ALERT_PAGE_ROUTE } from 'src/routes/routeList';

interface LeafMarkerContentProps {
  alert: Alert;
}

export const LeafMarkerContent: React.FC<LeafMarkerContentProps> = ({
  alert,
}) => {
  const { translate } = useTranslate();
  const history = useHistory();

  return (
    <VStack p='4'>
      <Text color='red' fontWeight='bold' fontSize='md'>
        {translate(TranslationKeys.emergencyAlert)}
      </Text>
      {alert.raisedBy ? (
        <Text fontWeight='bold'>
          {alert.raisedBy.firstName} {alert.raisedBy.lastName}
        </Text>
      ) : (
        <Text fontWeight='bold'>{translate(TranslationKeys.anonymous)}</Text>
      )}
      <Text>{moment(alert.createdAt).format(SOS_ALERT_DATE_FORMAT)}</Text>
      <Button
        colorScheme='blue'
        fontSize='xs'
        variant='link'
        onClick={() =>
          history.push(
            ALERT_PAGE_ROUTE.replace(':alertId', alert.id.toString()),
          )
        }
      >
        {`${translate(TranslationKeys.moreDetails)}...`}
      </Button>
    </VStack>
  );
};
