import { Alert, AlertIcon, Box, Button, Center } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { ValueCallback } from 'src/types/common.type';
import { RecipientTypingStatus } from './Typing';

interface MessageThreadStatusProps {
  typing: boolean;
  typingText: string;
  isGroupChat: boolean;
  hasAccepted: boolean | null;
  onAcceptanceChange?: ValueCallback<boolean>;
  hasRecipientBlocked: boolean;
}

export const MessageThreadStatus: React.FC<MessageThreadStatusProps> = ({
  typing,
  typingText,
  isGroupChat,
  onAcceptanceChange,
  hasAccepted,
  hasRecipientBlocked,
}) => {
  const { translate } = useTranslate();
  const onAcceptanceClickHandler = (accepted: boolean) => () =>
    onAcceptanceChange && onAcceptanceChange(accepted);

  // null indicates no action. We ensure user has taken some action before checking for block
  // If user has taken an action (not-null), check if he accepted
  const hasUserBlockedRecipient = hasAccepted !== null && !hasAccepted;

  if (isGroupChat) {
    return <RecipientTypingStatus typingText={typingText} isTyping={typing} />;
  }

  return (
    <Box position='absolute' bottom='0' width='full' px='3'>
      {hasRecipientBlocked && (
        <Alert status='info'>
          <AlertIcon />
          {translate(TranslationKeys.youAreBlocked)}
        </Alert>
      )}
      {!hasRecipientBlocked && (
        <>
          {hasUserBlockedRecipient && (
            <Alert status='info'>
              <AlertIcon />
              {translate(TranslationKeys.youHaveBlocked)}
              <Button
                onClick={onAcceptanceClickHandler(true)}
                colorScheme='blue'
                size='xs'
                variant='ghost'
              >
                {translate(TranslationKeys.unblock)}
              </Button>
            </Alert>
          )}

          {hasAccepted === null && (
            <Center>
              <Button
                onClick={onAcceptanceClickHandler(true)}
                colorScheme='gray'
                variant='ghost'
              >
                {translate(TranslationKeys.accept)}
              </Button>
              <Button
                onClick={onAcceptanceClickHandler(false)}
                colorScheme='red'
                variant='ghost'
              >
                {translate(TranslationKeys.block)}
              </Button>
            </Center>
          )}
          {!hasUserBlockedRecipient && (
            <RecipientTypingStatus typingText={typingText} isTyping={typing} />
          )}
        </>
      )}
    </Box>
  );
};
