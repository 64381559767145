import { useCallback, useEffect, useState } from 'react';
import * as _ from 'lodash';

import { useToast } from 'src/hooks/use-toast';
import { AUDIO_AND_VIDEO_CONSTRAINTS } from 'src/constants/calls.constant';

import { UserMediaContext } from '../user-media.context';

const UserMediaProvider: React.FC = ({ children }) => {
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);

  const { showErrorToast } = useToast();

  const releaseCameraAndMicrophone = useCallback(() => {
    if (localStream) {
      _.forEach(localStream.getTracks(), track => {
        track.stop();
      });
    }
  }, [localStream]);

  const requestUserMedia = async (constraints: MediaStreamConstraints) => {
    try {
      releaseCameraAndMicrophone();

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (!stream) {
        showErrorToast('User media not available');
        return;
      }
      setLocalStream(stream);
    } catch (error) {
      showErrorToast('Error fetching user media');
      console.error('Error fetching user media', error);
    }
  };

  useEffect(() => {
    if (!localStream) requestUserMedia(AUDIO_AND_VIDEO_CONSTRAINTS);

    return () => {
      if (localStream) releaseCameraAndMicrophone();
    };
  }, [localStream]);

  const contextValue = {
    localStream,
  };

  return (
    <UserMediaContext.Provider value={contextValue}>
      {children}
    </UserMediaContext.Provider>
  );
};

export default UserMediaProvider;
