import _ from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { NAVIGATION_ON_CALL_REJECT_DELAY_TIME_MS } from 'src/constants';
import usePersonalCalls from 'src/pages/calls/hooks/usePersonalCalls';

import CallConnectionStatus from '../../call-connection-status/CallConnectionStatus';
import CallDuration from '../../call-duration';
import usePersonalCallSession from '../hooks/usePersonalCallSession';

const PersonalCallStatus: React.FC = () => {
  const history = useHistory();
  const session = usePersonalCallSession();
  const { personalCalls } = usePersonalCalls();
  const personalCall = _.chain(personalCalls)
    .sortBy('createdAt')
    .last()
    .value();

  const hasDisconnected =
    session?.hasMissed || session?.hasRejected || session?.isUnreachable;

  /**
   * use debounce because we need to show call status first and,
   * than navigate back on call disconnect using following:
   * 1) on reject
   * 2) on missed
   * 3) unreachable
   */
  const navigateBackOnCallDisconnect = _.debounce(() => {
    history.goBack();
  }, NAVIGATION_ON_CALL_REJECT_DELAY_TIME_MS);

  useEffect(() => {
    if (hasDisconnected) {
      navigateBackOnCallDisconnect();
    }
    return () => {
      navigateBackOnCallDisconnect.cancel();
    };
  }, [hasDisconnected]);

  if (!session) {
    return null;
  }

  // Session is ongoing. Show the call duration.
  if (session.joinedAt) {
    return <CallDuration callSession={session} />;
  }

  return <CallConnectionStatus call={personalCall!} />;
};

export default PersonalCallStatus;
