import { createIcon } from '@chakra-ui/react';

export const VoiceIcon = createIcon({
  displayName: 'voice icon',
  viewBox: '0 0 45 45',
  defaultProps: {
    width: '45',
    height: '45',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  path: [
    <>
      <circle cx='22.9587' cy='22.8093' r='22' fill='#BE63F9' />
      <g clip-path='url(#clip0_346_1122)'>
        <path
          d='M22.9587 8.8093C20.254 8.8093 18.0536 11.0098 18.0536 13.7144V23.3546C18.0536 26.0589 20.254 28.2594 22.9587 28.2594C25.6633 28.2594 27.8638 26.0742 27.8638 23.3883V13.7144C27.8638 11.0098 25.6633 8.8093 22.9587 8.8093ZM25.8197 23.3881C25.8197 24.9468 24.5361 26.2153 22.9587 26.2153C21.3812 26.2153 20.0974 24.9321 20.0974 23.3543V13.7144C20.0974 12.1366 21.3809 10.8531 22.9587 10.8531C24.5365 10.8531 25.82 12.1366 25.8197 13.7144V23.3881Z'
          fill='#FCFCFC'
        />
        <path
          d='M23.9806 31.1888H21.9368V35.7874H23.9806V31.1888Z'
          fill='#FCFCFC'
        />
        <path
          d='M25.6497 34.7655H20.2677C19.7032 34.7655 19.2458 35.223 19.2458 35.7874C19.2458 36.3518 19.7032 36.8093 20.2677 36.8093H25.6497C26.2141 36.8093 26.6716 36.3518 26.6716 35.7874C26.6716 35.223 26.2141 34.7655 25.6497 34.7655Z'
          fill='#FCFCFC'
        />
        <path
          d='M30.691 21.6511C30.1266 21.6511 29.6692 22.1086 29.6692 22.673V23.4905C29.6692 27.1905 26.6587 30.201 22.9587 30.201C19.2584 30.201 16.2482 27.1905 16.2482 23.4905V22.673C16.2482 22.1086 15.7907 21.6511 15.2263 21.6511C14.6619 21.6511 14.2044 22.1086 14.2044 22.673V23.4905C14.2044 28.3176 18.1316 32.2448 22.9587 32.2448C27.7858 32.2448 31.7129 28.3176 31.7129 23.4905V22.673C31.7129 22.1086 31.2555 21.6511 30.691 21.6511Z'
          fill='#FCFCFC'
        />
      </g>
      <defs>
        <clipPath id='clip0_346_1122'>
          <rect
            width='28'
            height='28'
            fill='white'
            transform='translate(8.95868 8.8093)'
          />
        </clipPath>
      </defs>
    </>,
  ],
});
