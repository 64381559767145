import React, { createContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { getCurrentUser } from 'src/apis/auth.api';
import useAuthStore from 'src/hooks/useAuthStore';
import { User } from 'src/models';

// Define the shape of your user details
interface UserDetailsContextValue {
  currentUser: User | undefined;
  refetchUser: UseQueryResult['refetch'];
  isUserLoading: boolean;
  queryResult: Omit<UseQueryResult, 'data'>;
}

const kGetCurrentUserQueryKey = 'getCurrentUser';

// Create a context with initial values
const UserDetailsContext = createContext<UserDetailsContextValue>(null!);

// Create a provider component
const UserDetailsProvider: React.FC = ({ children }) => {
  const { currentAccessToken } = useAuthStore();
  const { data: user, ...userQueryResult } = useQuery(
    kGetCurrentUserQueryKey,
    getCurrentUser,
    {
      enabled: !!currentAccessToken,
    },
  );

  return (
    <UserDetailsContext.Provider
      value={{
        currentUser: user,
        isUserLoading: userQueryResult.isLoading,
        refetchUser: userQueryResult.refetch,
        queryResult: userQueryResult,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

const useUserDetails = () => {
  const context = React.useContext(UserDetailsContext);
  if (!context) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider');
  }
  return context;
};

export { UserDetailsProvider, useUserDetails };
