import { Icon } from '@chakra-ui/react';
import { FcEndCall } from 'react-icons/fc';
import { MdNotInterested, MdPhoneMissed } from 'react-icons/md';
import { VscCallIncoming, VscCallOutgoing } from 'react-icons/vsc';

import { Call } from 'src/models/Call.model';
import { User } from 'src/models/user.model';
import { CallStatusType } from 'src/types/call.type';

export interface CallIconProps {
  callerId: number;
  currentUser: User | null;
  color: string;
  callStatus: Call['callStatus'];
}

const CallIcons: React.FC<CallIconProps> = ({
  callerId,
  currentUser,
  color,
  callStatus,
}) => {
  if (currentUser) {
    if (callStatus === CallStatusType.connected) {
      return (
        <>
          {callerId === currentUser.id ? (
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color={color}
            />
          ) : (
            <Icon
              as={VscCallIncoming}
              aria-label='user'
              bg='white'
              color={color}
            />
          )}
        </>
      );
    } else if (callStatus === CallStatusType.missed) {
      if (currentUser.id === callerId) {
        return (
          <>
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color='gray.500'
            />
          </>
        );
      }

      return (
        <>
          <Icon as={MdPhoneMissed} aria-label='user' bg='white' color='red' />
        </>
      );
    } else if (callStatus === CallStatusType.rejected) {
      if (currentUser.id === callerId) {
        return (
          <>
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color='gray.500'
            />
          </>
        );
      }
      return (
        <>
          <Icon as={FcEndCall} aria-label='user' bg='white' color='red' />
        </>
      );
    } else if (callStatus === CallStatusType.unreachable) {
      if (currentUser.id === callerId) {
        return (
          <>
            <Icon
              as={MdNotInterested}
              aria-label='user'
              bg='white'
              color='red.500'
            />
          </>
        );
      }
      return (
        <>
          <Icon as={VscCallIncoming} aria-label='user' bg='white' color='red' />
        </>
      );
    } else {
      return (
        <>
          {callerId === currentUser.id ? (
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color={color}
            />
          ) : (
            <Icon
              as={VscCallIncoming}
              aria-label='user'
              bg='white'
              color={color}
            />
          )}
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default CallIcons;
