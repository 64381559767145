import { Box, Center, Input } from '@chakra-ui/react';

import '../css/mic-animation.css';

interface AsrChatVoiceAnimationProps {
  microPhoneRef: React.RefObject<HTMLInputElement>;
}

export const MicrophoneComponent: React.FC<AsrChatVoiceAnimationProps> = ({
  microPhoneRef,
}) => {
  return (
    <Box className='frame'>
      <Input
        ref={microPhoneRef}
        type='checkbox'
        className='checkbox'
        display='none'
      />
      <Center className='microphone'>
        <Box className='circle' />
        <Box className='mic'>
          <Box className='top'>
            <Box className='decoration'></Box>
          </Box>
          <Box className='foot1'></Box>
          <Box className='foot2'></Box>
        </Box>
        <Box className='dots' mx='auto' my='auto'>
          <Box className='dot dot1'></Box>
          <Box className='dot dot2'></Box>
          <Box className='dot dot3'></Box>
        </Box>
      </Center>
    </Box>
  );
};
