import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIsFetching } from 'react-query';
import Loader from 'src/components/loader/Loader';

interface QueryLoaderContextType {
  isQueryLoading: boolean;
  setIsLoaderRequired: Dispatch<SetStateAction<boolean>>;
}

export const QueryLoaderContext = createContext<QueryLoaderContextType>(null!);

export const QueryLoaderProvider: FC = ({ children }) => {
  const isFetching = useIsFetching();
  const [isQueryLoading, setIsQueryLoading] = useState<boolean>(false);
  const [isLoaderRequired, setIsLoaderRequired] = useState<boolean>(true);

  useEffect(() => {
    setIsQueryLoading(!!isFetching);
  }, [isFetching]);

  const value = {
    isQueryLoading,
    setIsLoaderRequired,
  };

  return (
    <QueryLoaderContext.Provider value={value}>
      {children}
      {isQueryLoading && isLoaderRequired && <Loader />}
    </QueryLoaderContext.Provider>
  );
};

export const useQueryLoaderContext = () => {
  const context = useContext(QueryLoaderContext);

  if (!context) {
    throw new Error(
      'useQueryLoaderContext must be used within QueryLoaderProvider',
    );
  }

  return context;
};
