import { Text } from '@chakra-ui/layout';
import { ModalProps } from '@chakra-ui/modal';
import { useMutation } from 'react-query';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { removeFeedMember } from '../../../apis/feedsMembers.api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { FeedMember } from '../../../models/FeedMember.model';
import { getUserDisplayName } from '../../../utils/user.utils';

interface ConfirmRemoveMemberDialogProps extends Omit<ModalProps, 'children'> {
  memberToRemove: FeedMember;
  onMemberRemoved: () => any;
}

const ConfirmRemoveFeedMemberDialog: React.FC<
  ConfirmRemoveMemberDialogProps
> = ({ memberToRemove, onMemberRemoved, ...props }) => {
  const { translate } = useTranslate();
  const { mutate: removeMember, isLoading: isRemovingMember } = useMutation(
    ['removeFeedMember', memberToRemove.id],
    () => removeFeedMember(memberToRemove.FeedId, memberToRemove.id),
    { onSuccess: onMemberRemoved },
  );

  let warningMessage = translate(TranslationKeys.userRemoveFromFeedWarning);

  const memberName =
    memberToRemove.user && getUserDisplayName(memberToRemove.user);

  warningMessage = warningMessage.replace(
    /{.*?}/,
    memberName || translate(TranslationKeys.thisMember),
  );

  const confirmationDescription = <Text>{warningMessage}</Text>;

  return (
    <ConfirmationDialog
      title={translate(TranslationKeys.removeMember)}
      description={confirmationDescription}
      confirmLabel={translate(TranslationKeys.removeMember)}
      confirmButtonProps={{ isLoading: isRemovingMember }}
      onConfirmed={() => removeMember()}
      {...props}
    />
  );
};

export default ConfirmRemoveFeedMemberDialog;
