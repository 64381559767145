import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  getAllCommentOfAuthenticatedPost,
  getAllCommentOfPublicPost,
} from 'src/apis/posts.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

export const usePostComments = () => {
  const { id } = useParams<{ id: string }>();
  const { postId } = useParams<{ postId: string }>();
  const { currentUser } = useUserDetails();

  const { data: publicComments, isLoading: isLoadingPublicComments } = useQuery(
    ['getAllCommentOfPublicPost', postId, id],
    () => getAllCommentOfPublicPost(+id, +postId),
  );

  const {
    data: authenticatedComments,
    isLoading: isLoadingAuthenticatedComments,
  } = useQuery(
    ['getAllAuthenticatedComments', postId, id],
    () => getAllCommentOfAuthenticatedPost(+id, +postId),
    { enabled: !!currentUser },
  );

  const comments = publicComments || authenticatedComments;
  const isLoading = isLoadingPublicComments || isLoadingAuthenticatedComments;

  return { comments, isLoading };
};
