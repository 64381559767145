import { Button, ButtonGroup } from '@chakra-ui/button';
import { Text, VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import * as _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';
import {
  GroupChatFormSchemaDto,
  useGroupChatSchema,
} from 'src/schema/group-chat.schema';
import {
  editGroupMessageThread,
  getGroupMessageThread,
} from 'src/apis/group-message-threads.api';
import {
  EditOrCreateGroupFormDto,
  UpdateGroupMessageThreadDto,
} from 'src/dto/group-messageThreads.dto';
import { GroupMessageThread } from 'src/models/GroupMessageThread.model';
import { CustomModalProps } from 'src/types/common.type';
import GroupMetadataForm from '../../messages/components/new-group-chat-dialog/GroupMetadataForm.step';

interface GroupEditDialogProps {
  messageThreadId: number;
}

const kEditGroupFormId = 'edit-group-dialog-form';

const GroupEditDialog: React.FC<CustomModalProps<GroupEditDialogProps>> = ({
  messageThreadId,
  ...props
}) => {
  const { translate } = useTranslate();
  const editGroupChatFormSchema = useGroupChatSchema();
  const { data: groupMessageThread, isLoading } = useQuery(
    ['getOneGroupChat', messageThreadId],
    () => getGroupMessageThread(messageThreadId),
  );

  const groupMetadata: EditOrCreateGroupFormDto = useMemo(
    () => _.pick(groupMessageThread?.group, ['name', 'description']),
    [groupMessageThread],
  );

  const editGroupForm = useForm<EditOrCreateGroupFormDto>({
    defaultValues: groupMetadata,
    resolver: yupResolver(editGroupChatFormSchema),
  });

  useEffect(() => {
    editGroupForm.reset(groupMetadata);
  }, [groupMetadata]);

  const editGroupMutation = useMutation<
    GroupMessageThread,
    AxiosError,
    UpdateGroupMessageThreadDto
  >(updateData => editGroupMessageThread(messageThreadId, updateData));

  const { showErrorToast, showToast } = useToast();

  const handleOnClose = () => {
    editGroupForm.reset();
    props.onClose();
  };

  const handleEditGroupSubmit = (data: GroupChatFormSchemaDto) => {
    editGroupMutation.mutate(data, {
      onSuccess: () => {
        showToast({
          title: translate(TranslationKeys.groupUpdatedSuccessfully),
          status: 'success',
        });
        handleOnClose();
      },
      onError: showErrorToast,
    });
  };

  const isUpdating = editGroupMutation.isLoading;
  const isLoadingOrUptading = isLoading || isUpdating;

  return (
    <Modal {...props} onClose={handleOnClose} isCentered>
      <ModalOverlay />
      <ModalContent mx='2'>
        <ModalHeader>
          <Text>{translate(TranslationKeys.editGroupInfo)}</Text>
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <VStack>
              <Text color='gray.500'>
                {translate(TranslationKeys.fetchingGroup)}
              </Text>
            </VStack>
          ) : (
            <GroupMetadataForm
              id={kEditGroupFormId}
              onSubmit={handleEditGroupSubmit}
              formProps={editGroupForm}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={handleOnClose}>
              {translate(TranslationKeys.cancel)}
            </Button>
            <Button
              colorScheme='blue'
              disabled={isLoadingOrUptading}
              isLoading={isUpdating}
              loadingText={isUpdating ? 'Updating...' : undefined}
              type='submit'
              form={kEditGroupFormId}
            >
              {translate(TranslationKeys.editGroup)}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GroupEditDialog;
