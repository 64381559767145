import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { getUsersPagination } from 'src/apis/users.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { User } from 'src/models';
import { USER_PROFILE_ROUTE } from 'src/routes/routeList';

const DEBOUNCE_TIME = 500;
const GET_ALL_USERS_QUERY_KEY = 'users';

interface UserSearchBarProps {
  toggleSearch: () => void;
}

export const NavbarUserSearchBar: React.FC<UserSearchBarProps> = ({
  toggleSearch,
}) => {
  const { translate } = useTranslate();
  const history = useHistory();

  const [searchInput, setSearchInput] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data: users } = useQuery<User[]>(
    [GET_ALL_USERS_QUERY_KEY, searchQuery],
    () => getUsersPagination(searchQuery),
  );

  const debouncedSearchTerm = useCallback(
    _.debounce(() => {
      setSearchQuery(searchInput);
    }, DEBOUNCE_TIME),
    [searchInput],
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
  };

  const handleSelect = (user: User | null) => {
    if (user) {
      history.push(USER_PROFILE_ROUTE.replace(':userId', `${user.id}`));
    }
  };

  useEffect(() => {
    debouncedSearchTerm();
    return debouncedSearchTerm.cancel;
  }, [debouncedSearchTerm, searchInput]);

  return (
    <Flex w='full'>
      <Box w='full'>
        <Select
          placeholder={translate(TranslationKeys.searchUsers)}
          components={{
            NoOptionsMessage: () => {
              if (_.isEmpty(searchQuery)) {
                return (
                  <Text p='2' color='gray.500'>
                    {translate(TranslationKeys.startSearchingUsers)}
                  </Text>
                );
              }

              return (
                <Text p='2' color='gray.500'>
                  {translate(TranslationKeys.noUsersFound)}
                </Text>
              );
            },
          }}
          autoFocus
          options={users}
          onInputChange={handleSearch}
          onChange={handleSelect}
          getOptionLabel={u => `${u.firstName} ${u.lastName}`}
          getOptionValue={u => u.email}
        />
      </Box>
      <IconButton aria-label='close-search' onClick={toggleSearch}>
        <MdClose />
      </IconButton>
    </Flex>
  );
};
