import _ from 'lodash';
import axios from 'axios';

import { localStorageKeys } from 'src/constants/local-storage-keys.constant';

const isEnvironmentProduction = _.isEqual(process.env.NODE_ENV, 'production');
export const DEVICE_LOCAL_LACS = 'l-lacs';

export const axiosInstance = axios.create({
  baseURL: isEnvironmentProduction
    ? '/backend/api'
    : 'http://localhost:3011/api',
});

axiosInstance.interceptors.request.use(
  async config => {
    if (!config.headers.Authorization) {
      config.headers.Authorization =
        'Bearer ' + localStorage.getItem(localStorageKeys.accessTokenKey);
      return config;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export const axiosAsrInstance = axios.create({
  baseURL: isEnvironmentProduction ? '/asr-server' : 'http://localhost:5500',
});

export const openStreetMapUrl =
  process.env.REACT_APP_LACS_DEVICE === DEVICE_LOCAL_LACS
    ? '/osm-tile-server/tile/{z}/{x}/{y}.png'
    : 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
