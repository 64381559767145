import { Menu, MenuButton, Icon, MenuList, MenuItem } from '@chakra-ui/react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { PageMembers } from 'src/models/PageMembersPage.model';

import useDialog from '../../../hooks/useDialog';
import ConfirmRemovePageMemberDialog from './ConfirmRemovePageMemberDialog';

interface PageMemberActionsMenuProps {
  pageMember: PageMembers;
  onMemberRemoved: () => any;
}

const PageMemberActionsMenu: React.FC<PageMemberActionsMenuProps> = ({
  pageMember,
  onMemberRemoved,
}) => {
  const { translate } = useTranslate();
  const [
    isConfirmRemoveMemberDialogOpen,
    openConfirmRemoveMemberDialog,
    closeConfirmRemoveMemberDialog,
  ] = useDialog();

  return (
    <>
      <Menu autoSelect={false}>
        <MenuButton>
          <Icon as={IoEllipsisVertical} boxSize='6' />
        </MenuButton>
        <MenuList>
          <MenuItem color='red' onClick={openConfirmRemoveMemberDialog}>
            {translate(TranslationKeys.removeFromPage)}
          </MenuItem>
        </MenuList>
      </Menu>
      <ConfirmRemovePageMemberDialog
        onMemberRemoved={onMemberRemoved}
        memberToRemove={pageMember}
        isOpen={isConfirmRemoveMemberDialogOpen}
        onClose={closeConfirmRemoveMemberDialog}
      />
    </>
  );
};

export default PageMemberActionsMenu;
