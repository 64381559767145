import { Button, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { AppNavigationType } from 'src/types/navigation.type';

import AdditionalDetails from './additionalDetails';
import BasicDetails from './basicDetails';
import EditableForm from './editableForm';

const MyProfilePage = () => {
  const [isEditable, setIsEditable] = useState(false);
  const { translate } = useTranslate();
  const { refetchUser } = useUserDetails();

  useEffect(() => {
    refetchUser();
  }, [isEditable === false]);

  const makeFormEditable = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsEditable(true);
  };

  return (
    <HeaderWrapper
      pageTitle={translate(TranslationKeys.profile)}
      navigationType={AppNavigationType.back}
    >
      <Flex
        w='full'
        alignItems='center'
        flexDir='column'
        justifyContent='center'
        py='2'
        px='2'
      >
        {!isEditable ? (
          <>
            <BasicDetails />
            <AdditionalDetails />
            <Flex gridColumn='1/-1' justifyContent='center' my='6'>
              <Button mx='1' colorScheme='blue' onClick={makeFormEditable}>
                {translate(TranslationKeys.edit)}
              </Button>
            </Flex>
          </>
        ) : (
          <EditableForm setIsEditable={setIsEditable} />
        )}
      </Flex>
    </HeaderWrapper>
  );
};

export default MyProfilePage;
