import { Box } from '@chakra-ui/react';
import { FiArrowDownRight } from 'react-icons/fi';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { ValueCallback } from 'src/types/common.type';

import { ImageConstraints } from '../hooks/useImageResize';

interface ResizableImageWrapperProps extends ImageConstraints {
  setConstraints: ValueCallback<ImageConstraints, void>;
  setFinalImageConstraints: ValueCallback<ImageConstraints, void>;
}

// maxConstraints is the maximum width and height of the resizable image so that it doesn't exceed the screen size
const maxConstraints: { width: number; height: number } = {
  width: 500,
  height: 400,
};

const ResizableImageWrapper: React.FC<ResizableImageWrapperProps> = ({
  children,
  width,
  height,
  setConstraints,
  setFinalImageConstraints,
}) => {
  return (
    <ResizableBox
      width={width}
      height={height}
      maxConstraints={[maxConstraints.width, maxConstraints.height]}
      onResize={(_, { size }) => setConstraints(size)}
      onResizeStop={(_, { size }) => setFinalImageConstraints(size)}
      handle={() => (
        <Box
          cursor='se-resize'
          position='absolute'
          bottom='0'
          right='0'
          bgColor='blackAlpha.600'
          color='white'
        >
          <FiArrowDownRight />
        </Box>
      )}
    >
      {children}
    </ResizableBox>
  );
};

export default ResizableImageWrapper;
