import * as yup from 'yup';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

export const useResetPasswordSchema = () => {
  const { translate } = useTranslate();

  const resetPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterPassword)),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        translate(TranslationKeys.passwordMismatch),
      )
      .required(translate(TranslationKeys.pleaseEnterConfirmPassword)),
  });

  return resetPasswordSchema;
};
export type ResetPasswordDto = yup.InferType<
  ReturnType<typeof useResetPasswordSchema>
>;
