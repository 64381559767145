import { Avatar, AvatarProps } from '@chakra-ui/avatar';
import { User } from '../models';
import { getUserDisplayName } from '../utils/user.utils';

interface UserAvatarProps extends Omit<AvatarProps, 'name'> {
  user?: User;
  avatarUrl?: string;
  name?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  avatarUrl,
  name,
  ...props
}) => {
  const getBgProps = (): Partial<Pick<AvatarProps, 'bg'>> => {
    if (!avatarUrl || !user?.avatarUrl) {
      return {};
    }

    return { bg: 'unset' };
  };

  return (
    <Avatar
      src={avatarUrl || user?.avatarUrl}
      name={name || (user && getUserDisplayName(user))}
      {...getBgProps()}
      {...props}
    />
  );
};

export default UserAvatar;
