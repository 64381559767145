import { Box, Center } from '@chakra-ui/react';
import _ from 'lodash';

import { CALL_PAGE_HEIGHT } from 'src/constants/group-call.constant';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

import { usePeerConnection } from '../../context/usePeerConnection';
import useCall from '../../hooks/useCall';
import { CallMicStatus } from './components/CallMicStatus';
import CallUserImage from './components/CallUserImage';
import EndCallButton from './components/EndCallButton';
import PeerVideo from './components/PeerVideo';

interface CallPageBodyProps {}

const CallPageBody: React.FC<CallPageBodyProps> = () => {
  const { call } = useCall();

  const { callSessions } = useCallSessionsContext();
  const session = _.head(callSessions);
  const otherUserId = session?.otherUsers.id;

  const {
    peerStreams,
    peerVideoStatus,
    peerAudioStatus: peerMicStatus,
  } = usePeerConnection();

  const isGroupCall = call?.isGroupCall;
  const videoStream = peerStreams[otherUserId!];
  const isPeerVideoEnabled = peerVideoStatus[otherUserId!];
  const isPeerMicEnabled = peerMicStatus[otherUserId!];

  return (
    <Box h={CALL_PAGE_HEIGHT} w='full' position='relative'>
      {videoStream ? (
        <PeerVideo
          stream={videoStream}
          name={session?.otherUsers.fullName!}
          peerVideoStatus={isPeerVideoEnabled}
          session={session}
        />
      ) : (
        <CallUserImage
          key={session?.otherUsers.id}
          name={session?.otherUsers.fullName!}
          hasJoined={!!session?.joinedAt}
        />
      )}
      <CallMicStatus
        isPeerMicEnabled={isPeerMicEnabled}
        name={session?.otherUsers.fullName!}
      />
      {!isGroupCall && (
        <Center position='absolute' w='full' bottom='4rem'>
          <EndCallButton />
        </Center>
      )}
    </Box>
  );
};

export default CallPageBody;
