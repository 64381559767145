import _ from 'lodash';

import { axiosInstance } from '../config';
import {
  MultipleMessageStatusUpdate,
  MultipleMessageStatusUpdateDto,
} from '../dto/messages.dto';
import { MessageThread } from '../models/MessageThread.model';
import { getRequestData } from './utils/get-request-data.util';

export const kMessageThreadsUrl = '/message-threads';

export const getOneMessageThread = (id: number) => {
  const url = `${kMessageThreadsUrl}/${id}`;

  return getRequestData<MessageThread>(axiosInstance.get(url));
};

export const updateMessageThreadAcceptance = async (
  id: number,
  hasAccepted: boolean,
  latitude?: string,
  longitude?: string,
): Promise<MessageThread> => {
  const url = `${kMessageThreadsUrl}/${id}/acceptance`;
  return getRequestData<MessageThread>(
    axiosInstance.patch(url, { hasAccepted, latitude, longitude }),
  );
};

const updateAllMessagesStatus = (updates: MultipleMessageStatusUpdateDto) => {
  const url = `${kMessageThreadsUrl}/message-status`;

  return getRequestData(axiosInstance.patch(url, updates));
};

export const markAllMessagesSeenForOneThread = (messageThreadId: number) => {
  return updateAllMessagesStatus({
    updates: [{ messageThreadId, status: 'seen' }],
  });
};

export const markAllMessagesDeliveredForMultipleThreads = (
  messageThreadIds: number[],
) => {
  const updates = _.map<number, MultipleMessageStatusUpdate>(
    messageThreadIds,
    messageThreadId => ({ messageThreadId, status: 'delivered' }),
  );

  return updateAllMessagesStatus({ updates });
};
