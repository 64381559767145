import { VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
  AlertInformation,
  AlertMediaInformation,
  AlertResolvedButton,
  AudioAlertMedia,
  ImageAlertMedia,
  TextAlertMedia,
  VideoAlertMedia,
} from 'src/components';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useExistingAlert } from 'src/hooks/useExistingAlert';
import { useTranslate } from 'src/hooks/useTranslate';
import useAlert from 'src/pages/alerts/hooks/useAlert';
import { AppNavigationType } from 'src/types/navigation.type';
import { AlertMapSection } from './AlertMapSection';
import { AlertResolvedBanner } from './AlertResolvedBanner';
import { AlertResolvedBy } from './AlertResolvedBy';

interface AlertPageUrlParams {
  ['alertId']: string;
}

export const AlertPage = () => {
  const { translate } = useTranslate();
  const alertParams = useParams<AlertPageUrlParams>();

  const alertId = +alertParams.alertId;

  const { alert, refetch } = useAlert(alertId);

  const { liveAlert, liveAlertMedia } = useExistingAlert(alertId);

  if (!alert) {
    return null;
  }

  const alertMedia = liveAlertMedia ?? alert.alertMedias;
  const isResolved = (liveAlert?.ResolvedBy || alert.ResolvedBy) !== null;

  return (
    <HeaderWrapper
      pageTitle={translate(TranslationKeys.alertDetails)}
      navigationType={AppNavigationType.back}
    >
      {isResolved && <AlertResolvedBanner />}
      <VStack py='6' width='full' spacing='6' px='2'>
        <AlertInformation alert={alert} />
        {isResolved && <AlertResolvedBy alert={liveAlert ?? alert} />}
        <AlertMediaInformation title={translate(TranslationKeys.location)}>
          <AlertMapSection alert={alert} />
        </AlertMediaInformation>
        <TextAlertMedia
          alertContent={liveAlert?.textContent ?? alert.textContent}
        />
        <ImageAlertMedia alertMedia={alertMedia} />
        <AudioAlertMedia alertMedia={alertMedia} />
        <VideoAlertMedia alertMedia={alertMedia} />
        <AlertResolvedButton
          alert={liveAlert ?? alert}
          refetchAlert={refetch}
        />
      </VStack>
    </HeaderWrapper>
  );
};
