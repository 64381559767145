import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { QueryKey, useQuery } from 'react-query';

import { getAllSessionsForCall } from 'src/apis/call-sessions.api';
import { CallSession } from 'src/models/CallSession.model';
import useCallParams from 'src/pages/peer-call/hooks/useCallParams';
import { CallSessionsContextType } from 'src/types/call-session.type';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const initialCallSessionContextValue: CallSessionsContextType = {
  callSessions: [],
  refetch: () => {},
  addOne: () => {},
  addMultiple: () => {},
  removeOne: () => {},
  updateOne: () => {},
  updateMultiple: () => {},
  updatePartial: () => {},
  updateMultiplePartial: () => {},
};

const CallSessionsContext = createContext<CallSessionsContextType>(
  initialCallSessionContextValue,
);

const getCallQueryKey = (callId: CallSession['CallId']): QueryKey => [
  'getAllCallSessionsForCall',
  callId,
];

export const CallSessionsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { callId } = useCallParams();
  const { data, refetch } = useQuery(
    getCallQueryKey(callId),
    () => getAllSessionsForCall(callId),
    { enabled: !!callId },
  );
  const callSessionUpdateMethods = useArrayDataQuery<CallSession>({
    queryKey: getCallQueryKey(callId),
  });

  const callSessions = useMemo(() => data || [], [data]);

  const contextValue = {
    callSessions,
    refetch,
    ...callSessionUpdateMethods,
  };

  return (
    <CallSessionsContext.Provider value={contextValue}>
      {children}
    </CallSessionsContext.Provider>
  );
};

export const useCallSessionsContext = (): CallSessionsContextType => {
  const context = useContext(CallSessionsContext);
  if (!context) {
    throw new Error(
      'useCallSessions must be used within a CallSessionsProvider',
    );
  }
  return context;
};
