import { useEffect, useRef } from 'react';
import { TextAudio } from 'src/apis/socket.io/messages.socket.io';

const TEXT_AUDIO_PLAY_DELAY = 100;
const TEXT_AUDIO_CURRENT_TIME = 0;

export const useMessageAudio = (
  textAudio: TextAudio | undefined,
  setTextAudio: (value: React.SetStateAction<TextAudio | undefined>) => void,
) => {
  const playingAudioQueue = useRef<string[]>([]);
  const audio = useRef(new Audio());

  useEffect(() => {
    const handleAudioEnded = () => {
      if (playingAudioQueue.current.length) {
        audio.current.pause();

        setTimeout(() => {
          audio.current.currentTime = TEXT_AUDIO_CURRENT_TIME;
          audio.current.src = playingAudioQueue.current.shift() as string;
          audio.current.play();
        }, TEXT_AUDIO_PLAY_DELAY);

        return;
      }
      audio.current.src = '';
      audio.current.removeAttribute('src');
    };

    audio.current.addEventListener('ended', handleAudioEnded);

    return () => {
      audio.current.removeEventListener('ended', handleAudioEnded);
    };
  }, []);

  useEffect(() => {
    if (textAudio) {
      const blob = new Blob([textAudio.audio], { type: 'audio/wav' });
      const blobUrl = URL.createObjectURL(blob);

      if (blobUrl) {
        playingAudioQueue.current.push(blobUrl);
      }
      if (!audio.current.src || audio.current.src === '') {
        audio.current.src = playingAudioQueue.current.shift() as string;
        audio.current.play();
      }

      setTextAudio(undefined);
    }
  }, [textAudio, setTextAudio]);

  return { audio: audio.current, playingAudioQueue: playingAudioQueue.current };
};
