import _ from 'lodash';
import { useRef } from 'react';
import { useMutation } from 'react-query';

import { addFeedPostViews } from 'src/apis/views.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import useIntersectionObserver from 'src/hooks/useIntersectionObserver';
import { FeedPostView } from 'src/models/FeedPostView.model';
import { Post } from 'src/models/Post.model';
import { EntityType } from 'src/models/View.model';

const FEED_POST_SESSION_KEY = 'feedPosts';
export const useAddViewIntersection = (post: Post) => {
  const { currentUser } = useUserDetails();
  const horizontalLine = useRef<HTMLDivElement>(null);
  const addPostViewMutation = useMutation((postView: FeedPostView) =>
    addFeedPostViews(postView),
  );

  const addView = (post: Post) => {
    if (post) {
      const feedPostsStoredInSession = sessionStorage.getItem(
        FEED_POST_SESSION_KEY,
      );
      let isFeedPostAlreadyExist = false;
      if (feedPostsStoredInSession) {
        const parsedList = JSON.parse(feedPostsStoredInSession);
        if (parsedList.length) {
          isFeedPostAlreadyExist = _.includes(parsedList, post.id);
        }
      }
      if (isFeedPostAlreadyExist) {
        return;
      }

      addPostViewMutation.mutate(
        {
          entityId: post.id,
          entityType: EntityType.post,
          UserId: currentUser?.id,
        },
        {
          onSuccess: () => {
            // store data in session
            // here if the data is already stored in session so we do not store that data multiple time
            const feedPostsStoredInSession = sessionStorage.getItem(
              FEED_POST_SESSION_KEY,
            );
            let updatedList: string;
            if (feedPostsStoredInSession) {
              const parsedList = JSON.parse(feedPostsStoredInSession);
              if (parsedList.length) {
                updatedList = JSON.stringify([...parsedList, post.id]);
              } else {
                updatedList = JSON.stringify([post.id]);
              }
            } else {
              updatedList = JSON.stringify([post.id]);
            }

            sessionStorage.setItem(FEED_POST_SESSION_KEY, updatedList);
          },
        },
      );
    }
  };

  useIntersectionObserver({
    target: horizontalLine,
    onIntersect: () => addView(post),
    enabled: true,
  });

  return { horizontalLine };
};
