import { useEffect } from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useQueryLoaderContext } from 'src/hoc';
import { useTranslate } from 'src/hooks/useTranslate';

import HeaderWrapper from '../hoc/HeaderWrapper';
import HomePagePresentation from './home/HomePage';

const HomePage = () => {
  const { translate } = useTranslate();
  const { setIsLoaderRequired } = useQueryLoaderContext();

  useEffect(() => {
    setIsLoaderRequired(false);

    return () => setIsLoaderRequired(true);
  }, []);

  return (
    <HeaderWrapper
      pageTitle={translate(TranslationKeys.home)}
      bodyContainerProps={{ pb: '6', px: '4' }}
    >
      <HomePagePresentation />
    </HeaderWrapper>
  );
};

export default HomePage;
