import { CustomTabs } from 'src/components';
import { MESSAGE_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import useUnreadGroupChatsCount from 'src/hooks/useUnreadGroupChatsCount';
import useUnreadPersonalChatsCount from 'src/hooks/useUnreadPersonalChatsCount';

import { AsrMessagePanel } from './panels/AsrMessagePanel/AsrMessagePanel';
import GroupMessagesPanel from './panels/GroupMessagePanel';
import PersonalMessagesPanel from './panels/PersonalMessagesPanel';

const MessagesPage = () => {
  const { translate } = useTranslate();
  const unreadPersonalThreadsCount = useUnreadPersonalChatsCount();
  const unreadGroupThreadsCount = useUnreadGroupChatsCount();

  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.messages)}>
      <CustomTabs
        firstTabPanel={<PersonalMessagesPanel />}
        secondTabPanel={<GroupMessagesPanel />}
        tabIndexKey={MESSAGE_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY}
        thirdTabPanel={<AsrMessagePanel />}
        thirdTabTitle={translate(TranslationKeys.asr)}
        unreadGroupCount={unreadGroupThreadsCount}
        unreadPersonalCount={unreadPersonalThreadsCount}
      />
    </HeaderWrapper>
  );
};

export default MessagesPage;
