import { axiosInstance } from 'src/config';
import { AsrMessageThread } from 'src/models/AsrMessageThread.model';

import { kMessageThreadsUrl } from './message-threads.api';
import { getRequestData } from './utils/get-request-data.util';

const kAsrMessageThreadsUrl = `${kMessageThreadsUrl}/asr-chat`;
const kStartAsrChatUrl = (messageThreadId: number) =>
  `speech-recognition/start/asr-chat/${messageThreadId}`;

export const getAllAsrChatMessageThreads = (): Promise<AsrMessageThread> => {
  return getRequestData<AsrMessageThread>(
    axiosInstance.get(kAsrMessageThreadsUrl),
  );
};

export const startNewAsrChat = (messageThreadId: number) => {
  return axiosInstance.post(kStartAsrChatUrl(messageThreadId));
};
