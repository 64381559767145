import { QueryKey, useQuery } from 'react-query';
import { getIndividualComments } from 'src/apis/comment.api';
import { Comment } from 'src/models/Comment.model';
import { EntityType } from 'src/models/View.model';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const getIndividualCommentId = (
  entityId: Comment['EntityId'],
  entityType: Comment['entityType'],
): QueryKey => ['getIndividualComments', entityId, entityType];

const useComments = (entityId: number, entityType: EntityType) => {
  const { data, refetch } = useQuery(
    getIndividualCommentId(entityId, entityType),
    () => getIndividualComments(entityId, entityType),
  );

  const { addOne, addMultiple, updateOne, removeOne } =
    useArrayDataQuery<Comment>({
      queryKey: getIndividualCommentId(entityId, entityType as EntityType),
    });

  return {
    data,
    refetch,
    addComment: addOne,
    addMultipleComments: addMultiple,
    updateComment: updateOne,
    removeComment: removeOne,
  };
};
export default useComments;
