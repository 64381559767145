import { Button, useToast } from '@chakra-ui/react';
import { useMutation, UseQueryResult } from 'react-query';
import { resolveAlert } from 'src/apis/alerts.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';

interface AlertResolvedButtonProps {
  alert: Alert;
  refetchAlert: UseQueryResult['refetch'];
}
export const AlertResolvedButton: React.FC<AlertResolvedButtonProps> = ({
  alert,
  refetchAlert,
}) => {
  const { translate } = useTranslate();
  const showToast = useToast();
  const updateSoSMediaMutation = useMutation((alertId: number) =>
    resolveAlert(alertId),
  );

  const handleOnBtnClick = () => {
    if (alert) {
      updateSoSMediaMutation.mutate(alert.id, {
        onSuccess: () => {
          refetchAlert();
          showToast({
            title: translate(TranslationKeys.alertIsResolved),
            status: 'success',
          });
        },
      });
    }
  };

  if (alert.ResolvedBy) {
    return null;
  }

  return (
    <Button
      colorScheme='blue'
      px='20'
      py='6'
      fontWeight='extrabold'
      fontSize='lg'
      onClick={() => handleOnBtnClick()}
    >
      {translate(TranslationKeys.markAsResolved)}
    </Button>
  );
};
