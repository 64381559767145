import { Icon, IconButton } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';

interface UserSearchIconProps {
  toggleSearch: () => void;
  userSearchAllowed: boolean;
}

export const UserSearchIcon: React.FC<UserSearchIconProps> = ({
  toggleSearch,
  userSearchAllowed,
}) => {
  const { currentUser } = useUserDetails();

  if (!currentUser || !userSearchAllowed) {
    return null;
  }

  return (
    <IconButton
      icon={<Icon as={MdSearch} boxSize='6' />}
      aria-label='search'
      variant='ghost'
      _focus={{ boxShadow: 'none' }}
      _hover={{ background: 'none' }}
      onClick={toggleSearch}
      size='sm'
      pr='6px'
    />
  );
};
