import { useEffect, useState } from 'react';
import { Flex, Menu, MenuButton, Image as ChakraImage } from '@chakra-ui/react';
import { PlateRenderElementProps } from '@udecode/plate';
import { ImageElement } from 'slate';
import { useReadOnly, useSelected } from 'slate-react';

import useImageResize from './hooks/useImageResize';
import ImageBubbleToolbarList from './components/ImageBubbleToolbarList';
import ResizableImageWrapper from './components/ResizableImageWrapper';
import { getDocumentUrl } from 'src/apis/document.api';

const Image = ({
  attributes,
  children,
  element,
  editor,
}: PlateRenderElementProps<ImageElement>) => {
  const selected = useSelected();
  const isReadonly = useReadOnly();
  const { width, height, setConstraints, setFinalImageConstraints } =
    useImageResize({
      element,
      editor,
    });
  const [isMenuOpen, setMenuOpen] = useState(selected);
  useEffect(() => {
    setMenuOpen(selected);
  }, [selected]);

  const renderImage = () => {
    const img = (
      <ChakraImage
        h={`${height}px`}
        w={`${width}px`}
        src={getDocumentUrl(element.url)}
      />
    );

    if (isReadonly) {
      return img;
    }

    return (
      <ResizableImageWrapper
        width={width}
        height={height}
        setConstraints={setConstraints}
        setFinalImageConstraints={setFinalImageConstraints}
      >
        <MenuButton
          borderWidth={selected ? 'medium' : '0'}
          borderColor='blue.500'
          h='max-content'
          w='max-content'
        >
          {img}
        </MenuButton>
      </ResizableImageWrapper>
    );
  };

  return (
    <Flex
      {...attributes}
      position='relative'
      w='full'
      my='1'
      justifyContent={element.align}
      contentEditable={false}
    >
      <Menu
        placement='top'
        isOpen={!isReadonly && isMenuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        closeOnSelect={false}
        matchWidth
      >
        {renderImage()}
        <ImageBubbleToolbarList element={element} editor={editor} />
      </Menu>
      {children}
    </Flex>
  );
};

export default Image;
