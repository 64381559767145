import { createIcon } from '@chakra-ui/react';

export const TextToSpeechFillIcon = createIcon({
  displayName: 'Text to speech icon',
  viewBox: '0 0 512 512',
  defaultProps: {
    width: '512',
    height: '512',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  path: [
    <>
      <path
        d='M255.999 21.3335C214.591 21.315 173.916 32.2584 138.107 53.0519C102.299 73.8453 72.6328 103.748 52.1242 139.72C31.6155 175.693 20.9951 216.453 21.3422 257.86C21.6894 299.266 32.9916 339.843 54.1005 375.467L22.6338 462.038C21.4614 465.259 21.0826 468.716 21.5295 472.115C21.9764 475.514 23.236 478.755 25.2014 481.564C27.1668 484.373 29.7802 486.667 32.8203 488.251C35.8604 489.836 39.2376 490.664 42.6658 490.667C45.1532 490.661 47.621 490.228 49.9618 489.387L136.533 457.899C167.602 476.304 202.5 487.291 238.51 490.004C274.52 492.716 310.67 487.082 344.146 473.539C377.622 459.995 407.521 438.909 431.515 411.921C455.509 384.933 472.951 352.771 482.482 317.94C492.014 283.108 493.378 246.547 486.469 211.102C479.56 175.658 464.564 142.286 442.648 113.584C420.732 84.8829 392.488 61.6267 360.114 45.6261C327.741 29.6255 292.111 21.3122 255.999 21.3335Z'
        fill='black'
      />
      <path
        d='M322.78 333.281C320.082 333.281 317.383 332.241 315.336 330.183C311.241 326.067 311.262 319.42 315.378 315.325C331.253 299.554 340 278.491 340 256C340 233.509 331.253 212.446 315.378 196.675C311.262 192.58 311.241 185.944 315.336 181.817C319.42 177.701 326.056 177.68 330.182 181.775C350.059 201.526 361 227.881 361 256C361 284.119 350.059 310.474 330.182 330.225C328.135 332.262 325.458 333.281 322.78 333.281Z'
        fill='white'
      />
      <path
        d='M293.065 303.565C290.356 303.565 287.647 302.526 285.6 300.447C281.515 296.32 281.557 289.674 285.684 285.6C293.506 277.861 298 267.067 298 256C298 244.933 293.506 234.139 285.684 226.401C281.557 222.327 281.526 215.68 285.6 211.554C289.674 207.438 296.32 207.396 300.447 211.47C312.238 223.125 319 239.358 319 256C319 272.643 312.238 288.876 300.447 300.531C298.399 302.557 295.732 303.565 293.065 303.565Z'
        fill='white'
      />
      <path
        d='M256.001 361.001C253.271 361.001 250.583 359.93 248.577 357.924L199.153 308.5H172C160.419 308.5 151 299.082 151 287.5V224.5C151 212.919 160.419 203.5 172 203.5H199.153L248.577 154.076C251.58 151.063 256.095 150.17 260.022 151.798C263.949 153.425 266.501 157.258 266.501 161.5V350.501C266.501 354.743 263.949 358.575 260.022 360.203C258.72 360.738 257.355 361.001 256.001 361.001ZM172 224.5V287.5H203.5C206.293 287.5 208.96 288.603 210.924 290.577L245.501 325.153V186.847L210.924 221.424C208.96 223.398 206.293 224.5 203.5 224.5H172Z'
        fill='white'
      />
    </>,
  ],
});
