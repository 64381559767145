import * as yup from 'yup';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

export const useLoginSchema = () => {
  const { translate } = useTranslate();

  const loginFormSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterEmail))
      .email(translate(TranslationKeys.pleaseEnterValidEmail)),
    password: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterPassword)),
  });

  return loginFormSchema;
};

export type LoginDto = yup.InferType<ReturnType<typeof useLoginSchema>>;
