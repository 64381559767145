import {
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  UseToastOptions,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import {
  makeUserGroupAdmin,
  removeUserAsGroupAdmin,
} from '../../../apis/group-message-threads.api';
import useDialog from '../../../hooks/useDialog';
import { MessageThreadMember } from '../../../models/MessageThreadMember.model';
import { USER_PROFILE_ROUTE } from '../../../routes/routeList';
import { ValueCallback } from '../../../types/common.type';
import ConfirmRemoveMemberDialog from './ConfirmRemoveMemberDialog';
import LoadingMenuItem from './LoadingMenuItem';

interface MemberActionsMenuProps {
  currentUserMemberRecord: MessageThreadMember;
  member: MessageThreadMember;
  onMemberRemoved: () => any;
  onMemberUpdated: ValueCallback<MessageThreadMember>;
}

const MemberActionsMenu: React.FC<MemberActionsMenuProps> = ({
  currentUserMemberRecord,
  member,
  onMemberRemoved,
  onMemberUpdated,
}) => {
  const { translate } = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const [
    isConfirmRemoveMemberDialogOpen,
    openConfirmRemoveMemberDialog,
    closeConfirmRemoveMemberDialog,
  ] = useDialog();
  const showToast = useToast();

  const handleUpdateMemberSuccess = (updatedMember: MessageThreadMember) => {
    onMemberUpdated(updatedMember);
    setIsOpen(false);
  };

  const showErrorToast = (toast: Omit<UseToastOptions, 'status'>) => {
    showToast({
      status: 'error',
      ...toast,
    });
  };

  const { mutate: makeAdmin, isLoading: isMakingAdmin } = useMutation(
    ['makeGroupAdmin', member.id],
    () => makeUserGroupAdmin(member.MessageThreadId, member.UserId),
    {
      onSuccess: handleUpdateMemberSuccess,
      onError: () =>
        showErrorToast({
          title: translate(TranslationKeys.failedMakingAdmin),
          description: translate(TranslationKeys.pleaseTryAgain),
        }),
    },
  );

  const { mutate: removeAdmin, isLoading: isRemovingAdmin } = useMutation(
    ['removeGroupAdmin', member.id],
    () => removeUserAsGroupAdmin(member.MessageThreadId, member.UserId),
    {
      onSuccess: handleUpdateMemberSuccess,
      onError: () =>
        showErrorToast({
          title: translate(TranslationKeys.failedRemovingAdmin),
          description: translate(TranslationKeys.pleaseTryAgain),
        }),
    },
  );

  const isMemberAdmin = member.isAdmin;
  const memberProfileUrl = USER_PROFILE_ROUTE.replace(
    ':userId',
    member.UserId.toString(),
  );

  const getAdminFunctions = () => {
    return (
      <>
        {!isMemberAdmin && (
          <LoadingMenuItem
            isLoading={isMakingAdmin}
            onClick={() => makeAdmin()}
          >
            <Text>{translate(TranslationKeys.makeAdmin)}</Text>
          </LoadingMenuItem>
        )}

        <MenuDivider />
        {isMemberAdmin && (
          <LoadingMenuItem
            isLoading={isRemovingAdmin}
            closeOnSelect={false}
            color='red'
            onClick={() => removeAdmin()}
          >
            <Text>{translate(TranslationKeys.removeAdmin)}</Text>
          </LoadingMenuItem>
        )}
        <MenuItem color='red' onClick={openConfirmRemoveMemberDialog}>
          {translate(TranslationKeys.removeFromGroup)}
        </MenuItem>
      </>
    );
  };

  return (
    <>
      <Menu
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        autoSelect={false}
      >
        <MenuButton>
          <Icon as={IoEllipsisVertical} boxSize='6' />
        </MenuButton>
        <MenuList>
          <MenuItem as={Link} to={memberProfileUrl}>
            {translate(TranslationKeys.viewProfile)}
          </MenuItem>
          {currentUserMemberRecord.isAdmin && getAdminFunctions()}
        </MenuList>
      </Menu>
      <ConfirmRemoveMemberDialog
        onMemberRemoved={onMemberRemoved}
        memberToRemove={member}
        isOpen={isConfirmRemoveMemberDialogOpen}
        onClose={closeConfirmRemoveMemberDialog}
      />
    </>
  );
};

export default MemberActionsMenu;
