import { Box, Icon, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'src/hooks/useTranslate';
import { NavigationLinksType } from '../constants/navigation-link.constant';

interface NavigationLinkComponentProps extends NavigationLinksType {
  onClick?: () => void;
}

export const NavigationLinkComponent: React.FC<
  NavigationLinkComponentProps
> = ({ color, hoverColor, icon, iconBgColor, label, path, onClick }) => {
  const { push } = useHistory();
  const { translate } = useTranslate();

  const navigate = () => {
    if (path) {
      push(path);
    } else {
      onClick?.();
    }
  };

  return (
    <VStack
      bg={color}
      _hover={{
        bgColor: hoverColor,
      }}
      shadow='md'
      rounded='md'
      cursor='pointer'
      py={{ base: '3', sm: '4' }}
      key={label}
      onClick={navigate}
    >
      <Box rounded='full' p={{ base: '2', sm: '3' }} bg={iconBgColor}>
        <Icon as={icon} color='white' boxSize={{ base: '25', sm: '30' }} />
      </Box>
      <Text
        fontWeight='semibold'
        fontSize={{ base: 'md', sm: 'xl', md: '2xl' }}
        color='white'
        textAlign='center'
        lineHeight='none'
      >
        {translate(label)}
      </Text>
    </VStack>
  );
};
