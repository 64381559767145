import { Icon, IconButton } from '@chakra-ui/react';
import { useCallback } from 'react';
import { MdCallEnd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { useMutation } from 'react-query';

import { leaveMultipleCallSessions } from 'src/apis/call-sessions.api';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

interface EndCallButtonProps {}

const EndGroupCallButton: React.FC<EndCallButtonProps> = () => {
  const history = useHistory();
  const { callSessions } = useCallSessionsContext();
  const leaveAllCallSessions = useMutation(leaveMultipleCallSessions);

  const leaveCall = useCallback(() => {
    const callSessionIds = _.map(callSessions, 'id');
    leaveAllCallSessions.mutate(callSessionIds);
  }, [callSessions, history]);

  return (
    <IconButton
      aria-label='End Group Call'
      colorScheme='red'
      isRound
      size='lg'
      icon={<Icon as={MdCallEnd} fontSize='4xl' />}
      onClick={leaveCall}
    />
  );
};

export default EndGroupCallButton;
