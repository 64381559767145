import { HStack, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';

import Loader from 'src/components/loader/Loader';
import { usePageDraftsForUser } from 'src/pages/blogs/blog/hooks/usePageDraftsForUser';

import { Editor } from '../editor/Editor';
import { PageDraftStatusBadge } from '../PageRejectStatusBadge';
import { DraftReview } from './PageDraftReview';

const DRAFT_CREATED_AT_FORMAT = 'lll';

export const DraftPageContentStatus: React.FC = () => {
  const { pageDrafts, isLoading } = usePageDraftsForUser();

  if (isLoading) {
    return <Loader />;
  }

  if (_.isEmpty(pageDrafts)) {
    return <Text textAlign='center'>No edit content request raise yet</Text>;
  }

  return (
    <VStack w='full' align='start'>
      {_.map(pageDrafts, draft => (
        <VStack
          w='full'
          p='4'
          rounded='md'
          border='1px solid'
          borderColor='gray.300'
          align='start'
          key={draft.id}
        >
          <HStack w='full' justifyContent='space-between'>
            <Text fontWeight='semibold' noOfLines={1}>
              {moment(draft?.createdAt).format(DRAFT_CREATED_AT_FORMAT)}
            </Text>
            {draft?.status && <PageDraftStatusBadge status={draft.status} />}
          </HStack>
          <Editor
            isReadonly
            editorKeyId={draft.id}
            w='full'
            value={JSON.parse(draft?.draftContent)}
            contentPadding='0'
          />
          <DraftReview
            reviewRemarks={draft?.reviewRemarks}
            reviewedBy={draft?.reviewedBy}
          />
        </VStack>
      ))}
    </VStack>
  );
};

export default DraftPageContentStatus;
