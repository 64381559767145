type LocalStorageKeys =
  | 'accessTokenKey'
  | 'unreadGroupChatsStoreKey'
  | 'languageLocalStorageKey';

type LocalStorageKeyRecord = Record<LocalStorageKeys, string>;

export const localStorageKeys: LocalStorageKeyRecord = {
  languageLocalStorageKey: 'transcribe-language',
  accessTokenKey: '--lacs-access-token',
  unreadGroupChatsStoreKey: '--lacs-unread-group-chats',
};
