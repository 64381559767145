import _ from 'lodash';
import { useEffect } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { MdVolumeUp, MdVolumeOff } from 'react-icons/md';

import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

import FooterIconButton from './FooterIconButton';

interface CallPageSpeakerButtonProps {
  isSpeakerDisabled: boolean;
}

const CallPageSpeakerButton: React.FC<CallPageSpeakerButtonProps> = ({
  isSpeakerDisabled,
}) => {
  const [isSpeakerOn, { toggle: toggleSpeaker }] = useBoolean(true);
  const { unmutePeerStream, mutePeerStream } = usePeerConnection();
  const { callSessions } = useCallSessionsContext();

  useEffect(() => {
    if (!callSessions) {
      return;
    }

    _.map(callSessions, callSession => {
      if (isSpeakerOn) unmutePeerStream(callSession.otherUsers?.id);
      else mutePeerStream(callSession.otherUsers?.id);
    });
  }, [isSpeakerOn, callSessions, mutePeerStream, unmutePeerStream]);

  return (
    <FooterIconButton
      activeIcon={MdVolumeUp}
      inactiveIcon={MdVolumeOff}
      onToggleClick={toggleSpeaker}
      isActive={isSpeakerOn}
      aria-label='Toggle volume'
      disabled={isSpeakerDisabled}
    />
  );
};

export default CallPageSpeakerButton;
