import { Center, Flex, Icon, IconButton, Link, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { addTextToSpeechEnabledState } from 'src/apis/messages.api';
import {
  subscribeOnSpeech,
  TextAudio,
  TextAudioPayload,
  unsubscribeOnSpeech,
} from 'src/apis/socket.io/messages.socket.io';
import { TextToSpeechFillIcon, TextToSpeechIcon } from 'src/assets';
import { WalkiesTalkieIcon } from 'src/assets/icons';
import { NAVBAR_HEIGHT } from 'src/constants';
import { maxWidth } from 'src/constants/max-width.constant';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { GroupMessageThread } from 'src/models/GroupMessageThread.model';
import { MessageType } from 'src/models/Message.model';
import { MessageThread } from 'src/models/MessageThread.model';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';
import { useStartNewAsrChat } from 'src/pages/asr-chat/hooks/use-start-new-asr-chat';
import { ValueCallback } from 'src/types/common.type';
import { getUserDisplayName } from 'src/utils/user.utils';

import GroupCallButton from './GroupCallButton';
import GroupMenu from './GroupMenu';
import PersonalCallButton from './PersonalCallButton';
import PersonalChatMenu from './PersonalChatMenu';

interface ChatHeaderProps {
  messageThread: MessageThread;
  setTextAudio: React.Dispatch<React.SetStateAction<TextAudio | undefined>>;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  messageThread,
  setTextAudio,
}) => {
  const history = useHistory();
  const { currentUser } = useUserDetails();
  const { toggleTtsIcon, isTextToSpeechEnable } =
    useHandsFreeOperationSetting();
  const { startNewASRChat } = useStartNewAsrChat();

  const getTitle = (): string => {
    if (messageThread.isGroup) {
      const group = (messageThread as unknown as GroupMessageThread).group;
      return group.name;
    }

    const recipient = (messageThread as unknown as PersonalMessageThread)
      .recipient;
    return getUserDisplayName(recipient);
  };

  useEffect(() => {
    addTextToSpeechEnabledState(messageThread.id, isTextToSpeechEnable);
  }, [isTextToSpeechEnable]);

  const handleAudio = useCallback<ValueCallback<TextAudioPayload>>(
    newMessage => {
      if (newMessage.messageType !== MessageType.normalChat) {
        return;
      }
      if (_.includes(newMessage.userId, currentUser?.id)) {
        const audio = Buffer.from(newMessage.audio as string, 'base64');
        setTextAudio({ ...newMessage, audio });
      }
    },
    [],
  );

  useEffect(() => {
    if (isTextToSpeechEnable) {
      subscribeOnSpeech(handleAudio);
    }

    return () => {
      unsubscribeOnSpeech(handleAudio);
    };
  }, [isTextToSpeechEnable]);

  return (
    <Center position='fixed' top='0' w='full' zIndex='modal' h={NAVBAR_HEIGHT}>
      <Flex
        alignItems='center'
        bg='gray.50'
        justifyContent='space-between'
        boxShadow='lg'
        borderTop='1px solid'
        borderColor='gray.200'
        px='2'
        zIndex='200'
        h='full'
        w='full'
        maxW={maxWidth}
        mx='auto'
      >
        <IconButton
          variant='ghost'
          borderRight='1px solid'
          borderColor='gray.200'
          _focus={{}}
          _hover={{}}
          size='lg'
          aria-label='Go back'
          icon={<Icon as={FiArrowLeft} boxSize='7' />}
          onClick={() => history.goBack()}
        />
        <Text
          fontWeight='bold'
          flex='2'
          textAlign='center'
          ml='8'
          noOfLines={1}
        >
          {getTitle()}
        </Text>

        <Flex justifyContent='flex-end' alignItems='center'>
          <IconButton
            icon={<WalkiesTalkieIcon boxSize='10' color='black' />}
            aria-label='walkies-talkie'
            variant='link'
            onClick={() => startNewASRChat(messageThread.id)}
          />
          <IconButton
            as={Link}
            variant='link'
            aria-label='text-to-speech'
            icon={
              isTextToSpeechEnable ? (
                <Icon as={TextToSpeechFillIcon} boxSize='7' />
              ) : (
                <Icon as={TextToSpeechIcon} boxSize='6' />
              )
            }
            size='lg'
            onClick={toggleTtsIcon}
          />

          {messageThread.isGroup ? (
            <GroupCallButton
              groupMessageThread={messageThread as GroupMessageThread}
            />
          ) : (
            <PersonalCallButton
              personalMessageThread={
                messageThread as unknown as PersonalMessageThread
              }
            />
          )}
          {messageThread.isGroup ? (
            <GroupMenu messageThreadId={messageThread.id} />
          ) : (
            <PersonalChatMenu
              messageThread={messageThread as unknown as PersonalMessageThread}
            />
          )}
        </Flex>
      </Flex>
    </Center>
  );
};

export default ChatHeader;
