import { Box, Text } from '@chakra-ui/react';

export const AlertResolvedBanner = () => (
  <Box
    width={{ base: '200px', sm: '250px' }}
    height={{ base: '40px', sm: '50px' }}
    bg='red.500'
    color='white'
    position='absolute'
    top={{ base: '6', sm: '7' }}
    right={{ base: '-14', sm: '-16' }}
    zIndex='1'
    display='flex'
    alignItems='center'
    justifyContent='center'
    transform='rotate(45deg)'
  >
    <Text fontSize={{ base: 'md', sm: 'xl' }} fontWeight='bold'>
      Resolved
    </Text>
  </Box>
);
