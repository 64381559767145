import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { deletePost } from 'src/apis/posts.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { Post } from 'src/models/Post.model';
import { useFeedPostsQueryClient } from './hooks/useFeedPosts';

interface DeletePostModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeletePost: () => void;
  post: Post;
}

const DeletePostModal = ({ isOpen, onClose, post }: DeletePostModalProps) => {
  const { translate } = useTranslate();
  const toast = useToast();

  const feedId = post.FeedId;
  const postId = post.id;
  const { deleteFeedPost } = useFeedPostsQueryClient({
    feedId: Number(feedId),
  });
  const deletePostMutation = useMutation(
    ({ feedId, postId }: { feedId: number; postId: number }) =>
      deletePost({ FeedId: feedId, id: postId }),
    {
      onSuccess: () => {
        deleteFeedPost();
        toast({
          title: translate(TranslationKeys.postDelete),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onDeletePost = () => {
    deletePostMutation.mutate({ feedId, postId });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{translate(TranslationKeys.deletePost)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{translate(TranslationKeys.areYouSureMessage)}</ModalBody>
        <ModalFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            {translate(TranslationKeys.cancel)}
          </Button>
          <Button colorScheme='red' onClick={onDeletePost}>
            {translate(TranslationKeys.delete)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeletePostModal;
