import { useCallback } from 'react';
import { useMutation, UseQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';

import { updatePage } from 'src/apis/pages.api';
import { Page } from 'src/models/Page.model';
import { EditablePageContent } from '../types/EditablePageContent.type';
import { useTranslate } from 'src/hooks/useTranslate';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useToast } from 'src/hooks/use-toast';

interface UsePublishPageContentOptions extends Pick<Page, 'id'> {
  refetchPageContent: UseQueryResult['refetch'];
}

type PageContentUpdateDto = Pick<Page, 'content'>;

const usePublishPageContent = ({
  id,
  refetchPageContent,
}: UsePublishPageContentOptions) => {
  const { translate } = useTranslate();
  const { showToast, showErrorToast } = useToast();
  const history = useHistory();

  const updatePageContentMutation = useMutation<
    Page,
    unknown,
    PageContentUpdateDto
  >(pageContentUpdate => updatePage(id, pageContentUpdate), {
    onSuccess: () => {
      refetchPageContent();
      history.goBack();
      showToast({
        title: translate(TranslationKeys.pageContentUpdatedSuccessfully),
        status: 'success',
      });
    },
    onError: showErrorToast,
  });

  const handlePublishPageContent = useCallback(
    (content: EditablePageContent) =>
      updatePageContentMutation.mutate({ content: JSON.stringify(content) }),
    [updatePageContentMutation],
  );

  return { handlePublishPageContent, updatePageContentMutation };
};

export default usePublishPageContent;
