import * as yup from 'yup';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

export const useCommentFormSchema = () => {
  const { translate } = useTranslate();

  const CommentFormSchema = yup.object().shape({
    commentContent: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterComment)),
  });

  return CommentFormSchema;
};

export type CommentDto = yup.InferType<ReturnType<typeof useCommentFormSchema>>;
