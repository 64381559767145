import { useMemo } from 'react';
import _ from 'lodash';

import { CallSession } from 'src/models/CallSession.model';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

const usePersonalCallSession = (): CallSession | undefined => {
  const { callSessions } = useCallSessionsContext();
  const personalCallSession = useMemo(
    () => _.head(callSessions),
    [callSessions],
  );

  return personalCallSession;
};

export default usePersonalCallSession;
