import { Center, CenterProps } from '@chakra-ui/react';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import CallUserImage from 'src/pages/peer-call/components/body/components/CallUserImage';
import { useUserMedia } from 'src/pages/peer-call/components/footer/context/user-media/user-media.context';
import VideoStream from 'src/pages/peer-call/components/VideoStream';
import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';

export const UserOwnStream: React.FC<CenterProps> = props => {
  const { isVideoMuted } = usePeerConnection();
  const { localStream } = useUserMedia();
  const { currentUser } = useUserDetails();

  const propsCenter = {
    w: 'full',
    h: 'full',
    backgroundColor: 'gray.700',
    ...props,
  };

  if (currentUser && localStream && !isVideoMuted) {
    return (
      <Center {...propsCenter}>
        <VideoStream
          stream={localStream}
          w='full'
          h='full'
          objectFit='cover'
          muted
        />
      </Center>
    );
  }
  return (
    <Center {...propsCenter}>
      <CallUserImage
        name={
          currentUser?.lastName
            ? `${currentUser?.firstName} ${currentUser?.lastName}`
            : currentUser?.firstName!
        }
        key={currentUser?.id}
        isCurrentUser={true}
      />
    </Center>
  );
};
