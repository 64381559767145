import { Avatar, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { FC } from 'react';

import {
  SOS_ALERT_DATE_FORMAT,
  USER_AVATAR_SIZE,
} from 'src/constants/sos-alert.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';

import { CallMessageIcon } from '../CallMessageIcon';
import UserAvatar from '../UserAvatar';

interface AlertInformationProps {
  alert: Alert;
}

export const AlertInformation: FC<AlertInformationProps> = ({ alert }) => {
  const alertUser = alert?.raisedBy;
  const { currentUser } = useUserDetails();
  const alertDate = moment(alert.createdAt).format(SOS_ALERT_DATE_FORMAT);
  const { translate } = useTranslate();

  return (
    <>
      <VStack justifyContent='center'>
        {alertUser ? (
          <UserAvatar user={alertUser} boxSize={USER_AVATAR_SIZE} />
        ) : (
          <Avatar name='Anonymous' boxSize={USER_AVATAR_SIZE} />
        )}
        {alertUser ? (
          <Text fontWeight='bold' fontSize='3xl'>
            {alertUser.firstName} {alertUser.lastName}
          </Text>
        ) : (
          <Text fontWeight='bold' fontSize='3xl'>
            {translate(TranslationKeys.anonymous)}
          </Text>
        )}
        <Text fontSize='md' color='#606060'>
          {alertDate}
        </Text>
      </VStack>
      {alertUser && alertUser.id !== currentUser?.id && (
        <CallMessageIcon userId={alertUser.id} />
      )}
    </>
  );
};
