import { Center, VStack } from '@chakra-ui/react';

import { CALL_HEADER_HEIGHT } from 'src/constants/group-call.constant';
import { maxWidth } from 'src/constants/max-width.constant';

import PersonalCallStatus from './components/personal-call/status/PersonalCallStatus';
import PersonalCallTitle from './components/personal-call/title/PersonalCallTitle';

const CallPageHeader: React.FC = () => {
  return (
    <Center w='full' top='0'>
      <Center
        bgColor='blue.500'
        w='full'
        maxW={maxWidth}
        h={CALL_HEADER_HEIGHT}
      >
        <VStack>
          <PersonalCallTitle />
          <PersonalCallStatus />
        </VStack>
      </Center>
    </Center>
  );
};

export default CallPageHeader;
