import _ from 'lodash';
import { UseMutationResult } from 'react-query';

import { ONE_USER_IN_CALL, TWO_USER_IN_CALL } from 'src/constants';
import { CallSession } from 'src/models/CallSession.model';

type CallSessionUserId = CallSession['CallerId'] | CallSession['ReceiverId'];

export const getOtherUserId = (
  callSession: CallSession,
  selfUserId: CallSessionUserId,
) => {
  return callSession.CallerId === selfUserId
    ? callSession.ReceiverId
    : callSession.CallerId;
};

export const getHeightForStreamComponent = (
  heightRef: React.RefObject<HTMLDivElement>,
) => {
  return `calc(${heightRef.current?.clientHeight! / 2}px)`;
};

export const getCallingGridColumns = (
  callSessionsChunk: CallSession[],
  isFirstTab: boolean,
) => {
  if (callSessionsChunk.length === ONE_USER_IN_CALL) {
    return 0;
  }
  if (callSessionsChunk.length === TWO_USER_IN_CALL) {
    return isFirstTab ? 2 : 0;
  }
  return 2;
};

export const leaveCalls = (
  callSessions: CallSession[],
  leaveAllCallSessions: UseMutationResult<void, unknown, number[], unknown>,
) => {
  const callSessionIds = _.map(
    callSessions,
    callSession => !callSession.disconnectedAt && callSession.id,
  );

  const callSessionIdsWithoutNull = _.compact(callSessionIds);
  if (_.isEmpty(callSessionIdsWithoutNull)) {
    return;
  }
  leaveAllCallSessions.mutate(callSessionIdsWithoutNull);
};
