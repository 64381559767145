import { Grid, Text } from '@chakra-ui/react';

interface DetailContainerProps {
  label: string;
  value: string | undefined;
}

const DetailContainer: React.FC<DetailContainerProps> = ({ label, value }) => {
  return (
    <Grid fontSize='xl' w='full' my='2' gridGap='4' templateColumns='1fr 2.5fr'>
      <Text>{label}</Text>
      <Text fontWeight='bold'>{value || '-'}</Text>
    </Grid>
  );
};

export default DetailContainer;
