import _ from 'lodash';

import { MessageThread } from 'src/models/MessageThread.model';
import { getUserFullName } from 'src/utils';

export const getPageTitleForASRChat = (messageThread: MessageThread) => {
  return _.startCase(
    messageThread.isGroup
      ? messageThread.group.name
      : getUserFullName(messageThread.recipient),
  );
};
