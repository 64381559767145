import { DataConnection, MediaConnection } from 'peerjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';

import { CAMERA_FACING_MODE } from 'src/constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { ReportType, VideoQualityLevels } from 'src/types/call.type';
import {
  ADJUST_VIDEO_QUALITY_THROTTLE_TIME,
  HIGH_VIDEO_QUALITY_CONSTRAINTS,
  LAST_ADJUSTED_TIME,
  LOW_VIDEO_QUALITY_CONSTRAINTS,
  MEDIUM_VIDEO_QUALITY_CONSTRAINTS,
} from 'src/constants/calls.constant';

import {
  addTrack,
  notifyDisableMic,
  notifyDisableVideo,
  notifyEnableMic,
  notifyEnableVideo,
} from '../utils/data-connection.utils';
import {
  hasAudioTracks,
  disableAudioTracks,
  enableAudioTracks,
  hasVideoTracks,
  disableVideoTracks,
  enableVideoTracks,
} from '../utils/media-stream.utils';

export const useStreamControl = (
  localStream: MediaStream | null,
  calls: MediaConnection[],
  dataConnections: DataConnection[],
) => {
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  const callsRef = useRef<MediaConnection[]>([]);

  const { currentUser } = useUserDetails();

  const audioConstraints: MediaStreamConstraints = {
    audio: true,
  };

  const videoConstraints: MediaStreamConstraints = useMemo(() => {
    return {
      video: {
        facingMode: isFrontCamera
          ? CAMERA_FACING_MODE.user
          : CAMERA_FACING_MODE.environment,
      },
    };
  }, [isFrontCamera]);

  const addStreamedTrack = useCallback(
    (track: MediaStreamTrack) => {
      if (localStream && callsRef.current.length) {
        localStream.addTrack(track);
        addTrack(track, localStream, callsRef.current);
      }
    },
    [localStream, callsRef.current.length],
  );

  const fetchStreamAndMerge = useCallback(
    async (constraints: MediaStreamConstraints, type: 'audio' | 'video') => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (stream && localStream) {
          if (type === 'audio') {
            _.forEach(localStream.getAudioTracks(), track => {
              track.stop();
              localStream.removeTrack(track);
            });
            _.forEach(stream.getAudioTracks(), addStreamedTrack);
            return;
          }
          _.forEach(localStream.getVideoTracks(), track => {
            track.stop();
            localStream.removeTrack(track);
          });
          _.forEach(stream.getVideoTracks(), addStreamedTrack);
        }
      } catch (error) {
        console.error('Error fetching user media', error);
      }
    },
    [localStream],
  );

  const toggleAudio = useCallback(() => {
    if (!currentUser) return;
    setIsAudioMuted(prevAudioMuted => {
      const willAudioBeMuted = !prevAudioMuted;

      if (localStream) {
        if (hasAudioTracks(localStream)) {
          if (willAudioBeMuted) {
            notifyDisableMic(dataConnections, currentUser.id);
            disableAudioTracks(localStream);
          } else {
            notifyEnableMic(dataConnections, currentUser.id);
            enableAudioTracks(localStream);
          }
        }
      } else {
        fetchStreamAndMerge(audioConstraints, 'audio');
      }

      return willAudioBeMuted;
    });
  }, [localStream, currentUser, dataConnections]);

  const toggleVideo = useCallback(() => {
    if (!currentUser) return;
    setIsVideoMuted(prevVideoMuted => {
      const willVideoBeMuted = !prevVideoMuted;

      if (localStream) {
        if (hasVideoTracks(localStream)) {
          if (willVideoBeMuted) {
            notifyDisableVideo(dataConnections, currentUser.id);
            disableVideoTracks(localStream);
          } else {
            notifyEnableVideo(dataConnections, currentUser.id);
            enableVideoTracks(localStream);
          }
        } else {
          fetchStreamAndMerge(videoConstraints, 'video');
        }
      } else {
        fetchStreamAndMerge(videoConstraints, 'video');
      }

      return willVideoBeMuted;
    });
  }, [localStream, currentUser, dataConnections, videoConstraints]);

  const switchCamera = useCallback(() => {
    if (localStream) {
      setIsFrontCamera(prevIsFrontCamera => !prevIsFrontCamera);
    }
  }, [localStream]);

  const adjustVideoQualityThrottled = (() => {
    let lastAdjustmentTime = LAST_ADJUSTED_TIME;
    const throttleTime = ADJUST_VIDEO_QUALITY_THROTTLE_TIME;

    return (
      peerConnection: RTCPeerConnection,
      qualityLevel: VideoQualityLevels,
    ) => {
      const now = Date.now();
      if (now - lastAdjustmentTime > throttleTime) {
        lastAdjustmentTime = now;
        adjustVideoQuality(peerConnection, qualityLevel);
      }
    };
  })();

  const adjustVideoQuality = (
    peerConnection: RTCPeerConnection,
    qualityLevel: VideoQualityLevels,
  ) => {
    const sender = peerConnection
      .getSenders()
      .find(s => s.track && s.track.kind === 'video');

    if (sender) {
      let constraints = {};

      if (qualityLevel === VideoQualityLevels.low) {
        constraints = LOW_VIDEO_QUALITY_CONSTRAINTS;
      } else if (qualityLevel === VideoQualityLevels.medium) {
        constraints = MEDIUM_VIDEO_QUALITY_CONSTRAINTS;
      } else if (qualityLevel === VideoQualityLevels.high) {
        constraints = HIGH_VIDEO_QUALITY_CONSTRAINTS;
      }

      // Check if applyConstraints is supported
      if (sender.track && sender.track.applyConstraints) {
        sender.track
          .applyConstraints(constraints)
          .catch(e => console.error('Error adjusting video quality', e));
      } else {
        console.error('applyConstraints is not supported on this browser');
      }
    }
  };

  const checkNetworkAndAdjustQuality = (peerConnection: RTCPeerConnection) => {
    let intervalId = setInterval(() => {
      // Check if the peerConnection is still connected and in use
      if (
        peerConnection.connectionState === 'closed' ||
        peerConnection.iceConnectionState === 'closed'
      ) {
        clearInterval(intervalId);
        return;
      }

      peerConnection.getStats(null).then(stats => {
        stats.forEach(report => {
          // Look for outbound RTP stats for the video track
          if (
            report.type === ReportType.outboundRtp &&
            report.kind === 'video'
          ) {
            // Adjust video quality based on packet loss or bitrate
            if (report.packetsLost > 100) {
              adjustVideoQualityThrottled(
                peerConnection,
                VideoQualityLevels.low,
              );
            } else if (report.packetsLost > 50) {
              adjustVideoQualityThrottled(
                peerConnection,
                VideoQualityLevels.medium,
              );
            } else {
              adjustVideoQualityThrottled(
                peerConnection,
                VideoQualityLevels.high,
              );
            }
          }

          // Similarly, you can check inbound-rtp if you want to monitor the quality of the received stream
          if (
            report.type === ReportType.inboundRtp &&
            report.kind === 'video'
          ) {
            if (report.jitter > 0.03) {
              adjustVideoQualityThrottled(
                peerConnection,
                VideoQualityLevels.low,
              );
            } else if (report.jitter > 0.01) {
              adjustVideoQualityThrottled(
                peerConnection,
                VideoQualityLevels.medium,
              );
            } else {
              adjustVideoQualityThrottled(
                peerConnection,
                VideoQualityLevels.high,
              );
            }
          }
        });
      });
    }, 5000);

    // Return interval ID for manual clearing if needed
    return intervalId;
  };

  useEffect(() => {
    const intervalIds: NodeJS.Timeout[] = [];

    _.forEach(calls, call => {
      // Start the network check for each peer connection
      const intervalId = checkNetworkAndAdjustQuality(call.peerConnection);
      intervalIds.push(intervalId);
    });

    // Cleanup intervals when calls end or component unmounts
    return () => {
      intervalIds.forEach(intervalId => clearInterval(intervalId));
    };
  }, [calls]);

  useEffect(() => {
    const initialStatusUpdate = (dataConnections: DataConnection[]) => {
      if (!currentUser) return;
      if (isAudioMuted) notifyDisableMic(dataConnections, currentUser?.id);
      else notifyEnableMic(dataConnections, currentUser?.id);
      if (!isVideoMuted) notifyEnableVideo(dataConnections, currentUser?.id);
      else notifyDisableVideo(dataConnections, currentUser?.id);
    };

    initialStatusUpdate(dataConnections);
  }, [currentUser, dataConnections, isAudioMuted, isVideoMuted]);

  useEffect(() => {
    fetchStreamAndMerge(videoConstraints, 'video');
  }, [videoConstraints]);

  useEffect(() => {
    if (calls.length) {
      callsRef.current = calls;
    }
  }, [calls]);

  return {
    isAudioMuted,
    isVideoMuted,
    toggleAudio,
    toggleVideo,
    isFrontCamera,
    switchCamera,
  };
};
