import { Text } from '@chakra-ui/react';
import _ from 'lodash';
import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { AlertMedia } from 'src/models/Alerts.model';
import { AlertMediaInformation } from './AlertMediaInformation';

interface AudioAlertMediaProps {
  alertMedia: AlertMedia[] | undefined;
}

export const AudioAlertMedia: React.FC<AudioAlertMediaProps> = ({
  alertMedia,
}) => {
  const { translate } = useTranslate();
  const audioMedia = _.filter(alertMedia, media =>
    media.type.startsWith(ALERT_MEDIA_TYPES.audio),
  );

  if (_.isEmpty(audioMedia)) {
    return null;
  }

  return (
    <AlertMediaInformation title={translate(TranslationKeys.audio)}>
      {_.map(audioMedia, media => {
        return (
          <>
            <audio style={{ width: '100%' }} controls playsInline>
              <source src={media.mediaUrl} type={media.type} />
            </audio>

            <Text>{media.caption}</Text>
          </>
        );
      })}
    </AlertMediaInformation>
  );
};
