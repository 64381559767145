import { MediaMessageDto } from 'src/dto/messages.dto';
import { kMessageThreadsUrl } from '../message-threads.api';

const kMessageThreadsUrlIdParam = ':messageThreadId';

// Sample URL: /message-threads/123/messages
const kMessagesUrl = `${kMessageThreadsUrl}/${kMessageThreadsUrlIdParam}/messages`;

export const getMessagesUrl = (messageThreadId: number) =>
  kMessagesUrl.replace(kMessageThreadsUrlIdParam, messageThreadId.toString());

export const createMediaMessageApi = (
  messageThreadId: number,
  mediaMessagePayload: MediaMessageDto,
) => {
  const url = getMessagesUrl(messageThreadId) + '/upload';

  const formData = new FormData();
  formData.append('messageAt', new Date().toISOString());

  if (mediaMessagePayload.caption) {
    formData.append('text', mediaMessagePayload.caption);
  }

  for (let file of mediaMessagePayload.messageMedia) {
    formData.append('messageMedia', file);
  }

  if (mediaMessagePayload.latitude) {
    formData.append('latitude', mediaMessagePayload.latitude.toString());
  }

  if (mediaMessagePayload.longitude) {
    formData.append('longitude', mediaMessagePayload.longitude.toString());
  }

  if (mediaMessagePayload.messageType) {
    formData.append('messageType', mediaMessagePayload.messageType.toString());
  }

  return { url, formData };
};
