import { useEffect } from 'react';

import {
  joinMessageThreadRoom,
  leaveMessageThreadRoom,
} from 'src/apis/socket.io/message-threads.socket.io';
import { useIntParam } from 'src/hooks/use-int-params';
import { MessageType } from 'src/models/Message.model';
import useMessages from 'src/pages/chat/hooks/useMessages';
import useMessageThread from 'src/pages/chat/hooks/useMessageThread';

import { useASRChatAudio } from './use-asr-chat-audio';
import { useStartNewAsrChat } from './use-start-new-asr-chat';

export const useAsrChatHistory = () => {
  const { messageThreadId } = useIntParam<{ messageThreadId: string }>();

  const { setTextAudio, textAudio } = useASRChatAudio();
  const { startNewASRChat } = useStartNewAsrChat();
  const { messageThread } = useMessageThread(messageThreadId);

  const { messages } = useMessages({
    messageThreadId: messageThreadId,
    messageType: MessageType.asrChat,
  });

  useEffect(() => {
    if (messageThreadId) {
      joinMessageThreadRoom(messageThreadId);
      return () => {
        leaveMessageThreadRoom(messageThreadId);
      };
    }
  }, [messageThreadId]);

  return {
    messages,
    textAudio,
    setTextAudio,
    messageThread,
    startNewASRChat,
  };
};
