export const RECOGNIZER_TYPE = 'BROWSER_FFT';

export const WEAK_WORD = 'hello_max';

export const RECOGNIZER_COMMAND_CONFIG = {
  suppressionTimeMillis: 500,
  probabilityThreshold: 0.97,
};

export type TensorflowModelUrl = {
  model: string;
  metaData: string;
  binaryFile1: string;
  binaryFile2: string;
};

export const TENSORFLOW_MODEL_URL_QUERY_KEY = 'tensorflow-model-url-query-key';
