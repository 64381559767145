import { createIcon } from '@chakra-ui/react';

export const TextIcon = createIcon({
  displayName: 'Text icon',
  viewBox: '0 0 45 46',
  defaultProps: {
    width: '45',
    height: '46',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  path: [
    <>
      <circle cx='22.1836' cy='22.4099' r='22' fill='#ED8915' />
      <g clip-path='url(#clip0_346_1141)'>
        <path
          d='M19.1836 13.4099H25.1836V16.4099H26.6836V11.9099H10.1836V16.4099H11.6836V13.4099H17.6836V31.4099H14.6836V32.9099H22.1836V31.4099H19.1836V13.4099Z'
          fill='#F9F9F9'
        />
        <path
          d='M20.6836 17.9099V20.9099H22.1836V19.4099H26.6836V31.4099H23.6836V32.9099H31.1836V31.4099H28.1836V19.4099H32.6836V20.9099H34.1836V17.9099H20.6836Z'
          fill='#F9F9F9'
        />
      </g>
      <defs>
        <clipPath id='clip0_346_1141'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(10.1836 10.4099)'
          />
        </clipPath>
      </defs>
    </>,
  ],
});
