import {
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {
  useState,
  useCallback,
  SetStateAction,
  useRef,
  useEffect,
} from 'react';
import { MdSearch } from 'react-icons/md';
import { useQuery } from 'react-query';

import { getUsersPagination } from 'src/apis/users.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { User } from 'src/models';
import { SelectedMemberMap } from 'src/pages/peer-call/group-call/components/MemberItem';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';
import { ValueCallback } from 'src/types/common.type';
import { sortByDate } from 'src/utils/sort-by-date';

import { UsersList } from './UsersList';

type InputChangeHandler = React.ChangeEventHandler<HTMLInputElement>;
type SearchCallback = ValueCallback<string>;

interface SearchUserToCallProps {
  searchLabel?: string;
  shouldRenderUser?: ValueCallback<User, boolean>;
  isGroupCall: boolean;
  selectedMembers: SelectedMemberMap;
  setSelectedMembers: React.Dispatch<SetStateAction<SelectedMemberMap>>;
  isOpen: boolean;
  totalMembersCount: number;
  setTotalMembersCount: React.Dispatch<SetStateAction<number>>;
}

const SearchUserToCall: React.FC<SearchUserToCallProps> = ({
  shouldRenderUser,
  searchLabel,
  isGroupCall,
  selectedMembers,
  setSelectedMembers,
  isOpen,
  totalMembersCount,
  setTotalMembersCount,
}) => {
  const { translate } = useTranslate();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>();
  const { callSessions } = useCallSessions();
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: userSearchResults, isLoading: isSearchingUsers } = useQuery<
    User[]
  >(
    ['searchUsersForNewCall', searchQuery],
    () => getUsersPagination(searchQuery),
    { enabled: !!searchQuery },
  );

  // React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead  react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSearch = useCallback<SearchCallback>(
    _.debounce<SearchCallback>(setSearchQuery, 500),
    [setSearchQuery],
  );

  const onSearchInputChange: InputChangeHandler = e => {
    const searchInputValue = e.currentTarget.value?.trim();
    setSearchInputValue(searchInputValue);

    if (!_.isEmpty(searchInputValue)) {
      performSearch(searchInputValue);
    }
  };

  const sortedBySelectedAt = () => {
    return _.map(
      _.values(selectedMembers).sort((a, b) =>
        sortByDate(a.selectedAt, b.selectedAt),
      ),
      member => member.user,
    );
  };

  // Keep selected users at top
  const userSearchResultAndSelectedUsers = _.concat(
    sortedBySelectedAt(),
    userSearchResults || [],
  );

  // Users selected could re-appear in search results if user changes search term
  // To avoid showing selected users twice,
  // we select the unique items from the concatenated array of search results and selected users
  const userListWithoutDuplicates = _.uniqBy(
    userSearchResultAndSelectedUsers,
    'id',
  );

  const userListWithoutAlreadyAddedUsers = _.remove(
    userListWithoutDuplicates,
    user => {
      return !callSessions?.some(
        session =>
          session.CallerId === user?.id || session.ReceiverId === user?.id,
      );
    },
  );

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <VStack spacing='6' align='start'>
      <FormControl>
        <FormLabel>{searchLabel}</FormLabel>
        <InputGroup>
          <InputLeftElement>
            <Icon as={MdSearch} color='gray.300' boxSize='6' />
          </InputLeftElement>
          <Input
            placeholder={translate(TranslationKeys.searchForAUser)}
            onChange={onSearchInputChange}
            value={searchInputValue}
            ref={inputRef}
          />
        </InputGroup>
      </FormControl>

      <UsersList
        shouldRenderUser={shouldRenderUser}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        userListWithoutAlreadyAddedUsers={userListWithoutAlreadyAddedUsers}
        isGroupCall={isGroupCall}
        isSearchingUsers={isSearchingUsers}
        searchQuery={searchQuery}
        totalMembersCount={totalMembersCount}
        setTotalMembersCount={setTotalMembersCount}
      />
    </VStack>
  );
};

export default SearchUserToCall;
