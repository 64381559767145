import * as _ from 'lodash';
import { axiosInstance } from 'src/config';
import { FeedPostCommentDto } from 'src/dto/feed-post-comment.dto';
import { Comment } from 'src/models/Comment.model';
import { formDataFactory } from './utils/form-data.util';
import { getRequestData } from './utils/get-request-data.util';

export const kCommentRootUrl = '/comments';

export const getIndividualComments = (
  EntityId: number,
  entityType: string,
): Promise<Comment[]> => {
  const url = `${kCommentRootUrl}/entity/${EntityId}/${entityType}`;
  const result = getRequestData(axiosInstance.get(url));
  return result;
};

export const addCommentsToPost = (
  feedPostCommentPayload: FeedPostCommentDto,
): Promise<FeedPostCommentDto[]> => {
  return getRequestData<FeedPostCommentDto[]>(
    axiosInstance.post(
      kCommentRootUrl,
      formDataFactory({
        ...feedPostCommentPayload,
        EntityId: _.toString(feedPostCommentPayload.EntityId),
      }),
    ),
  );
};

export const updateCommentsToPost = (
  commentId: number,
  feedPostCommentPayload: FeedPostCommentDto,
): Promise<FeedPostCommentDto[]> => {
  const url = `${kCommentRootUrl}/${commentId}`;
  return getRequestData<FeedPostCommentDto[]>(
    axiosInstance.put(
      url,
      formDataFactory({
        ...feedPostCommentPayload,
        EntityId: _.toString(feedPostCommentPayload.EntityId),
      }),
    ),
  );
};

export const deleteComment = (commentId: number) => {
  const url = `${kCommentRootUrl}/${commentId}`;
  return getRequestData(axiosInstance.delete(url));
};
