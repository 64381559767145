import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { createTextMessage, postTextMessage } from 'src/apis/messages.api';
import {
  joinMessageThreadRoom,
  leaveMessageThreadRoom,
} from 'src/apis/socket.io/message-threads.socket.io';
import { useUserLocation } from 'src/hoc';
import { useStartSpeakingSound } from 'src/hooks/use-asr-sounds';
import { useIntParam } from 'src/hooks/use-int-params';
import { useASRAudio } from 'src/hooks/useAsrAudio';
import { MessageType } from 'src/models/Message.model';
import useMessages from 'src/pages/chat/hooks/useMessages';
import useMessageThread from 'src/pages/chat/hooks/useMessageThread';

import { MicState } from '../types/mic-state.type';
import { useASRChatAudio } from './use-asr-chat-audio';
import { useASRWakeWordDetection } from './use-asr-wake-word-detection';
import { useMessageThreadMembersLocation } from './use-message-thread-members-location';

export const useAsrChat = () => {
  const { messageThreadId } = useIntParam<{ messageThreadId: string }>();
  const { locationStream } = useUserLocation();
  const microPhoneRef = useRef<HTMLInputElement>(null);
  const { playStartSpeakingSound } = useStartSpeakingSound();
  const [micState, setMicState] = useState<MicState>();

  const { mutate: postMessageMutate } = useMutation(postTextMessage);
  const { mutate: createMessageMutate } = useMutation(createTextMessage);

  const { refetchMembersLocation, threadMembersLocation } =
    useMessageThreadMembersLocation(messageThreadId);

  const {
    setIsWakeWordListening,
    isListeningASR,
    setIsListeningASR,
    stopListeningWakeWordDetection,
  } = useASRWakeWordDetection();
  const { setTextAudio, textAudio } = useASRChatAudio();

  const { messages } = useMessages({
    messageThreadId: messageThreadId,
    messageType: MessageType.asrChat,
    refetchMembersLocation,
    isLiveChat: true,
  });

  const toggleMicAnimation = () => {
    if (microPhoneRef.current) {
      microPhoneRef.current.click();
    }
  };

  const { messageThread } = useMessageThread(messageThreadId);

  const {
    isEndOfUtteranceDetected,
    transcribeMessage,
    stopRecording,
    isRecording,
    setTranscribeMessage,
    setStartTime,
  } = useASRAudio(
    messageThreadId,
    () => {},
    () => {},
    isListeningASR,
  );

  const sendMessage = () => {
    createMessageMutate({
      messageThreadId,
      text: transcribeMessage,
      isTextToSpeechEnable: true,
      messageType: MessageType.asrChat,
      latitude: locationStream?.latitude?.toString(),
      longitude: locationStream?.longitude?.toString(),
    });
    postMessageMutate({
      messageThreadId,
      text: transcribeMessage,
      messageType: MessageType.asrChat,
    });
  };

  const onFinishAsrChatRecording = () => {
    toggleMicAnimation();
    stopRecording();
    sendMessage();
    setTranscribeMessage('');
    setIsListeningASR(false);
    setIsWakeWordListening(true);
    setMicState(undefined);
    setStartTime(Date.now());
  };

  useEffect(() => {
    if (micState === MicState.manualEnable && !isRecording) {
      stopListeningWakeWordDetection();
    }

    if (micState === MicState.manualDisable && isRecording) {
      onFinishAsrChatRecording();
    }
  }, [micState, isRecording]);

  useEffect(() => {
    if (isEndOfUtteranceDetected) {
      onFinishAsrChatRecording();
    }
  }, [isEndOfUtteranceDetected]);

  useEffect(() => {
    if (messageThreadId) {
      joinMessageThreadRoom(messageThreadId);
      return () => {
        leaveMessageThreadRoom(messageThreadId);
      };
    }
  }, [messageThreadId]);

  // This effect is used to start the asr chat wake word detection and stop the HFO listening during the chat
  useEffect(() => {
    setIsWakeWordListening(true);

    return () => {
      setIsWakeWordListening(false);
      stopRecording();
    };
  }, []);

  useEffect(() => {
    if (isRecording) {
      // notify the user that the mic is listening.
      playStartSpeakingSound();
      toggleMicAnimation();
      setMicState(MicState.manualEnable);
    }
  }, [isRecording]);

  return {
    messages,
    transcribeMessage,
    isRecording,
    textAudio,
    setTextAudio,
    messageThread,
    threadMembersLocation,
    microPhoneRef,
    setMicState,
  };
};
