import {
  Avatar,
  Box,
  ButtonGroup,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { INCOMING_CALL_NOTIFICATION_DURATION } from 'src/constants/calls.constant';
import { maxWidth, minWidth } from 'src/constants/max-width.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useAsrChatRingingSound } from 'src/hooks/use-asr-sounds';
import { useUpdateUserLocationInMessageThread } from 'src/hooks/use-update-user-location';
import { useTranslate } from 'src/hooks/useTranslate';

import IncomingCallButton from '../incoming-call/components/IncomingCallButton';
import './css/animation.css';

interface IncomingAsrChatProps {
  isOpen: boolean;
  onClose: () => void;
  messageThreadId: number;
  callerName: string;
}

const IncomingAsrChatModal: React.FC<IncomingAsrChatProps> = ({
  isOpen,
  onClose,
  messageThreadId,
  callerName,
}) => {
  const { translate } = useTranslate();
  const { playRingingSound, ringtoneCleanup } = useAsrChatRingingSound();
  const { updateUserLocation } = useUpdateUserLocationInMessageThread();

  const handleAccept = () => {
    if (messageThreadId) {
      updateUserLocation(messageThreadId);
    }
  };

  const avatarSize = useBreakpointValue({ base: 'lg', sm: 'xl' });

  useEffect(() => {
    playRingingSound();

    return () => {
      ringtoneCleanup();
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, INCOMING_CALL_NOTIFICATION_DURATION);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='3xl'
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalContent
        py={{ base: '3', sm: '6' }}
        px={{ base: '1', sm: '4' }}
        mt='0'
        maxW={maxWidth}
        minW={minWidth}
        rounded='none'
      >
        <ModalBody>
          <HStack spacing={{ base: '6', sm: '10' }}>
            <Center>
              <Box
                className='pulse-ring'
                boxSize={{ base: '70px', sm: '100px' }}
              />
              <Avatar size={avatarSize} name={callerName} />
            </Center>
            <VStack align='start' lineHeight='none'>
              <Text fontSize={{ base: 'sm', sm: 'xl' }} noOfLines={1}>
                {translate(TranslationKeys.incomingRadioCommunication)}
              </Text>
              <Text
                fontSize={{ base: 'xl', sm: '2xl' }}
                fontWeight='semibold'
                noOfLines={1}
              >
                {callerName}
              </Text>
              <ButtonGroup w='full' spacing='2'>
                <IncomingCallButton
                  aria-label='Accept ASR Chat'
                  colorScheme='green'
                  buttonLabel='Accept'
                  onClick={handleAccept}
                  variant='solid'
                />
                <IncomingCallButton
                  aria-label='Ignore ASR Chat'
                  colorScheme='red'
                  buttonLabel='Ignore'
                  onClick={onClose}
                  variant='solid'
                />
              </ButtonGroup>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default IncomingAsrChatModal;
