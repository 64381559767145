import { Center } from '@chakra-ui/react';

import Loader from 'src/components/loader/Loader';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import { AppNavigationType } from 'src/types/navigation.type';
import { usePostComments } from './hooks/usePostComments';

import PostCard from './PostCard';

interface CommentCardProps {}

export const CommentCard: React.FC<CommentCardProps> = () => {
  const { comments, isLoading } = usePostComments();
  const { translate } = useTranslate();

  if (isLoading) {
    return <Loader />;
  }

  if (!comments) {
    return (
      <HeaderWrapper
        pageTitle={translate(TranslationKeys.comments)}
        navigationType={AppNavigationType.back}
      >
        <Center h='full' color='gray.500' fontWeight='500' fontSize='lg'>
          {translate(TranslationKeys.noCommentsToShow)}
        </Center>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper
      pageTitle={translate(TranslationKeys.comments)}
      bodyContainerProps={{ px: '2' }}
      navigationType={AppNavigationType.back}
    >
      <PostCard post={comments} />
    </HeaderWrapper>
  );
};
