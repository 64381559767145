import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getMorePersonalCalls } from 'src/apis/personal-calls.api';
import { PersonalCallResponse, PersonalCalls } from 'src/models/PersonalCall';

const kMorePersonalCallsQueryKey = (id: number) => `morePersonalCalls-${id}`;

const useMorePersonalCalls = (
  otherUserId: number,
  isVisible: boolean,
  pageNumber?: number,
) => {
  const [morePersonalCalls, setMorePersonalCalls] = useState<PersonalCalls[]>(
    [],
  );

  const { data: remainingPersonalCalls, ...queryMetadata } =
    useQuery<PersonalCallResponse>(
      [kMorePersonalCallsQueryKey(otherUserId), isVisible, pageNumber],
      () => getMorePersonalCalls(otherUserId, isVisible, pageNumber),
    );

  useEffect(() => {
    setMorePersonalCalls(prevCalls => {
      if (remainingPersonalCalls) {
        const { calls } = remainingPersonalCalls;
        return [...prevCalls, ...calls];
      }
      return prevCalls;
    });
  }, [remainingPersonalCalls]);

  const total = remainingPersonalCalls?.total ?? 0;
  const pageSize = remainingPersonalCalls?.pageSize ?? 0;
  const hasMore = total - pageSize * (pageNumber ?? 0) > 0; // Determine if there are more calls to fetch

  return { calls: morePersonalCalls, hasMore, queryMetadata };
};

export default useMorePersonalCalls;
