import _ from 'lodash';
import { useQuery } from 'react-query';

import { getMessageThreadMembersLocation } from 'src/apis/message-thread-members.api';

const kMessageThreadMembersLocationQueryKey = 'message-thread-members-location';

export const useMessageThreadMembersLocation = (messageThreadId: number) => {
  const { data, refetch: refetchMembersLocation } = useQuery(
    kMessageThreadMembersLocationQueryKey,
    () => getMessageThreadMembersLocation(messageThreadId),
    {
      enabled: !!messageThreadId,
    },
  );

  return {
    threadMembersLocation: _.values(data) ?? {},
    refetchMembersLocation,
  };
};
