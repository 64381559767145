import { Icon, IconButton } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { BaseEmoji } from 'emoji-mart';
import _ from 'lodash';
import { MdSend } from 'react-icons/md';
import { useMutation } from 'react-query';

import { SOSMediaUpdateDto } from 'src/dto/SoS.dto';
import { Alert } from 'src/models/Alerts.model';
import BasicInputPanel from 'src/pages/chat/components/BasicInputPanel';
import PopoverEmojiButton from 'src/pages/chat/components/PopoverEmojiButton';
import useMediaPreview from 'src/pages/chat/hooks/useMediaPreview';

interface SendVideoWithCaptionFooterProps {
  media: Blob[] | File[] | FileList;
  alertId: Alert['id'];
  onSuccessOrError: () => void;
  updateAlertMedia: (
    payload: SOSMediaUpdateDto,
  ) => Promise<AxiosResponse<void>>;
}
export const SendVideoWithCaptionFooter: React.FC<
  SendVideoWithCaptionFooterProps
> = ({ media, alertId, onSuccessOrError, updateAlertMedia }) => {
  const updateSoSMediaMutation = useMutation((alertMedia: SOSMediaUpdateDto) =>
    updateAlertMedia(alertMedia),
  );

  const [selectedMediaItems, caption, { setCaption }] = useMediaPreview({
    media,
  });

  const handleOnInputChange: React.ChangeEventHandler<HTMLInputElement> = e =>
    setCaption(e.currentTarget.value);

  const onSend = () => {
    updateSoSMediaMutation.mutate(
      {
        alertId: _.toString(alertId),
        caption,
        messageMedia: selectedMediaItems,
      },
      { onSuccess: onSuccessOrError, onError: onSuccessOrError },
    );
  };

  const onEmojiSelect = ({ native }: BaseEmoji) => {
    setCaption(`${caption || ''}${native}`);
  };

  const sendButton = (
    <IconButton
      variant='ghost'
      icon={<Icon as={MdSend} boxSize='6' />}
      aria-label='send button'
      onClick={onSend}
      isLoading={updateSoSMediaMutation.isLoading}
    />
  );

  return (
    <BasicInputPanel
      inputProps={{
        value: caption,
        onChange: handleOnInputChange,
      }}
      inputPrefix={<PopoverEmojiButton onSelectEmoji={onEmojiSelect} />}
      inputSuffix={sendButton}
    />
  );
};
