import { Variants } from 'framer-motion';

export const CONVERT_TO_PERCENTAGE = 100;

export const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const itemVariants: Variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

export const INITIAL_TAB_INDEX = 0;

export const CALL_SESSION_FIRST_CHUNK_LENGTH = 3;

export const CALL_SESSION_REMAINING_CHUNK_LENGTH = 4;

export const AUDIO_AND_VIDEO_CONSTRAINTS = {
  audio: {
    advanced: [
      { echoCancellation: true },
      { autoGainControl: true },
      { noiseSuppression: true },
    ],
  },
  video: true,
};
export const INCOMING_CALL_NOTIFICATION_DURATION = 30 * 1000;

export const audioConstraints: MediaStreamConstraints = {
  audio: true,
};

export const LAST_ADJUSTED_TIME = 0;

export const ADJUST_VIDEO_QUALITY_THROTTLE_TIME = 30000;

export const LOW_VIDEO_QUALITY_CONSTRAINTS = {
  width: { ideal: 640 },
  height: { ideal: 360 },
};

export const MEDIUM_VIDEO_QUALITY_CONSTRAINTS = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
};

export const HIGH_VIDEO_QUALITY_CONSTRAINTS = {
  width: { ideal: 1920 },
  height: { ideal: 1080 },
};
