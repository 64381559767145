import { BoxProps, Flex } from '@chakra-ui/react';
import { TextAudio } from 'src/apis/socket.io/messages.socket.io';
import { Message } from 'src/models/Message.model';
import MessageBox from './MessageBox';

interface MessageBoxContainerProps {
  message: Message;
  alignMessage: 'start' | 'end';
  MessageComponent?: React.ComponentType<{ message: Message } & BoxProps>;
  textAudio?: TextAudio;
  hasAccepted: boolean | null;
}

export const MessageBoxContainer: React.FC<MessageBoxContainerProps> = ({
  message,
  alignMessage,
  MessageComponent,
  textAudio,
  hasAccepted,
}) => {
  const msgColor = 'blackAlpha.800';
  return (
    <Flex
      w='full'
      justifyContent={alignMessage}
      flexDir={alignMessage === 'start' ? 'row' : 'row-reverse'}
      mb='4'
    >
      {MessageComponent ? (
        <MessageComponent
          maxW='100%'
          bg={alignMessage === 'start' ? '#bee3f8' : 'gray.100'}
          color={msgColor}
          message={message}
        />
      ) : (
        <MessageBox
          maxW='100%'
          bg={alignMessage === 'start' ? '#bee3f8' : 'gray.100'}
          color={msgColor}
          message={message}
          textAudio={textAudio}
        />
      )}
    </Flex>
  );
};
