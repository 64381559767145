import { axiosInstance } from '../config';
import { FeedPostView } from '../models/FeedPostView.model';
import { getRequestData } from './utils/get-request-data.util';

const getFeedPostViewUrl = () => `/views`;

export const addFeedPostViews = ({
  entityId,
  entityType,
  UserId,
}: FeedPostView): Promise<FeedPostView[]> => {
  return getRequestData<FeedPostView[]>(
    axiosInstance.post(getFeedPostViewUrl(), {
      EntityId: entityId,
      entityType,
      UserId,
    }),
  );
};
