import { Grid } from '@chakra-ui/layout';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { createNewAlert, CreateNewAlertDto } from 'src/apis/alerts.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { UserLocationContext } from 'src/hoc/UserLocationContext/user-location.context';
import { useToast } from 'src/hooks/use-toast';
import { Alert } from 'src/models/Alerts.model';
import { DashboardSection } from 'src/models/DashboardSection.model';
import useAlerts from '../alerts/hooks/useAlerts';
import { AlertMapSection } from './components/AlertMapSection';
import HomePageHeading from './components/HomePageHeading';
import IconSection from './components/IconSection';
import ImageSection from './components/ImageSection';
import ImageSliderSection from './components/ImageSliderSection';
import { NavigationLinkComponent } from './components/NavigartionLink';
import { SosInformationDialog } from './components/SosInformationDialog';
import TextSection from './components/TextSection';
import {
  NavigationLinks,
  SOSNavigationLink,
} from './constants/navigation-link.constant';
import useDashboardSections from './hooks/useDashboardSections';

const renderDashboardSection = (dashboardSection: DashboardSection) => {
  const { type, id } = dashboardSection;
  switch (type) {
    default:
    case 'textLink':
      return <TextSection dashboardSection={dashboardSection} key={id} />;
    case 'imageSlider':
      return (
        <ImageSliderSection dashboardSection={dashboardSection} key={id} />
      );
    case 'imageLink':
      return <ImageSection dashboardSection={dashboardSection} key={id} />;
    case 'iconLink':
      return <IconSection dashboardSection={dashboardSection} key={id} />;
  }
};

const HomePagePresentation = () => {
  const { showToast } = useToast();

  const [dashboardSections] = useDashboardSections();
  const sosInformationProviderModalDisclosure = useDisclosure();

  const [alertInfo, setAlertInfo] = useState<Alert>();

  const { currentUser } = useUserDetails();
  const { locationStream, getIsLocationPermissionGranted } =
    useContext(UserLocationContext);
  const { allAlerts } = useAlerts();

  const newAlertMutation = useMutation((newAlert: CreateNewAlertDto) =>
    createNewAlert(newAlert),
  );

  const handleOnEmergencySosClick = async () => {
    const isLocationPermissionGranted = await getIsLocationPermissionGranted();
    if (!isLocationPermissionGranted) {
      showToast({
        title: 'SOS alert raised without location',
        status: 'warning',
      });
    }
    newAlertMutation.mutate(
      {
        RaisedBy: currentUser?.id,
        latitude: locationStream?.latitude?.toString() || null,
        longitude: locationStream?.longitude?.toString() || null,
      },
      {
        onSuccess: (data: Alert) => {
          setAlertInfo(data);
          sosInformationProviderModalDisclosure.onOpen();
        },
      },
    );
  };

  return (
    <Box w='full'>
      <Flex
        direction='column'
        gridGap={{ base: '4', sm: '6' }}
        w='full'
        h='full'
      >
        <HomePageHeading />
        <Box border='2px solid' borderColor='gray.400' rounded='md'>
          <AlertMapSection allAlerts={allAlerts} />
        </Box>
        <Grid templateColumns='repeat(2, 1fr)' gap='4'>
          <NavigationLinkComponent
            {...SOSNavigationLink}
            onClick={handleOnEmergencySosClick}
          />
          {_.map(NavigationLinks, link => (
            <NavigationLinkComponent {...link} />
          ))}
        </Grid>
      </Flex>
      <Grid gap={8} align='start' my='6'>
        {dashboardSections.map(renderDashboardSection)}
      </Grid>
      {alertInfo && (
        <SosInformationDialog
          alertId={alertInfo.id}
          {...sosInformationProviderModalDisclosure}
        />
      )}
    </Box>
  );
};

export default HomePagePresentation;
