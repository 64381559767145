import { Avatar, Box, Badge } from '@chakra-ui/react';
import * as _ from 'lodash';

import { MessageGroup } from 'src/models/Call.model';
import { CallSession } from 'src/models/CallSession.model';

interface CallAvatarProps {
  messageGroup: MessageGroup;
  sessions: CallSession[];
}

export const CallAvatar: React.FC<CallAvatarProps> = ({
  messageGroup,
  sessions,
}) => {
  const uniqueSessions = _.uniqBy(sessions, 'otherUsers.id');
  const topUniqueSession = _.head(uniqueSessions);

  return (
    <Box position='relative' display='inline-block'>
      <Avatar
        name={
          messageGroup
            ? messageGroup.name
            : topUniqueSession?.otherUsers.fullName
        }
      />
      {uniqueSessions.length > 1 && (
        <Badge
          position='absolute'
          top='0'
          left='8'
          borderRadius='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          minHeight='5'
          minWidth='5'
        >
          +{uniqueSessions.length - 1}
        </Badge>
      )}
    </Box>
  );
};
