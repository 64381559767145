import { Fragment } from 'react';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { Call } from 'src/models/Call.model';
import { CallSession } from 'src/models/CallSession.model';

import CallIcons from './CallIcons';

interface GroupCallIconProps {
  callStatus: Call['callStatus'];
  sessions: CallSession[];
}

export const GroupCallIcon: React.FC<GroupCallIconProps> = ({
  callStatus,
  sessions,
}) => {
  const { currentUser } = useUserDetails();
  const getColor = (joinedAt: Date | null) => {
    if (joinedAt) {
      return 'green.500';
    } else if (!joinedAt) {
      return 'red.500';
    } else {
      return 'gray.500';
    }
  };

  const currentUserSessionWithCallerOrReceiver = sessions.find(
    session =>
      session.CallerId === currentUser?.id ||
      session.ReceiverId === currentUser?.id,
  );

  return (
    <Fragment>
      <CallIcons
        callerId={currentUserSessionWithCallerOrReceiver?.CallerId!}
        currentUser={currentUser!}
        color={getColor(currentUserSessionWithCallerOrReceiver?.joinedAt!)}
        callStatus={callStatus}
      />
    </Fragment>
  );
};
