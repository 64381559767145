import {
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { IoCameraOutline } from 'react-icons/io5';
import { CameraSwitchIcon } from 'src/components/Common/CameraSwitchIcon';
import { maxWidth } from 'src/constants/max-width.constant';
import { TranslationKeys } from 'src/constants/translation-keys';

import { useCapturePhoto } from 'src/hooks/usePhotoCapture';
import { useTranslate } from 'src/hooks/useTranslate';

interface CameraDialogProps extends Omit<ModalProps, 'children'> {
  onImageCapture: BlobCallback;
}

const CameraDialog: React.FC<CameraDialogProps> = ({
  onImageCapture,
  onClose,
  ...props
}) => {
  const { translate } = useTranslate();
  const { canvasRef, releaseCamera, takePhoto, videoRef, setIsFrontCamera } =
    useCapturePhoto(onImageCapture);

  const handleModalClose = () => {
    releaseCamera();
    onClose();
  };

  return (
    <Modal {...props} onClose={handleModalClose}>
      <ModalOverlay />

      <ModalContent maxW={maxWidth}>
        <ModalHeader>{translate(TranslationKeys.takeAPicture)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          h='full'
          display='flex'
          alignItems='center'
          position='relative'
        >
          <video ref={videoRef} style={{ minWidth: '100%' }} />
          <canvas
            ref={canvasRef}
            style={{
              display: 'none',
              position: 'absolute',
              width: videoRef.current?.clientWidth,
            }}
          />
        </ModalBody>
        <ModalFooter display='flex' justifyContent='center'>
          <IconButton
            borderRadius='52'
            colorScheme='blue'
            aria-label='capture image button'
            boxSize='20'
            icon={<Icon as={IoCameraOutline} boxSize='14' />}
            onClick={takePhoto}
          ></IconButton>
          <CameraSwitchIcon
            setIsFrontCamera={setIsFrontCamera}
            position='absolute'
            bottom='8'
            right='6'
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CameraDialog;
