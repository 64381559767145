import { HStack, Icon, ListItem, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { AlertMedia } from 'src/models/Alerts.model';
import { ALERT_PAGE_ROUTE } from 'src/routes/routeList';
import {
  getAlertMediaPreview,
  getAlertSubtitle,
} from 'src/utils/get-alert-details';

export interface AlertItemsProps {
  alertId: number;
  avatarComponent?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  alertMedias?: AlertMedia[];
  textContent?: string;
}

const AlertItem: React.FC<AlertItemsProps> = ({
  alertId,
  title,
  subtitle,
  avatarComponent,
  alertMedias,
  textContent,
}) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const handleOnClick = () => {
    history.push(ALERT_PAGE_ROUTE.replace(':alertId', alertId.toString()));
  };

  const alertMedia = _.head(alertMedias);

  const caption = getAlertSubtitle(textContent, alertMedia, translate);

  return (
    <ListItem
      as={HStack}
      py='2'
      onClick={() => handleOnClick()}
      cursor='pointer'
    >
      {avatarComponent}
      <HStack
        width='full'
        justifyContent='space-between'
        borderBottom='1px solid #E2E8F0'
        gridGap='2'
      >
        <VStack spacing='1' align='start' w='full'>
          <Text fontWeight='bold'>{title}</Text>
          {caption ? (
            <Text fontWeight='normal' color='gray.500' noOfLines={1}>
              {caption}
            </Text>
          ) : (
            <Text fontWeight='normal' color='gray.500'>
              {translate(TranslationKeys.raiseAnAlert)}
            </Text>
          )}
          <Text fontWeight='normal' color='gray.500' fontSize='15px'>
            {subtitle}
          </Text>
        </VStack>
        {alertMedia && (
          <Icon boxSize='2rem' as={getAlertMediaPreview(alertMedia)}></Icon>
        )}
      </HStack>
    </ListItem>
  );
};

export default AlertItem;
