import { Box, Center, SimpleGrid, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import BlogPageCard from './components/blog-page-card';
import usePages from './hooks/usePages';

const BlogsPage: React.FC = () => {
  const { translate } = useTranslate();
  const { pages, ...queryMetadata } = usePages();

  const EmptyPages = () => {
    return (
      <Text
        w='full'
        textAlign='center'
        fontSize='xl'
        color='gray.500'
        fontWeight='semibold'
      >
        {translate(TranslationKeys.noPagesYet)}
      </Text>
    );
  };

  const renderPages = () => {
    if (_.isEmpty(pages)) {
      return (
        <Center pt='4'>
          <Text fontSize='xl' color='gray.500' fontWeight='semibold'>
            {translate(TranslationKeys.noPagesToShow)}
          </Text>
        </Center>
      );
    }
    if (queryMetadata.isFetched && _.isEmpty(pages)) {
      return <EmptyPages />;
    }

    return (
      <SimpleGrid
        p='2'
        columns={1}
        spacing={4}
        mx='auto'
        justifyContent='center'
      >
        {pages.map(page => (
          <BlogPageCard key={page.id} page={page} />
        ))}
      </SimpleGrid>
    );
  };

  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.pages)}>
      <Box py='4'>{renderPages()}</Box>
    </HeaderWrapper>
  );
};

export default BlogsPage;
