import { AvatarProps } from '@chakra-ui/avatar';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import UserAvatar from './UserAvatar';

const CurrentUserAvatar: React.FC<Omit<AvatarProps, 'name'>> = props => {
  const { currentUser } = useUserDetails();

  return <UserAvatar user={currentUser} {...props} />;
};

export default CurrentUserAvatar;
