import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect } from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useHandsFreeOperation } from 'src/hoc/HandsFreeOperationProvider/hands-free-operation.provider';
import { useHFOAudioStream } from 'src/hoc/HandsFreeOperationProvider/hooks/use-hfo-audio';
import { useTranslate } from 'src/hooks/useTranslate';

import { WakeWordDetectionDialogBody } from './WakeWordDetectionDialogBody';

export const WakeWordDetectionDialog: React.FC = () => {
  const { translate } = useTranslate();
  const { hfoModelDisclosure, setIsListening } = useHandsFreeOperation();
  const { isEndOfUtteranceDetected, transcribeMessage, stopRecording } =
    useHFOAudioStream();

  useEffect(() => {
    setIsListening(false);
    return () => {
      setIsListening(true);
    };
  }, []);

  return (
    <Modal
      {...hfoModelDisclosure}
      onClose={() => {
        stopRecording();
        hfoModelDisclosure.onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader align='center'>
          {translate(TranslationKeys.tryToSay)} "
          {translate(TranslationKeys.commandSampleThree)}"
        </ModalHeader>
        <ModalBody>
          <WakeWordDetectionDialogBody
            isEndOfUtteranceDetected={isEndOfUtteranceDetected}
            transcribeMessage={transcribeMessage}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='red' mr={3} onClick={hfoModelDisclosure.onClose}>
            {translate(TranslationKeys.cancel)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
