import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
} from '@chakra-ui/tabs';
import _ from 'lodash';
import { UseInfiniteQueryResult } from 'react-query';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import useFeed from '../../../hooks/apis/useFeed';
import { FeedModel, User } from '../../../models';
import { FeedMember } from '../../../models/FeedMember.model';
import { Post } from '../../../models/Post.model';
import useCurrentUserFeedMember from './hooks/useCurrentUserFeedMember';
import PostList from './PostList';

interface PostListProps
  extends Pick<
    UseInfiniteQueryResult,
    'hasNextPage' | 'isFetching' | 'isFetchingNextPage' | 'fetchNextPage'
  > {
  feedId: number;
  posts: Post[];
}

const CustomTab: React.FC<TabProps> = props => (
  <Tab
    margin='1'
    fontWeight='bold'
    color='gray.500'
    borderRadius='10'
    _selected={{
      color: 'black',
      bg: 'white',
      // boxShadow: xAxisOffset yAxisOffset blur spread color
      boxShadow: '0 0.2rem 0.5rem -0.1rem grey',
    }}
    {...props}
  />
);

const canUserPost = (
  currentUser?: User,
  currentUserMemberRecord?: FeedMember,
  feed?: FeedModel,
) => {
  if (!currentUser) {
    return false;
  }

  if (!feed?.allowsPostByAnyUser && !currentUserMemberRecord?.canPost) {
    return false;
  }

  return true;
};

const PostsView: React.FC<PostListProps> = ({
  feedId,
  posts,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
}) => {
  const { translate } = useTranslate();
  const { feed } = useFeed(feedId);
  const { currentUser } = useUserDetails();
  const currentUserMemberRecord = useCurrentUserFeedMember(feedId);

  const postsGroupedByApproval = _.groupBy(
    posts,
    ({ isApproved }) => isApproved,
  );
  const approvedPosts = postsGroupedByApproval['true'] || [];
  const postsAwaitingApproval = postsGroupedByApproval['null'] || [];

  const currentUserPosts = _.filter(
    posts,
    post => post.CreatedByUserId === currentUser?.id,
  );
  const postsAwaitingApprovalExceptCurrentUser = _.filter(
    postsAwaitingApproval,
    post => post.CreatedByUserId !== currentUser?.id,
  );

  const isCurrentUserModerator = currentUserMemberRecord?.canModeratePosts;
  const canUserModeratePosts = feed?.isModerated && isCurrentUserModerator;
  const shouldShowYourPostsTab = canUserPost(
    currentUser,
    currentUserMemberRecord,
    feed,
  );

  // User has no extra tabs to be shown ("To Moderate" and "Your Posts") so simply render posts without tabs
  if (!(canUserModeratePosts || shouldShowYourPostsTab)) {
    return (
      <PostList
        mt='4'
        mx='2'
        posts={approvedPosts}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    );
  }

  return (
    <>
      <Tabs isFitted isLazy variant='unstyled' px='2' bg='gray.100'>
        <TabList bg='white' borderRadius='10'>
          <CustomTab>{translate(TranslationKeys.posts)}</CustomTab>
          {canUserModeratePosts && (
            <CustomTab>{translate(TranslationKeys.approve)}</CustomTab>
          )}
          {shouldShowYourPostsTab && (
            <CustomTab>{translate(TranslationKeys.yourPosts)}</CustomTab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel px='0' pt='8'>
            <PostList
              posts={approvedPosts}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
            />
          </TabPanel>
          {canUserModeratePosts && (
            <TabPanel px='0' pt='8'>
              <PostList
                posts={postsAwaitingApprovalExceptCurrentUser}
                isFetching={isFetching}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
              />
            </TabPanel>
          )}
          {shouldShowYourPostsTab && (
            <TabPanel px='0' pt='8' minH='full'>
              <PostList
                posts={currentUserPosts}
                showPostApprovalStatus
                isFetching={isFetching}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default PostsView;
