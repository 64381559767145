import { Center, HStack, Textarea } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

import '../css/mic-animation.css';
import { MicState } from '../types/mic-state.type';
import { MicrophoneComponent } from './asr-chat-mic-animation';

interface AsrChatVoiceAnimationProps {
  microPhoneRef: React.RefObject<HTMLInputElement>;
  transcribeMessage: string;
  setMicState: Dispatch<SetStateAction<MicState | undefined>>;
}

export const AsrChatVoiceAnimation: React.FC<AsrChatVoiceAnimationProps> = ({
  microPhoneRef,
  transcribeMessage,
  setMicState,
}) => {
  const onMicClick = () => {
    setMicState(prev => {
      if (prev === MicState.manualEnable) {
        return MicState.manualDisable;
      }
      return MicState.manualEnable;
    });
  };

  return (
    <HStack w='full' h='full'>
      <Center px='4' h='full' onMouseUp={onMicClick} cursor='pointer'>
        <MicrophoneComponent microPhoneRef={microPhoneRef} />
      </Center>
      <Textarea
        autoFocus={false}
        overflowY='auto'
        size='md'
        height='full'
        focusBorderColor='gray.300'
        value={transcribeMessage}
        _focus={{}}
        isReadOnly
      />
    </HStack>
  );
};
