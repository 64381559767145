import { FC } from 'react';

import { CallSession } from 'src/models/CallSession.model';

import VideoStream from '../../VideoStream';
import CallUserImage from './CallUserImage';

interface CallVideoProps {
  stream: MediaStream;
  peerVideoStatus?: boolean;
  name: string;
  session?: CallSession;
  height: string;
}

const CallVideo: FC<CallVideoProps> = ({
  stream,
  peerVideoStatus,
  name,
  session,
  height,
}) => {
  return (
    <>
      <VideoStream
        stream={stream}
        w='full'
        h={height}
        objectFit='cover'
        controls={false}
        display={peerVideoStatus ? 'block' : 'none'}
      />
      {!peerVideoStatus && (
        <CallUserImage name={name} hasJoined={!!session?.joinedAt!} />
      )}
    </>
  );
};

export default CallVideo;
