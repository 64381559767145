import { useMutation } from 'react-query';

import { createTextMessage, postTextMessage } from 'src/apis/messages.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserLocation } from 'src/hoc/UserLocationContext';
import { useToast } from 'src/hooks/use-toast';
import { useUpdateUserLocationInMessageThread } from 'src/hooks/use-update-user-location';
import useNewCall from 'src/hooks/useNewCall';
import { useTranslate } from 'src/hooks/useTranslate';
import { MessageType } from 'src/models/Message.model';

import { useHandsFreeOperation } from '../hands-free-operation.provider';

export enum CommandType {
  call = 'call',
  message = 'message',
  sos = 'sos',
  asrChat = 'asrChat',
}

export type NLPCommandExecutionResponse = {
  command: CommandType;
  recipientUserId?: number;
  messageThreadId?: number;
  message?: string;
  alertId?: number;
};

export const useHFOCommandExecution = () => {
  const { translate } = useTranslate();
  const { showToast } = useToast();
  const { locationStream } = useUserLocation();
  const { makePersonalCall } = useNewCall();
  const { setAlertId, onOpenAlertInformationProvider } =
    useHandsFreeOperation();
  const { updateUserLocation } = useUpdateUserLocationInMessageThread();

  const { mutate: postMessageMutate } = useMutation(postTextMessage);
  const { mutate: createMessageMutate } = useMutation(createTextMessage);

  const executeCommand = (response: NLPCommandExecutionResponse) => {
    if (response.command === CommandType.call && response.recipientUserId) {
      makePersonalCall({ ReceiverId: response.recipientUserId });
      return;
    }

    if (
      response.command === CommandType.message &&
      response.messageThreadId &&
      response.message
    ) {
      createMessageMutate(
        {
          messageThreadId: response.messageThreadId,
          text: response.message,
          messageType: MessageType.normalChat,
          latitude: locationStream?.latitude?.toString(),
          longitude: locationStream?.longitude?.toString(),
        },
        {
          onSuccess: () => {
            showToast({
              status: 'success',
              title: translate(TranslationKeys.messageSendSuccessfully),
            });
          },
        },
      );
      postMessageMutate({
        messageThreadId: response.messageThreadId,
        text: response.message,
        messageType: MessageType.normalChat,
      });
      return;
    }

    if (response.command === CommandType.asrChat && response.messageThreadId) {
      updateUserLocation(response.messageThreadId);
      return;
    }

    if (response.command === CommandType.sos && response.alertId) {
      setAlertId(response.alertId);
      showToast({
        status: 'success',
        title: translate(TranslationKeys.sosAlertSuccess),
      });
      onOpenAlertInformationProvider();
      return;
    }
  };

  return { executeCommand };
};
