import {
  Avatar,
  AvatarProps,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdCall, MdCallEnd } from 'react-icons/md';
import { maxWidth } from 'src/constants/max-width.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import IncomingCallButton from './components/IncomingCallButton';

interface IncomingCallAlertProps {
  callerName: string;
  avatarProps?: Pick<AvatarProps, 'src' | 'name'>;
  onAcceptCall: () => void;
  onRejectCall: () => void;
  sound: () => void;
  stopSound: () => void;
}

const IncomingCallAlert: React.FC<IncomingCallAlertProps> = ({
  avatarProps,
  callerName,
  onAcceptCall,
  onRejectCall,
  sound,
  stopSound,
}) => {
  const { translate } = useTranslate();
  // Toast by default puts a 0.5 rem margin on the toast container (The parent of the `Center` component).
  // Removing them using the `m` prop.
  // TODO: Find a better, non-hacky way to do this.

  useEffect(() => {
    sound();
    return () => {
      stopSound();
    };
  }, []);

  return (
    <Center w='100vw' m='-2'>
      <Center w='full' maxW={maxWidth} h='16' color='white'>
        <Flex alignItems='center' w='full' bg='gray.700' py='2' px='4'>
          <HStack flex={8} spacing='4'>
            {avatarProps && <Avatar size='sm' {...avatarProps} />}
            <VStack fontSize='sm' spacing='0' align='start'>
              <Text color='whiteAlpha.600'>
                {translate(TranslationKeys.incomingCall)}...
              </Text>
              <Text
                as='span'
                fontSize='md'
                fontWeight='semibold'
                letterSpacing='wide'
              >
                {callerName}
              </Text>
            </VStack>
          </HStack>
          <ButtonGroup w='full' spacing='2' flex={2} justifyContent='flex-end'>
            <IncomingCallButton
              aria-label='Accept Call'
              colorScheme='green'
              onClick={onAcceptCall}
              icon={MdCall}
            />
            <IncomingCallButton
              aria-label='Reject Call'
              colorScheme='red'
              onClick={onRejectCall}
              icon={MdCallEnd}
            />
          </ButtonGroup>
        </Flex>
      </Center>
    </Center>
  );
};

export default IncomingCallAlert;
