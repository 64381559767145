import { useQuery } from 'react-query';

import { getAllAlerts } from 'src/apis/alerts.api';
import { Alert } from 'src/models/Alerts.model';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kAlertsQueryKey = 'allAlerts';

export const useAlertsQueryClient = () => {
  return useArrayDataQuery<Alert>({
    queryKey: kAlertsQueryKey,
  });
};

const useAlerts = () => {
  const { data: allAlerts, ...queryMetadata } = useQuery<Alert[]>(
    kAlertsQueryKey,
    getAllAlerts,
  );

  return { allAlerts, queryMetadata };
};

export default useAlerts;
