import * as _ from 'lodash';

import { axiosInstance } from 'src/config/axiosInstance';
import { GroupCallResponse, GroupCalls } from 'src/models/GroupCall';
import { getAccessToken } from 'src/providers/auth-store.provider';

import { getRequestData } from './utils/get-request-data.util';

axiosInstance.interceptors.request.use(config => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

const kGroupCallsUrl = 'calls/group';

export const getAllGroupCalls = async (
  pageNumber: number,
): Promise<GroupCallResponse> => {
  const { calls, pageSize, total } = await getRequestData<GroupCallResponse>(
    axiosInstance.get(`${kGroupCallsUrl}?pageNumber=${pageNumber}`),
  );

  const groupCalls = _.map(calls, (groupCall: GroupCalls) => ({
    ...groupCall,
    createdAt: new Date(groupCall.createdAt),
  }));

  return { calls: groupCalls, pageSize, total };
};
