import { UseToastOptions, useToast } from '@chakra-ui/react';

const TOAST_VISIBILITY_TIME_MS = 5000;

export const AlertMessage = () => {
  const toast = useToast();
  const showToast = (
    { title, status }: Pick<UseToastOptions, 'title' | 'status'>,
    isCloseAlreadyOpened = true,
  ) => {
    /*
      General behavior of toast is that they will queue on the screen one after another.
      And they will keep on showing until the user closes them or the time is over for the toast.
      Depending on the implementation. But for same type of toast, we don't want to show multiple toast.
      And there are not many cases in our app to have multiple toast at the same time. Neither for different types.
      So, we are closing all the toast before showing the new toast.
      If isCloseAlreadyOpened is true, then close all the opened toast before showing the new toast.
      Default is true that means it will always close the open toast's before showing the new toast.
    */
    if (isCloseAlreadyOpened) {
      toast.closeAll();
    }
    toast({
      title,
      status,
      position: 'bottom',
      duration: TOAST_VISIBILITY_TIME_MS,
    });
  };
  return showToast;
};
