import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import _ from 'lodash';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import {
  CUSTOM_SCROLL_BAR_CSS,
  CUSTOM_TABS_PADDING_TOP,
  CUSTOM_TABS_PADDING_TOP_PX,
  INITIAL_TAB_INDEX,
  PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER,
  TAB_LIST_HEIGHT,
} from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import {
  getCallsTabIndexNumber,
  setCallsTabIndexNumber,
} from 'src/pages/calls/utils/call.util';

import { CommonTab } from './CommonTab';

interface CustomTabsProps {
  pageRef?: MutableRefObject<HTMLDivElement | null>;
  firstTabPanel: React.ReactNode;
  secondTabPanel: React.ReactNode;
  thirdTabPanel?: React.ReactNode;
  firstTabTitle?: string;
  secondTabTitle?: string;
  thirdTabTitle?: string;
  unreadPersonalCount?: number;
  unreadGroupCount?: number;
  tabIndexKey: string;
}

export const CustomTabs: React.FC<CustomTabsProps> = ({
  pageRef,
  secondTabPanel,
  firstTabPanel,
  thirdTabPanel,
  unreadGroupCount,
  unreadPersonalCount,
  firstTabTitle,
  secondTabTitle,
  thirdTabTitle,
  tabIndexKey,
}) => {
  const { translate } = useTranslate();
  const firstTabTitleValue =
    firstTabTitle ?? translate(TranslationKeys.personal);
  const secondTabTitleValue =
    secondTabTitle ?? translate(TranslationKeys.groups);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const tabListRef = useRef<HTMLDivElement>(null);

  const handleTabChange = (indexValue: number) => {
    setTabIndex(indexValue);
    setCallsTabIndexNumber(tabIndexKey, _.toString(indexValue));
  };

  const tabPanelHeight = useMemo(
    () =>
      tabListRef.current
        ? `calc(${PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER} - ${
            tabListRef.current?.clientHeight + CUSTOM_TABS_PADDING_TOP_PX
          }px)`
        : `calc(${PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER} - ${
            TAB_LIST_HEIGHT + CUSTOM_TABS_PADDING_TOP_PX
          }px)`,
    [tabListRef.current],
  );

  useEffect(() => {
    const getTabIndexFromLocalStorage = _.toNumber(
      getCallsTabIndexNumber(tabIndexKey),
    );

    getTabIndexFromLocalStorage
      ? setTabIndex(_.toNumber(getTabIndexFromLocalStorage))
      : setTabIndex(INITIAL_TAB_INDEX);
  }, []);

  return (
    <Tabs
      isFitted
      isLazy
      pt={CUSTOM_TABS_PADDING_TOP}
      variant='unstyled'
      index={tabIndex}
      onChange={handleTabChange}
    >
      <TabList
        bg='gray.100'
        borderRadius='10'
        mx={{ base: '2', sm: '4' }}
        zIndex='10'
        ref={tabListRef}
      >
        <CommonTab
          title={firstTabTitleValue}
          unreadCount={unreadPersonalCount}
        />
        <CommonTab title={secondTabTitleValue} unreadCount={unreadGroupCount} />
        {thirdTabTitle && <CommonTab title={thirdTabTitle} />}
      </TabList>
      <TabPanels
        h={tabPanelHeight}
        w='full'
        px={{ base: '2', sm: '4' }}
        overflow='auto'
        css={CUSTOM_SCROLL_BAR_CSS}
        ref={pageRef}
      >
        <TabPanel>{firstTabPanel}</TabPanel>
        <TabPanel>{secondTabPanel}</TabPanel>
        {thirdTabPanel && <TabPanel>{thirdTabPanel}</TabPanel>}
      </TabPanels>
    </Tabs>
  );
};
