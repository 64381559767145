import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { IoMdPersonAdd } from 'react-icons/io';

interface AddMembersIconProps extends Omit<IconButtonProps, 'aria-label'> {
  openSelectUserToCallModal: () => void;
  iconFill?: string;
}

export const AddMembersIcon: React.FC<AddMembersIconProps> = ({
  openSelectUserToCallModal,
  iconFill = 'black',
  ...props
}) => {
  return (
    <IconButton
      aria-label='Add members'
      variant='ghost'
      onClick={openSelectUserToCallModal}
      size='xl'
      icon={<IoMdPersonAdd size='30' fill={iconFill} />}
      _focus={{}}
      _active={{}}
      {...props}
    />
  );
};
