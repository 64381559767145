import { HStack, Image, Text } from '@chakra-ui/react';
import { TextSectionItemProps } from './types/TextSectionItem.props.types';

export const TextSectionItem: React.FC<TextSectionItemProps> = ({
  iconImgSrc,
  text,
}) => {
  return (
    <HStack spacing='4'>
      <Image src={iconImgSrc} objectFit='cover' boxSize='14' minW='14' />
      <Text fontSize='md' maxHeight='full' noOfLines={3}>
        {text}
      </Text>
    </HStack>
  );
};
