import { Box, Text } from '@chakra-ui/react';
import * as L from 'leaflet';
import { FC, useContext, useRef } from 'react';
import { MapContainer } from 'react-leaflet';

import { LeafletMarker, MarkerColors } from 'src/components/Marker';
import {
  DEFAULT_CENTER_LOCATION,
  DEFAULT_USER_LOCATION,
  DEFAULT_ZOOM_FOR_ALERTS_MAP,
  DEFAULT_ZOOM_FOR_LOCAL_STREAM_NOT_AVAILABLE,
  MAX_BOUNDS,
  MAX_ZOOM,
  MIN_ZOOM,
} from 'src/constants/maps.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { UserLocationContext } from 'src/hoc/UserLocationContext/user-location.context';
import { useTranslate } from 'src/hooks/useTranslate';
import { MapContainerBodyContent } from './map-container';

//INFO: Since react leaflet cannot read icon, we are doing this.
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

interface AsrMapSectionProps {
  leafletMarkerPositions: {
    latitude: string;
    longitude: string;
    userName: string;
  }[];
}

export const AsrMapSection: FC<AsrMapSectionProps> = ({
  leafletMarkerPositions,
}) => {
  const mapRef = useRef();
  const { locationStream } = useContext(UserLocationContext);
  const { translate } = useTranslate();

  const getLatitude = () => {
    if (locationStream?.latitude) {
      return locationStream?.latitude;
    }
    return DEFAULT_CENTER_LOCATION.latitude;
  };

  const getLongitude = () => {
    if (locationStream?.longitude) {
      return locationStream?.longitude;
    }
    return DEFAULT_CENTER_LOCATION.longitude;
  };

  const userPosition = locationStream
    ? [locationStream?.latitude, locationStream?.longitude]
    : DEFAULT_USER_LOCATION;

  const mapZoom = locationStream
    ? DEFAULT_ZOOM_FOR_ALERTS_MAP
    : DEFAULT_ZOOM_FOR_LOCAL_STREAM_NOT_AVAILABLE;

  return (
    <Box position='relative' w='full'>
      <MapContainer
        style={{
          height: '100%',
          border: '1px solid #000',
          borderRadius: '10px',
        }}
        ref={mapRef}
        center={new L.LatLng(getLatitude(), getLongitude())}
        zoom={mapZoom}
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        maxBounds={MAX_BOUNDS}
      >
        <MapContainerBodyContent
          leafletMarkerPositions={leafletMarkerPositions}
          mapZoom={mapZoom}
          mapCenter={new L.LatLng(getLatitude(), getLongitude())}
        />
        {userPosition && locationStream && (
          <LeafletMarker
            position={userPosition}
            markerColor={MarkerColors.blue}
            markerContent={
              <Text p='3'>
                {translate(TranslationKeys.yourCurrentLocation)}
              </Text>
            }
          />
        )}
      </MapContainer>
    </Box>
  );
};
