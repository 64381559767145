import {
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { AppNavigationType } from 'src/types/navigation.type';
import { getFeed } from '../../apis';
import { getUserDetails } from '../../apis/users.api';
import FeedCoverImage from '../../components/FeedCoverImage';
import UserAvatar from '../../components/UserAvatar';
import HeaderWrapper from '../../hoc/HeaderWrapper';
import useDialog from '../../hooks/useDialog';
import { FeedModel, User } from '../../models';
import { FeedMember } from '../../models/FeedMember.model';
import {
  getUserDisplayName,
  usersAlphabeticSortWithCurrentUserLast,
} from '../../utils/user.utils';
import AddFeedMemberDialog from './components/AddFeedMemberDialog';
import { FeedMemberCard } from './components/FeedMemberCard';
import useFeedMembers from './hooks/useFeedMembers';

interface FeedMembersPagePathParams {
  id: string;
}

const FeedMembersPage = () => {
  const { translate } = useTranslate();
  const feedId = +useParams<FeedMembersPagePathParams>().id;
  const { data: feed } = useQuery<FeedModel>('getOneFeed', () =>
    getFeed(feedId.toString()),
  );
  const { data: feedCreatorUser } = useQuery<User | undefined>(
    ['getUser', feed?.CreatedByUserId],
    () => {
      if (!feed) return;

      return getUserDetails(feed.CreatedByUserId.toString());
    },
    {
      enabled: Boolean(feed),
    },
  );

  const { feedMembers, addMultipleMembers, removeMember } = useFeedMembers({
    feedId,
  });
  const { currentUser } = useUserDetails();
  const currentUserMemberRecord = _.find(
    feedMembers,
    member => member.UserId === currentUser?.id,
  );

  const feedMembersWithoutCreator = _.filter(
    feedMembers,
    member => member.UserId !== feed?.CreatedByUserId,
  );

  const [isAddMemberDialogOpen, openAddMemberDialog, closeAddMemberDialog] =
    useDialog();

  const handleOnMembersAdded = (members: FeedMember[]) => {
    addMultipleMembers(members);
    closeAddMemberDialog();
  };

  return (
    <HeaderWrapper
      pageTitle={feed?.name || translate(TranslationKeys.feedMembers)}
      navigationType={AppNavigationType.back}
    >
      {feed && (
        <Flex
          border='1px solid'
          borderColor='gray.200'
          my='2'
          p='1'
          mx='1'
          flexDir='column'
        >
          <Heading my='2' isTruncated>
            {feed.name}
          </Heading>
          {/* Need to provide minH as well since FeedCoverImage has a min height which won't allow the maxH here to work */}
          <FeedCoverImage
            coverImageUrl={feed.coverImageUrl}
            minH='44'
            maxH='44'
            objectFit='cover'
          />
          <Flex m='2' justifyContent='space-between' alignItems='flex-start'>
            <Text my='2' noOfLines={4}>
              {feed.description}
            </Text>
          </Flex>
        </Flex>
      )}
      <Grid gap='2' mx='4'>
        <Grid gap='8' my='4'>
          {feedCreatorUser && (
            <VStack align='start'>
              <Text fontSize='lg' fontWeight='semibold'>
                {translate(TranslationKeys.feedCreator)}
              </Text>
              <HStack spacing='4' w='full'>
                <UserAvatar user={feedCreatorUser} />
                <VStack align='start' spacing='0'>
                  <HStack>
                    <Text fontWeight='bold'>
                      {getUserDisplayName(feedCreatorUser)}
                    </Text>
                    {feedCreatorUser.id === currentUser?.id && (
                      <Text as='span' color='gray' fontWeight='500'>
                        ({translate(TranslationKeys.you)})
                      </Text>
                    )}
                  </HStack>
                  <Text color='gray.500' fontSize='sm'>
                    {feedCreatorUser?.email}
                  </Text>
                </VStack>
              </HStack>
              <Divider my='-2' />
            </VStack>
          )}
          <HStack w='full'>
            <Text fontSize='lg' fontWeight='semibold'>
              {translate(TranslationKeys.feedMembers)}
            </Text>
            <Spacer />
            {currentUserMemberRecord?.canModerateMembers && (
              <IconButton
                aria-label='launch add feed member dialog'
                colorScheme='blue'
                size='sm'
                borderRadius='8'
                icon={<Icon as={MdAdd} boxSize='6' />}
                onClick={openAddMemberDialog}
              />
            )}
          </HStack>
          {_.chain(feedMembersWithoutCreator)
            .sortBy(({ user }) =>
              usersAlphabeticSortWithCurrentUserLast(user, currentUser),
            )
            .value()
            .map((member, index, { length: memberLength }) => {
              const isLastMember = index === memberLength - 1;

              return (
                <React.Fragment key={member.id}>
                  {currentUser && isLastMember && <Divider my='-2' />}
                  <FeedMemberCard
                    feedMember={member}
                    onMemberRemoved={() => removeMember(member.id)}
                    currentUserMemberRecord={currentUserMemberRecord}
                    mt={currentUser && isLastMember ? '-4' : 'unset'}
                  />
                </React.Fragment>
              );
            })}
        </Grid>
      </Grid>
      <AddFeedMemberDialog
        isOpen={isAddMemberDialogOpen}
        onClose={closeAddMemberDialog}
        feedId={feedId}
        onMembersAdded={handleOnMembersAdded}
        existingMembers={feedMembers || []}
      />
    </HeaderWrapper>
  );
};

export default FeedMembersPage;
