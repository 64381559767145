import Icon from '@chakra-ui/icon';
import { Button, HStack, Text, useToast } from '@chakra-ui/react';
import { MdExitToApp } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { logout } from 'src/apis/auth.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import useAuthStore from 'src/hooks/useAuthStore';
import { useTranslate } from 'src/hooks/useTranslate';

import useDialog from '../hooks/useDialog';
import { LOGIN_PAGE_ROUTE } from '../routes/routeList';
import ConfirmationDialog from './ConfirmationDialog';

const USER_LOGOUT_MESSAGE = 'logout';

interface LogoutButtonProps {}

export const LogoutButton: React.FC<LogoutButtonProps> = () => {
  const { translate } = useTranslate();
  const [
    isLogoutConfirmationDialogOpen,
    openLogoutConfirmationDialog,
    closeLogoutConfirmationDialog,
  ] = useDialog();
  const history = useHistory();
  const toast = useToast();

  const { removeAccessToken } = useAuthStore();
  const { currentUser, queryResult } = useUserDetails();
  const { mutate: logoutMutate } = useMutation(logout);

  if (!currentUser) {
    return null;
  }

  const logoutUser = () => {
    const userLogoutData = {
      currentUser: currentUser.id,
      logoutMessage: USER_LOGOUT_MESSAGE,
    };
    logoutMutate(userLogoutData, {
      onSuccess: () => {
        removeAccessToken();
        closeLogoutConfirmationDialog();
        queryResult.remove();
        history.replace(LOGIN_PAGE_ROUTE);
      },
      onError: () => {
        toast({
          title: 'Logout failed',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
        });
      },
    });
  };

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Button
        variant='unstyled'
        width='full'
        pr='4px'
        fontWeight='normal'
        height='max-content'
        onClick={openLogoutConfirmationDialog}
      >
        <HStack width='full' justifyContent='space-between'>
          <Text>{translate(TranslationKeys.logout)}</Text>
          <Icon as={MdExitToApp} boxSize='6' />
        </HStack>
      </Button>
      <ConfirmationDialog
        onConfirmed={logoutUser}
        title={translate(TranslationKeys.logout)}
        description={translate(TranslationKeys.areYouSureLogout)}
        isOpen={isLogoutConfirmationDialogOpen}
        onClose={closeLogoutConfirmationDialog}
      />
    </>
  );
};
