import { CustomTabs } from 'src/components';
import { DRAFTS_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import usePage from 'src/pages/blogs/blog/hooks/usePage';
import { AppNavigationType } from 'src/types/navigation.type';

import {
  DraftPageEditContent,
  DraftPageContentStatus,
} from './components/draft-page-content';

const DraftPageContentTabs = () => {
  const { page } = usePage();
  const { translate } = useTranslate();

  return (
    <HeaderWrapper
      pageTitle={page?.title || translate(TranslationKeys.pageDraft)}
      navigationType={AppNavigationType.back}
    >
      <CustomTabs
        firstTabPanel={<DraftPageEditContent />}
        secondTabPanel={<DraftPageContentStatus />}
        firstTabTitle={translate(TranslationKeys.editContent)}
        secondTabTitle={translate(TranslationKeys.contentStatus)}
        tabIndexKey={DRAFTS_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY}
      />
    </HeaderWrapper>
  );
};

export default DraftPageContentTabs;
