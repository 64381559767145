import { HStack, IconButton } from '@chakra-ui/react';
import { MdCall, MdMessage } from 'react-icons/md';

import { useChat } from 'src/hooks/use-chat';
import useNewCall from 'src/hooks/useNewCall';

interface CallMessageIconProps {
  userId: number;
  /**
   * function to be called when any type of communication(chat or call) is started.
   */
  onCommunicationStart?: () => void;
}

enum CommunicationType {
  chat = 'chat',
  call = 'call',
}

export const CallMessageIcon: React.FC<CallMessageIconProps> = ({
  userId,
  onCommunicationStart,
}) => {
  const { startChat } = useChat(userId, onCommunicationStart);
  const { makePersonalCall } = useNewCall();

  const startCommunication = (type: CommunicationType) => {
    if (type === CommunicationType.chat) {
      startChat();
      return;
    }
    onCommunicationStart?.();
    makePersonalCall({ ReceiverId: userId });
  };

  if (!userId) {
    return null;
  }

  return (
    <HStack w='full' justifyContent='center' spacing='6'>
      <IconButton
        onClick={() => startCommunication(CommunicationType.chat)}
        aria-label='message'
        icon={<MdMessage size='24px' />}
      />
      <IconButton
        onClick={() => startCommunication(CommunicationType.call)}
        aria-label='call'
        icon={<MdCall size='24px' />}
      />
    </HStack>
  );
};
