import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { BoxProps, Center, Flex } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/tag';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdArrowDownward } from 'react-icons/md';
import { animateScroll, Events } from 'react-scroll';

import {
  onNewMessage,
  TextAudio,
  unsubscribeNewMessage,
} from 'src/apis/socket.io/messages.socket.io';
import { CUSTOM_SCROLL_BAR_CSS } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { Message } from 'src/models/Message.model';
import { sortByDate } from 'src/utils/sort-by-date';

import { useMessageAudio } from '../hooks/useMessageAudio';
import { MessageBoxContainer } from './MessageBoxContainer';

interface ChatMessagesPanelProps extends BoxProps {
  messages: Message[];
  MessageComponent?: React.ComponentType<
    { message: Message; senderNameColor?: BoxProps['color'] } & BoxProps
  >;
  textAudio?: TextAudio;
  setTextAudio: React.Dispatch<React.SetStateAction<TextAudio | undefined>>;
  hasAccepted: boolean | null;
  hasRecipientBlocked: boolean;
  isAsRChat?: boolean;
}

const dateMonthYear = (dateObj: Date) => {
  return Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
    day: '2-digit',
  }).format(dateObj);
};

const renderMessages = (
  messages: Message[],
  currentUserId: number,
  hasAccepted: boolean | null,
  MessageComponent?: ChatMessagesPanelProps['MessageComponent'],
  textAudio?: TextAudio,
) => {
  return messages.map(message => {
    return (
      <MessageBoxContainer
        key={message.id}
        message={message}
        alignMessage={message.SenderId === currentUserId ? 'end' : 'start'}
        MessageComponent={MessageComponent}
        textAudio={textAudio}
        hasAccepted={hasAccepted}
      />
    );
  });
};

const ChatMessagesPanel: React.FC<ChatMessagesPanelProps> = ({
  messages,
  MessageComponent,
  textAudio,
  setTextAudio,
  hasAccepted,
  hasRecipientBlocked,
  isAsRChat,
  ...props
}) => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();
  useMessageAudio(textAudio, setTextAudio);

  const groupedMessages: Record<string, Message[]> = _.groupBy(
    messages,
    message => dateMonthYear(message.createdAt),
  );

  // We need the most recent/last msg in the top of the list hence order by descending
  const groupedMessageKeys = Object.keys(groupedMessages).sort((a, b) =>
    sortByDate(a, b, 'desc'),
  );

  const msgContainerRef = useRef<HTMLDivElement | null>(null);

  const [shouldShowNewMsgBtn, setShouldShowNewMsgBtn] = useState(false);

  const checkIfScrolledTooFar = () => {
    const container = msgContainerRef.current;
    return !!container && container.scrollTop < -100;
  };

  const getPaddingBottom = () => {
    if (hasRecipientBlocked || !hasAccepted) {
      return { base: '24', sm: '16' };
    }
    return 'none';
  };

  useEffect(() => {
    Events.scrollEvent.register('end', function () {
      setShouldShowNewMsgBtn(false);
    });

    return () => Events.scrollEvent.remove('end');
  }, []);

  const scrollToBottom = useCallback(
    () =>
      animateScroll.scrollToBottom({
        containerId: 'chat-messages-panel',
        smooth: 'easeInQuad',
      }),
    [],
  );

  const handleNewMessage = useCallback(() => {
    if (checkIfScrolledTooFar()) {
      setShouldShowNewMsgBtn(true);
    } else {
      scrollToBottom();
    }
  }, []);

  useEffect(() => {
    onNewMessage(handleNewMessage);
    return () => unsubscribeNewMessage(handleNewMessage);
  }, [handleNewMessage]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      {shouldShowNewMsgBtn && !isAsRChat && (
        <Center position='absolute' bottom='2' left='0' w='full'>
          <Button
            rightIcon={<Icon as={MdArrowDownward} mt='1' />}
            alignItems='center'
            size='sm'
            colorScheme='blue'
            onClick={scrollToBottom}
            zIndex='10'
          >
            {translate(TranslationKeys.newMessages)}
          </Button>
        </Center>
      )}
      <Flex
        {...props}
        w='full'
        h='full'
        flexDir='column-reverse'
        px='4'
        id='chat-messages-panel'
        ref={msgContainerRef}
        overflowX='hidden'
        pb={getPaddingBottom()}
        css={CUSTOM_SCROLL_BAR_CSS}
      >
        {groupedMessageKeys.map(msgKey => {
          let dateLabel = msgKey;

          const msgGroupDate = new Date(msgKey);
          const dateNow = new Date();
          const dateDiff = dateNow.getDate() - msgGroupDate.getDate();

          if (dateDiff === 0) {
            dateLabel = translate(TranslationKeys.today);
          }

          if (dateDiff === 1) {
            dateLabel = translate(TranslationKeys.yesterday);
          }

          return (
            <React.Fragment key={msgKey}>
              {renderMessages(
                groupedMessages[msgKey].sort((a, b) =>
                  sortByDate(a.createdAt, b.createdAt, 'desc'),
                ),
                currentUser.id,
                hasAccepted,
                MessageComponent,
                textAudio,
              )}
              <Center>
                <Tag mb='2' mt='6' align='center' color='gray.500' size='sm'>
                  {dateLabel}
                </Tag>
              </Center>
            </React.Fragment>
          );
        })}
      </Flex>
    </>
  );
};

export default ChatMessagesPanel;
