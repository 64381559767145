import _ from 'lodash';

import { Call, MessageGroup } from 'src/models/Call.model';
import { CallSession } from 'src/models/CallSession.model';

export const getGroupCallNameOrImage = (
  call: Call,
  query: 'name' | 'imageUrl',
) => {
  if (query === 'name') {
    return call.messageGroup ? call.messageGroup.name : 'Group Call';
  }
  return call.messageGroup ? call.messageGroup.imageUrl : '';
};

export const getCallerName = (
  messageGroup: MessageGroup,
  sessions: CallSession[],
) => {
  if (messageGroup) {
    return messageGroup.name;
  }

  const uniqueSessions = _.uniqBy(sessions, 'otherUsers.id');

  return uniqueSessions.map(session => session.otherUsers.fullName).join(', ');
};
