import { VStack, HStack, Text } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { User } from 'src/models';
import { getUserFullName } from 'src/utils';

interface DraftReviewProps {
  reviewedBy: User | undefined;
  reviewRemarks: string | undefined;
}

export const DraftReview: React.FC<DraftReviewProps> = ({
  reviewedBy,
  reviewRemarks,
}) => {
  const { translate } = useTranslate();
  if (!reviewedBy || !reviewRemarks) {
    return null;
  }

  return (
    <VStack
      p='2'
      w='full'
      align='start'
      rounded='md'
      borderColor='gray.200'
      bgColor='gray.200'
    >
      <HStack align='start'>
        <Text fontWeight='semibold'>
          {translate(TranslationKeys.reviewBy)}:{' '}
        </Text>
        <Text noOfLines={1}>{getUserFullName(reviewedBy)}</Text>
      </HStack>
      <HStack align='start'>
        <Text fontWeight='semibold'>
          {translate(TranslationKeys.reviewRemark)}:{' '}
        </Text>
        <Text>{reviewRemarks}</Text>
      </HStack>
    </VStack>
  );
};
