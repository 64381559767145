import { axiosInstance } from 'src/config';
import { SOSMediaUpdateDto } from 'src/dto/SoS.dto';
import { Alert } from 'src/models/Alerts.model';
import { getRequestData } from './utils/get-request-data.util';

export interface CreateNewAlertDto {
  RaisedBy?: number;
  latitude?: string | null;
  longitude?: string | null;
}

// TODO: will change the interface from MediaMessageDTO to AlertMediaDto
export const createNewAlert = async (
  payload: CreateNewAlertDto,
): Promise<Alert> => {
  const res = await axiosInstance.post('/alerts', payload);

  return res.data;
};

export const getAllAlerts = async (): Promise<Alert[]> => {
  const allAlerts = await getRequestData<Alert[]>(axiosInstance.get('/alerts'));
  return allAlerts.map<Alert>(alert => ({
    ...alert,
    createdAt: new Date(alert.createdAt),
  }));
};

export const getResolvedAlerts = async (): Promise<Alert[]> => {
  const allAlerts = await getRequestData<Alert[]>(
    axiosInstance.get('/alerts/resolved'),
  );
  return allAlerts.map<Alert>(alert => ({
    ...alert,
    createdAt: new Date(alert.createdAt),
  }));
};

export const updateAlertMediaApi = (dto: SOSMediaUpdateDto) => {
  const formData = new FormData();

  if (dto.alertId) {
    formData.append('alertId', dto.alertId);
  }
  if (dto.textContent) {
    formData.append('textContent', dto.textContent);
  }
  if (dto.caption) {
    formData.append('caption', dto.caption);
  }
  if (dto.messageMedia) {
    for (let file of dto.messageMedia) {
      formData.append('files', file);
    }
  }
  return { url: '/alerts/upload', formData };
};

export const resolveAlert = async (alertId: number) => {
  return axiosInstance.put('/alerts/markAsResolved', {
    AlertId: alertId,
  });
};

export const getOneAlert = async (alertId: string) => {
  const { data } = await axiosInstance.get(`/alerts/${alertId}`);
  return data;
};
