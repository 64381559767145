import { Route, Switch } from 'react-router-dom';

import NotFoundPage from 'src/components/NotFoundPage';
import AlertListPage from 'src/pages/alerts/AlertListPage';
import ASRChat from 'src/pages/asr-chat';
import { AsrChatHistory } from 'src/pages/asr-chat/history';
import CallsPage from 'src/pages/calls/CallsPage';
import { FeedPostsMap } from 'src/pages/feed-post-maps';
import { CommentCard } from 'src/pages/feeds/feed/CommentCard';
import { AlertPage } from 'src/pages/home/components/AlertPage';
import DraftPageContent from 'src/pages/page-members/edit-page-content/DraftPageContent';
import EditPageContent from 'src/pages/page-members/edit-page-content/EditPageContent';
import PageMembersPage from 'src/pages/page-members/PageMembersPage';
import GroupCall from 'src/pages/peer-call/group-call/group-call-page';
import PeerCallPage from 'src/pages/peer-call/PeerCallPage';
import ResetPassword from 'src/pages/reset-password';

import HomePage from '../pages';
import BlogPage from '../pages/blogs/blog/BlogPage';
import BlogsPage from '../pages/blogs/BlogsPage';
import ChatPage from '../pages/chat/ChatPage';
import FeedMembersPage from '../pages/feed-members/FeedMembersPage';
import FeedsPage from '../pages/feeds';
import FeedPage from '../pages/feeds/feed';
import LoginPage from '../pages/login/LoginPage';
import MessageMembersPage from '../pages/message-members/MessageMembersPage';
import MessagesPage from '../pages/messages/MessagesPage';
import RegisterPage from '../pages/register/RegisterPage';
import MyProfilePage from '../pages/users/me';
import UserProfilePage from '../pages/users/profile';
import {
  ALERT_LIST_ROUTE,
  ALERT_PAGE_ROUTE,
  ASR_CHAT_HISTORY_PAGE_ROUTE,
  ASR_CHAT_PAGE_ROUTE,
  CALLS_PAGE_ROUTE,
  CALL_PAGE_ROUTE,
  CHAT_PAGE_ROUTE,
  COMMENT_CARD_ROUTE,
  DRAFT_REQUEST_PAGE_ROUTE,
  FEEDS_PAGE_ROUTE,
  FEED_MEMBERS_PAGE_ROUTE,
  FEED_PAGE_ROUTE,
  FEED_POSTS_MAP_VIEW,
  GROUP_CALL_ROUTE,
  HOME_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  MESSAGES_PAGE_ROUTE,
  MESSAGE_MEMBERS_PAGE_ROUTE,
  MY_PROFILE_ROUTE,
  PAGES_ROUTE,
  PAGE_CONTENT_PAGE_ROUTE,
  PAGE_MEMBERS_PAGE_ROUTE,
  PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
  RESET_PASSWORD_ROUTE,
  USER_PROFILE_ROUTE,
} from './routeList';

const Routes = () => {
  return (
    <Switch>
      <Route path={FEEDS_PAGE_ROUTE} exact component={FeedsPage} />
      <Route path={FEED_PAGE_ROUTE} exact component={FeedPage} />
      <Route path={FEED_POSTS_MAP_VIEW} exact component={FeedPostsMap} />
      <Route path={FEED_MEMBERS_PAGE_ROUTE} exact component={FeedMembersPage} />
      <Route path={HOME_PAGE_ROUTE} exact component={HomePage} />
      <Route path={USER_PROFILE_ROUTE} exact component={UserProfilePage} />
      <Route path={MY_PROFILE_ROUTE} exact component={MyProfilePage} />
      <Route path={LOGIN_PAGE_ROUTE} exact component={LoginPage} />
      <Route path={REGISTER_PAGE_ROUTE} exact component={RegisterPage} />
      <Route path={MESSAGES_PAGE_ROUTE} exact component={MessagesPage} />
      <Route path={CHAT_PAGE_ROUTE} exact component={ChatPage} />
      <Route
        path={MESSAGE_MEMBERS_PAGE_ROUTE}
        exact
        component={MessageMembersPage}
      />
      <Route path={PAGES_ROUTE} exact component={BlogsPage} />
      <Route path={PAGE_ROUTE} exact component={BlogPage} />
      <Route path={PAGE_MEMBERS_PAGE_ROUTE} exact component={PageMembersPage} />
      <Route path={PAGE_CONTENT_PAGE_ROUTE} exact component={EditPageContent} />
      <Route
        path={DRAFT_REQUEST_PAGE_ROUTE}
        exact
        component={DraftPageContent}
      />
      <Route path={CALL_PAGE_ROUTE} exact component={PeerCallPage} />

      <Route path={CALLS_PAGE_ROUTE} exact component={CallsPage} />
      <Route path={GROUP_CALL_ROUTE} exact component={GroupCall} />

      <Route path={COMMENT_CARD_ROUTE} exact component={CommentCard} />
      <Route path={ALERT_LIST_ROUTE} exact component={AlertListPage} />
      <Route path={ALERT_PAGE_ROUTE} exact component={AlertPage} />
      <Route path={RESET_PASSWORD_ROUTE} exact component={ResetPassword} />
      <Route path={ASR_CHAT_PAGE_ROUTE} exact component={ASRChat} />
      <Route
        path={ASR_CHAT_HISTORY_PAGE_ROUTE}
        exact
        component={AsrChatHistory}
      />
      <Route path='*' component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
