import { RESET_PASSWORD_TOKEN_KEY } from 'src/hooks/useResetPasswordToken';
import { ForgetPasswordDto, LoginDto, RegisterDto } from 'src/schema';
import { ResetPasswordDto } from 'src/schema/reset-password.schema';
import { axiosInstance } from '../config';
import {
  LoginResponseDto,
  LogoutResponseDto,
  RegisterResponseDto,
} from '../dto/auth.dto';
import { User } from '../models';
import { getRequestData } from './utils/get-request-data.util';
import { getAuthorizationHeaders } from './utils/set-authorization-headers.util';

const rootUrl = '/auth';

export const login = async (loginData: LoginDto): Promise<LoginResponseDto> => {
  const endpointUrl = `${rootUrl}/login`;
  const res = await axiosInstance.post(endpointUrl, loginData);

  return res.data;
};

export const register = async (
  registerData: RegisterDto,
): Promise<RegisterResponseDto> => {
  const endpointUrl = `${rootUrl}/register`;
  const res = await axiosInstance.post(endpointUrl, registerData);

  return res.data;
};

export const logout = async (logoutData: {
  currentUser: number;
  logoutMessage: string;
}): Promise<LogoutResponseDto> => {
  const res = await axiosInstance.post('activity-logs/user-logout', logoutData);
  return res.data;
};

export const getCurrentUser = () => {
  const url = `${rootUrl}/me`;

  return getRequestData<User>(
    axiosInstance.get(url, { headers: getAuthorizationHeaders() }),
  );
};

export const getIsLacsUnitIsCloud = () => {
  const url = `${rootUrl}/allow/forget-password`;
  return getRequestData<boolean>(axiosInstance.get(url));
};

export const sendForgetPasswordMail = (emailPayload: ForgetPasswordDto) => {
  const url = `${rootUrl}/forget-password`;
  return getRequestData<void>(axiosInstance.post(url, emailPayload));
};

export const resetPassword = (resetPasswordPayload: ResetPasswordDto) => {
  const url = `${rootUrl}/reset-password`;
  const token = localStorage.getItem(RESET_PASSWORD_TOKEN_KEY);

  return getRequestData<void>(
    axiosInstance.post(url, resetPasswordPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const checkIfInternetAvailableOrNot = () => {
  const url = `${rootUrl}/check-internet`;

  return getRequestData<boolean>(axiosInstance.get(url));
};
