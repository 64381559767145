import { IconButton } from '@chakra-ui/button';
import { List } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import * as _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { markAllMessagesDeliveredForMultipleThreads } from 'src/apis/message-threads.api';
import {
  onNewPersonalMessageThread,
  unsubscribeNewPersonalMessageThread,
} from 'src/apis/socket.io/personal-message-threads.socket.io';
import FloatingActionButton from 'src/components/FloatingActionButton';
import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { MessageDto } from 'src/dto/messages.dto';
import useDialog from 'src/hooks/useDialog';
import { useTranslate } from 'src/hooks/useTranslate';
import { MessageType } from 'src/models/Message.model';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';
import {
  CHAT_PAGE_ROUTE,
  kMessageThreadIdParamName,
} from 'src/routes/routeList';
import { getUserDisplayName } from 'src/utils/user.utils';
import MessageItem from '../components/MessageItem';

import NewPersonalChatDialog from '../components/NewPersonalChatDialog';
import useNewMessageObserver from '../hooks/useNewMessageObserver';
import usePersonalMessages, {
  usePersonalMessageQueryClient,
} from '../hooks/usePersonalMessages';
import useUnreadMessageThreads from '../hooks/useUnreadMessageThreads';
import getLatestMessageText from '../utils/getLatestMessage.util';
import getMessageThreadDate from '../utils/getMessageThreadDate.util';
import EmptyPersonalChats from './components/EmptyPersonalChats';
import { sortMessageThreads } from './utils/sort-message-threads.util';

const PersonalMessagesPanel = () => {
  const { translate } = useTranslate();
  const { personalMessageThreads, queryMetadata: personalChatsQuery } =
    usePersonalMessages();

  const { addOne: addPersonalChat, updateOne: updatePersonalChat } =
    usePersonalMessageQueryClient();

  const { unreadMessageThreadMap, handleUnReadMessage } =
    useUnreadMessageThreads(personalMessageThreads);

  const history = useHistory();
  const goToChat = (messageThreadId: number) => {
    history.push(
      CHAT_PAGE_ROUTE.replace(
        `:${kMessageThreadIdParamName}`,
        messageThreadId.toString(),
      ),
    );
  };

  useEffect(() => {
    if (personalMessageThreads.length) {
      markAllMessagesDeliveredForMultipleThreads(
        personalMessageThreads.map(({ id }) => id),
      );
    }
  }, [personalMessageThreads]);

  const [isNewChatDialogOpen, openNewChatDialog, closeNewChatDialog] =
    useDialog();

  const onChatCreated = (chat: PersonalMessageThread) => {
    closeNewChatDialog();
    goToChat(chat.id);
  };

  const handleNewMessageThread = useCallback(addPersonalChat, [
    addPersonalChat,
  ]);

  useEffect(() => {
    onNewPersonalMessageThread(handleNewMessageThread);

    return () => unsubscribeNewPersonalMessageThread(handleNewMessageThread);
  }, [handleNewMessageThread]);

  const handleNewMessage = useCallback(
    (message: MessageDto) => {
      _.forEach(personalMessageThreads, thread => {
        if (
          thread.id === message.MessageThreadId &&
          message.messageType === MessageType.normalChat
        ) {
          const unreadMessagesCount = handleUnReadMessage(message);
          updatePersonalChat({
            ...thread,
            unreadMessagesCount: unreadMessagesCount,
            latestMessage: {
              ...thread.latestMessage,
              ...message,
              createdAt: new Date(message.createdAt),
              updatedAt: new Date(message.updatedAt),
              messageAt: new Date(message.messageAt),
            },
          });
        }
      });
    },
    [updatePersonalChat, personalMessageThreads],
  );

  useNewMessageObserver({ handleNewMessage });

  const sortedPersonalChats = sortMessageThreads<PersonalMessageThread>(
    personalMessageThreads,
    id => unreadMessageThreadMap[id],
  );

  return (
    <>
      <List spacing='4' paddingInline='0' pb='10'>
        {_.isEmpty(personalMessageThreads) ? (
          <EmptyPersonalChats />
        ) : (
          _.map(
            sortedPersonalChats,
            ({
              id,
              recipient,
              latestMessage,
              createdAt,
              unreadMessagesCount,
            }) => (
              <MessageItem
                key={id}
                title={getUserDisplayName(recipient)}
                subtitle={getLatestMessageText(
                  latestMessage,
                  translate(TranslationKeys.beginConversation),
                )}
                topRightInfo={getMessageThreadDate({
                  createdAt,
                  latestMessage,
                })}
                unreadMessageCount={unreadMessagesCount}
                avatarComponent={
                  <IconButton
                    as={RouterLink}
                    aria-label='Open user details'
                    icon={<UserAvatar user={recipient} />}
                    to={`/user/${recipient.id}/profile`}
                  />
                }
                onClick={() => goToChat(id)}
              />
            ),
          )
        )}
      </List>
      <FloatingActionButton
        aria-label='Create New Group Chat Button'
        icon={<Icon as={MdAdd} boxSize='5' />}
        onClick={openNewChatDialog}
        isDisabled={personalChatsQuery.isLoading}
        bottom='10'
        right='6'
      />
      {isNewChatDialogOpen && (
        <NewPersonalChatDialog
          onChatCreated={onChatCreated}
          existingPersonalChats={personalMessageThreads}
          isOpen={isNewChatDialogOpen}
          onClose={closeNewChatDialog}
        />
      )}
    </>
  );
};

export default PersonalMessagesPanel;
