import {
  Alert,
  AlertIcon,
  Avatar,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { getGroupMessageThread } from 'src/apis/group-message-threads.api';
import { CustomModalProps } from 'src/types/common.type';

interface GroupInfoDialogProps {
  messageThreadId: number;
}

const GroupInfoDialog: React.FC<CustomModalProps<GroupInfoDialogProps>> = ({
  messageThreadId,
  ...props
}) => {
  const { translate } = useTranslate();
  const { data: groupMessageThread, isError } = useQuery(
    ['getOneGroupChat', messageThreadId],
    () => getGroupMessageThread(messageThreadId),
  );

  const renderBody = () => {
    if (isError) {
      return (
        <Alert status='error'>
          <AlertIcon />
          {translate(TranslationKeys.errorFetchingGroupInfo)}
        </Alert>
      );
    }

    return (
      <VStack align='start' w='full' spacing='8' minW='0' maxW='full'>
        <VStack w='full' minW='0' maxW='full'>
          <Avatar
            name={groupMessageThread?.group?.name}
            size='lg'
            src={groupMessageThread?.group?.imageUrl}
          />
          <Text
            fontSize='xl'
            fontWeight='semibold'
            color='gray.500'
            maxW='full'
            isTruncated
          >
            {groupMessageThread?.group?.name}
          </Text>
        </VStack>
        {groupMessageThread?.group?.description && (
          <VStack align='start' spacing='0' minW='0' maxW='full'>
            <Text fontSize='md' color='gray.500' fontWeight='semibold'>
              {`${translate(TranslationKeys.groupDescription)}:`}
            </Text>
            <Text maxW='full' color='gray.500' fontSize='sm' noOfLines={8}>
              {groupMessageThread?.group?.description}
            </Text>
          </VStack>
        )}
      </VStack>
    );
  };

  return (
    <Modal {...props} isCentered>
      <ModalOverlay />
      <ModalContent mx='4'>
        <ModalHeader display='flex' justifyContent='space-between'>
          <Text>{translate(TranslationKeys.groupInfo)}</Text>
          <CloseButton onClick={props.onClose} />
        </ModalHeader>
        <ModalBody>{renderBody()}</ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GroupInfoDialog;
