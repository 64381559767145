export const formDataFactory = (object: Record<string, any>) => {
  const formData = new FormData();
  Object.keys(object).forEach(key => {
    const value = object[key];
    if (typeof value !== 'string' && value.length !== undefined) {
      value.forEach((obj: any) => {
        formData.append(key, obj);
      });
    } else formData.append(key, value);
  });

  return formData;
};
