import { VStack } from '@chakra-ui/layout';
import { UseFormReturn } from 'react-hook-form';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { GroupChatFormSchemaDto } from 'src/schema/group-chat.schema';

import ErrorMessage from '../../../../components/form/ErrorMessage';
import TextField from '../../../../components/form/TextField';
import { EditOrCreateGroupFormDto } from '../../../../dto/group-messageThreads.dto';
import { ValueCallback } from '../../../../types/common.type';

export type GroupMetadataSubmitCallback = ValueCallback<GroupChatFormSchemaDto>;

interface GroupMetadataFormProps {
  onSubmit: GroupMetadataSubmitCallback;
  id: string;
  formProps: UseFormReturn<EditOrCreateGroupFormDto>;
}

const GroupMetadataForm: React.FC<GroupMetadataFormProps> = ({
  onSubmit,
  id,
  formProps,
}) => {
  const { translate } = useTranslate();
  const { register, handleSubmit, formState } = formProps;

  return (
    <VStack
      align='start'
      spacing='6'
      as='form'
      id={id}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        label={translate(TranslationKeys.name)}
        inputProps={register('name')}
        formControlProps={{ isInvalid: !!formState.errors.name }}
      >
        <ErrorMessage error={formState.errors.name} />
      </TextField>
      <TextField
        label={translate(TranslationKeys.description)}
        inputProps={register('description')}
        formControlProps={{ isInvalid: !!formState.errors.description }}
      >
        <ErrorMessage error={formState.errors.description} />
      </TextField>
    </VStack>
  );
};

export default GroupMetadataForm;
