import * as _ from 'lodash';
import { useEffect, useState } from 'react';

import { MessageDto } from 'src/dto/messages.dto';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';

type MessageThreadUnreadMessageCountRecord = Record<
  PersonalMessageThread['id'],
  number
>;

type UseUnreadMessageThreads = {
  unreadMessageThreadMap: MessageThreadUnreadMessageCountRecord;
  handleUnReadMessage: (message: MessageDto) => number;
};

const useUnreadMessageThreads = (
  messageThreads: PersonalMessageThread[],
): UseUnreadMessageThreads => {
  const { currentUser } = useUserDetails();
  const [unreadMessageThreadMap, setUnreadMessageThreadMap] =
    useState<MessageThreadUnreadMessageCountRecord>({});

  useEffect(() => {
    setUnreadMessageThreadMap(prev =>
      _.reduce(
        messageThreads,
        (acc, msgThread) => {
          return {
            ...acc,
            [msgThread.id]: prev[msgThread.id] || msgThread.unreadMessagesCount,
          };
        },
        {},
      ),
    );
  }, [messageThreads]);

  const handleUnReadMessage = (message: MessageDto) => {
    const { MessageThreadId } = message;
    let unReadMessageCount = 0;

    if (message.SenderId !== currentUser?.id) {
      setUnreadMessageThreadMap(prevMsgThreadUnreadMsgCountMap => {
        const prevUnreadMsgCount =
          prevMsgThreadUnreadMsgCountMap[MessageThreadId] || 0;
        unReadMessageCount = prevUnreadMsgCount + 1;
        return {
          ...prevMsgThreadUnreadMsgCountMap,
          [MessageThreadId]: unReadMessageCount,
        };
      });
      return unReadMessageCount;
    }
    return 0;
  };

  return { unreadMessageThreadMap, handleUnReadMessage };
};

export default useUnreadMessageThreads;
