import { Icon } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import { IoExitOutline } from 'react-icons/io5';
import { MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { HOME_PAGE_ROUTE } from 'src/routes/routeList';
import { AppNavigationType } from 'src/types/navigation.type';

import { NavbarLeftIcon } from '../components/navbar-left-icon';

export const getNavbarLeftButton = (
  goBack: () => void,
  pathname: string,
  navigationType: AppNavigationType | undefined,
): React.ReactNode => {
  if (pathname === HOME_PAGE_ROUTE) {
    return null;
  }

  if (navigationType === AppNavigationType.back) {
    return (
      <NavbarLeftIcon
        aria-label='Go back'
        icon={<Icon as={FiArrowLeft} boxSize='7' />}
        onClick={goBack}
      />
    );
  }

  if (navigationType === AppNavigationType.exit) {
    return (
      <NavbarLeftIcon
        aria-label='Exit'
        icon={<Icon as={IoExitOutline} boxSize='7' />}
        onClick={goBack}
      />
    );
  }

  return (
    <NavbarLeftIcon
      as={Link}
      to={HOME_PAGE_ROUTE}
      aria-label='Go to home'
      icon={<Icon as={MdHome} boxSize='7' />}
    />
  );
};
