import { axiosInstance } from 'src/config';
import {
  AddMembersInGroupCallDto,
  CreateGroupCallDto,
  CreatePersonalCallDto,
} from 'src/dto/calls.dto';
import { Call } from 'src/models/Call.model';

import { getRequestData } from './utils/get-request-data.util';

export const createPersonalCall = (
  payload: CreatePersonalCallDto,
): Promise<Call> => {
  return getRequestData<Call>(axiosInstance.post('/calls/personal', payload));
};

export const createGroupCall = (
  payload: CreateGroupCallDto,
): Promise<Call[]> => {
  return getRequestData<Call[]>(axiosInstance.post('/calls/group', payload));
};

export const getCall = (callId: Call['id']): Promise<Call | undefined> => {
  return getRequestData<Call | undefined>(
    axiosInstance.get(`/calls/${callId}`),
  );
};

export const updateGroupCall = (
  payload: AddMembersInGroupCallDto,
): Promise<Call[]> => {
  return getRequestData<Call[]>(
    axiosInstance.put('/calls/add-members', payload),
  );
};
