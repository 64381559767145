import { Box, BoxProps } from '@chakra-ui/react';
import { useEffect, useRef, VideoHTMLAttributes } from 'react';

type VideoStreamProps = VideoHTMLAttributes<{}> &
  BoxProps & {
    stream: MediaStream | null;
  };

const VideoStream: React.FC<VideoStreamProps> = ({ stream, ...props }) => {
  const vidRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (vidRef.current && stream) {
      try {
        vidRef.current.srcObject = stream;
        vidRef.current.play().catch(console.error);
      } catch (error) {
        console.error('Error setting stream:', error);
      }
    }
  }, [stream, vidRef]);

  return <Box as='video' ref={vidRef as any} {...props} playsInline autoPlay />;
};

export default VideoStream;
