import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { MdGroup } from 'react-icons/md';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { getFeed } from 'src/apis';
import FeedCoverImage from 'src/components/FeedCoverImage';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import { FeedModel } from 'src/models';
import { FEED_MEMBERS_PAGE_ROUTE } from 'src/routes/routeList';
import { AppNavigationType } from 'src/types/navigation.type';

import { AddPostForm } from './AddPostForm/AddPostForm';
import { AddPostView } from './AddPostView';
import useCurrentUserFeedMember from './hooks/useCurrentUserFeedMember';
import useFeedPosts from './hooks/useFeedPosts';
import PostsView from './PostsView';

const FeedPage = () => {
  const { translate } = useTranslate();
  const { id: feedId }: { id: string } = useParams();
  const { data: feed } = useQuery<FeedModel>(['getOneFeed', feedId], () =>
    getFeed(feedId),
  );
  const currentUserMemberRecord = useCurrentUserFeedMember(+feedId);
  const [isAddPostFormOpen, setIsAddPostFormOpen] = useState<boolean>(false);

  const handleFormDialogToggle = () => {
    setIsAddPostFormOpen(() => !isAddPostFormOpen);
  };

  const [
    postsList,
    { isFetching, isFetchingNextPage, fetchNextPage, hasNextPage },
  ] = useFeedPosts({
    feed,
    feedId: +feedId,
  });

  return (
    <HeaderWrapper
      pageTitle={feed?.name || translate(TranslationKeys.feed)}
      bodyContainerProps={{ bg: 'gray.100' }}
      isFeedPostsPage
      feedId={feed?.id}
      navigationType={AppNavigationType.back}
    >
      {feed && (
        <Flex
          border='1px solid'
          borderColor='gray.100'
          py='2'
          px='1'
          flexDir='column'
          bg='gray.100'
        >
          <FeedCoverImage
            maxH='44'
            objectFit='cover'
            coverImageUrl={feed.coverImageUrl}
          />
          <Flex
            justifyContent='space-between'
            alignItems='flex-start'
            borderBottom='2px solid'
            borderColor='gray.300'
          >
            <Text m='2' noOfLines={4}>
              {feed.description}
            </Text>
            <Link to={FEED_MEMBERS_PAGE_ROUTE.replace(':id', feedId)}>
              <IconButton
                colorScheme='blue'
                variant='ghost'
                aria-label='view members'
                icon={<Icon as={MdGroup} boxSize='8' />}
                mx='4'
              >
                {translate(TranslationKeys.viewMembers)}
              </IconButton>
            </Link>
          </Flex>
        </Flex>
      )}
      <AddPostView
        handleOpen={handleFormDialogToggle}
        feed={feed}
        currentUserMemberRecord={currentUserMemberRecord}
      />
      <PostsView
        feedId={+feedId}
        posts={postsList}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
      <AddPostForm
        isOpen={isAddPostFormOpen}
        handleClose={handleFormDialogToggle}
        feedId={feedId}
      />
    </HeaderWrapper>
  );
};

export default FeedPage;
