import { AvatarGroup, Flex, Text, useDisclosure } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect } from 'react';

import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { FeedPostLikeDto } from 'src/dto/feed-post-likes.dto';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { Like } from 'src/models/Like.model';
import { Post } from 'src/models/Post.model';

import { MAXIMUM_NUMBER_OF_LIKE_TO_SHOW } from './constants/post-form.constant';
import { ViewLikesByUsersModal } from './ViewLikesByUsersModal';

// only used in this file
const ONE_POST_LIKE = 1;
const NO_LIKES_ON_POST = 0;

interface FeedPostLikesProps {
  LikesData: FeedPostLikeDto[];
  post: Post;
  setHasLiked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeedPostLikes: React.FC<FeedPostLikesProps> = ({
  LikesData,
  post,
  setHasLiked,
}) => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();

  const likeModalDiscloser = useDisclosure();

  useEffect(() => {
    if (
      _.filter(LikesData, (like: Like) => {
        return like?.UserId === currentUser?.id;
      }).length === ONE_POST_LIKE
    ) {
      setHasLiked(true);
    }
  }, [LikesData]);

  return (
    <Flex>
      <AvatarGroup size='sm' max={2}>
        {_.map(LikesData, (like: Like, index: number) => {
          return index < MAXIMUM_NUMBER_OF_LIKE_TO_SHOW ? (
            <UserAvatar user={like.user} />
          ) : null;
        })}
      </AvatarGroup>

      <Text as='button' onClick={likeModalDiscloser.onOpen} pl='2'>
        {LikesData &&
          LikesData?.length > NO_LIKES_ON_POST &&
          LikesData?.length === ONE_POST_LIKE && (
            <Text color='gray.400'>
              {LikesData?.length} {translate(TranslationKeys.like)}
            </Text>
          )}
        {LikesData &&
          LikesData?.length > NO_LIKES_ON_POST &&
          LikesData?.length > ONE_POST_LIKE && (
            <Text color='gray.400'>
              {LikesData?.length} {translate(TranslationKeys.likes)}
            </Text>
          )}
      </Text>
      {likeModalDiscloser.isOpen && LikesData && (
        <ViewLikesByUsersModal {...likeModalDiscloser} post={post} />
      )}
    </Flex>
  );
};
