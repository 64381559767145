import { StyleProps } from '@chakra-ui/react';
import { BiBookContent } from 'react-icons/bi';
import { FiAlertTriangle, FiPhoneCall } from 'react-icons/fi';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';
import { RiPagesLine } from 'react-icons/ri';
import { SosIcon } from 'src/assets';
import { TranslationKeys } from 'src/constants/translation-keys';
import {
  ALERT_LIST_ROUTE,
  CALLS_PAGE_ROUTE,
  FEEDS_PAGE_ROUTE,
  MESSAGES_PAGE_ROUTE,
  PAGES_ROUTE,
} from 'src/routes/routeList';

export type NavigationLinksType = {
  label: string;
  path?: string;
  icon: IconType;
  iconBgColor: StyleProps['backgroundColor'];
  color: StyleProps['color'];
  hoverColor: StyleProps['color'];
};

export const SOSNavigationLink: NavigationLinksType = {
  label: TranslationKeys.emergencySOS,
  icon: SosIcon as IconType,
  iconBgColor: 'red.400',
  color: 'red.500',
  hoverColor: 'red.600',
};

export const NavigationLinks: NavigationLinksType[] = [
  {
    label: TranslationKeys.alert,
    path: ALERT_LIST_ROUTE,
    icon: FiAlertTriangle,
    iconBgColor: 'red.300',
    color: 'red.400',
    hoverColor: 'red.500',
  },
  {
    label: TranslationKeys.calls,
    path: CALLS_PAGE_ROUTE,
    icon: FiPhoneCall,
    iconBgColor: 'facebook.400',
    color: 'facebook.500',
    hoverColor: 'facebook.600',
  },
  {
    label: TranslationKeys.messages,
    path: MESSAGES_PAGE_ROUTE,
    icon: IoChatbubbleEllipsesOutline,
    iconBgColor: 'cyan.400',
    color: 'cyan.500',
    hoverColor: 'cyan.600',
  },
  {
    label: TranslationKeys.feeds,
    path: FEEDS_PAGE_ROUTE,
    icon: RiPagesLine,
    iconBgColor: 'blue.400',
    color: 'blue.500',
    hoverColor: 'blue.600',
  },
  {
    label: TranslationKeys.pages,
    path: PAGES_ROUTE,
    icon: BiBookContent,
    iconBgColor: 'orange.400',
    color: 'orange.500',
    hoverColor: 'orange.600',
  },
];
