import { insertMediaEmbed } from '@udecode/plate';
import { MdDevices } from 'react-icons/md';
import { useMutation } from 'react-query';
import { createFile } from 'src/apis/document.api';
import FileInputButton from 'src/components/FileInputButton';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { VoidCallback } from 'src/types/common.type';

import useEditorState from '../../../../hooks/useEditorState';

interface DeviceUploadMediaButtonProps {
  onMediaInserted: VoidCallback;
}

const DeviceUploadMediaButton: React.FC<
  DeviceUploadMediaButtonProps
> = props => {
  const { translate } = useTranslate();
  const editor = useEditorState();
  const fileUploadMutation = useMutation(createFile);

  const handleFiles = async (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const uploadedMedia = await fileUploadMutation.mutateAsync({
        file,
      });

      const url = uploadedMedia.documentUrl;
      insertMediaEmbed(editor, { url });
      props.onMediaInserted();
    }
  };

  return (
    <FileInputButton
      variant='unstyled'
      size='xs'
      fileInputProps={{
        accept: 'video/*; *.mkv',
        onFiles: handleFiles,
      }}
      leftIcon={<MdDevices />}
    >
      {translate(TranslationKeys.uploadMediaFromDevice)}
    </FileInputButton>
  );
};

export default DeviceUploadMediaButton;
