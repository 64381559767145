import { Button, Icon, IconButton, IconButtonProps } from '@chakra-ui/react';

interface IncomingCallButtonProps
  extends Pick<
    IconButtonProps,
    'onClick' | 'colorScheme' | 'aria-label' | 'variant'
  > {
  icon?: React.ElementType;
  buttonLabel?: string;
}

const IncomingCallButton: React.FC<IncomingCallButtonProps> = ({
  icon,
  buttonLabel,
  ...props
}) => {
  if (buttonLabel) {
    return (
      <Button size='sm' isRound _hover={{}} _active={{}} {...props}>
        {buttonLabel}
      </Button>
    );
  }

  return (
    <IconButton
      variant='ghost'
      size='lg'
      isRound
      icon={<Icon as={icon} boxSize='7' />}
      _hover={{}}
      _active={{}}
      {...props}
    />
  );
};

export default IncomingCallButton;
