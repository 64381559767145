// @ts-nocheck
import { Marker, Popup } from 'react-leaflet';
import { BlueMarker } from './icons/blue-marker';
import { RedMarker } from './icons/red-marker';

export enum MarkerColors {
  red = 'Red',
  blue = 'Blue',
}

interface LeafletMarkerProps {
  position: number[];
  markerContent?: JSX.Element;
  markerColor?: MarkerColors;
}

export const LeafletMarker: React.FC<LeafletMarkerProps> = ({
  position,
  markerContent,
  markerColor,
}) => {
  const getIcon = () => {
    if (markerColor === MarkerColors.red) {
      return RedMarker;
    }
    return BlueMarker;
  };

  //  when position is [0, 0], it means that the location is not available
  if (_.includes(position, 0)) {
    return null;
  }

  return (
    <Marker icon={getIcon()} position={position}>
      {markerContent && <Popup>{markerContent}</Popup>}
    </Marker>
  );
};
