import { ChakraProvider } from '@chakra-ui/react';
import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import {
  HandsFreeOperationProvider,
  HandsFreeOperationSettingProvider,
} from 'src/hoc/HandsFreeOperationProvider';
import { QueryCustomProvider } from 'src/hoc/query-context';
import { UserDetailsProvider } from 'src/hoc/UserDetailsProvider';
import { UserLocationProvider } from 'src/hoc/UserLocationContext/user-location.provider';

import AuthQueryClientProvider, {
  AuthQueryClientContext,
} from '../hoc/AuthQueryClientProvider';
import { theme } from '../utils/chakra-theme/theme';
import { AppContent } from './components/AppContent';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthQueryClientProvider>
          <AppQueryProvider>
            <UserDetailsProvider>
              <UserLocationProvider>
                <HandsFreeOperationSettingProvider>
                  <HandsFreeOperationProvider>
                    <AppContent />
                  </HandsFreeOperationProvider>
                </HandsFreeOperationSettingProvider>
              </UserLocationProvider>
            </UserDetailsProvider>
          </AppQueryProvider>
        </AuthQueryClientProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const AppQueryProvider: React.FC = ({ children }) => {
  const { queryClient } = useContext(AuthQueryClientContext);
  if (!queryClient) {
    return null;
  }

  return (
    <QueryCustomProvider queryClient={queryClient}>
      {children}
    </QueryCustomProvider>
  );
};

export default App;
