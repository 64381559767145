import Peer, { DataConnection, MediaConnection } from 'peerjs';
import { useRef, useState } from 'react';
import * as _ from 'lodash';
import { useMutation } from 'react-query';

import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';
import { leaveMultipleCallSessions } from 'src/apis/call-sessions.api';

import usePeerDataConnection from './usePeerDataConnection';
import { leaveCalls } from '../utils/call-session.utils';

const usePeerCall = () => {
  const [dataConnections, setDataConnections] = useState<DataConnection[]>([]);
  const [calls, setCalls] = useState<MediaConnection[]>([]);
  const [peerStreams, setPeerStreams] = useState<
    Record<number, MediaStream | null>
  >({});
  const {
    handleDataChannelMessage,
    peerAudioStatus,
    peerVideoStatus,
    cleanupPeerStatus,
  } = usePeerDataConnection();

  const peerIDRef = useRef<string>();
  const peerInstanceRef = useRef<Peer>();
  const isPeerConnectedRef = useRef(false);

  const { callSessions } = useCallSessionsContext();
  const leaveAllCallSessions = useMutation(leaveMultipleCallSessions);

  const createPeer = (selfPeerId: string) => {
    peerInstanceRef.current = new Peer(selfPeerId, {
      host: window.location.hostname,
      port: +process.env.REACT_APP_PEERJS_PORT!,
      path: process.env.REACT_APP_PEERJS_PATH,
    });
  };

  const handlePeer = (localStream: MediaStream | null) => {
    const peer = peerInstanceRef.current;

    if (!peer) {
      return;
    }

    peer.on('open', (id: string) => {
      peerIDRef.current = id;
      isPeerConnectedRef.current = true;
    });

    peer.on('call', call => {
      setCalls(prevCalls => [...prevCalls, call]);

      if (localStream) {
        call.answer(localStream);

        call.on('stream', remoteStream => {
          setPeerStreams(prevStreams => ({
            ...prevStreams,
            [parseInt(call.peer)]: remoteStream,
          }));
        });

        call.on('error', error => {
          console.error('Call error:', error);
        });

        const dataConnection = peer.connect(call.peer, {
          serialization: 'json',
        });

        dataConnection.on('open', () => {
          setDataConnections(prev => [...prev, dataConnection]);
        });

        handleDataChannelMessage(dataConnection);
      }
    });

    peer.on('connection', connection => {
      setDataConnections(prevConnections => [...prevConnections, connection]);
      handleDataChannelMessage(connection);
    });

    peer.on('error', error => {
      console.error('Peer error:', error);
      isPeerConnectedRef.current = false;
      leaveCalls(callSessions, leaveAllCallSessions);
    });

    peer.on('close', () => {
      isPeerConnectedRef.current = false;
      leaveCalls(callSessions, leaveAllCallSessions);
    });
  };

  const callPeer = (otherPeerId: string, localStream: MediaStream | null) => {
    const peer = peerInstanceRef.current;
    const isConnected = isPeerConnectedRef.current;

    if (
      localStream &&
      otherPeerId &&
      peer &&
      isConnected &&
      !peerStreams[parseInt(otherPeerId)]
    ) {
      const call = peer.call(otherPeerId, localStream);

      setCalls(prevCalls => [...prevCalls, call]);

      call.on('stream', remoteStream => {
        setPeerStreams(prevStreams => ({
          ...prevStreams,
          [parseInt(otherPeerId)]: remoteStream,
        }));
      });

      call.on('error', error => {
        console.error('Call error:', error);
      });

      const dataConnection = peer.connect(otherPeerId, {
        serialization: 'json',
      });
      dataConnection.on('open', () => {
        setDataConnections(prev => [...prev, dataConnection]);
      });
      handleDataChannelMessage(dataConnection);
    }
  };

  const cleanupRemovedPeerCall = (peerId: number) => {
    setCalls(prevCalls => {
      const newCalls = prevCalls.filter(call => {
        if (parseInt(call.peer) === peerId) {
          call.close();
          return false;
        }
        return true;
      });
      return newCalls;
    });

    setPeerStreams(prevStreams => {
      const newPeerStreams = { ...prevStreams };
      delete newPeerStreams[peerId];
      return newPeerStreams;
    });

    setDataConnections(prevConnections => {
      const newConnections = prevConnections.filter(connection => {
        if (parseInt(connection.peer) === peerId) {
          connection.close();
          return false;
        }
        return true;
      });
      return newConnections;
    });

    cleanupPeerStatus(peerId);
  };

  const cleanupPeer = () => {
    _.forEach(dataConnections, connection => {
      connection.close();
    });
    _.forEach(calls, call => {
      call.close();
    });
  };

  return {
    callPeer,
    createPeer,
    handlePeer,
    dataConnections,
    calls,
    peerStreams,
    peer: peerInstanceRef.current,
    peerAudioStatus,
    peerVideoStatus,
    cleanupPeer,
    cleanupRemovedPeerCall,
  };
};

export default usePeerCall;
