import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getOneAlert } from 'src/apis/alerts.api';
import { Alert } from 'src/models/Alerts.model';

const useAlert = (alertId: Alert['id']) => {
  const { data, refetch, ...queryResult } = useQuery<void, AxiosError, Alert>(
    ['getOneAlert', alertId],
    () => getOneAlert(alertId.toString()),
  );

  return { alert: data, refetch, ...queryResult };
};

export default useAlert;
