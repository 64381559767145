import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface InternalNavigationLinkProps {
  linkUrl: string;
}

export const InternalNavigationLink: React.FC<InternalNavigationLinkProps> = ({
  linkUrl,
  children,
}) => {
  return (
    <Link as={RouterLink} to={linkUrl}>
      {children}
    </Link>
  );
};
