import { IconButton, IconButtonProps } from '@chakra-ui/button';

interface CallButtonProps extends IconButtonProps {}

const CallButton: React.FC<CallButtonProps> = props => {
  return (
    <IconButton colorScheme='blue' borderRadius='52' size='lg' {...props} />
  );
};

export default CallButton;
