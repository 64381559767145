import { useState, useEffect } from 'react';

import useUserRoom from '../../hooks/useUserRoom';

interface UserRoomWrapperProps {}

const UserRoomWrapper: React.FC<UserRoomWrapperProps> = ({ children }) => {
  const { joinRoom } = useUserRoom();
  const [joinedRoom, setJoinedRoom] = useState(false);
  
  useEffect(() => {
    if (!joinedRoom) {
      const didJoinRoom = joinRoom();
      setJoinedRoom(didJoinRoom);
    }
    
  }, [joinedRoom, joinRoom]);

  return <>{children}</>;
};

export default UserRoomWrapper;
