import { useCallback, useEffect, useState } from 'react';
import {
  joinAlertPage,
  leaveAlertPage,
  subscribeExistingIncomingAlert,
  unsubscribeExistingIncomingAlert,
} from 'src/apis/socket.io/events/alerts.socket.io';
import { Alert } from 'src/models/Alerts.model';

export const useExistingAlert = (alertId: number) => {
  const [liveAlert, setLiveAlert] = useState<Alert>();

  const handleIncomingALert = useCallback((alert: Alert) => {
    if (alert && alert.id === alertId) {
      setLiveAlert(alert);
    }
  }, []);

  const liveAlertMedia = liveAlert?.alertMedias;

  useEffect(() => {
    if (alertId) {
      joinAlertPage(alertId);
      return () => {
        leaveAlertPage(alertId);
      };
    }
  }, [alertId]);

  useEffect(() => {
    subscribeExistingIncomingAlert(handleIncomingALert);

    return () => {
      unsubscribeExistingIncomingAlert(handleIncomingALert);
    };
  }, []);

  return { liveAlert, liveAlertMedia };
};
