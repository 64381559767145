import { Icon, IconButton } from '@chakra-ui/react';
import { MdCallEnd } from 'react-icons/md';
import { useMutation } from 'react-query';

import { leaveMultipleCallSessions } from 'src/apis/call-sessions.api';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';
import { leaveCalls } from 'src/pages/peer-call/utils/call-session.utils';

interface EndCallButtonProps {}

const EndCallButton: React.FC<EndCallButtonProps> = () => {
  const { callSessions } = useCallSessionsContext();
  const leaveAllCallSessions = useMutation(leaveMultipleCallSessions);

  return (
    <IconButton
      variant='solid'
      aria-label='End Call'
      size='lg'
      colorScheme='red'
      isRound
      icon={<Icon as={MdCallEnd} fontSize='28px' />}
      onClick={() => leaveCalls(callSessions, leaveAllCallSessions)}
    />
  );
};

export default EndCallButton;
