import { socket } from 'src/config/socketIoInstance';
import { Alert } from 'src/models/Alerts.model';
import { ValueCallback } from 'src/types/common.type';

enum kAlertsNotificationEvents {
  newAlert = 'alert:new_alert',
  existingAlert = 'alert:existing_alert',
}

export const kJoinAlertPageRoom = 'alert:join_alert_page';
export const kLeaveAlertPageRoom = 'alert:leave_alert_page';

export type AlertHandler = ValueCallback<Alert>;

export const subscribeNewIncomingAlert = (handler: AlertHandler) => {
  socket.on(kAlertsNotificationEvents.newAlert, handler);
};

export const unsubscribeNewIncomingAlert = (handler: AlertHandler) => {
  socket.off(kAlertsNotificationEvents.newAlert, handler);
};

export const subscribeExistingIncomingAlert = (handler: AlertHandler) => {
  socket.on(kAlertsNotificationEvents.existingAlert, handler);
};

export const unsubscribeExistingIncomingAlert = (handler: AlertHandler) => {
  socket.off(kAlertsNotificationEvents.existingAlert, handler);
};

export const joinAlertPage = (alertId: number) =>
  socket.emit(kJoinAlertPageRoom, { alertId });

export const leaveAlertPage = (alertId: number) =>
  socket.emit(kLeaveAlertPageRoom, { alertId });
