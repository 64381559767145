import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';

import { maxWidth } from 'src/constants/max-width.constant';
import { useHandsFreeOperation } from 'src/hoc/HandsFreeOperationProvider';
import { useQueryLoaderContext } from 'src/hoc/query-context';
import { CallSessionsProvider } from 'src/hoc/call-sessions.provider';

import UserMediaProvider from '../components/footer/context/user-media/provider/UserMedia.provider';
import PeerConnectionProvider from '../provider/peer-connection/PeerConnection.provider';
import useCallParams from '../hooks/useCallParams';
import useCallSessionsUpdates from '../hooks/useCallSessionUpdates';
import useCallValidator from '../hooks/useCallValidator';
import GroupCallBody from './components/body';
import GroupCallFooter from './components/footer';
import GroupCallHeader from './components/header';

const GroupCall = () => {
  useCallSessionsUpdates();
  useCallValidator();

  return (
    <Box userSelect='none' maxW={maxWidth} mx='auto'>
      <GroupCallHeader />
      <UserMediaProvider>
        <PeerConnectionProvider>
          <GroupCallBody />
          <GroupCallFooter />
        </PeerConnectionProvider>
      </UserMediaProvider>
    </Box>
  );
};

const GroupCallPageWrapper = () => {
  const { callId } = useCallParams();
  const { setIsListening } = useHandsFreeOperation();
  const { setIsLoaderRequired } = useQueryLoaderContext();

  useEffect(() => {
    setIsListening(false);
    setIsLoaderRequired(false);

    return () => {
      setIsListening(true);
      setIsLoaderRequired(true);
    };
  }, []);

  return (
    <CallSessionsProvider>
      <GroupCall key={`group-call-page-${callId}`} />
    </CallSessionsProvider>
  );
};

export default GroupCallPageWrapper;
