import {
  Icon,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { getUserDetails } from '../../../apis/users.api';
import useFeed from '../../../hooks/apis/useFeed';
import useDialog from '../../../hooks/useDialog';
import { User } from '../../../models';
import { Post } from '../../../models/Post.model';
import DeletePostModal from './DeletePostModal';
import EditPostModal from './EditPostForm/EditPostModal';
import useCurrentUserFeedMember from './hooks/useCurrentUserFeedMember';
import PostReviewDialog from './PostReviewDialog';

interface PostActionsMenuProps extends Omit<MenuButtonProps, 'children'> {
  post: Post;
}

const PostActionsMenu: React.FC<PostActionsMenuProps> = ({
  post,
  ...props
}) => {
  const { translate } = useTranslate();
  const currentUserFeedMemberRecord = useCurrentUserFeedMember(post.FeedId);
  const { currentUser } = useUserDetails();
  const { feed } = useFeed(post.FeedId);

  const { data: feedCreatorUser } = useQuery<User | undefined>(
    ['getUser', feed?.CreatedByUserId],
    () => {
      if (!feed) return;

      return getUserDetails(feed.CreatedByUserId.toString());
    },
    {
      enabled: Boolean(feed),
    },
  );

  const [isReviewDialogOpen, openReviewDialog, closeReviewDialog] = useDialog();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useDialog();
  const [isDeleteDialogOpen, deletePostDialog, closeDeleteDialog] = useDialog();

  const isPostByCurrentUser = post.CreatedByUserId === currentUser?.id;
  const isPostByFeedCreator = post.CreatedByUserId === feedCreatorUser?.id;
  const canUserUpdatePost =
    !isPostByFeedCreator &&
    (isPostByCurrentUser || currentUserFeedMemberRecord?.canUpdate);
  const canUserReviewPost =
    feed?.isModerated &&
    !isPostByCurrentUser &&
    !isPostByFeedCreator &&
    post.isApproved === null &&
    currentUserFeedMemberRecord?.canModeratePosts;

  const hasPostActions = canUserReviewPost || canUserUpdatePost;
  if (!hasPostActions) {
    return null;
  }

  return (
    <>
      <Menu autoSelect={false}>
        <MenuButton {...props} px='4'>
          <Icon as={IoEllipsisVertical} boxSize='6' />
        </MenuButton>
        <MenuList>
          {canUserReviewPost && (
            <MenuItem onClick={openReviewDialog}>
              {translate(TranslationKeys.reviewPost)}
            </MenuItem>
          )}
          {canUserUpdatePost && (
            <MenuItem onClick={openEditDialog}>
              {translate(TranslationKeys.editPost)}
            </MenuItem>
          )}
          {canUserUpdatePost && (
            <MenuItem color='red' onClick={deletePostDialog}>
              {translate(TranslationKeys.deletePost)}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <PostReviewDialog
        post={post}
        isOpen={isReviewDialogOpen}
        onClose={closeReviewDialog}
      />
      <EditPostModal
        isOpen={isEditDialogOpen}
        onClosePost={closeEditDialog}
        post={post}
      />
      <DeletePostModal
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onDeletePost={closeDeleteDialog}
        post={post}
      />
    </>
  );
};

export default PostActionsMenu;
