import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps,
  Button,
} from '@chakra-ui/react';
import _ from 'lodash';
import { PageMembers } from '../../../models/PageMembersPage.model';
import { ValueCallback } from '../../../types/common.type';

import SearchSelectMultipleUsers, {
  useSelectedUsers,
} from 'src/components/SearchSelectMultipleUsers';
import { addMultiplePageMembers } from 'src/apis/pageMembers.api';
import { useMutation } from 'react-query';
import { NewPageMember } from 'src/pages/blogs/types/pageMember.types';
import { User } from 'src/models';
import { useTranslate } from 'src/hooks/useTranslate';
import { TranslationKeys } from 'src/constants/translation-keys';

interface AddPageMemberDialogProps extends Omit<ModalProps, 'children'> {
  pageId: number;
  onMembersAdded: ValueCallback<PageMembers[]>;
  existingMembers: PageMembers[];
}

const AddPageMemberDialog: React.FC<AddPageMemberDialogProps> = ({
  pageId,
  onMembersAdded,
  existingMembers,
  ...props
}) => {
  const { translate } = useTranslate();
  const [selectedUsers, setSelectedUsers] = useSelectedUsers();

  const { mutate: addMembers, isLoading: isAddingMembers } = useMutation(
    'addPageMembers',
    (pageMembers: NewPageMember[]) =>
      addMultiplePageMembers(pageId, pageMembers),
    {
      onSuccess: addedMembers => {
        setSelectedUsers({});
        onMembersAdded(addedMembers);
      },
    },
  );

  const handleAddMembers = () =>
    addMembers(_.map(selectedUsers, ({ id: UserId }) => ({ UserId })));

  const isUserInMembers = (user: User): boolean =>
    !!_.find(existingMembers, member => member.UserId === user.id);

  return (
    <Modal isCentered scrollBehavior='inside' {...props}>
      <ModalOverlay />
      <ModalContent minH='50%' mx='4'>
        <ModalHeader>{translate(TranslationKeys.addPageMembers)}</ModalHeader>
        <ModalBody>
          <SearchSelectMultipleUsers
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            shouldRenderUser={user => !isUserInMembers(user)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isAddingMembers}
            colorScheme='blue'
            onClick={handleAddMembers}
            disabled={_.isEmpty(selectedUsers)}
          >
            {translate(TranslationKeys.addSelectedUsers)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPageMemberDialog;
