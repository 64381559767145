import {
  Button,
  Divider,
  Flex,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { FiFileText } from 'react-icons/fi';
import { HiLocationMarker } from 'react-icons/hi';
import { MdOutlineLocationOff } from 'react-icons/md';
import { RiImageFill, RiVideoFill } from 'react-icons/ri';

import { UploadingProgress } from 'src/components';
import CurrentUserAvatar from 'src/components/CurrentUserAvatar';
import { CUSTOM_SCROLL_BAR_CSS } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { getUserDisplayName } from 'src/utils/user.utils';

import { maxPostLength } from '../constants/max-post-length.constant';
import FileInput from '../FileInput';
import { useAddFeedPost } from '../hooks/useAddFeedPost';
import FilePreviews from './FilePreviews';
import { FileUploadButton } from './FileUploadIcon';

interface AddPostFormProps {
  isOpen: boolean;
  handleClose: () => void;
  feedId: string;
}

export const AddPostForm: React.FC<AddPostFormProps> = ({
  isOpen,
  handleClose,
  feedId,
}) => {
  const { translate } = useTranslate();
  const {
    register,
    handleSubmit,
    addImage,
    removeImage,
    addVideo,
    removeVideo,
    addDocumentFile,
    removeDocumentFile,
    hasPostContent,
    allowLocation,
    toggleAllowLocation,
    imageURIs,
    videoURIs,
    documentFileURIs,
    formSubmitHandle,
    onClose,
    documentFiles,
    images,
    videos,
    isMutationLoading,
    uploadProgress,
  } = useAddFeedPost(feedId, handleClose);

  const { currentUser } = useUserDetails();

  return (
    <Modal isOpen={isOpen} onClose={handleClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent overflowY='auto' css={CUSTOM_SCROLL_BAR_CSS}>
        <ModalHeader>{translate(TranslationKeys.createPost)}</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <form onSubmit={handleSubmit(formSubmitHandle)}>
          <ModalBody>
            <HStack borderRadius='full' bg='gray.200' w='max' pr='4'>
              <CurrentUserAvatar size='sm' />
              <Text>{currentUser && getUserDisplayName(currentUser)}</Text>
            </HStack>
            <FormControl my='2'>
              <Textarea
                maxLength={maxPostLength}
                placeholder={translate(TranslationKeys.whatToPost)}
                size='lg'
                rows={3}
                {...register('textContent')}
                autoFocus
              />
            </FormControl>

            <VStack my='4' align='start' w='full' spacing='6'>
              <FilePreviews
                fileURIs={imageURIs}
                files={images}
                label={`${translate(TranslationKeys.images)}:`}
                closeBtnAriaLabel='remove image'
                onFileRemove={removeImage}
              />
              <FilePreviews
                fileURIs={videoURIs}
                files={videos}
                label={`${translate(TranslationKeys.videos)}:`}
                closeBtnAriaLabel='remove video'
                onFileRemove={removeVideo}
                previewBoxProps={{ bg: 'gray.800' }}
              />

              <FilePreviews
                fileURIs={documentFileURIs}
                files={documentFiles}
                label={`${translate(TranslationKeys.documents)}:`}
                closeBtnAriaLabel='remove document'
                onFileRemove={removeDocumentFile}
                previewBoxProps={{ h: 'unset', minW: '32' }}
              />
            </VStack>
            <HStack align='start' justify='start'>
              <FileUploadButton
                id='add-image'
                htmlFor='add-image-input'
                colorSchema='green'
                icon={RiImageFill}
                fileInput={
                  <FileInput
                    accept='image/*'
                    id='add-image-input'
                    onFiles={addImage}
                  />
                }
              />
              <FileUploadButton
                id='add-video'
                htmlFor='add-video-input'
                colorSchema='purple'
                icon={RiVideoFill}
                fileInput={
                  <FileInput
                    accept='video/*, .mkv'
                    id='add-video-input'
                    onFiles={addVideo}
                  />
                }
              />
              <FileUploadButton
                id='add-document'
                htmlFor='add-document-input'
                fileInput={
                  <FileInput
                    id='add-document-input'
                    onFiles={addDocumentFile}
                  />
                }
                colorSchema='linkedin'
                icon={FiFileText}
              />
              <FileUploadButton
                id='add-location'
                htmlFor='add-location-input'
                colorSchema='blue'
                icon={allowLocation ? HiLocationMarker : MdOutlineLocationOff}
                onClick={toggleAllowLocation}
              />
            </HStack>
          </ModalBody>
          <ModalFooter>
            <VStack align='start' w='full'>
              <UploadingProgress progressValue={uploadProgress} />
              <Flex alignSelf='flex-end' gridGap='2'>
                <Button
                  onClick={onClose}
                  variant='outline'
                  colorScheme='red'
                  w='full'
                >
                  {translate(TranslationKeys.cancel)}
                </Button>
                <Button
                  colorScheme='blue'
                  type='submit'
                  w='full'
                  isLoading={isMutationLoading}
                  loadingText='Posting...'
                  isDisabled={!hasPostContent}
                >
                  {translate(TranslationKeys.post)}
                </Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
