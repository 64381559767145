import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import {
  UserLocation,
  UserLocationContext,
  UserLocationProps,
} from './user-location.context';

const USER_LOCATION_KEY = 'user-location';

interface UserLocationProviderProps {}

export const UserLocationProvider: React.FC<UserLocationProviderProps> = ({
  children,
}) => {
  const [location, setLocation] =
    useLocalStorage<UserLocation>(USER_LOCATION_KEY);

  const getIsLocationPermissionGranted = async () => {
    if (navigator.permissions) {
      const locationPermission = await navigator.permissions.query({
        name: 'geolocation',
      });
      /**
       * checking location permission
       * - if location is not null, then location permission is granted,
       * - if location permission state is granted, then location permission is granted
       * - else location permission is not granted
       *
       * we use location here because in IOS devices we are unable to fetch permission state from `locationPermission`,
       * so if we get the location object, then it means that location permission is granted
       */
      return !!location ?? locationPermission.state === 'granted';
    }
    return false;
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
        });
      });
    }
  }, [navigator.geolocation]);

  const value: UserLocationProps = {
    locationStream: location,
    getIsLocationPermissionGranted,
  };

  return (
    <UserLocationContext.Provider value={value}>
      {children}
    </UserLocationContext.Provider>
  );
};
