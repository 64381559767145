import { Container, Text } from '@chakra-ui/react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import HeaderWrapper from '../../hoc/HeaderWrapper';
import RegisterForm from './components/RegisterForm';

const RegisterPage = () => {
  const { translate } = useTranslate();
  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.register)}>
      <Container my='8'>
        <Text
          align='center'
          mb='10'
          lineHeight='none'
          fontWeight='bold'
          color='blue.700'
          fontSize='4xl'
        >
          {translate(TranslationKeys.createAccount)}
        </Text>
        <RegisterForm />
      </Container>
    </HeaderWrapper>
  );
};

export default RegisterPage;
