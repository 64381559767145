import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { updateMessageThreadAcceptance } from 'src/apis/message-threads.api';
import { createTextMessage } from 'src/apis/messages.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserLocation } from 'src/hoc';
import { useIntParam } from 'src/hooks/use-int-params';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';
import { MessageType } from 'src/models/Message.model';
import { MessageThread } from 'src/models/MessageThread.model';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';

import { getTypingText } from '../utils/get-typing-text';
import useMessages from './useMessages';
import useMessageThread from './useMessageThread';
import useTypingObserver from './useTyping';

export const useChatPage = () => {
  const { translate } = useTranslate();

  const { messageThreadId } = useIntParam<{ messageThreadId: string }>();
  const { showToast } = useToast();
  const { locationStream } = useUserLocation();

  const { mutate: createMessageMutate } = useMutation(createTextMessage);

  const { messageThread, updateMessageThread } =
    useMessageThread(messageThreadId);

  const { mutate: updateAcceptance } = useMutation<
    MessageThread,
    any,
    { hasAccepted: boolean; latitude?: string; longitude?: string }
  >(
    ['updateMessageThreadAcceptance', messageThreadId],
    ({ hasAccepted, latitude, longitude }) =>
      updateMessageThreadAcceptance(
        messageThreadId,
        hasAccepted,
        latitude,
        longitude,
      ),
    { onSuccess: updateMessageThread },
  );

  const handleUpdateAcceptance = (hasAccepted: boolean) => {
    updateAcceptance({
      hasAccepted,
      latitude: locationStream?.latitude?.toString(),
      longitude: locationStream?.longitude?.toString(),
    });
  };

  const { messages } = useMessages({
    messageThreadId,
    messageType: MessageType.normalChat,
  });
  const { usersTyping, isSomeoneTyping } = useTypingObserver();

  const onMessageSend = (text: string, isTextToSpeechEnable?: boolean) => {
    createMessageMutate(
      {
        messageThreadId,
        text,
        isTextToSpeechEnable,
        messageType: MessageType.normalChat,
      },
      {
        onError: error => {
          showToast({
            title:
              translate((error as AxiosError)?.response?.data?.message) ||
              translate(TranslationKeys.failedToSendMessage),
            status: 'error',
          });
        },
      },
    );
  };

  const personalMsgThread = messageThread as unknown as PersonalMessageThread;
  const hasAcceptedPersonalChat = personalMsgThread?.hasAccepted;
  const hasRecipientBlocked = personalMsgThread?.hasRecipientBlocked;

  return {
    messageThread,
    messageThreadId,
    hasAcceptedPersonalChat,
    hasRecipientBlocked,
    isSomeoneTyping,
    messages,
    onMessageSend,
    typingText: getTypingText(messageThread, usersTyping, translate),
    handleUpdateAcceptance,
  };
};
