import { Icon, Text, Box } from '@chakra-ui/react';
import { BsMicFill, BsMicMuteFill } from 'react-icons/bs';

interface MicIconProps {
  isPeerMicEnabled: boolean | undefined;
  name: string;
}

export const CallMicStatus: React.FC<MicIconProps> = ({
  isPeerMicEnabled,
  name,
}) => {
  return (
    <Box
      display='flex'
      gridColumnGap='1.5'
      position='absolute'
      bottom='4'
      left='4'
      alignItems='center'
    >
      <Icon
        as={isPeerMicEnabled ? BsMicFill : BsMicMuteFill}
        color={isPeerMicEnabled ? 'green.400' : 'red.400'}
        boxSize='1.5rem'
      />
      {name && (
        <Text
          borderRadius='md'
          color='white'
          bg='blackAlpha.800'
          px='2'
          py='1'
          noOfLines={1}
        >
          {name}
        </Text>
      )}
    </Box>
  );
};
