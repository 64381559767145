import { createContext } from 'react';
import { QueryClient } from 'react-query';

import { useQueryClientInstance } from './hooks/useQueryClientInstance';

interface AuthQueryClientValue {
  queryClient: QueryClient;
}

export const AuthQueryClientContext = createContext<AuthQueryClientValue>(
  null!,
);

interface AuthQueryClientProviderProps {}

const AuthQueryClientProvider: React.FC<AuthQueryClientProviderProps> = ({
  children,
}) => {
  const queryClient = useQueryClientInstance();

  return (
    <AuthQueryClientContext.Provider value={{ queryClient }}>
      {children}
    </AuthQueryClientContext.Provider>
  );
};

export default AuthQueryClientProvider;
