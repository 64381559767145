export enum SpeechRecognitionApiKeys {
  messageThreadId = 'MessageThreadId',
  userId = 'UserId',
  timestamp = 'timestamp',
  accessToken = 'accessToken',
  recordingStop = 'recStop',
  language = 'language',
  file = 'file',
}

export const AUDIO_RECORDER_CHUNK_TIME_SLICE_MS = 1000;
export const AUDIO_RECORDER_INITIAL_CHUNK_SIZE = 0;
export const AUDIO_RECORDER_CHUNK_TYPE = 'audio/ogg; codecs=opus';
export const AUDIO_MIC_ANIMATION = {
  scale: [0.8, 1.2, 1.2, 0.8, 0.8],
  borderRadius: ['60%', '60%', '60%', '60%', '60%'],
};
export const AUDIO_MIC_ANIMATION_TRANSITION = {
  duration: 3,
  ease: 'easeInOut',
  repeat: Infinity,
  repeatType: 'loop',
};

/**
 * provide name and value as en and ja because,
 * globally we set the language as en and ja,
 * so to set the default user language for ASR we need to set the value as en and ja.
 */
export const LANGUAGE_OPTIONS = [
  { name: 'en', value: 'en' },
  { name: 'ja', value: 'ja' },
];
