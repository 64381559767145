import { useCallback, useEffect, useState } from 'react';

import {
  onMessageTranscribed,
  TranscribeMessage,
  unsubscribeOnMessageTranscribed,
} from 'src/apis/socket.io/messages.socket.io';
import { TRANSCRIBE_MESSAGE_LENGTH } from 'src/constants/chat.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { ValueCallback } from 'src/types/common.type';

export const useAsrAudioTranscribeMessage = (messageThreadId: number) => {
  const { currentUser } = useUserDetails();

  const [startTime, setStartTime] = useState<number>(0);
  const [receivedMessage, setReceivedMessage] = useState<TranscribeMessage>();
  const [transcribeMessage, setTranscribeMessage] = useState<string>('');
  const [isEndOfUtteranceDetected, setIsEndOfUtteranceDetected] =
    useState<boolean>(false);

  const handleTranscribeMessage = useCallback<ValueCallback<TranscribeMessage>>(
    newMessage => {
      if (currentUser?.id === newMessage.userId) {
        setReceivedMessage(newMessage);
      }
    },
    [currentUser],
  );

  useEffect(() => {
    if (receivedMessage) {
      if (startTime && receivedMessage.timestamp < startTime) {
        return;
      }
      setTranscribeMessage(receivedMessage?.text || '');
      setIsEndOfUtteranceDetected(receivedMessage.isEndOfUtteranceDetected);
    }
  }, [receivedMessage, startTime]);

  useEffect(() => {
    if (transcribeMessage.length >= TRANSCRIBE_MESSAGE_LENGTH) {
      setIsEndOfUtteranceDetected(true);
    }
  }, [transcribeMessage]);

  useEffect(() => {
    if (messageThreadId) {
      onMessageTranscribed(handleTranscribeMessage);
    }

    return () => {
      unsubscribeOnMessageTranscribed(handleTranscribeMessage);
    };
  }, [handleTranscribeMessage, messageThreadId]);

  return {
    setStartTime,
    transcribeMessage,
    setTranscribeMessage,
    isEndOfUtteranceDetected,
  };
};
