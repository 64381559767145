export const TAB_INDEX_LOCAL_STORAGE_KEY = 'kTabIndex';
export const CALL_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY = 'kCallPageIndex';
export const MESSAGE_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY = 'kMessagePageIndex';
export const ALERTS_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY = 'kAlertPageIndex';
export const DRAFTS_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY = 'kDraftPageIndex';
export const EDIT_PAGE_CONTENT_TAB_INDEX_LOCAL_STORAGE_KEY =
  'kEditPageContentIndex';
export const INITIAL_TAB_INDEX = 0;

export const CUSTOM_TABS_PADDING_TOP_PX = 16;
export const TAB_LIST_HEIGHT = 48;
export const CUSTOM_TABS_PADDING_TOP = `${CUSTOM_TABS_PADDING_TOP_PX}px`;
