// Reference: https://github.com/mdn/dom-examples/tree/main/media/web-dictaphone
export const visualizeAudio = (
  stream: MediaStream,
  canvas: HTMLCanvasElement,
  isRecording: boolean,
) => {
  const canvasCtx = canvas?.getContext('2d');
  const audioCtx = new AudioContext();

  const source = audioCtx.createMediaStreamSource(stream);
  const analyser = audioCtx.createAnalyser();
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  source.connect(analyser);

  drawAudioLine();

  function drawAudioLine() {
    if (!canvasCtx) {
      return;
    }
    const canvasWidth = canvas.clientWidth;
    const canvasHeight = canvas.clientHeight;
    analyser.getByteTimeDomainData(dataArray);

    requestAnimationFrame(drawAudioLine);

    canvasCtx.fillStyle = '#343434';
    canvasCtx.fillRect(0, 0, canvasWidth, canvasHeight);
    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = '#FFFFFF';
    canvasCtx.beginPath();

    //Info: all the magic number are assumed according to audio visualization
    let sliceWidth = canvasWidth / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      let v = dataArray[i] / 128;
      let y = (v * canvasHeight) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    if (isRecording) {
      canvasCtx.lineTo(canvasWidth, canvasHeight);
      canvasCtx.stroke();
    }
  }
};
