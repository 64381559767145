import {
  Avatar,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import LoadingMenuItem from 'src/pages/message-members/components/LoadingMenuItem';

interface UserWithAvatarProps {
  avatarSrc: string;
  removeAvatarMutation: any;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UserWithAvatar: React.FC<UserWithAvatarProps> = ({
  avatarSrc,
  removeAvatarMutation,
  handleImageUpload,
}) => {
  const { currentUser: user } = useUserDetails();
  const { translate } = useTranslate();

  return (
    <Avatar
      src={avatarSrc || user?.avatarUrl}
      boxSize='12rem'
      maxH='64'
      position='relative'
      bg='white'
      my='8'
    >
      <Menu closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          aria-label='edit avatar'
          position='absolute'
          p='0'
          right='0'
          bottom='0'
          colorScheme='blue'
          rounded='full'
        >
          <Icon as={MdEdit} boxSize='5' />
        </MenuButton>
        <MenuList>
          <MenuItem cursor='pointer'>
            <FormLabel
              htmlFor='input:update-avatar'
              m='0'
              cursor='pointer'
              w='full'
            >
              <Input
                type='file'
                id='input:update-avatar'
                accept='image/*'
                display='none'
                onChange={handleImageUpload}
              />
              <Text as='span'>{translate(TranslationKeys.updateAvatar)}</Text>
            </FormLabel>
          </MenuItem>
          <LoadingMenuItem
            isLoading={removeAvatarMutation.isLoading}
            color='red'
            fontSize='md'
            onClick={() => removeAvatarMutation.mutate()}
          >
            <Text textTransform='none'>
              {translate(TranslationKeys.removeAvatar)}
            </Text>
          </LoadingMenuItem>
        </MenuList>
      </Menu>
    </Avatar>
  );
};
