import { Link } from '@chakra-ui/react';
import { useInternetConnection } from 'src/hooks/useInternetConnection';

interface ExternalNavigationLinkProps {
  linkUrl: string;
}

export const ExternalNavigationLink: React.FC<ExternalNavigationLinkProps> = ({
  linkUrl,
  children,
}) => {
  const { isInternetAvailable } = useInternetConnection();

  return (
    <Link
      href={linkUrl}
      isDisabled={!isInternetAvailable}
      title={isInternetAvailable ? linkUrl : 'No internet connection'}
      pointerEvents={isInternetAvailable ? 'auto' : 'none'}
      cursor={isInternetAvailable ? 'pointer' : 'not-allowed'}
      isExternal
    >
      {children}
    </Link>
  );
};
