import { MdMic, MdMicOff } from 'react-icons/md';

import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';

import FooterIconButton from './FooterIconButton';

interface MicrophoneButtonProps {
  isMicDisabled: boolean;
}

const MicrophoneButton: React.FC<MicrophoneButtonProps> = ({
  isMicDisabled,
}) => {
  const { isAudioMuted, toggleAudio } = usePeerConnection();

  return (
    <FooterIconButton
      activeIcon={MdMic}
      inactiveIcon={MdMicOff}
      onToggleClick={toggleAudio}
      isActive={!isAudioMuted}
      aria-label='Toggle microphone'
      disabled={isMicDisabled}
    />
  );
};

export default MicrophoneButton;
