import { Box, Center } from '@chakra-ui/react';

import { CallSession } from 'src/models/CallSession.model';
import useCall from 'src/pages/peer-call/hooks/useCall';

import CallVideo from './CallVideo';

interface PeerVideoProps {
  stream: MediaStream;
  name: string;
  peerVideoStatus?: boolean;
  session?: CallSession;
}

const PeerVideo: React.FC<PeerVideoProps> = ({
  stream,
  name,
  peerVideoStatus,
  session,
}) => {
  const { call } = useCall();

  if (call && !call.isGroupCall) {
    return (
      <Box w='full' h='full'>
        <CallVideo
          stream={stream}
          name={name}
          peerVideoStatus={peerVideoStatus}
          height='full'
          session={session}
        />
      </Box>
    );
  }

  return (
    <Center w='full' h='full' position='relative'>
      <CallVideo
        stream={stream}
        name={name}
        peerVideoStatus={peerVideoStatus}
        session={session}
        height='full'
      />
    </Center>
  );
};

export default PeerVideo;
