import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useMemo } from 'react';
import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { notifyUserUnauthorized } from 'src/apis/socket.io/users.socket.io';
import { noOfRequestToServer } from 'src/constants/queryClient.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { HttpStatus } from 'src/hoc/constants/HttpStatus';
import useAuthStore from 'src/hooks/useAuthStore';
import { useTranslate } from 'src/hooks/useTranslate';
import {
  FEEDS_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
  RESET_PASSWORD_ROUTE,
} from 'src/routes/routeList';

const kAuthRoutes = [
  LOGIN_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
  FEEDS_PAGE_ROUTE,
  RESET_PASSWORD_ROUTE,
];
// Regex to check if we are in the exact routes required (kAuthRoutes, in this context)
const kAuthRoutesRegex = new RegExp(`^/?(${kAuthRoutes.join('|')})/?$`);

// Feeds routes are like: /feeds/:feedId, /feeds/:feedId/posts, /feeds/:feedId/members
// So to match the route, we need to check if the route is in the list of feeds routes
// To do so, simply see if route begins with /feed and may end with anything
const kFeedsRouteRegex = new RegExp('^/?feed/?');
const kPagesRouteRegex = new RegExp('^/?pages/?');

const isPublicRoute = (route: string) => {
  const isAuthRoute = kAuthRoutesRegex.test(route);
  const isFeedRoute = kFeedsRouteRegex.test(route);
  const isPagesRoute = kPagesRouteRegex.test(route);
  const isHomePageRoute = route === '/';

  return isAuthRoute || isFeedRoute || isPagesRoute || isHomePageRoute;
};

export const useQueryClientInstance = () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const showToast = useToast();
  const location = useLocation();
  const { removeAccessToken } = useAuthStore();

  const handleError = (error: unknown) => {
    const errorStatus = (error as AxiosError).response?.status;
    const isUnauthorizedError = errorStatus === HttpStatus.UNAUTHORIZED;

    const isInternalServerError =
      errorStatus === HttpStatus.INTERNAL_SERVER_ERROR;

    // Even if the user is in a public route, we still want to notify to the socket.io server
    if (isUnauthorizedError) {
      notifyUserUnauthorized();
    }

    if (isInternalServerError) {
      showToast({
        title: translate(TranslationKeys.serverError),
        description: translate(TranslationKeys.pleaseContactAdmin),
        status: 'error',
      });
    }

    if (isUnauthorizedError && !isPublicRoute(location.pathname)) {
      removeAccessToken();
      history.push(LOGIN_PAGE_ROUTE);
    }
  };

  const queryClient = useMemo(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({ onError: handleError }),
        queryCache: new QueryCache({ onError: handleError }),
        defaultOptions: {
          queries: {
            retry: (index, error) => {
              return (
                !_.includes(
                  [HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN],
                  (error as AxiosError).response?.status,
                ) && index < noOfRequestToServer
              );
            },
            refetchOnWindowFocus: false,
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  return queryClient;
};
