import _ from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { removeCurrentUserAvatar, updateUserAvatar } from 'src/apis/users.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';

export const useAvatarImage = () => {
  const { translate } = useTranslate();
  const { showErrorToast, showToast } = useToast();
  const { currentUser, refetchUser } = useUserDetails();

  const [avatarSrc, setAvatarSrc] = useState<string>('');

  const updateImageMutation = useMutation(
    ({ image, id }: { image: File; id: string }) => updateUserAvatar(id, image),
  );

  const removeAvatarMutation = useMutation(removeCurrentUserAvatar, {
    onSuccess: () => {
      showToast({
        title: translate(TranslationKeys.avatarRemovedSuccessfully),
        status: 'success',
      });
      setAvatarSrc('');
      refetchUser();
    },
    onError: showErrorToast,
  });

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const avatarFile = _.head(e.target.files);
    if (avatarFile && currentUser) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarSrc(
          (prevAvatar: string) => reader.result?.toString() || prevAvatar,
        );
      };

      updateImageMutation.mutate(
        { image: avatarFile, id: currentUser.id.toString() },
        {
          onSuccess: () => {
            // Update avatar src only once we have successfully uploaded the image
            const avatarFile = _.head(e.target.files);
            if (avatarFile) {
              reader.readAsDataURL(avatarFile);
            }

            showToast({
              title: translate(TranslationKeys.avatarUpdateSuccess),
              status: 'success',
            });
          },
          onError: showErrorToast,
        },
      );
    }
  };

  return {
    avatarSrc,
    setAvatarSrc,
    handleImageUpload,
    removeAvatarMutation,
  };
};
