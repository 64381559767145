import { StyleProps } from '@chakra-ui/styled-system';

/**
 * This is the maxWidth that the top-level components like Footer, Header and the Body will be.
 *
 * This is to ensure that on desktop view, the top-level components will be at the same width
 * and not extend to the full width of the screen since the components are yet to be responsive
 */
export const maxWidth: StyleProps['maxW'] = 'xl';

export const minWidth: StyleProps['minW'] = '320px';
