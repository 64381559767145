import { createContext } from 'react';

type EditorId = string | number;

interface EditorIdContextType {
  editorId: EditorId;
}

export const EditorIdContext = createContext<EditorIdContextType>(null!);

export const EditorIdProvider = EditorIdContext.Provider;
