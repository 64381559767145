import { Box, BoxProps } from '@chakra-ui/react';
import { Plate, PlateProps } from '@udecode/plate';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import Toolbar from './components/toolbar';
import useSlatePlugins from './hooks/useSlatePlugins';
import { EditorIdProvider } from './providers/EditorId.provider';

interface EditorProps extends Omit<BoxProps, 'value' | 'onChange'> {
  value: PlateProps['value'];
  editorKeyId: string;
  isReadonly: boolean;
  onChange?: PlateProps['onChange'];
  contentPadding?: string;
}

export const Editor: React.FC<EditorProps> = ({
  value,
  onChange,
  editorKeyId,
  isReadonly,
  contentPadding = '1rem',
  ...props
}) => {
  const { translate } = useTranslate();
  const plugins = useSlatePlugins();

  return (
    <EditorIdProvider value={{ editorId: editorKeyId }}>
      <Box width='full' maxW='full' {...props}>
        {!isReadonly && <Toolbar />}
        <Plate
          id={editorKeyId}
          value={value}
          onChange={onChange}
          editableProps={{
            placeholder: isReadonly
              ? ''
              : translate(TranslationKeys.typeYourPageContentHere),
            readOnly: isReadonly,
            style: {
              backgroundColor: 'white',
              paddingBlock: contentPadding,
            },
          }}
          plugins={plugins}
        />
      </Box>
    </EditorIdProvider>
  );
};
