import { createIcon } from '@chakra-ui/react';

export const SosIcon = createIcon({
  displayName: 'sos icon',
  viewBox: '0 0 25 25',
  defaultProps: {
    width: '25',
    height: '25',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  path: [
    <>
      <g clip-path='url(#clip0_346_931)'>
        <path
          d='M21.0741 2.76954C20.7995 2.49499 20.3543 2.49499 20.0797 2.76954C19.8051 3.04413 19.8052 3.48935 20.0797 3.7639C22.0807 5.76476 23.1826 8.42506 23.1826 11.2549C23.1826 11.6432 23.4975 11.958 23.8858 11.958C24.2741 11.958 24.5889 11.6432 24.5889 11.2549C24.5889 8.04945 23.3407 5.03595 21.0741 2.76954Z'
          fill='#FBFBFB'
        />
        <path
          d='M5.09792 2.77018C4.82332 2.49564 4.37815 2.49559 4.10356 2.77018C1.83706 5.03678 0.588867 8.05023 0.588867 11.2554C0.588867 11.6438 0.90368 11.9586 1.29199 11.9586C1.68031 11.9586 1.99512 11.6438 1.99512 11.2554C1.99512 8.42584 3.09706 5.76554 5.09792 3.76459C5.37251 3.49 5.37251 3.04478 5.09792 2.77018Z'
          fill='#FBFBFB'
        />
        <path
          d='M17.9494 4.89945C17.6748 5.17404 17.6749 5.61921 17.9494 5.89381C19.3814 7.32574 20.1701 9.22962 20.1701 11.2548C20.1701 11.6431 20.4849 11.9579 20.8732 11.9579C21.2615 11.9579 21.5763 11.6431 21.5763 11.2548C21.5763 8.85401 20.6414 6.59693 18.9438 4.8994C18.6692 4.6249 18.224 4.6249 17.9494 4.89945Z'
          fill='#FBFBFB'
        />
        <path
          d='M7.22796 4.89977C6.95336 4.62523 6.50815 4.62523 6.2336 4.89982C4.53621 6.59745 3.60138 8.85434 3.60138 11.2548C3.60138 11.6431 3.91619 11.9579 4.3045 11.9579C4.69282 11.9579 5.00763 11.6431 5.00763 11.2548C5.00763 9.22995 5.79621 7.32617 7.22805 5.89413C7.5026 5.61954 7.50255 5.17437 7.22796 4.89977Z'
          fill='#FBFBFB'
        />
        <path
          d='M18.5638 16.3611V12.7615C18.5638 11.4485 18.1458 10.2026 17.3549 9.15847C16.6939 8.2858 15.7925 7.6041 14.7797 7.20299C14.6387 6.11966 13.71 5.28027 12.5888 5.28027C11.4677 5.28027 10.5388 6.11962 10.3979 7.20299C9.38509 7.60415 8.48364 8.28585 7.82266 9.15847C7.03178 10.2026 6.61375 11.4485 6.61375 12.7615V16.3611L5.18177 19.2252C5.07278 19.4432 5.08446 19.702 5.21257 19.9093C5.34067 20.1166 5.56699 20.2428 5.81069 20.2428H8.94072C9.27039 21.9564 10.7804 23.2553 12.5888 23.2553C14.3971 23.2553 15.9071 21.9564 16.2367 20.2428H19.3669C19.6106 20.2428 19.8369 20.1166 19.9651 19.9093C20.0932 19.702 20.1048 19.4432 19.9959 19.2252L18.5638 16.3611ZM12.5888 21.849C11.5605 21.849 10.687 21.1734 10.3888 20.2428H14.7887C14.4906 21.1734 13.6172 21.849 12.5888 21.849ZM15.6013 18.8365H9.5763H6.94839L7.94585 16.8416C7.99469 16.7439 8.02005 16.6363 8.02005 16.5271V12.7615C8.02005 10.7556 9.36184 8.95513 11.2831 8.38316C11.5812 8.29438 11.7856 8.0203 11.7856 7.70929V7.48963C11.7856 7.0468 12.1459 6.68652 12.5888 6.68652C13.0316 6.68652 13.3919 7.0468 13.3919 7.48963V7.70929C13.3919 8.02035 13.5963 8.29443 13.8944 8.38316C15.8157 8.95513 17.1576 10.7556 17.1576 12.7615V16.5271C17.1576 16.6363 17.183 16.7439 17.2318 16.8416L18.2292 18.8365H15.6013Z'
          fill='#FBFBFB'
        />
      </g>
      <defs>
        <clipPath id='clip0_346_931'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.588867 0.909912)'
          />
        </clipPath>
      </defs>
    </>,
  ],
});
