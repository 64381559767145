import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';

import { ChakraAnimationBox } from 'src/components';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useHandsFreeOperation } from 'src/hoc/HandsFreeOperationProvider';
import { useAlertText } from 'src/hooks/useAlertText';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import { VoiceAnimation } from 'src/pages/chat/components/voice-animation';

interface AlertTextDialogProps {
  isOpen: boolean;
  onClose: () => void;
  alertId: Alert['id'];
}

export const AlertTextDialog: FC<AlertTextDialogProps> = ({
  isOpen,
  onClose,
  alertId,
}) => {
  const { setIsListening } = useHandsFreeOperation();
  const { translate } = useTranslate();

  const {
    transcribeMessage,
    handleSend,
    isValidTextContent,
    isRecording,
    restartRecording,
    cleanUp,
    handleInputChange,
    resetAudioRecorder,
  } = useAlertText(onClose, alertId);

  useEffect(() => {
    setIsListening(false);

    return () => {
      setIsListening(true);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={cleanUp}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {translate(TranslationKeys.addMoreInfoToAlert)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Container
            display='flex'
            alignItems='center'
            justifyContent='center'
            my='6'
          >
            <ChakraAnimationBox>
              <VoiceAnimation isAnimating={isRecording} />
            </ChakraAnimationBox>
          </Container>
          <Textarea
            noOfLines={4}
            overflowY='auto'
            size='md'
            height={130}
            placeholder={translate(TranslationKeys.startSpeaking)}
            value={transcribeMessage}
            autoFocus={false}
            _focus={{}}
            onChange={handleInputChange}
            isReadOnly
          />
        </ModalBody>
        <ModalFooter justifyContent='end' gridGap='4'>
          {isRecording ? (
            <Button
              variant='outline'
              onClick={() => {
                resetAudioRecorder();
              }}
            >
              {translate(TranslationKeys.stop)}
            </Button>
          ) : (
            <Button variant='outline' onClick={restartRecording}>
              {translate(TranslationKeys.reset)}
            </Button>
          )}

          <Button
            colorScheme='blue'
            onClick={handleSend}
            isDisabled={isValidTextContent}
          >
            {translate(TranslationKeys.send)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
