import { CustomTabs } from 'src/components';
import { ALERTS_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';

import AllAlertsPanel from './components/AllAlertsPanel';
import ResolveAlertsPanel from './components/ResolveAlertPanel';

export const AlertListPage = () => {
  const { translate } = useTranslate();

  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.alert)}>
      <CustomTabs
        firstTabPanel={<AllAlertsPanel />}
        firstTabTitle={translate(TranslationKeys.active)}
        secondTabPanel={<ResolveAlertsPanel />}
        secondTabTitle={translate(TranslationKeys.resolved)}
        tabIndexKey={ALERTS_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY}
      />
    </HeaderWrapper>
  );
};

export default AlertListPage;
