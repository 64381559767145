import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { Controller } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';

import { TranslationKeys } from 'src/constants/translation-keys';
import { UpdateUserType } from 'src/types/user.type';

interface CommonInputBoxProps {
  label: string;
  value: keyof UpdateUserType;
  register: UseFormRegister<UpdateUserType>;
  error: string | undefined;
  inputType?: string;
  control?: any;
}

export const CommonInputBox: React.FC<CommonInputBoxProps> = ({
  label,
  value,
  register,
  error,
  inputType,
  control,
}) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      {value === TranslationKeys.phoneNumber ? (
        <Controller
          name={value}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            const handlePhoneChange = (
              phone: string,
              data: { dialCode: string },
            ) => {
              const formattedPhone = phone.replace(
                data.dialCode,
                `${data.dialCode}-`,
              );
              onChange(formattedPhone);
            };

            return (
              <PhoneInput
                country={'jp'}
                value={value}
                onChange={handlePhoneChange}
                onBlur={onBlur}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  border: '1px solid #E2E8F0',
                }}
                buttonStyle={{
                  border: '1px solid #E2E8F0',
                  height: '40px',
                }}
              />
            );
          }}
        />
      ) : (
        <Input
          disabled={value === TranslationKeys.email}
          type={inputType}
          textColor='black'
          {...register(value)}
        />
      )}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
