import { Text } from '@chakra-ui/layout';
import { ModalProps } from '@chakra-ui/modal';
import { useMutation } from 'react-query';
import { removePageMember } from 'src/apis/pageMembers.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { PageMembers } from 'src/models/PageMembersPage.model';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { getUserDisplayName } from '../../../utils/user.utils';

interface ConfirmRemovePageMemberDialogProps
  extends Omit<ModalProps, 'children'> {
  memberToRemove: PageMembers;
  onMemberRemoved: () => any;
}

const ConfirmRemovePageMemberDialog: React.FC<
  ConfirmRemovePageMemberDialogProps
> = ({ memberToRemove, onMemberRemoved, ...props }) => {
  const { translate } = useTranslate();

  let warningMessage = translate(TranslationKeys.removeFromPageWarning);

  const { mutate: removeMember, isLoading: isRemovingMember } = useMutation(
    ['removePageMember', memberToRemove.id],
    () => removePageMember(memberToRemove.PageId, memberToRemove.id),
    { onSuccess: onMemberRemoved },
  );

  const memberName =
    memberToRemove.user && getUserDisplayName(memberToRemove.user);

  warningMessage = warningMessage.replace(
    /{.*?}/,
    memberName || translate(TranslationKeys.thisMember),
  );

  const confirmationDescription = <Text>{warningMessage}</Text>;

  return (
    <ConfirmationDialog
      title={translate(TranslationKeys.removeMember)}
      description={confirmationDescription}
      confirmLabel={translate(TranslationKeys.removeMember)}
      confirmButtonProps={{ isLoading: isRemovingMember }}
      onConfirmed={() => removeMember()}
      {...props}
    />
  );
};

export default ConfirmRemovePageMemberDialog;
