import { User } from './';
import { BaseModel } from './base.model';
import { MessageMedia } from './MessageMedia.model';

export type MessageStatus = 'sent' | 'delivered' | 'seen';

export interface Message extends BaseModel {
  text: string;
  MessageThreadId: number;
  SenderId: number;
  sender?: User;
  status: MessageStatus;
  messageMedias: MessageMedia[];
  messageAt: Date;
  messageType: MessageType;
}

export enum MessageType {
  asrChat = 'asrChat',
  normalChat = 'normalChat',
}
