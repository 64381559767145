import { Box, Flex, HStack, Icon } from '@chakra-ui/react';
import { MdOndemandVideo } from 'react-icons/md';

import { AlignToolbarButtons } from './Align.toolbar';
import { BallonToolbarMarks } from './BaloonMarks.toolbar';
import { ListToolbarButtons } from './List.toolbar';
import { MarkToolbarButtons } from './mark-toolbar';
import { ImageToolbar } from './Image.toolbar';
import { LinkToolbarButton } from './link-toolbar';
import { MediaEmbedToolbarButton } from '../../plugins/media-embed/toolbar-button';
import { CUSTOM_SCROLL_BAR_CSS } from 'src/constants';

interface ToolbarProps {}

const Toolbar: React.FC<ToolbarProps> = () => {
  return (
    <Flex css={CUSTOM_SCROLL_BAR_CSS} overflowX='auto' width='full'>
      <BallonToolbarMarks />
      <Box as={HStack} color='gray.600'>
        <HStack spacing='0'>
          <MarkToolbarButtons />
        </HStack>

        <HStack spacing='0'>
          <ListToolbarButtons />
        </HStack>

        <HStack spacing='0'>
          <AlignToolbarButtons />
        </HStack>

        <HStack spacing='1'>
          <ImageToolbar />
          <LinkToolbarButton iconProps={{ fontSize: '24px' }} size='xs' />
          <MediaEmbedToolbarButton
            icon={<Icon as={MdOndemandVideo} boxSize='5' />}
            size='xs'
            p='0'
          />
        </HStack>
      </Box>
    </Flex>
  );
};

export default Toolbar;
