import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { EnglishTranslationValues } from 'src/constants/english-translation-values';
import { JapaneseTranslationValues } from 'src/constants/japanese-translation-values';

const resources = {
  en: {
    translation: EnglishTranslationValues,
  },
  ja: {
    translation: JapaneseTranslationValues,
  },
};

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
