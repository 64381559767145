import { useMutation } from 'react-query';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';
import {
  BEFORE_UNLOAD_EVENT,
  POP_STATE_EVENT,
} from 'src/constants/event-listeners.constants';
import { leaveMultipleCallSessions } from 'src/apis/call-sessions.api';

import { leaveCalls } from '../utils/call-session.utils';

const useCallValidator = () => {
  const history = useHistory();
  const { callSessions } = useCallSessionsContext();
  const leaveAllCallSessions = useMutation(leaveMultipleCallSessions);

  const allDisconnected = useMemo(() => {
    if (_.isEmpty(callSessions)) return false;

    return _.every(callSessions, session => Boolean(session.disconnectedAt));
  }, [callSessions]);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    leaveCalls(callSessions, leaveAllCallSessions);
    return true;
  };

  const handlePopState = () => {
    leaveCalls(callSessions, leaveAllCallSessions);
  };

  useEffect(() => {
    if (allDisconnected) {
      history.goBack();
    } else {
      window.addEventListener(BEFORE_UNLOAD_EVENT, handleBeforeUnload);
      window.addEventListener(POP_STATE_EVENT, handlePopState);
    }

    return () => {
      window.removeEventListener(BEFORE_UNLOAD_EVENT, handleBeforeUnload);
      window.removeEventListener(POP_STATE_EVENT, handlePopState);
    };
  }, [allDisconnected, history]);
};

export default useCallValidator;
