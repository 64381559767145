import { Center, Text, useBreakpointValue } from '@chakra-ui/react';

import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import useCall from 'src/pages/peer-call/hooks/useCall';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

interface CallUserImageProps {
  name: string;
  isCurrentUser?: boolean;
  hasJoined?: boolean;
}

const CallUserImage: React.FC<CallUserImageProps> = ({
  name,
  isCurrentUser,
  hasJoined,
}) => {
  const { translate } = useTranslate();
  const { callSessions } = useCallSessionsContext();
  const { call } = useCall();
  const userAvatarSize = useBreakpointValue({ base: 'lg', sm: 'xl' });

  if (call && !call.isGroupCall) {
    return (
      <Center h='full' w='full' bgColor='gray.700'>
        <UserAvatar name={name} size='xl' fontSize='44' />
      </Center>
    );
  }

  const getConnectingStatus = () => {
    if (!isCurrentUser) {
      if (callSessions.length && !hasJoined) {
        return (
          <Text color='white'>{translate(TranslationKeys.connecting)}</Text>
        );
      }
      return null;
    }
  };

  return (
    <Center
      h='full'
      w='full'
      bgColor='gray.700'
      flexDir='column'
      gridGap='2'
      position='relative'
    >
      <UserAvatar name={name} size={userAvatarSize} />
      {getConnectingStatus()}
    </Center>
  );
};

export default CallUserImage;
