import { VStack, Text } from '@chakra-ui/react';

interface AlertMediaInformationProps {
  children: React.ReactNode;
  title: string;
}

export const AlertMediaInformation: React.FC<AlertMediaInformationProps> = ({
  children,
  title,
}) => {
  return (
    <VStack width='full' align='start' px='2'>
      <Text fontSize='2xl' fontWeight='bold'>
        {title}
      </Text>
      {children}
    </VStack>
  );
};
