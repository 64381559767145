import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { RiCameraSwitchFill } from 'react-icons/ri';

interface CameraSwitchIconProps extends Omit<IconButtonProps, 'aria-label'> {
  switchCamera: () => void;
  iconFill?: string;
}

export const CameraSwitchIcon: React.FC<CameraSwitchIconProps> = ({
  switchCamera,
  iconFill = 'black',
  ...props
}) => {
  return (
    <IconButton
      aria-label='Switch camera'
      variant='ghost'
      onClick={switchCamera}
      size='xl'
      icon={<RiCameraSwitchFill size='30' fill={iconFill} />}
      _focus={{}}
      _active={{}}
      {...props}
    />
  );
};
