import * as Leaflet from 'leaflet';

import iconUrl from './blue-icon.svg';

export const BlueMarker = new Leaflet.Icon({
  iconUrl,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [25, 55],
});
