import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { RESET_PASSWORD_ROUTE } from 'src/routes/routeList';

export const RESET_PASSWORD_TOKEN_KEY = 'reset-password-token';
const TOKEN_SEARCH_PARAM = 'token';

export const useResetPasswordToken = () => {
  const history = useHistory();
  const token = useSearchParam(TOKEN_SEARCH_PARAM);

  const setResetPasswordToken = (token: string) => {
    if (!token) return;

    localStorage.setItem(RESET_PASSWORD_TOKEN_KEY, token);
  };

  const resetPasswordToken = localStorage.getItem(RESET_PASSWORD_TOKEN_KEY);

  const removeResetPasswordToken = () =>
    localStorage.removeItem(RESET_PASSWORD_TOKEN_KEY);

  useEffect(() => {
    if (token) {
      setResetPasswordToken(token);
      history.push(RESET_PASSWORD_ROUTE);
    }
  }, [token]);

  return { resetPasswordToken, removeResetPasswordToken };
};
