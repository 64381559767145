import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

interface CustomAccordionItemProps {
  title: string;
}

export const CustomAccordionItem: React.FC<CustomAccordionItemProps> = ({
  title,
  children,
}) => {
  return (
    <AccordionItem>
      <AccordionButton _focus={{}}>
        <Box as='span' flex='1' textAlign='left'>
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>{children}</AccordionPanel>
    </AccordionItem>
  );
};
