import { Icon, IconProps } from '@chakra-ui/react';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import useUnreadChatsCount from '../../../hooks/useUnreadChatsCount';
import NumberBadge from '../../NumberBadge';

interface MessagesButtonIconProps {
  isCurrentPage: boolean;
}

export const MessagesButtonIcon: React.FC<MessagesButtonIconProps> = ({
  isCurrentPage,
}) => {
  const unreadChatsCount = useUnreadChatsCount();
  const hasUnreadChats = unreadChatsCount > 0;
  const iconColor: IconProps['color'] = isCurrentPage ? 'white' : 'black';

  return (
    <>
      <Icon as={IoChatbubbleEllipsesOutline} color={iconColor} boxSize='5' />
      {hasUnreadChats && (
        <NumberBadge
          position='absolute'
          top='0.5'
          right={{
            base: '16',
            md: '28',
          }}
          left={{
            base: '10',
            md: '20',
          }}
          minW='5'
          maxW='10'
          fontSize='xs'
          fontWeight='bold'
          color='white'
          bg='blue.500'
          displayNumber={unreadChatsCount}
        />
      )}
    </>
  );
};
