import { useDisclosure, useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useMutation, UseQueryResult } from 'react-query';

import { reviewPage } from 'src/apis/pages.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { PageDraftReviewDto } from 'src/dto/pages.dto';
import { useTranslate } from 'src/hooks/useTranslate';
import { PageDraft, PageDraftStatus } from 'src/models/pageDraft';

export const useRejectContent = (
  refetchDraftRequest: UseQueryResult['refetch'],
) => {
  const showToast = useToast();
  const rejectDraftModalDisclosure = useDisclosure();
  const { translate } = useTranslate();

  const updatePageContentMutation = useMutation<
    void,
    AxiosError,
    PageDraftReviewDto
  >(reviewPage, {
    onSuccess: () => {
      refetchDraftRequest();
      rejectDraftModalDisclosure.onClose();
      showToast({
        title: translate(TranslationKeys.pageContentRejected),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      rejectDraftModalDisclosure.onClose();
      showToast({
        title: translate(TranslationKeys.errorApprovingPageContent),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleRejectPageContent = (draft: PageDraft, review: string) => {
    if (!draft) {
      return;
    }
    updatePageContentMutation.mutate({
      status: PageDraftStatus.rejected,
      reviewRemarks: review,
      PageId: draft.PageId,
      id: draft.id,
    });
  };

  return {
    handleRejectPageContent,
    updatePageContentMutation,
    rejectDraftModalDisclosure,
  };
};
