import { Accordion } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getAllAsrChatMessageThreads } from 'src/apis/asr-message-threads.api';
import { CustomAccordionItem } from 'src/components';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import {
  ASR_CHAT_HISTORY_PAGE_ROUTE,
  MESSAGE_THREAD_PARAM_NAME,
} from 'src/routes/routeList';

import { AsrGroupMessagePanel } from './AsrGroupMessagePanel';
import { AsrPersonalMessagePanel } from './AsrPersonalMessagePanel';

const ASR_CHAT_MESSAGE_THREADS_QUERY_KEY = 'getAllAsrChatMessageThreads';

export const AsrMessagePanel = () => {
  const { translate } = useTranslate();
  const { push } = useHistory();

  const { data: asrMessageThreads } = useQuery(
    ASR_CHAT_MESSAGE_THREADS_QUERY_KEY,
    getAllAsrChatMessageThreads,
  );

  const goToAsrChat = (messageThreadId: number) => {
    push(
      ASR_CHAT_HISTORY_PAGE_ROUTE.replace(
        MESSAGE_THREAD_PARAM_NAME,
        messageThreadId.toString(),
      ),
    );
  };

  if (!asrMessageThreads) {
    return null;
  }

  return (
    <Accordion defaultIndex={[0]}>
      <CustomAccordionItem title={translate(TranslationKeys.personal)}>
        <AsrPersonalMessagePanel
          asrPersonalMessageThread={asrMessageThreads.personal}
          goToAsrChat={goToAsrChat}
        />
      </CustomAccordionItem>
      <CustomAccordionItem title={translate(TranslationKeys.groups)}>
        <AsrGroupMessagePanel
          asrGroupMessageThread={asrMessageThreads.group}
          goToAsrChat={goToAsrChat}
        />
      </CustomAccordionItem>
    </Accordion>
  );
};
