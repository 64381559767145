import * as _ from 'lodash';

import { axiosInstance } from 'src/config/axiosInstance';
import { PersonalCallResponse, PersonalCalls } from 'src/models/PersonalCall';
import { getAccessToken } from 'src/providers/auth-store.provider';

import { getRequestData } from './utils/get-request-data.util';

axiosInstance.interceptors.request.use(config => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

const kPersonalCallsUrl = 'calls/personal';

export const getAllPersonalCalls = async (
  pageNumber?: number,
): Promise<PersonalCallResponse> => {
  const { calls, pageSize, total } = await getRequestData<PersonalCallResponse>(
    axiosInstance.get(`${kPersonalCallsUrl}?pageNumber=${pageNumber}`),
  );

  const personalCalls = _.map(calls, (personalCall: PersonalCalls) => ({
    ...personalCall,
    createdAt: new Date(personalCall.createdAt),
  }));

  return { calls: personalCalls, pageSize, total };
};

export const getMorePersonalCalls = async (
  otherUserId: number,
  isVisible: boolean,
  pageNumber?: number,
): Promise<PersonalCallResponse> => {
  if (!isVisible) {
    return { calls: [], pageSize: 0, total: 0 };
  }

  const { calls, pageSize, total } = await getRequestData<PersonalCallResponse>(
    axiosInstance.get(
      `${kPersonalCallsUrl}/more?pageNumber=${pageNumber}&otherUser=${otherUserId}`,
    ),
  );

  const personalCalls = _.map(calls, (personalCall: PersonalCalls) => ({
    ...personalCall,
    createdAt: new Date(personalCall.createdAt),
  }));

  return { calls: personalCalls, pageSize, total };
};
