import { IconButton, List } from '@chakra-ui/react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';
import { getUserDisplayName } from 'src/utils';

import MessageItem from '../../components/MessageItem';
import getLatestMessageText from '../../utils/getLatestMessage.util';
import getMessageThreadDate from '../../utils/getMessageThreadDate.util';
import EmptyPersonalChats from '../components/EmptyPersonalChats';

interface AsePersonalMessagePanelProps {
  asrPersonalMessageThread: PersonalMessageThread[];
  goToAsrChat: (messageThreadId: number) => void;
}

export const AsrPersonalMessagePanel: React.FC<
  AsePersonalMessagePanelProps
> = ({ asrPersonalMessageThread, goToAsrChat }) => {
  const { translate } = useTranslate();

  return (
    <List spacing='4' paddingInline='0'>
      {_.isEmpty(asrPersonalMessageThread) ? (
        <EmptyPersonalChats showTips={false} />
      ) : (
        _.map(
          asrPersonalMessageThread,
          ({
            id,
            recipient,
            latestMessage,
            createdAt,
            unreadMessagesCount,
          }) => (
            <MessageItem
              key={id}
              title={getUserDisplayName(recipient)}
              subtitle={getLatestMessageText(
                latestMessage,
                translate(TranslationKeys.beginConversation),
              )}
              topRightInfo={getMessageThreadDate({
                createdAt,
                latestMessage,
              })}
              unreadMessageCount={unreadMessagesCount}
              avatarComponent={
                <IconButton
                  as={Link}
                  aria-label='Open user details'
                  icon={<UserAvatar user={recipient} />}
                  to={`/user/${recipient.id}/profile`}
                />
              }
              onClick={() => goToAsrChat(id)}
            />
          ),
        )
      )}
    </List>
  );
};
