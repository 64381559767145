import { SimpleGrid } from '@chakra-ui/react';

import { ONE_USER_IN_CALL } from 'src/constants';
import { getCallingGridColumns } from 'src/pages/peer-call/utils/call-session.utils';
import { CallSession } from 'src/models/CallSession.model';

interface GroupCallGridWrapperProps {
  callSessionsChunk: CallSession[];
  height: string;
  isFirstTab: boolean;
}

export const GroupCallGridWrapper: React.FC<GroupCallGridWrapperProps> = ({
  callSessionsChunk,
  children,
  height,
  isFirstTab,
}) => {
  return (
    <SimpleGrid
      bgColor='black'
      gap='2'
      color='white'
      columns={getCallingGridColumns(callSessionsChunk, isFirstTab)}
      rows={callSessionsChunk.length === ONE_USER_IN_CALL ? 0 : 2}
      h={height}
    >
      {children}
    </SimpleGrid>
  );
};
