import {
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { getGroupMemberRecordForSelf } from '../../../apis/group-message-threads.api';
import useDialog from '../../../hooks/useDialog';
import {
  kMessageThreadIdParamName,
  MESSAGE_MEMBERS_PAGE_ROUTE,
} from '../../../routes/routeList';
import GroupEditDialog from './GroupEditDialog';
import LeaveGroupDialog from './LeaveGroupDialog';

interface GroupMenuProps {
  messageThreadId: number;
}

const GroupMenu: React.FC<GroupMenuProps> = ({ messageThreadId }) => {
  const { translate } = useTranslate();
  const { data: currentUserGroupMemberRecord } = useQuery(
    ['getGroupMemberRecordForSelf', messageThreadId],
    () => getGroupMemberRecordForSelf(messageThreadId),
  );

  const [
    isLeaveGroupConfirmDialogOpen,
    openLeaveGroupConfirmationDialog,
    closeLeaveGroupConfirmationDialog,
  ] = useDialog();

  const [isEditGroupDialogOpen, openEditGroupDialog, closeEditGroupDialog] =
    useDialog();

  const history = useHistory();

  const memberRoute = MESSAGE_MEMBERS_PAGE_ROUTE.replace(
    `:${kMessageThreadIdParamName}`,
    messageThreadId.toString(),
  );

  const onGroupLeave = () => history.goBack();

  return (
    <>
      <Menu autoSelect={false} placement='left-start'>
        <MenuButton>
          <Icon as={IoEllipsisVertical} boxSize='6' />
        </MenuButton>
        <MenuList>
          <MenuItem as={Link} to={memberRoute}>
            {translate(TranslationKeys.viewMembers)}
          </MenuItem>
          {currentUserGroupMemberRecord?.isAdmin && (
            <MenuItem onClick={openEditGroupDialog}>
              {translate(TranslationKeys.editGroupInfo)}
            </MenuItem>
          )}
          <MenuDivider />
          <MenuItem color='red' onClick={openLeaveGroupConfirmationDialog}>
            {translate(TranslationKeys.leaveGroup)}
          </MenuItem>
        </MenuList>
      </Menu>
      <LeaveGroupDialog
        onLeave={onGroupLeave}
        isOpen={isLeaveGroupConfirmDialogOpen}
        onClose={closeLeaveGroupConfirmationDialog}
        messageThreadId={messageThreadId}
      />
      <GroupEditDialog
        messageThreadId={messageThreadId}
        isOpen={isEditGroupDialogOpen}
        onClose={closeEditGroupDialog}
      />
    </>
  );
};

export default GroupMenu;
