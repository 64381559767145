import { Container, Textarea } from '@chakra-ui/react';
import React from 'react';
import { BsMicFill } from 'react-icons/bs';

import { ChakraAnimationBox } from 'src/components';
import {
  AUDIO_MIC_ANIMATION,
  AUDIO_MIC_ANIMATION_TRANSITION,
} from 'src/constants/speech-recognition.constant';

interface WakeWordDetectionDialogProps {
  isEndOfUtteranceDetected: boolean;
  transcribeMessage: string;
}

export const WakeWordDetectionDialogBody: React.FC<
  WakeWordDetectionDialogProps
> = ({ isEndOfUtteranceDetected, transcribeMessage }) => {
  return (
    <Container
      display='flex'
      alignItems='center'
      justifyContent='center'
      marginTop='20px'
      flexDir='column'
    >
      <VoiceAnimation isEndOfUtteranceDetected={isEndOfUtteranceDetected} />
      <Textarea
        autoFocus={false}
        overflowY='auto'
        size='md'
        height={130}
        border='none'
        top='3'
        focusBorderColor='transparent'
        value={transcribeMessage}
        _focus={{}}
        isReadOnly
      />
    </Container>
  );
};

interface VoiceAnimationProps {
  isEndOfUtteranceDetected: boolean;
}

const VoiceAnimation: React.FC<VoiceAnimationProps> = ({
  isEndOfUtteranceDetected,
}) => {
  if (isEndOfUtteranceDetected) {
    return (
      <ChakraAnimationBox layerStyle='ActiveRecordingAnimation'>
        <BsMicFill size={30} style={{ color: 'white' }} />
      </ChakraAnimationBox>
    );
  }

  return (
    <ChakraAnimationBox
      layerStyle='ActiveRecordingAnimation'
      animate={AUDIO_MIC_ANIMATION}
      // @ts-ignore no problem in operation, although type error appears.
      transition={AUDIO_MIC_ANIMATION_TRANSITION}
    >
      <BsMicFill size={30} style={{ color: 'white' }} />
    </ChakraAnimationBox>
  );
};
