import { useMutation } from 'react-query';

import { startNewAsrChat } from 'src/apis/asr-message-threads.api';
import { useUpdateUserLocationInMessageThread } from 'src/hooks/use-update-user-location';

export const useStartNewAsrChat = () => {
  const { updateUserLocation } = useUpdateUserLocationInMessageThread();

  const { mutate: startAsrChat } = useMutation(startNewAsrChat);

  const startNewASRChat = (messageThreadId: number) => {
    if (messageThreadId) {
      startAsrChat(messageThreadId);
      updateUserLocation(messageThreadId);
    }
  };

  return {
    startNewASRChat,
  };
};
