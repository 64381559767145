import { Badge, BadgeProps } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { Post } from '../../../models/Post.model';

const CustomBadge: React.FC<BadgeProps> = props => (
  <Badge fontSize='xs' {...props} />
);

const PostApprovalStatusBadge: React.FC<{ post: Post }> = ({ post }) => {
  const { translate } = useTranslate();
  if (post.isApproved === null) {
    return (
      <CustomBadge colorScheme='cyan'>
        {translate(TranslationKeys.awaitingApproval)}
      </CustomBadge>
    );
  }

  return post.isApproved ? (
    <CustomBadge colorScheme='green'>
      {translate(TranslationKeys.approved)}
    </CustomBadge>
  ) : (
    <CustomBadge colorScheme='red'>
      {translate(TranslationKeys.rejected)}
    </CustomBadge>
  );
};

export default PostApprovalStatusBadge;
