import { MutableRefObject, memo } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  ListItem,
  Text,
} from '@chakra-ui/react';

import { GroupCalls } from 'src/models/GroupCall';
import { getCallerName } from 'src/utils/get-call-details';

import { GroupCallIcon } from './GroupCallIcon';
import RandomGroupCallButton from './RandomGroupCallButton';
import GroupVoiceCallButton from './GroupVoiceCallButton';
import { CallAvatar } from './CallAvatar';
import { GroupCallTable } from './GroupCallTable';

interface GroupCallItemProps {
  call: GroupCalls;
  observerRef: MutableRefObject<HTMLDivElement | null> | null;
}

const GroupCallItem: React.FC<GroupCallItemProps> = memo(
  ({ call, observerRef }) => {
    const { messageGroup, session, MessageGroupId, createdAt, callStatus } =
      call;

    // Scroll time formatter
    const time24HourWithDay = (date: Date) => {
      return Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date));
    };

    return (
      <AccordionItem key={MessageGroupId} ref={observerRef}>
        <ListItem py='2' as={AccordionButton}>
          <Flex pr='5'>
            <CallAvatar sessions={session} messageGroup={messageGroup} />
          </Flex>
          <Flex flexDirection='column' w='full' flex='1'>
            <HStack w='full'>
              <Flex>
                <Text
                  textAlign='start'
                  fontWeight='bold'
                  fontSize='lg'
                  noOfLines={1}
                >
                  {getCallerName(messageGroup, session)}
                </Text>
              </Flex>
            </HStack>
            <HStack w='full'>
              <Flex>
                <Flex mr='2'>
                  <Text fontWeight='bold'>
                    <GroupCallIcon sessions={session} callStatus={callStatus} />
                  </Text>
                </Flex>
                <Flex>
                  <Text
                    noOfLines={1}
                    fontWeight='normal'
                    color='gray.500'
                    whiteSpace='nowrap'
                  >
                    {time24HourWithDay(createdAt)}
                  </Text>
                </Flex>
              </Flex>
            </HStack>
          </Flex>
          <Flex flex='1' justifyContent='flex-end' pr='2' alignItems='center'>
            <Flex>
              {MessageGroupId ? (
                <GroupVoiceCallButton
                  groupMessageThreadId={messageGroup.MessageThreadId}
                  groupId={MessageGroupId}
                />
              ) : (
                <RandomGroupCallButton sessions={session} />
              )}
            </Flex>
          </Flex>
          <Flex>
            <HStack w='full' as={Flex} justifyContent='flex-end'>
              <AccordionIcon />
            </HStack>
          </Flex>
        </ListItem>
        <AccordionPanel px={0} pb={4}>
          <GroupCallTable sessions={session} />
        </AccordionPanel>
      </AccordionItem>
    );
  },
);
export default GroupCallItem;
