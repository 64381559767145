import { useEffect, useMemo } from 'react';

import IncomingAsrChatModal from 'src/components/incoming-asr-chat';
import { useNewAsrChatNotification } from 'src/hooks/use-new-asr-chat';
import Routes from 'src/routes';
import { ASR_CHAT_URL } from 'src/routes/routeList';

import useCallNotifications from '../../hooks/useCallNotifications';
import UserRoomWrapper from './UserRoomWrapper';
import { UserSOSAlertWrapper } from './UserSOSAlertWrapper';

export const AppContent = () => {
  useCallNotifications();

  const isASRChatPage = useMemo(
    () => window.location.pathname.includes(ASR_CHAT_URL),
    [window.location.pathname],
  );

  const { newAsrChat, incomingAsrChatModalDisclosure } =
    useNewAsrChatNotification();

  const renderIncomingAsrChatModal = () => {
    if (!incomingAsrChatModalDisclosure.isOpen) {
      return null;
    }
    if (newAsrChat && !isASRChatPage) {
      return (
        <IncomingAsrChatModal
          {...incomingAsrChatModalDisclosure}
          {...newAsrChat}
        />
      );
    }
  };

  useEffect(() => {
    if (incomingAsrChatModalDisclosure.isOpen && isASRChatPage) {
      incomingAsrChatModalDisclosure.onClose();
    }
  }, [isASRChatPage, incomingAsrChatModalDisclosure.isOpen]);

  return (
    <UserRoomWrapper>
      <UserSOSAlertWrapper>
        <Routes />
        {renderIncomingAsrChatModal()}
      </UserSOSAlertWrapper>
    </UserRoomWrapper>
  );
};
