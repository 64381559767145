import * as speech from '@tensorflow-models/speech-commands';
import * as tf from '@tensorflow/tfjs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getTensorflowModelUrl } from 'src/apis/speech-recognition-api';
import {
  RECOGNIZER_COMMAND_CONFIG,
  RECOGNIZER_TYPE,
  TensorflowModelUrl,
  TENSORFLOW_MODEL_URL_QUERY_KEY,
  WEAK_WORD,
} from 'src/hoc/constants/hfo.constants';
import { getTfUrlPath } from 'src/hoc/HandsFreeOperationProvider/utils/get-tf-url-path';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { getAccessToken } from 'src/providers/auth-store.provider';

export const useASRWakeWordDetection = () => {
  const accessToken = getAccessToken();
  const { currentUser } = useUserDetails();

  const [isWakeWordListening, setIsWakeWordListening] = useState<boolean>(true);
  const [isListeningASR, setIsListeningASR] = useState<boolean>(false);
  const [model, setModel] = useState<speech.SpeechCommandRecognizer>();

  const { data: tensorflowModelUrl } = useQuery(
    TENSORFLOW_MODEL_URL_QUERY_KEY,
    getTensorflowModelUrl,
    {
      enabled: !!currentUser,
    },
  );

  // get the permission states of the microphone
  const permissionState = async () => {
    try {
      const permissionStateFromBrowser = await navigator.permissions.query({
        name: 'microphone',
      });
      return permissionStateFromBrowser;
    } catch (err) {
      console.log('Unable to check permission in browser', err);
    }
  };

  const initializeModel = async (tensorflowModelUrl: TensorflowModelUrl) => {
    if (tensorflowModelUrl) {
      const tfUrlModelPath = getTfUrlPath(tensorflowModelUrl.model);
      const tfUrlMetaDataPath = getTfUrlPath(tensorflowModelUrl.metaData);
      const recognizer = speech.create(
        RECOGNIZER_TYPE,
        undefined,
        tfUrlModelPath,
        tfUrlMetaDataPath,
      );
      await recognizer.ensureModelLoaded();
      setModel(recognizer);
    }
  };

  const processResult = (
    scores: Float32Array | Float32Array[],
    labels: string[],
  ) => {
    const scoreArr = Object.values(scores);

    const ArrScore = scoreArr.map((s, i) => ({
      score: s,
      word: labels[i],
    }));
    // TODO: unable to identify the type, so using any for now
    ArrScore.sort((s1: any, s2: any) => s2.score - s1.score);
    return ArrScore;
  };

  const detectWakeWord = async (
    ArrScore: {
      score: Float32Array | Float32Array[];
      word: string;
    }[],
    model: speech.SpeechCommandRecognizer,
  ) => {
    const isMatchWord = ArrScore[0].word === WEAK_WORD;

    if (isMatchWord && model && isWakeWordListening) {
      await stopListeningWakeWordDetection();
    }
  };

  const recognizeCommands = (
    labels: string[],
    model: speech.SpeechCommandRecognizer,
  ) => {
    if (model && isWakeWordListening) {
      model.listen(
        async ({
          scores,
        }: {
          scores: Float32Array | Float32Array[];
        }): Promise<void> => {
          const arrScore = processResult(scores, labels);
          detectWakeWord(arrScore, model);
        },
        { ...RECOGNIZER_COMMAND_CONFIG },
      );
    }
  };

  const stopListeningWakeWordDetection = async () => {
    if (model?.isListening()) {
      await model.stopListening();
      setIsWakeWordListening(false);
      setIsListeningASR(true);
    }
  };

  const loadModel = async (tensorflowModelUrl: TensorflowModelUrl) => {
    const micPermissionState = await permissionState();
    initializeModel(tensorflowModelUrl);

    if (
      micPermissionState &&
      micPermissionState.state === 'granted' &&
      tensorflowModelUrl
    ) {
      tf.getBackend();
    }
  };

  useEffect(() => {
    if (model && !isWakeWordListening) {
      if (model.isListening()) {
        model.stopListening();
      }
    }

    if (model) {
      const extractedLabels = model.wordLabels();
      recognizeCommands(extractedLabels, model);
    }
  }, [model, isWakeWordListening]);

  useEffect(() => {
    if (accessToken && tensorflowModelUrl && !model) {
      loadModel(tensorflowModelUrl);
    }
  }, [model, accessToken, tensorflowModelUrl]);

  useEffect(() => {
    return () => {
      if (model?.isListening()) {
        model.stopListening();
        setModel(undefined);
      }
    };
  }, [model]);

  return {
    setIsWakeWordListening,
    isListeningASR,
    setIsListeningASR,
    stopListeningWakeWordDetection,
  };
};
