import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useMutation } from 'react-query';

import { useUploadAlertMedia } from 'src/apis';
import { VoiceIcon } from 'src/assets/icons/voice-icon';
import { TranslationKeys } from 'src/constants/translation-keys';
import { SOSMediaUpdateDto } from 'src/dto/SoS.dto';
import { useAudioRecording } from 'src/hooks/useAudioRecording';
import { useTranslate } from 'src/hooks/useTranslate';

import { RecordAndStopIcon } from './RecordAndStopIcon';
import { RecordingTime } from './RecordingTime';

interface AudioDialogProps extends Omit<ModalProps, 'children'> {
  alertId: number;
}

export const AudioDialog: React.FC<AudioDialogProps> = ({
  alertId,
  ...props
}) => {
  const { updateAlertMedia } = useUploadAlertMedia();
  const { translate } = useTranslate();
  const updateSoSMediaMutation = useMutation((alertMedia: SOSMediaUpdateDto) =>
    updateAlertMedia(alertMedia),
  );

  const {
    startRecording,
    stopRecording,
    audioUrl,
    chunks,
    releaseAudio,
    isRecording,
    mimeType,
  } = useAudioRecording();

  const releaseAudioAndCloseModal = () => {
    releaseAudio();
    props.onClose();
  };

  const onSend = () => {
    const blob = new Blob(chunks, { type: mimeType });
    updateSoSMediaMutation.mutate(
      {
        alertId: _.toString(alertId),
        messageMedia: [blob],
      },
      {
        onSuccess: releaseAudioAndCloseModal,
        onError: releaseAudioAndCloseModal,
      },
    );
  };

  return (
    <Modal {...props} onClose={releaseAudioAndCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {audioUrl
            ? translate(TranslationKeys.previewAudio)
            : translate(TranslationKeys.audioRecording)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack} pb='4'>
          <RecordingTime isRecording={isRecording} />
          {audioUrl ? (
            <audio
              src={audioUrl}
              style={{ width: '100%', paddingBottom: '10px' }}
              controls
            />
          ) : (
            <canvas
              className='visualizer'
              height='60px'
              style={{ width: '100%' }}
            />
          )}
          {audioUrl ? (
            <Button
              w='full'
              variant='solid'
              onClick={onSend}
              colorScheme='teal'
              isLoading={updateSoSMediaMutation.isLoading}
            >
              {translate(TranslationKeys.send)}
            </Button>
          ) : (
            <RecordAndStopIcon
              icon={VoiceIcon}
              iconColor='#BE63F9'
              isRecording={isRecording}
              startRecording={startRecording}
              stopRecording={stopRecording}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
