import { VStack, Table, TableContainer, Thead, Tr, Th } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import * as _ from 'lodash';
import { useRef, useState } from 'react';

import { time24Hour } from 'src/utils';
import { CUSTOM_SCROLL_BAR_CSS } from 'src/constants';
import { itemVariants } from 'src/constants/calls.constant';

import usePagination from '../hooks/usePagination';
import CallHistoryItem from './CallHistoryItem';
import useMorePersonalCalls from '../hooks/useMorePersonalCalls';

interface CallHistoryProps {
  otherUserId: number;
  isVisible: boolean;
}

export const CallHistory: React.FC<CallHistoryProps> = ({
  otherUserId,
  isVisible,
}) => {
  const pageRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<HTMLTableSectionElement | null>(null);
  const isFetchingData = useRef(false);
  const observerTargetIndex = useRef(0);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    calls: morePersonalCalls,
    hasMore,
    queryMetadata,
  } = useMorePersonalCalls(otherUserId, isVisible, pageNumber);

  usePagination({
    calls: morePersonalCalls,
    observerTargetIndex,
    isLoading: queryMetadata.isLoading,
    isFetched: queryMetadata.isFetched,
    isFetchingData,
    pageRef,
    observerRef,
    hasMore,
    setPageNumber,
  });

  return (
    <VStack
      as={motion.div}
      variants={itemVariants}
      initial='hidden'
      animate='visible'
      py='2'
    >
      <TableContainer
        w='full'
        maxH='52'
        overflowY='auto'
        css={CUSTOM_SCROLL_BAR_CSS}
        ref={pageRef}
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Joined</Th>
              <Th>Status</Th>
              <Th>Duration</Th>
            </Tr>
          </Thead>
          {/* render all items except the item at top */}
          {_.map(
            morePersonalCalls,
            ({ id, session, createdAt, callStatus }, index) => {
              const isObserverTarget =
                index === observerTargetIndex.current && hasMore;

              return (
                <CallHistoryItem
                  key={id}
                  callerId={session.CallerId}
                  joinedAt={session.joinedAt}
                  callStatus={callStatus}
                  subtitle={time24Hour(createdAt)}
                  disconnectedAt={session.disconnectedAt}
                  callItemRef={isObserverTarget ? observerRef : null}
                />
              );
            },
          )}
        </Table>
      </TableContainer>
    </VStack>
  );
};
