import { HStack, Spacer, Tag, Text } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import UserAvatar from '../../../components/UserAvatar';

import { MessageThreadMember } from '../../../models/MessageThreadMember.model';
import { ValueCallback } from '../../../types/common.type';
import { getUserDisplayName } from '../../../utils/user.utils';
import MemberActionsMenu from './MemberActionsMenu';

interface MemberItemProps {
  member: MessageThreadMember;
  // Not using ?: to make sure the record is passed - even if undefined - and not marked as optional
  currentUserMemberRecord: MessageThreadMember | undefined;
  onMemberRemoved: () => any;
  onMemberUpdated: ValueCallback<MessageThreadMember>;
}

const MemberItem: React.FC<MemberItemProps> = ({
  member,
  currentUserMemberRecord,
  onMemberRemoved,
  onMemberUpdated,
}) => {
  const { translate } = useTranslate();
  if (!member.user) {
    return null;
  }

  const isCurrentUserAndMemberSame = () =>
    currentUserMemberRecord && currentUserMemberRecord.id === member.id;

  return (
    <HStack spacing='4' w='full' key={member?.id}>
      <UserAvatar user={member?.user} />
      <HStack>
        <Text fontWeight='bold'>{getUserDisplayName(member.user)}</Text>
        {isCurrentUserAndMemberSame() && (
          <Text as='span' color='gray' fontWeight='500'>
            ({translate(TranslationKeys.you)})
          </Text>
        )}
      </HStack>
      <Spacer />
      {member.isAdmin && (
        <Tag variant='outline' colorScheme='green'>
          {translate(TranslationKeys.admin)}
        </Tag>
      )}

      {!isCurrentUserAndMemberSame() && currentUserMemberRecord && (
        <MemberActionsMenu
          currentUserMemberRecord={currentUserMemberRecord}
          member={member}
          onMemberRemoved={onMemberRemoved}
          onMemberUpdated={onMemberUpdated}
        />
      )}
    </HStack>
  );
};

export default MemberItem;
