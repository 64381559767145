import {
  DraftPageDto,
  PageDraftReviewDto,
  UpdatePageDto,
} from 'src/dto/pages.dto';
import { PageDraft } from 'src/models/pageDraft';
import { axiosInstance } from '../config/axiosInstance';
import { Page } from '../models/Page.model';
import { getRequestData } from './utils/get-request-data.util';

const kPagesRootUrl = '/pages';

export const getAllPages = (): Promise<Page[]> => {
  return getRequestData<Page[]>(axiosInstance.get(kPagesRootUrl));
};

export const getOnePage = (id: Page['id']): Promise<Page | undefined> => {
  const url = `${kPagesRootUrl}/${id}`;

  return getRequestData<Page | undefined>(axiosInstance.get(url));
};

export const updatePage = (
  id: Page['id'],
  pageUpdate: UpdatePageDto,
): Promise<Page> => {
  const url = `${kPagesRootUrl}/${id}`;
  return getRequestData<Page>(axiosInstance.patch(url, pageUpdate));
};

export const draftPage = (pageDraft: DraftPageDto) => {
  const url = `${kPagesRootUrl}/create/draft`;
  return getRequestData<PageDraft>(axiosInstance.post(url, pageDraft));
};

export const reviewPage = (pageUpdate: PageDraftReviewDto) => {
  const url = `${kPagesRootUrl}/draft/review`;
  return getRequestData<void>(axiosInstance.patch(url, pageUpdate));
};

export const getPendingDraftsForPage = (pageId: number) => {
  const url = `${kPagesRootUrl}/${pageId}/drafts/pending`;
  return getRequestData<PageDraft[]>(axiosInstance.get(url));
};

export const getUserPageDrafts = (pageId: number) => {
  const url = `${kPagesRootUrl}/${pageId}/drafts/user`;
  return getRequestData<PageDraft[]>(axiosInstance.get(url));
};
