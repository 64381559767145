import { useBoolean } from '@chakra-ui/react';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { postTextMessage } from 'src/apis/messages.api';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider';
import { MessageType } from 'src/models/Message.model';
import { TRANSCRIBE_LANGUAGE } from 'src/pages/messages/constants/language.constant';
import { ValueCallback } from 'src/types/common.type';

import { useAsrAudioTranscribeMessage } from './useAsrAudioTranscribeMessage';
import { useCommonAudioStream } from './useCommonAudioStream';

export const useASRAudio = (
  messageThreadId: number,
  onClose?: () => void,
  onMessageSend?: ValueCallback<string>,
  isListeningASR: boolean = true,
) => {
  const {
    setStartTime,
    transcribeMessage,
    setTranscribeMessage,
    isEndOfUtteranceDetected,
  } = useAsrAudioTranscribeMessage(messageThreadId);
  const { transcribeLanguage } = useHandsFreeOperationSetting();

  const [selectedLanguage, setSelectedLanguage] = useState<
    TRANSCRIBE_LANGUAGE | undefined
  >(transcribeLanguage);

  const {
    isRecording,
    resetAudioRecorder,
    startRecording,
    stopRecording,
    audioRecorder,
  } = useCommonAudioStream(
    setStartTime,
    setTranscribeMessage,
    messageThreadId,
    selectedLanguage,
    isListeningASR,
  );

  const { mutate: messageMutate } = useMutation(postTextMessage);

  const [edit, setEdit] = useBoolean(false);

  const handleLanguageChange = (language: TRANSCRIBE_LANGUAGE) => {
    setSelectedLanguage(language);
    resetAudioRecorder(() => {
      startRecording(() => {
        setEdit.off();
      });
    });
  };

  const onTranscriptionSend = () => {
    stopRecording();
    if (transcribeMessage.trim().length === 0) {
      onClose?.();
      return;
    }
    onMessageSend?.(`${transcribeMessage}`);
    messageMutate(
      {
        messageThreadId,
        text: transcribeMessage,
        messageType: MessageType.normalChat,
      },
      { onSuccess: () => onClose?.() },
    );
  };

  const handleEdit = () => setEdit.on();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTranscribeMessage(event.target.value);
  };

  const restartRecording = () => {
    setTranscribeMessage('');
    startRecording(() => {
      setEdit.off();
    });
  };

  const resetState = () => {
    resetAudioRecorder();
  };

  return {
    handleLanguageChange,
    isRecording,
    transcribeMessage,
    handleInputChange,
    edit,
    restartRecording,
    resetState,
    handleEdit,
    onTranscriptionSend,
    isEndOfUtteranceDetected,
    stopRecording,
    audioRecorder,
    setTranscribeMessage,
    setStartTime,
  };
};
