import { useQuery, UseQueryResult } from 'react-query';

import { getOnePage } from 'src/apis/pages.api';
import { Page } from 'src/models/Page.model';
import usePageParams from './usePageParams';

const getPageQueryKey = (id: Page['id']) => ['getOnePage', id];

type UsePageReturn = Omit<UseQueryResult, 'data'> & {
  page: Page | undefined;
};

const usePage = (): UsePageReturn => {
  const { id } = usePageParams();
  const { data, ...queryMetadata } = useQuery(
    getPageQueryKey(id),
    () => getOnePage(id),
    {
      enabled: !!id,
    },
  );

  return { page: data, ...queryMetadata };
};

export default usePage;
