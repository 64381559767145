import { useEffect, useMemo } from 'react';

import { CallPaginationOptions } from 'src/types/call.type';
import { checkElementPosition } from 'src/pages/calls/utils/call.util';

const usePagination = ({
  calls,
  hasMore,
  isFetched,
  isFetchingData,
  isLoading,
  observerRef,
  observerTargetIndex,
  pageRef,
  setPageNumber,
}: CallPaginationOptions) => {
  // Determine if the current item is the 70% threshold of the visible list
  useMemo(() => {
    const latestIndex = Math.floor(calls.length * 0.7);
    if (observerTargetIndex.current < latestIndex)
      observerTargetIndex.current = latestIndex;
  }, [observerTargetIndex, calls]);

  useEffect(() => {
    if (!isLoading && isFetched) {
      isFetchingData.current = false;
    }
  }, [isLoading, isFetched, calls.length]);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.addEventListener('scroll', () =>
        checkElementPosition({
          pageRef,
          observerRef,
          hasMore,
          isLoading,
          isFetched,
          isFetchingData,
          setPageNumber,
        }),
      );
    }

    return () => {
      pageRef.current?.removeEventListener('scroll', () =>
        checkElementPosition({
          pageRef,
          observerRef,
          hasMore,
          isLoading,
          isFetched,
          isFetchingData,
          setPageNumber,
        }),
      );
    };
  }, [pageRef, observerRef, hasMore, isLoading, isFetched, isFetchingData]);
};

export default usePagination;
