import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallMissed,
  unsubscribeCallMissed,
} from 'src/apis/socket.io/call-sessions.socket.io';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

const useCallMissedUpdate = () => {
  const { updatePartial } = useCallSessionsContext();

  const handleCallMissed = useCallback<CallSessionHandler>(updatePartial, [
    updatePartial,
  ]);

  useEffect(() => {
    subscribeCallMissed(handleCallMissed);

    return () => {
      unsubscribeCallMissed(handleCallMissed);
    };
  }, [handleCallMissed]);
};

export default useCallMissedUpdate;
