import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallRejected,
  unsubscribeCallRejected,
} from 'src/apis/socket.io/call-sessions.socket.io';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

const useCallRejectedUpdate = () => {
  const { updatePartial } = useCallSessionsContext();

  const handleCallRejected = useCallback<CallSessionHandler>(updatePartial, [
    updatePartial,
  ]);

  useEffect(() => {
    subscribeCallRejected(handleCallRejected);

    return () => {
      unsubscribeCallRejected(handleCallRejected);
    };
  }, [handleCallRejected]);
};

export default useCallRejectedUpdate;
