import { Icon } from '@chakra-ui/react';
import * as _ from 'lodash';
import { useEffect, useRef } from 'react';
import { MdAddIcCall } from 'react-icons/md';

import { CustomTabs } from 'src/components';
import FloatingActionButton from 'src/components/FloatingActionButton';
import { CALL_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY } from 'src/constants';
import { IS_GROUP_CALL_TAB } from 'src/constants/group-call.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useQueryLoaderContext } from 'src/hoc';
import useDialog from 'src/hooks/useDialog';
import { useTranslate } from 'src/hooks/useTranslate';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import SelectUserToCallModal from './components/SelectUserForCall';
import GroupCallsPanel from './panels/groupcall/GroupCallsPanel';
import PersonalCallsPanel from './panels/PersonalCallsPanel';
import { getCallsTabIndexNumber } from './utils/call.util';

const CallsPage = () => {
  const { translate } = useTranslate();
  const [
    isSelectUserToCallModalOpen,
    openSelectUserToCallModal,
    closeSelectUserToCallModal,
  ] = useDialog();
  const { setIsLoaderRequired } = useQueryLoaderContext();

  // If the group call tab is opened then show check boxes to select multiple members and call them together
  const tabIndex = _.toNumber(
    getCallsTabIndexNumber(CALL_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY),
  );

  const pageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsLoaderRequired(false);

    return () => {
      setIsLoaderRequired(true);
    };
  }, []);

  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.calls)}>
      <CustomTabs
        pageRef={pageRef}
        firstTabPanel={<PersonalCallsPanel pageRef={pageRef} />}
        secondTabPanel={<GroupCallsPanel pageRef={pageRef} />}
        tabIndexKey={CALL_PAGE_TAB_INDEX_LOCAL_STORAGE_KEY}
      />
      <FloatingActionButton
        aria-label='Make Call Button'
        icon={<Icon as={MdAddIcCall} boxSize='5' />}
        onClick={openSelectUserToCallModal}
      />
      <SelectUserToCallModal
        isOpen={isSelectUserToCallModalOpen}
        onClose={closeSelectUserToCallModal}
        isGroupCall={tabIndex === IS_GROUP_CALL_TAB}
      />
    </HeaderWrapper>
  );
};

export default CallsPage;
