import { IconButton } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { Center } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/modal';
import { BaseEmoji } from 'emoji-mart';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useMutation } from 'react-query';
import Slider, { Settings as CarouselSettings } from 'react-slick';

import { useUploadAlertMedia } from 'src/apis';
import { TranslationKeys } from 'src/constants/translation-keys';
import { SOSMediaUpdateDto } from 'src/dto/SoS.dto';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import BasicInputPanel from 'src/pages/chat/components/BasicInputPanel';
import MediaPreview from 'src/pages/chat/components/MediaPreview';
import PopoverEmojiButton from 'src/pages/chat/components/PopoverEmojiButton';
import useMediaPreview from 'src/pages/chat/hooks/useMediaPreview';

import BadgeBox from '../../../components/BadgeButton';
import CarouselArrow from '../../../components/CarouselArrow';
import '../../chat/components/MediaPreviewDialog.css';

interface SOSMediaPreviewProps {
  media: Blob;
}

const SoSMediaPreviewItem: React.FC<SOSMediaPreviewProps> = ({ media }) => {
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    if (!previewUrl) {
      const url = URL.createObjectURL(media);
      setPreviewUrl(url);
    }

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [media, previewUrl]);

  if (!previewUrl) {
    return null;
  }

  return (
    <Center h='full'>
      <MediaPreview
        h='full'
        maxH='96'
        media={{
          mimeType: media.type,
          mediaUrl: previewUrl,
          previewName: media instanceof File ? media.name : undefined,
        }}
      />
    </Center>
  );
};

const kMediaPreviewSliderSettings: CarouselSettings = {
  dots: true,
  infinite: true,
  lazyLoad: 'ondemand',
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CarouselArrow direction='next' />,
  prevArrow: <CarouselArrow direction='prev' />,
};

export interface MediaPreviewDialogProps extends Omit<ModalProps, 'children'> {
  media: Blob[] | File[] | FileList;
  alertId: Alert['id'];
}

export const SOSMediaPreviewDialog: React.FC<MediaPreviewDialogProps> = ({
  media,
  alertId,
  ...props
}) => {
  const { updateAlertMedia } = useUploadAlertMedia();
  const { translate } = useTranslate();
  const updateSoSMediaMutation = useMutation((alertMedia: SOSMediaUpdateDto) =>
    updateAlertMedia(alertMedia),
  );

  const [selectedMediaItems, caption, { setCaption }] = useMediaPreview({
    media,
  });

  const countMediaPreviews = selectedMediaItems.length;
  const hasMultipleMediaPreviews = countMediaPreviews > 1;

  const handleOnInputChange: React.ChangeEventHandler<HTMLInputElement> = e =>
    setCaption(e.currentTarget.value);

  const onSend = () => {
    updateSoSMediaMutation.mutate(
      {
        alertId: _.toString(alertId),
        caption,
        messageMedia: selectedMediaItems,
      },
      { onSuccess: props.onClose, onError: props.onClose },
    );
  };

  const onEmojiSelect = ({ native }: BaseEmoji) => {
    setCaption(`${caption || ''}${native}`);
  };

  const sendButton = (
    <BadgeBox
      badgeContent={countMediaPreviews}
      showBadge={hasMultipleMediaPreviews}
    >
      <IconButton
        variant='ghost'
        icon={<Icon as={MdSend} boxSize='6' />}
        aria-label='send button'
        onClick={onSend}
        isLoading={updateSoSMediaMutation.isLoading}
      />
    </BadgeBox>
  );

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{translate(TranslationKeys.previewFiles)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody py='8'>
          <Slider {...kMediaPreviewSliderSettings}>
            {selectedMediaItems.map(mediaItem => (
              <SoSMediaPreviewItem media={mediaItem} key={mediaItem.size} />
            ))}
          </Slider>
        </ModalBody>

        <ModalFooter>
          <BasicInputPanel
            inputProps={{
              value: caption,
              onChange: handleOnInputChange,
            }}
            inputPrefix={<PopoverEmojiButton onSelectEmoji={onEmojiSelect} />}
            inputSuffix={sendButton}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
