import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Flex,
  ModalFooter,
} from '@chakra-ui/react';
import { IoCall } from 'react-icons/io5';
import * as _ from 'lodash';
import { Icon } from '@chakra-ui/react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import useGroupCall from 'src/hooks/useGroupCall';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';
import { useSelectedMembers } from 'src/pages/peer-call/group-call/components/MemberItem';
import CloseButton from 'src/components/CloseButton';
import { CUSTOM_SCROLL_BAR_CSS } from 'src/constants';
import CallButton from 'src/components/CallButton';
import { useToast } from 'src/hooks/use-toast';

import SearchUserToCall from './SearchUserToCall';
import { useEffect, useState } from 'react';

interface SelectUserToCallModalProps extends Omit<ModalProps, 'children'> {
  isGroupCall: boolean;
  isCallActive?: boolean;
}

const SelectUserToCallModal: React.FC<SelectUserToCallModalProps> = ({
  isGroupCall,
  isCallActive,
  ...props
}) => {
  const { translate } = useTranslate();
  const { makeGroupCall, updateGroupCallMembers } = useGroupCall();
  const { callSessions } = useCallSessions();
  const [selectedMembers, setSelectedMembers] = useSelectedMembers();
  const { showToast } = useToast();

  const showCallButton = Object.keys(selectedMembers).length > 0;
  const disableCallButton =
    _.map(
      callSessions,
      callSession => !callSession.disconnectedAt && callSession.joinedAt,
    ).length +
      Object.keys(selectedMembers).length <=
    1;
  const maximumMembersAdded = Object.keys(selectedMembers).length >= 4;

  const [totalMembersCount, setTotalMembersCount] = useState<number>(0);

  useEffect(() => {
    if (!props.isOpen) return;
    const membersInCallSessions = _.map(
      callSessions,
      callSession => !callSession.disconnectedAt,
    ).length;
    const selectedMembersCount = Object.keys(selectedMembers).length;
    setTotalMembersCount(Math.max(membersInCallSessions, selectedMembersCount));
  }, [props.isOpen]);

  const handleOnAddMemberClick = () => {
    if (disableCallButton) {
      showToast({
        title: translate(TranslationKeys.addOneMoreMemberToStartAGroupCall),
        status: 'warning',
      });
    } else if (maximumMembersAdded) {
      return;
    } else {
      const selectedMembersUserIds = _.map(
        selectedMembers,
        ({ UserId }) => UserId,
      );
      if (isCallActive) {
        updateGroupCallMembers({
          receiverUserIds: selectedMembersUserIds,
          callId: callSessions![0].CallId,
        });
        props.onClose?.();
      } else {
        makeGroupCall({
          receiverUserIds: selectedMembersUserIds,
        });
      }
      setSelectedMembers({});
    }
  };

  const onClose = () => {
    setSelectedMembers({});
    props.onClose();
  };

  return (
    <Modal scrollBehavior='inside' isCentered {...props} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minH='50%' mx='4'>
        <ModalHeader>
          <Flex justifyContent='space-between' align='center'>
            {translate(TranslationKeys.selectUserToCall)}
            <CloseButton onClose={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody css={CUSTOM_SCROLL_BAR_CSS}>
          <SearchUserToCall
            isGroupCall={isGroupCall}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            isOpen={props.isOpen}
            totalMembersCount={totalMembersCount}
            setTotalMembersCount={setTotalMembersCount}
          />
        </ModalBody>
        {isGroupCall && showCallButton && (
          <ModalFooter>
            <CallButton
              aria-label='Make Group Call Button'
              icon={<Icon as={IoCall} boxSize='5' />}
              onClick={handleOnAddMemberClick}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SelectUserToCallModal;
