import { VStack, Text } from '@chakra-ui/react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

interface EmptyPersonalChatsProps {
  showTips?: boolean;
}

const EmptyPersonalChats: React.FC<EmptyPersonalChatsProps> = ({
  showTips = true,
}) => {
  const { translate } = useTranslate();
  return (
    <VStack mt='12'>
      <Text fontSize='lg' color='gray.500' fontWeight='semibold'>
        {translate(TranslationKeys.noPersonalChatsYet)}
      </Text>
      {showTips && (
        <Text fontSize='sm' color='gray.500'>
          {translate(TranslationKeys.createPersonalChat)}
        </Text>
      )}
    </VStack>
  );
};

export default EmptyPersonalChats;
