import { Grid } from '@chakra-ui/react';

import { NavigationLink } from 'src/components/Navigation';
import { DashboardSection } from 'src/models/DashboardSection.model';
import useDashboardSectionLinks from '../../hooks/useDashboardSectionLinks';
import { BaseSection } from '../BaseSection';
import ImageCard from '../ImageCard';

export interface ImageSectionBaseProps {
  dashboardSection: DashboardSection;
}

const ImageSection: React.FC<ImageSectionBaseProps> = ({
  dashboardSection,
}) => {
  const [dashboardSectionLinks] = useDashboardSectionLinks({
    dashboardSectionId: dashboardSection.id,
  });

  const body = (
    <Grid gap='8'>
      {dashboardSectionLinks.map(link => (
        <NavigationLink
          url={link.linkUrl}
          key={link.id}
          isExternal={link.isExternal}
        >
          <ImageCard src={link.imageUrl} caption={link.linkText} />
        </NavigationLink>
      ))}
    </Grid>
  );

  return <BaseSection title={dashboardSection.title} body={body} />;
};

export default ImageSection;
