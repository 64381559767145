import { createIcon } from '@chakra-ui/react';

export const MessageSpokenIcon = createIcon({
  displayName: 'Text to speech icon',
  viewBox: '0 0 252 252',
  defaultProps: {
    width: '252',
    height: '252',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  path: [
    <>
      <path
        d='M192.78 203.281C190.082 203.281 187.383 202.241 185.336 200.183C181.241 196.067 181.262 189.42 185.378 185.325C201.253 169.554 210 148.491 210 126C210 103.509 201.253 82.4461 185.378 66.675C181.262 62.58 181.241 55.944 185.336 51.8175C189.42 47.7015 196.056 47.6805 200.182 51.7755C220.059 71.526 231 97.8811 231 126C231 154.119 220.059 180.474 200.182 200.225C198.135 202.262 195.458 203.281 192.78 203.281Z'
        fill='#8C8C8C'
      />
      <path
        d='M163.065 173.565C160.356 173.565 157.647 172.526 155.6 170.447C151.515 166.32 151.557 159.674 155.684 155.6C163.506 147.861 168 137.067 168 126C168 114.933 163.506 104.139 155.684 96.4008C151.557 92.3268 151.526 85.6804 155.6 81.5539C159.674 77.4379 166.32 77.3959 170.447 81.4699C182.238 93.1248 189 109.358 189 126C189 142.643 182.238 158.876 170.447 170.531C168.399 172.557 165.732 173.565 163.065 173.565Z'
        fill='#8C8C8C'
      />
      <path
        d='M126.001 231.001C123.271 231.001 120.583 229.93 118.577 227.924L69.1533 178.5H42.0001C30.4186 178.5 21 169.082 21 157.5V94.5001C21 82.9186 30.4186 73.5001 42.0001 73.5001H69.1533L118.577 24.0764C121.58 21.0629 126.095 20.1704 130.022 21.7979C133.949 23.4254 136.501 27.2579 136.501 31.4999V220.501C136.501 224.743 133.949 228.575 130.022 230.203C128.72 230.738 127.355 231.001 126.001 231.001ZM42.0001 94.5001V157.5H73.5003C76.2933 157.5 78.9603 158.603 80.9238 160.577L115.501 195.153V56.847L80.9238 91.4236C78.9603 93.3976 76.2933 94.5001 73.5003 94.5001H42.0001Z'
        fill='#8C8C8C'
      />
    </>,
  ],
});
