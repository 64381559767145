import { useQuery } from 'react-query';

import {
  getAllMembersForPublicPage,
  getAllPageMembers,
} from 'src/apis/pageMembers.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { PageMembers } from 'src/models/PageMembersPage.model';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

interface UsePageMembersOptions {
  pageId: number;
}

const usePageMembers = ({ pageId }: UsePageMembersOptions) => {
  const { currentUser } = useUserDetails();

  const getAllPageMembersQueryKey = ['getAllPageMembers', pageId];
  const { data: pageMembers } = useQuery(
    getAllPageMembersQueryKey,
    () => {
      if (currentUser) {
        return getAllPageMembers(pageId);
      }

      return getAllMembersForPublicPage(pageId);
    },
    { enabled: !!currentUser },
  );

  const { addOne, addMultiple, updateOne, removeOne } =
    useArrayDataQuery<PageMembers>({
      queryKey: getAllPageMembersQueryKey,
    });

  return {
    pageMembers,
    addMember: addOne,
    addMultipleMembers: addMultiple,
    updateMember: updateOne,
    removeMember: removeOne,
  };
};

export default usePageMembers;
