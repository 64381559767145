import { useEffect, useMemo } from 'react';

import { ONE_MEMBER_IN_GROUP } from 'src/constants/group-call.constant';
import CallPageBody from 'src/pages/peer-call/components/body/CallPageBody';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

import { GroupStreamContainer } from './components/GroupStreamContainer';

const GroupCallBody = () => {
  const { callSessions, refetch } = useCallSessionsContext();

  const isPersonalCall = useMemo(
    () => callSessions.length <= ONE_MEMBER_IN_GROUP,
    [callSessions],
  );

  useEffect(() => {
    if (callSessions) {
      refetch();
    }
  }, [callSessions]);

  return <>{isPersonalCall ? <CallPageBody /> : <GroupStreamContainer />}</>;
};

export default GroupCallBody;
