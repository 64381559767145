import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
} from '@chakra-ui/react';

import { useUploadAlertMedia } from 'src/apis/hooks/useUpdateAlertMedia';
import { VideoIcon } from 'src/assets/icons/video-icon';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { useVideoRecording } from 'src/hooks/useVideoRecording';
import { Alert } from 'src/models/Alerts.model';

import { CameraSwitchIcon } from '../Common/CameraSwitchIcon';
import { UploadingProgress } from '../UploadingProgress';
import { RecordAndStopIcon } from './RecordAndStopIcon';
import { RecordingTime } from './RecordingTime';
import { SendVideoWithCaptionFooter } from './SendVideoWithCaptionFooter';

interface VideoDialogProps extends Omit<ModalProps, 'children'> {
  alertId: Alert['id'];
}

export const VideoDialog: React.FC<VideoDialogProps> = ({
  alertId,
  ...props
}) => {
  const { translate } = useTranslate();
  const {
    releaseCamera,
    videoRef,
    videoUrl,
    videoBlob,
    startRecording,
    stopRecording,
    mediaRecorder,
    setIsFrontCamera,
  } = useVideoRecording();

  const handleModalClose = () => {
    releaseCamera();
    props.onClose();
  };

  const { progress, updateAlertMedia } = useUploadAlertMedia();

  const isRecording = mediaRecorder?.state === 'recording';

  return (
    <Modal {...props} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {videoUrl
            ? translate(TranslationKeys.videoPreview)
            : translate(TranslationKeys.recordVideo)}
        </ModalHeader>
        <ModalBody position='relative' as={VStack} w='full' py='4'>
          <RecordingTime isRecording={isRecording} />
          {!videoUrl && (
            <video ref={videoRef} style={{ minWidth: '100%' }} playsInline />
          )}
          {videoUrl && (
            <video
              src={videoUrl}
              style={{ minWidth: '100%' }}
              controls
              playsInline
            />
          )}
          <UploadingProgress progressValue={progress} />

          {videoUrl ? (
            <SendVideoWithCaptionFooter
              alertId={alertId}
              media={[videoBlob]}
              onSuccessOrError={handleModalClose}
              updateAlertMedia={updateAlertMedia}
            />
          ) : (
            <RecordAndStopIcon
              icon={VideoIcon}
              iconColor='#26C6DA'
              isRecording={isRecording}
              startRecording={startRecording}
              stopRecording={stopRecording}
            />
          )}
          {!isRecording && !videoUrl && (
            <CameraSwitchIcon
              setIsFrontCamera={setIsFrontCamera}
              position='absolute'
              bottom='8'
              right='6'
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
