import { HStack, Tab, TabProps, Text } from '@chakra-ui/react';

import NumberBadge from '../NumberBadge';

interface CommonTabProps extends TabProps {
  title: string;
  unreadCount?: number;
}

export const CommonTab: React.FC<CommonTabProps> = ({
  title,
  unreadCount,
  ...tabProps
}) => {
  return (
    <Tab
      as={HStack}
      cursor='pointer'
      alignItems='center'
      position='relative'
      m='1'
      fontWeight='bold'
      color='gray.500'
      borderRadius='10'
      _selected={{
        color: 'black',
        bg: 'white',
        boxShadow: '0 0.2rem 0.5rem -0.1rem grey',
      }}
      {...tabProps}
    >
      <Text>{title}</Text>
      {unreadCount && (
        <NumberBadge
          minW='6'
          maxW='10'
          fontSize='xs'
          fontWeight='bold'
          color='white'
          bg='blue.500'
          borderRadius='full'
          py='0.5'
          px='1'
          displayNumber={unreadCount}
        />
      )}
    </Tab>
  );
};
