import { Badge, BadgeProps } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { PageDraftStatus } from 'src/models/pageDraft';

interface PageRejectStatusBadgeProps {
  status: PageDraftStatus;
}

export const PageDraftStatusBadge: React.FC<PageRejectStatusBadgeProps> = ({
  status,
}) => {
  const { translate } = useTranslate();
  if (status === PageDraftStatus.pending) {
    return (
      <CustomBadge colorScheme='cyan'>
        {translate(TranslationKeys.awaitingApproval)}
      </CustomBadge>
    );
  }

  return status === PageDraftStatus.approved ? (
    <CustomBadge colorScheme='green'>
      {translate(TranslationKeys.approved)}
    </CustomBadge>
  ) : (
    <CustomBadge colorScheme='red'>
      {translate(TranslationKeys.rejected)}
    </CustomBadge>
  );
};

const CustomBadge: React.FC<BadgeProps> = props => (
  <Badge fontSize='xs' {...props} />
);
