import { IconButton } from '@chakra-ui/react';
import { FaRegWindowClose } from 'react-icons/fa';

interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => {
  return (
    <IconButton
      aria-label='Close Modal'
      icon={<FaRegWindowClose />}
      cursor='pointer'
      isRound
      onClick={onClose}
      p='0'
    />
  );
};

export default CloseButton;
