import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';

import { createGroupCall, updateGroupCall } from 'src/apis/calls.api';
import {
  AddMembersInGroupCallDto,
  CreateGroupCallDto,
} from 'src/dto/calls.dto';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';
import { useUserLocation } from 'src/hoc/UserLocationContext';
import { Call } from 'src/models/Call.model';
import { buildGroupCallPageRoute } from 'src/routes/call/groupCall.route';

const useGroupCall = () => {
  const { refetch } = useCallSessionsContext();
  const history = useHistory();
  const { locationStream } = useUserLocation();
  const newGroupCallMutation = useMutation(createGroupCall);
  const updateGroupCallMutation = useMutation(updateGroupCall);

  const goToCall = (callId: Call['id']) => {
    history.push(buildGroupCallPageRoute(callId.toString()));
  };

  const makeGroupCall = (data: CreateGroupCallDto) => {
    newGroupCallMutation.mutate(
      {
        ...data,
        ...locationStream,
      },
      {
        onSuccess: createdGroupCall => {
          const createdGroupCallId = _.head(createdGroupCall)?.id;
          if (createdGroupCallId) goToCall(createdGroupCallId);
        },
      },
    );
  };

  const updateGroupCallMembers = (data: AddMembersInGroupCallDto) => {
    updateGroupCallMutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  return { makeGroupCall, updateGroupCallMembers };
};

export default useGroupCall;
