import {
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { REACT_APP_VERSION } from 'src/constants';
import {
  appLanguageEnglish,
  appLanguageJapanese,
} from 'src/constants/app-language.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider/hands-free-operation-setting.provider';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { TRANSCRIBE_LANGUAGE } from 'src/pages/messages/constants/language.constant';
import {
  ASR_CHAT_URL,
  HOME_PAGE_ROUTE,
  MY_PROFILE_ROUTE,
} from 'src/routes/routeList';

import CurrentUserAvatar from './CurrentUserAvatar';
import { LogoutButton } from './LogoutButton';

export const TopbarDropdownMenu = () => {
  const { translate } = useTranslate();

  const { currentUser } = useUserDetails();
  const { isHfoEnable, toggleHfoIcon, changeLanguage, transcribeLanguage } =
    useHandsFreeOperationSetting();

  const [showLanguageMenu, setShowLanguageMenu] = useState(false);

  const handleLanguageClick = () => {
    setShowLanguageMenu(val => !val);
  };

  const setShowLanguageMenuFalse = () => {
    setTimeout(function () {
      setShowLanguageMenu(false);
    }, 100);
  };

  const handleLanguageSelect = (selectedLanguage: string | string[]) => {
    if (typeof selectedLanguage === 'string') {
      changeLanguage(selectedLanguage as TRANSCRIBE_LANGUAGE);
    }
    setShowLanguageMenu(false);
  };

  const isHomePage = window.location.pathname === HOME_PAGE_ROUTE;
  const isASRChatPage = window.location.pathname.includes(ASR_CHAT_URL);

  const isProfilePage = window.location.pathname === MY_PROFILE_ROUTE;

  const showProfileOption = currentUser && !isHomePage && !isProfilePage;

  return (
    <Menu
      autoSelect={false}
      placement='left-start'
      onClose={setShowLanguageMenuFalse}
    >
      <MenuButton>
        <Icon as={IoEllipsisVertical} boxSize='6' />
      </MenuButton>
      <MenuList>
        {showProfileOption && (
          <MenuItem as={Link} to={MY_PROFILE_ROUTE}>
            <HStack justifyContent='space-between' width='full'>
              <Text>{translate(TranslationKeys.myProfile)}</Text>
              <CurrentUserAvatar size='sm' />
            </HStack>
          </MenuItem>
        )}
        {currentUser && (
          <MenuItem closeOnSelect={false}>
            <HStack justifyContent='space-between' width='full'>
              <Text>{translate(TranslationKeys.enableHfo)}</Text>
              <Switch
                ml={3}
                colorScheme='teal'
                isChecked={isHfoEnable}
                onChange={toggleHfoIcon}
                justifyContent={'end'}
                display={'flex'}
                isDisabled={isASRChatPage}
              />
            </HStack>
          </MenuItem>
        )}

        <MenuItem onClick={handleLanguageClick} closeOnSelect={false}>
          <HStack justifyContent='space-between' width='full' pr='4px'>
            <Text>{translate(TranslationKeys.language)}</Text>
            <Icon
              as={showLanguageMenu ? MdKeyboardArrowUp : MdKeyboardArrowDown}
              boxSize='6'
            />
          </HStack>
        </MenuItem>
        {showLanguageMenu && (
          <MenuOptionGroup
            defaultValue={transcribeLanguage}
            type='radio'
            onChange={handleLanguageSelect}
          >
            <MenuItemOption value={appLanguageEnglish} closeOnSelect={false}>
              English
            </MenuItemOption>
            <MenuItemOption value={appLanguageJapanese} closeOnSelect={false}>
              Japanese
            </MenuItemOption>
          </MenuOptionGroup>
        )}
        <MenuItem>
          <LogoutButton />
        </MenuItem>
        <MenuItem py='0' mt='2'>
          <HStack justifyContent='end' width='full'>
            <Text>{REACT_APP_VERSION}</Text>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
