import { Icon, IconButton } from '@chakra-ui/react';
import _ from 'lodash';
import { IoCall } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { getOneMessageThread } from 'src/apis/message-threads.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import useGroupCall from 'src/hooks/useGroupCall';

interface GroupVoiceCallButtonProps {
  groupMessageThreadId: number;
  groupId: number;
}

const GroupVoiceCallButton: React.FC<GroupVoiceCallButtonProps> = ({
  groupMessageThreadId,
  groupId,
}) => {
  const { makeGroupCall } = useGroupCall();

  const { currentUser } = useUserDetails();

  const getMessageThreadQueryKey = [
    'getOneMessageThreadForGroupVoiceCallButton',
    groupMessageThreadId,
  ];
  const { data: messageThread } = useQuery(getMessageThreadQueryKey, () =>
    getOneMessageThread(groupMessageThreadId),
  );

  // filter current user from message thread member
  const messageThreadMembersWithoutCurrentUser = _.filter(
    messageThread?.members,
    member => {
      return member.user?.id !== currentUser?.id;
    },
  );

  const otherGroupMemberUserIds = _.map(
    messageThreadMembersWithoutCurrentUser,
    ({ UserId }) => UserId,
  );

  const handleCallForExistingMembers = (
    userIds: number[],
    MessageGroupId: number,
  ) => {
    makeGroupCall({
      receiverUserIds: userIds,
      MessageGroupId,
    });
  };

  return (
    <IconButton
      aria-label='voice call'
      variant='ghost'
      size='lg'
      isRound
      icon={<Icon as={IoCall} boxSize='6' color='blue.500' />}
      onClick={() => {
        handleCallForExistingMembers(otherGroupMemberUserIds, groupId);
      }}
    />
  );
};
export default GroupVoiceCallButton;
