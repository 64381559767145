import { TFunction } from 'i18next';
import _ from 'lodash';

import { TranslationKeys } from 'src/constants/translation-keys';
import { User } from 'src/models';
import { MessageThread } from 'src/models/MessageThread.model';
import { getUserDisplayName } from 'src/utils';

const kMaxUsersTypingDisplay = 2;

export const getTypingText = (
  messageThread: MessageThread | undefined,
  usersTyping: Pick<User, 'id' | 'firstName' | 'lastName'>[],
  translate: TFunction<['translation', ...string[]], undefined>,
) => {
  if (!messageThread) {
    return '';
  }
  if (messageThread.isGroup && !_.isEmpty(usersTyping)) {
    const countUsersTyping = usersTyping.length;
    if (countUsersTyping === 1) {
      return `${getUserDisplayName(usersTyping[0])} ${translate(
        TranslationKeys.isTyping,
      )}`;
    }

    if (countUsersTyping === 2) {
      const user1Name = getUserDisplayName(usersTyping[0]);
      const user2Name = getUserDisplayName(usersTyping[1]);

      return `${user1Name} and ${user2Name} ${translate(
        TranslationKeys.areTyping,
      )}`;
    }

    const usersTypingClipped = _.take(usersTyping, kMaxUsersTypingDisplay);
    const commaSeparatedUsers = _.join(
      _.map(usersTypingClipped, getUserDisplayName),
      ', ',
    );

    const remainingUsers = countUsersTyping - usersTypingClipped.length;
    const suffix =
      remainingUsers > 0
        ? `and ${remainingUsers} ${translate(TranslationKeys.more)} `
        : '';
    return `${commaSeparatedUsers} ${suffix} ${translate(
      TranslationKeys.areTyping,
    )}`;
  }

  return translate(TranslationKeys.typing);
};
