import { Flex, Text, VStack } from '@chakra-ui/react';
import { FaUserCircle } from 'react-icons/fa';

import UserAvatar from 'src/components/UserAvatar';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { getUserFullName } from 'src/utils';

const BasicDetails: React.FC = () => {
  const { currentUser: user } = useUserDetails();

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      alignItems='center'
      justify='space-evenly'
      w='full'
      my='10'
    >
      {user?.avatarUrl ? (
        <UserAvatar user={user} boxSize='10rem' position='relative' />
      ) : (
        <FaUserCircle fontSize='10rem' style={{ position: 'relative' }} />
      )}
      <VStack lineHeight='none'>
        <Text fontSize='3xl' fontWeight='bold'>
          {user && getUserFullName(user)}
        </Text>
        <Text fontSize='lg' color='gray.600'>
          {user?.email}
        </Text>
      </VStack>
    </Flex>
  );
};

export default BasicDetails;
