import { Box, BoxProps } from '@chakra-ui/react';
import _ from 'lodash';

import { Post } from '../../../models/Post.model';
import { valueContainingOne } from './constants/valueContaingOne.constant';
import PostMediaPreview from './PostMediaPreview';

interface PostCardMediaProps extends BoxProps {
  post: Post;
}

const PostCardMedia: React.FC<PostCardMediaProps> = ({ post, ...props }) => {
  if (_.isEmpty(post.postMedia)) {
    return null;
  }

  // bottom padding to fix the dots of the slider
  return (
    <Box pb='4' {...props}>
      {(post.postMedia.length > valueContainingOne && (
        <Box maxH='52' minH='24' overflowY='scroll' scrollBehavior='smooth'>
          {post.postMedia &&
            post.postMedia.map(media => (
              <PostMediaPreview media={media} key={media.id} />
            ))}
        </Box>
      )) || (
        <Box maxH='52' minH='24'>
          {post.postMedia &&
            post.postMedia.map(media => (
              <PostMediaPreview media={media} key={media.id} />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default PostCardMedia;
