import * as yup from 'yup';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

export const useForgetPasswordSchema = () => {
  const { translate } = useTranslate();
  const forgetPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .email(translate(TranslationKeys.pleaseEnterValidEmail))
      .required(translate(TranslationKeys.pleaseEnterEmail)),
  });

  return forgetPasswordSchema;
};

export type ForgetPasswordDto = yup.InferType<
  ReturnType<typeof useForgetPasswordSchema>
>;
