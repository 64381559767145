import { Box, Icon, Text } from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Select, { components, ValueContainerProps } from 'react-select';

import { getUsersPagination } from 'src/apis/users.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { User } from 'src/models';
import { USER_PROFILE_ROUTE } from 'src/routes/routeList';

const GET_ALL_USERS_QUERY_KEY = 'users';
const DEBOUNCE_TIME = 500;

interface CustomValueContainerProps extends ValueContainerProps<User, false> {
  children: React.ReactNode;
}

export const UserSearchBar = () => {
  const { translate } = useTranslate();
  const history = useHistory();

  const [searchInput, setSearchInput] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data: users } = useQuery<User[]>(
    [GET_ALL_USERS_QUERY_KEY, searchQuery],
    () => getUsersPagination(searchQuery),
  );

  const debouncedSearchTerm = _.debounce(() => {
    setSearchQuery(searchInput);
  }, DEBOUNCE_TIME);

  const handleSearch = (value: string) => {
    setSearchInput(value);
  };

  const handleSelect = (user: User | null) => {
    if (user) {
      history.push(USER_PROFILE_ROUTE.replace(':userId', `${user.id}`));
    }
  };

  useEffect(() => {
    debouncedSearchTerm();
    return debouncedSearchTerm.cancel;
  }, [searchInput]);

  return (
    <Box w='full'>
      <Select
        placeholder={`${translate(TranslationKeys.searchUsers)}...`}
        components={{
          ValueContainer: CustomValueContainer,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage: () => {
            if (_.isEmpty(searchQuery)) {
              return (
                <Text p='2' color='gray.500'>
                  {translate(TranslationKeys.startSearchingUsers)}
                </Text>
              );
            }

            return (
              <Text p='2' color='gray.500'>
                {translate(TranslationKeys.noUsersFound)}
              </Text>
            );
          },
        }}
        options={users}
        onInputChange={handleSearch}
        onChange={handleSelect}
        getOptionLabel={u => `${u.firstName} ${u.lastName}`}
        getOptionValue={u => u.email}
        styles={{
          control: baseStyles =>
            ({
              ...baseStyles,
              borderColor: 'none',
              backgroundColor: '#E9EDF6',
              borderRadius: '8px',
            } as CSSObject),
        }}
      />
    </Box>
  );
};

const CustomValueContainer = ({
  children,
  ...props
}: CustomValueContainerProps) => (
  <components.ValueContainer {...props}>
    <Icon as={IoIosSearch} boxSize={30} pl='2' />
    <Box>{children}</Box>
  </components.ValueContainer>
);
