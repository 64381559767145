import { Box } from '@chakra-ui/react';

interface GroupCallDotProps {
  isSelected: boolean;
}

export const GroupCallDot: React.FC<GroupCallDotProps> = ({ isSelected }) => {
  return (
    <Box
      p='1'
      border='1px solid white'
      rounded='full'
      display='flex'
      justifyContent='center'
      backgroundColor={isSelected ? 'white' : 'transparent'}
    />
  );
};
