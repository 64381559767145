import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

// place here because not used anywhere else
const SECONDS_IN_MINUTE = 60;
const START_PADDING_FILL_STRING = '0';
const PADDING_LENGTH = 2;
const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_INCREMENT = 1;

interface RecordingTimeProps {
  isRecording: boolean;
}
export const RecordingTime: React.FC<RecordingTimeProps> = ({
  isRecording,
}) => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  // use start padding because we need to show the time 1 as 01, 2 as 02,03.....
  const paddedMinutes = Math.floor(seconds / SECONDS_IN_MINUTE)
    .toString()
    .padStart(PADDING_LENGTH, START_PADDING_FILL_STRING);

  const paddedSeconds = (seconds % SECONDS_IN_MINUTE)
    .toString()
    .padStart(PADDING_LENGTH, START_PADDING_FILL_STRING);

  useEffect(() => {
    setIsActive(isRecording);
  }, [isRecording]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + SECONDS_INCREMENT);
      }, MILLISECONDS_IN_SECOND);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <Box h='36px'>
      {isRecording && (
        <Text
          w='full'
          px='2'
          py='1'
          fontFamily='Inter'
          textAlign='center'
          color='#555555'
          fontSize='2xl'
          rounded='md'
        >
          {paddedMinutes}:{paddedSeconds}
        </Text>
      )}
    </Box>
  );
};
