import { CustomTabs } from 'src/components';
import { EDIT_PAGE_CONTENT_TAB_INDEX_LOCAL_STORAGE_KEY } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import usePage from 'src/pages/blogs/blog/hooks/usePage';
import { AppNavigationType } from 'src/types/navigation.type';

import { ApprovePageContent } from './ApprovePageContent';
import { EditPageContentTab } from './EditPageContentTab';

interface EditPageContentTabProps {}

const EditPageContent: React.FC<EditPageContentTabProps> = () => {
  const { page } = usePage();
  const { translate } = useTranslate();

  return (
    <HeaderWrapper
      pageTitle={page?.title || translate(TranslationKeys.pageMembers)}
      navigationType={AppNavigationType.back}
    >
      <CustomTabs
        firstTabPanel={<EditPageContentTab />}
        firstTabTitle={translate(TranslationKeys.pageContent)}
        secondTabPanel={<ApprovePageContent />}
        secondTabTitle={translate(TranslationKeys.pageRequest)}
        tabIndexKey={EDIT_PAGE_CONTENT_TAB_INDEX_LOCAL_STORAGE_KEY}
      />
    </HeaderWrapper>
  );
};

export default EditPageContent;
