import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { RiImageFill } from 'react-icons/ri';
import { useMutation } from 'react-query';

import { updateCommentsToPost } from 'src/apis/comment.api';
import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { Comment } from 'src/models/Comment.model';
import { Post } from 'src/models/Post.model';
import { EntityType } from 'src/models/View.model';

import FilePreviews from './AddPostForm/FilePreviews';
import { maxCommentLength } from './constants/max-post-length.constant';
import FileInput from './FileInput';
import useComments from './hooks/useComments';
import useFiles from './hooks/useFiles';

interface EditCommentModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post;
  comment: Comment;
}

export const EditCommentModal: FC<EditCommentModalProps> = ({
  isOpen,
  onClose,
  post,
  comment,
}) => {
  const { translate } = useTranslate();
  const {
    handleSubmit,
    register,
    reset: resetForm,
  } = useForm({
    mode: 'onChange',
  });
  const { currentUser } = useUserDetails();

  const {
    files: images,
    fileURIs: imageURIs,
    addFile: addImage,
    removeFile: removeImage,
  } = useFiles();

  const { refetch } = useComments(post.id, EntityType.post);

  const updateCommentsToPostMutation = useMutation(
    (data: {
      commentId: number;
      EntityId: number;
      description: string;
      entityType: EntityType;
    }) =>
      updateCommentsToPost(data.commentId, {
        EntityId: data.EntityId,
        description: data.description,
        entityType: data.entityType,
        files: getAllFiles(),
      }),
  );

  interface commentType {
    commentContent: string;
  }
  const toast = useToast();

  const formSubmitHandle = (data: commentType) => {
    updateCommentsToPostMutation.mutate(
      {
        commentId: comment.id,
        EntityId: comment.EntityId,
        description: data.commentContent,
        entityType: comment.entityType,
      },
      {
        onSuccess: () => {
          resetForm();
          toast({
            title: translate(TranslationKeys.commentEditedSuccessfully),
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          refetch();
          onClose();
        },
      },
    );
    return;
  };

  const getAllFiles = (): File[] => _.concat(images);

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Flex>
            <Text>{translate(TranslationKeys.editComment)} </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(formSubmitHandle)}>
            <Tag size='lg' borderRadius='full'>
              {currentUser && (
                <>
                  <UserAvatar size='xs' mr='2' user={currentUser} />
                  <TagLabel>
                    {`${currentUser?.firstName} ${currentUser?.lastName}`}
                  </TagLabel>
                </>
              )}
            </Tag>
            <FormControl my='2'>
              <Textarea
                maxLength={maxCommentLength}
                defaultValue={comment.description}
                placeholder={translate(TranslationKeys.addYourComment)}
                size='lg'
                rows={3}
                {...register('commentContent')}
              />
            </FormControl>
            <Flex justifyContent='space-between'>
              <Flex>
                <FormControl id='add-image'>
                  <FormLabel htmlFor='add-image-input' m='0'>
                    <FileInput
                      accept='image/*'
                      id='add-image-input'
                      onFiles={addImage}
                    />
                    <Button
                      aria-label='add image button'
                      colorScheme='blue'
                      variant='ghost'
                      as='span'
                      cursor='pointer'
                      leftIcon={<Icon as={RiImageFill} boxSize='5' />}
                    />
                  </FormLabel>
                </FormControl>
                <Box>
                  <FilePreviews
                    fileURIs={imageURIs}
                    files={images}
                    label={`${translate(TranslationKeys.image)}:`}
                    closeBtnAriaLabel='remove image'
                    onFileRemove={removeImage}
                  />
                </Box>
              </Flex>
            </Flex>
            <Button
              loadingText={`${translate(TranslationKeys.updating)}...`}
              colorScheme='blue'
              type='submit'
              mx='1'
            >
              {translate(TranslationKeys.updateComment)}
            </Button>
            <Button
              ml='1'
              variant='outline'
              colorScheme='red'
              onClick={onClose}
            >
              {translate(TranslationKeys.cancel)}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
