import { FormControl, FormLabel, IconButton } from '@chakra-ui/react';

interface FileUploadButtonProps {
  id: string;
  htmlFor: string;
  fileInput?: JSX.Element;
  colorSchema: string;
  icon: React.ElementType;
  onClick?: () => void;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  id,
  htmlFor,
  fileInput,
  colorSchema,
  icon,
  onClick,
}) => {
  return (
    <FormControl id={id} w='max'>
      <FormLabel htmlFor={htmlFor}>
        {fileInput}
        <IconButton
          aria-label='file upload button'
          colorScheme={colorSchema}
          variant='ghost'
          role='button'
          as={icon}
          size='xs'
          onClick={onClick}
        />
      </FormLabel>
    </FormControl>
  );
};
