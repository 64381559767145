import { useParams } from 'react-router-dom';

export interface IntParams {
  messageThreadId: string;
}

/**
 * Custom hook that extracts string parameters from the URL using react-router-dom's useParams hook.
 * And converts them to integers.
 *
 * @returns An object containing the extracted integer parameters.
 */
export const useIntParam = <
  IntParams extends { [K in keyof IntParams]?: string } = {},
>() => {
  const params = useParams<IntParams>();
  const intParams: Record<string, number> = {};

  for (const key in params) {
    if (params[key]) {
      intParams[key] = Number(params[key]);
    }
  }

  return intParams as Record<keyof IntParams, number>;
};
