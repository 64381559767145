import { useQuery } from 'react-query';
import { checkIfInternetAvailableOrNot } from 'src/apis/auth.api';

export const useInternetConnection = () => {
  const { data: isInternetAvailable } = useQuery<boolean>(
    'checkIfInternetAvailableOrNot',
    checkIfInternetAvailableOrNot,
  );

  return { isInternetAvailable };
};
