import { HStack, Text } from '@chakra-ui/react';
import moment from 'moment';

import UserAvatar from 'src/components/UserAvatar';
import { User } from 'src/models';
import { getUserFullName } from 'src/utils';

interface DraftedByUserProps {
  draftedBy: User;
  createdAt: Date;
}

export const DraftedByUser: React.FC<DraftedByUserProps> = ({
  draftedBy,
  createdAt,
}) => {
  if (!draftedBy) return null;

  return (
    <HStack
      justify='space-between'
      w='full'
      borderBottom='1px solid'
      borderColor='gray.300'
      pb='2'
    >
      <HStack>
        <UserAvatar user={draftedBy} size='xs' />
        <Text lineHeight='none' fontWeight='semibold'>
          {getUserFullName(draftedBy)}
        </Text>
      </HStack>
      <Text fontWeight='semibold'>{moment(createdAt).format('lll')}</Text>
    </HStack>
  );
};
