import { LatLngBoundsLiteral } from 'leaflet';
import { LatLng } from 'leaflet';

export const DEFAULT_ZOOM_FOR_ALERTS_MAP = 8;
export const DEFAULT_ZOOM_FOR_LOCAL_STREAM_NOT_AVAILABLE = 1;
export const DEFAULT_ZOOM_FOR_POSTS_FEED_MAP = 12;
export const ZOOM_FOR_FLYING_TO_USER_LOCATION = 15;

/**
 * These values are based on the map in development mode. In production mode, users current location would be used
 * as the center for alert map.
 */
export const DEFAULT_CENTER_FOR_ALERTS_MAP = new LatLng(
  44.20924258848664,
  142.58764120105172,
);

export const getMapCenter = ({
  lat,
  long,
}: {
  lat?: string | number;
  long?: string | number;
}) => {
  if (lat && long) {
    return new LatLng(+lat, +long);
  }
  return DEFAULT_CENTER_FOR_ALERTS_MAP;
};

export const DEFAULT_CENTER_FOR_POSTS_FEED_MAP = new LatLng(
  43.88974739736627,
  142.33836608036293,
);

export const DEFAULT_CENTER_LOCATION = {
  latitude: 43.88974739736627,
  longitude: 142.33836608036293,
};

export const DEFAULT_USER_LOCATION = [43.88974739736627, 142.33836608036293];

export const MAX_BOUNDS: LatLngBoundsLiteral = [
  [-90, -180],
  [90, 180],
];

export const MAX_ZOOM = 15;
export const MIN_ZOOM = 3;

export const DISTANCE_MATCH_STRING = '{distance}';
