import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AiFillPlaySquare } from 'react-icons/ai';
import { BsFillImageFill } from 'react-icons/bs';
import { IoAttachOutline } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { deletePostMedia, updatePost } from '../../../../apis/posts.api';
import UserAvatar from '../../../../components/UserAvatar';
import { UpdatePostDto } from '../../../../dto/feed-posts.dto';
import useFileWithSizeLimit from '../../../../hooks/useFileWIthSizeLimit';
import { Post } from '../../../../models/Post.model';
import { PostMedia } from '../../../../models/PostMedia.model';
import { maxPostLength } from '../constants/max-post-length.constant';
import { useFeedPostsQueryClient } from '../hooks/useFeedPosts';
import EditPostMediaPreview from './MediaPreview';

interface AddPostModalProps {
  isOpen: boolean;
  onClosePost: (post: null) => void;
  post: Post;
}

const EditPostModal: FC<AddPostModalProps> = ({
  isOpen,
  onClosePost,
  post,
}) => {
  const { translate } = useTranslate();
  const { handleSubmit, register, control } = useForm<UpdatePostDto>({
    defaultValues: { textContent: post.textContent, id: post.id },
    // Validates on every change. Neccessary to check for post content
    // If textContent is empty the post can not be updated (unless media exists - in that case, empty text is allowed)
    mode: 'onChange',
  });
  const { currentUser } = useUserDetails();

  const { id: feedId }: { id: string } = useParams();
  const [coverSrc, setCoverSrc] = useState<
    { url: string; id: number; type: string; name: string }[]
  >([]);
  const [media, setMedia] = useState<{ file: File; id: number }[]>([]);
  const [removedImages, setRemovedImages] = useState<PostMedia[]>([]);

  const showToast = useToast();
  const updatePostMutation = useMutation((updatedPost: UpdatePostDto) => {
    return updatePost(post.FeedId, post.id, updatedPost);
  });

  const { updateFeedPost } = useFeedPostsQueryClient({ feedId: post.FeedId });

  const handleDialogClose = () => {
    setCoverSrc([]);
    setMedia([]);
    setRemovedImages([]);
    onClosePost(null);
  };
  const handlePostEditSuccess = (postEdit: Post) => {
    updateFeedPost(postEdit);
    showToast({
      title: translate(TranslationKeys.postUpdated),
      description: translate(TranslationKeys.postUpdateSuccess),
      status: 'success',
    });
    handleDialogClose();
  };

  const deletePostMediaMutation = useMutation(
    ({
      id,
      feedId,
      images,
    }: {
      id: number;
      feedId: number;
      images: PostMedia[];
    }) => deletePostMedia(feedId, id, images),
  );

  const textContent = useWatch({
    name: 'textContent',
    control,
  });
  const hasFiles = !_.isEmpty(coverSrc);
  const hasPostContent = hasFiles || textContent?.trim()?.length > 0;

  const formSubmitHandle = async (data: UpdatePostDto) => {
    if (!hasPostContent) {
      showToast({
        title: translate(TranslationKeys.emptyContent),
        description: translate(TranslationKeys.postMustHaveContent),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    updatePostMutation.mutate(
      {
        ...data,
        id: post.id,
        media: _.map(media, i => i.file),
      },
      {
        onSuccess: async updatedPost => {
          if (_.isEmpty(removedImages)) {
            handlePostEditSuccess(updatedPost);
          } else {
            deletePostMediaMutation.mutate(
              {
                id: post.id,
                feedId: +feedId,
                images: removedImages,
              },
              {
                onSuccess: updatedPostAfterDeleteMedia => {
                  handlePostEditSuccess(updatedPostAfterDeleteMedia);
                },
              },
            );
          }
        },
        onError: () => {
          onClosePost(null);
        },
      },
    );
  };

  const { validateFileSize } = useFileWithSizeLimit();
  const addFile = async (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const isValidFile = validateFileSize(file);
      if (!isValidFile) {
        return;
      }

      const id = Math.random();
      const reader = new FileReader();
      setMedia(f => [...f, { file, id }]);
      reader.onload = () => {
        if (reader && !_.isNull(reader.result)) {
          setCoverSrc(c => [
            ...c,
            {
              url: (reader.result as string).toString(),
              id,
              type: file.type,
              name: file.name,
            },
          ]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    addFile(e.target.files);
    /**
     * Reset the input field.
     * This is required for a specific case:
     * If the user removes a file added through the input field (and not the one(s) present in the media list)
     * and selects the same file back again, the input field will not register the change event - since the file is the same.
     * To fix this, we reset the input field on every change so that even if user selects the same file again after removing
     * the file, the input field will register the change event.
     */
    e.target.value = '';
  };

  const removeFile = (id: number) => {
    setCoverSrc(c => _.filter(c, i => i.id !== id));
    setMedia(i => _.filter(i, image => image.id !== id));
    const removedImageDetails = _.find(post.postMedia, m => m.id === id);

    if (removedImageDetails) {
      setRemovedImages(r => [...r, removedImageDetails]);
    }
  };

  useEffect(() => {
    if (post.postMedia) {
      setCoverSrc(
        _.map(post.postMedia, (media, index) => ({
          url: media.mediaUrl,
          id: media.id,
          type: media.type,
          name:
            media.mediaUrl?.split('/').pop() ||
            `file-${(Math.random() * 10).toString()}`,
        })),
      );
    }
  }, [post]);

  const isProcessingUpdate =
    updatePostMutation.isLoading || deletePostMediaMutation.isLoading;

  return (
    <Modal isOpen={isOpen} onClose={handleDialogClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{translate(TranslationKeys.editPost)}</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(formSubmitHandle)}>
            <Tag size='lg' borderRadius='full'>
              {currentUser && (
                <>
                  <UserAvatar size='xs' mr='2' user={currentUser} />
                  <TagLabel>
                    {`${currentUser?.firstName} ${currentUser?.lastName}`}
                  </TagLabel>
                </>
              )}
            </Tag>
            <FormControl my='2'>
              <Textarea
                maxLength={maxPostLength}
                placeholder={translate(TranslationKeys.whatToPost)}
                size='lg'
                rows={3}
                {...register('textContent')}
              />
            </FormControl>
            <Flex flexWrap='wrap'>
              {_.map(coverSrc, ({ url, id, type, name }) => (
                <Box
                  key={id}
                  mx='2'
                  boxSize='24'
                  my='2'
                  position='relative'
                  border='1px solid'
                  borderColor='gray.300'
                >
                  <IconButton
                    aria-label='remove-image'
                    position='absolute'
                    opacity='1'
                    colorScheme='red'
                    rounded='full'
                    right='-3'
                    top='-3'
                    onClick={() => removeFile(id)}
                    className='icon'
                    size='xs'
                    fontSize='100%'
                    cursor='pointer'
                    _hover={{ opacity: '1' }}
                  >
                    <MdClose />
                  </IconButton>
                  <EditPostMediaPreview
                    media={{ id, type, mediaUrl: url, name }}
                  />
                </Box>
              ))}
            </Flex>

            <Flex justifyContent='space-between'>
              <Flex>
                <FormControl id='add-image'>
                  <FormLabel htmlFor='upload-button'>
                    <Input
                      type='file'
                      id='upload-button'
                      accept='image/*'
                      display='none'
                      onChange={handleFileInputChange}
                    />
                    <IconButton
                      aria-label='action button'
                      colorScheme='blackAlpha'
                      variant='ghost'
                      as='span'
                      cursor='pointer'
                    >
                      <BsFillImageFill />
                    </IconButton>
                  </FormLabel>
                </FormControl>

                <FormControl id='add-video'>
                  <FormLabel htmlFor='upload-button2'>
                    <Input
                      type='file'
                      id='upload-button2'
                      accept='video/*, .mkv'
                      display='none'
                      onChange={handleFileInputChange}
                    />
                    <IconButton
                      as='span'
                      aria-label='action button'
                      colorScheme='blackAlpha'
                      variant='ghost'
                    >
                      <AiFillPlaySquare />
                    </IconButton>
                  </FormLabel>
                </FormControl>
                <FormControl id='add-doc'>
                  <FormLabel htmlFor='upload-button3'>
                    <Input
                      type='file'
                      id='upload-button3'
                      display='none'
                      onChange={handleFileInputChange}
                    />
                    <IconButton
                      as='span'
                      aria-label='action button'
                      colorScheme='blackAlpha'
                      variant='ghost'
                    >
                      <Icon as={IoAttachOutline} boxSize='6' />
                    </IconButton>
                  </FormLabel>
                </FormControl>
              </Flex>

              <Flex>
                <Button
                  disabled={!hasPostContent || isProcessingUpdate}
                  isLoading={isProcessingUpdate}
                  loadingText={`${translate(TranslationKeys.updating)}...`}
                  colorScheme='blue'
                  type='submit'
                  mx='1'
                >
                  {translate(TranslationKeys.post)}
                </Button>
                <Button
                  onClick={handleDialogClose}
                  ml='1'
                  variant='outline'
                  colorScheme='red'
                >
                  {translate(TranslationKeys.cancel)}
                </Button>
              </Flex>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditPostModal;
