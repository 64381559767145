import { Button } from '@chakra-ui/react';
import * as L from 'leaflet';
import { FC, useContext } from 'react';
import { IoMdLocate } from 'react-icons/io';
import { useMap } from 'react-leaflet';

import { ZOOM_FOR_FLYING_TO_USER_LOCATION } from 'src/constants/maps.constant';
import { UserLocationContext } from 'src/hoc/UserLocationContext/user-location.context';

interface FlyToUserLocationButtonProps {
  isFullScreen?: boolean;
}

export const FlyToUserLocationButton: FC<FlyToUserLocationButtonProps> = ({
  isFullScreen = false,
}) => {
  const { locationStream } = useContext(UserLocationContext);
  const map = useMap();
  const flyToUserLocation = () => {
    if (locationStream) {
      const latLng = new L.LatLng(
        locationStream?.latitude,
        locationStream?.longitude,
      );
      map.flyTo(latLng, ZOOM_FOR_FLYING_TO_USER_LOCATION);
    }
  };

  return (
    <>
      <Button
        as={IoMdLocate}
        aria-label='search'
        variant='solid'
        bg='white'
        p='2px'
        position='absolute'
        right={isFullScreen ? '6' : '4'}
        bottom={isFullScreen ? '6' : '4'}
        zIndex='1000' //zIndex is 1000 because the tile layer of Map container in leaflet has a fixed zIndex val of 800
        border='2px solid rgba(0,0,0,0.2)'
        onClick={flyToUserLocation}
      />
    </>
  );
};
