import { Icon, IconProps } from '@chakra-ui/react';
import { IoCheckmark, IoCheckmarkDone } from 'react-icons/io5';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { Message } from '../../../models/Message.model';

const MessageStatusIcon: React.FC<Pick<Message, 'status'>> = ({ status }) => {
  const getIcon = () => {
    switch (status) {
      case 'seen':
      case 'delivered':
        return IoCheckmarkDone;
      case 'sent':
      default:
        return IoCheckmark;
    }
  };

  const props: IconProps = {
    color: status === 'seen' ? 'blue.500' : 'gray.500',
    boxSize: '4',
  };

  return <Icon as={getIcon()} {...props} />;
};

interface MessageStatusProps {
  message: Message;
}

const MessageStatus: React.FC<MessageStatusProps> = ({ message }) => {
  const { currentUser } = useUserDetails();
  const isCurrentUserMessageAuthor = message.SenderId === currentUser?.id;

  if (!isCurrentUserMessageAuthor) {
    return null;
  }

  return <MessageStatusIcon status={message.status} />;
};

export default MessageStatus;
