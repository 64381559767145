import _ from 'lodash';

import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

const usePersonalCallSession = () => {
  const { callSessions } = useCallSessionsContext();

  return _.head(callSessions);
};

export default usePersonalCallSession;
