import { Dispatch, MutableRefObject, SetStateAction } from 'react';

import { GroupCalls } from 'src/models/GroupCall';
import { PersonalCalls } from 'src/models/PersonalCall';

export enum CallType {
  Personal = 'personal',
  Group = 'group',
}

export enum CallStatusType {
  connected = 'Connected',
  missed = 'Missed',
  rejected = 'Rejected',
  unreachable = 'Unreachable',
  notConnected = 'Not Connected',
}

export enum VideoQualityLevels {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum ReportType {
  outboundRtp = 'outbound-rtp',
  inboundRtp = 'inbound-rtp',
}

export interface CheckElementPositionProps {
  pageRef: MutableRefObject<HTMLDivElement | null>;
  observerRef: MutableRefObject<
    HTMLDivElement | HTMLTableSectionElement | null
  >;
  hasMore: boolean;
  isLoading: boolean;
  isFetched: boolean;
  isFetchingData: MutableRefObject<boolean>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export interface CallPaginationOptions {
  calls: PersonalCalls[] | GroupCalls[];
  observerTargetIndex: MutableRefObject<number>;
  isLoading: boolean;
  isFetched: boolean;
  isFetchingData: MutableRefObject<boolean>;
  pageRef: MutableRefObject<HTMLDivElement | null>;
  observerRef: MutableRefObject<
    HTMLDivElement | HTMLTableSectionElement | null
  >;
  hasMore: boolean;
  setPageNumber: Dispatch<SetStateAction<number>>;
}
