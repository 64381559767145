import ringingSound from 'src/assets/asr-chat/ringtone.mp3';
import startSpeakingSound from 'src/assets/asr-chat/start-speaking.mp3';
import { INCOMING_CALL_NOTIFICATION_DURATION } from 'src/constants/calls.constant';

export const useStartSpeakingSound = () => {
  const audio = new Audio(startSpeakingSound);

  const playStartSpeakingSound = () => {
    audio.play();
  };

  return { playStartSpeakingSound };
};

export const useAsrChatRingingSound = () => {
  const audio = new Audio(ringingSound);

  const ringtoneCleanup = () => {
    audio.loop = false;
    audio.pause();
    audio.currentTime = 0;
  };

  const playRingingSound = () => {
    audio.loop = true;
    setTimeout(() => {
      ringtoneCleanup();
    }, INCOMING_CALL_NOTIFICATION_DURATION);
    audio.play();
  };

  return { playRingingSound, ringtoneCleanup };
};
