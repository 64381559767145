import { Text } from '@chakra-ui/react';
import React from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

const HomePageHeading: React.FC = () => {
  const { translate } = useTranslate();
  return (
    <Text
      textAlign='center'
      pt={{ base: '4', sm: '6' }}
      fontWeight='bold'
      fontSize={{ base: 'xl', sm: '3xl' }}
      noOfLines={1}
    >
      {translate(TranslationKeys.applicationHeading)}
    </Text>
  );
};

export default HomePageHeading;
