import {
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import useDialog from '../../../hooks/useDialog';
import { FeedMember } from '../../../models/FeedMember.model';
import ConfirmRemoveFeedMemberDialog from './ConfirmRemoveFeedMemberDialog';
import ManageFeedMemberPermissionsDialog from './ManageFeedMemberPermissionsDialog';

interface FeedMemberActionsMenuProps {
  feedMember: FeedMember;
  onMemberRemoved: () => any;
}

const FeedMemberActionsMenu: React.FC<FeedMemberActionsMenuProps> = ({
  feedMember,
  onMemberRemoved,
}) => {
  const { translate } = useTranslate();
  const [
    isConfirmRemoveMemberDialogOpen,
    openConfirmRemoveMemberDialog,
    closeConfirmRemoveMemberDialog,
  ] = useDialog();

  const [
    isManageFeedMemberPermissionDialogOpen,
    openManageFeedMemberPermissionDialog,
    closeManageFeedMemberPermissionsDialog,
  ] = useDialog();

  return (
    <>
      <Menu autoSelect={false}>
        <MenuButton>
          <Icon as={IoEllipsisVertical} boxSize='6' />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={openManageFeedMemberPermissionDialog}>
            {translate(TranslationKeys.managePermission)}
          </MenuItem>
          <MenuDivider />
          <MenuItem color='red' onClick={openConfirmRemoveMemberDialog}>
            {translate(TranslationKeys.removeFromFeed)}
          </MenuItem>
        </MenuList>
      </Menu>
      <ConfirmRemoveFeedMemberDialog
        onMemberRemoved={onMemberRemoved}
        memberToRemove={feedMember}
        isOpen={isConfirmRemoveMemberDialogOpen}
        onClose={closeConfirmRemoveMemberDialog}
      />
      <ManageFeedMemberPermissionsDialog
        isOpen={isManageFeedMemberPermissionDialogOpen}
        onClose={closeManageFeedMemberPermissionsDialog}
        feedMember={feedMember}
        onMemberUpdated={() => {}}
      />
    </>
  );
};

export default FeedMemberActionsMenu;
