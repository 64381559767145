import { VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { PageDraft } from 'src/models/pageDraft';
import { useRejectContent } from './hooks/useRejectContent';

interface PageRemarkDialogProps {
  draft: PageDraft;
  refetchDraftRequest: UseQueryResult['refetch'];
}
export const PageRemarkDialog: React.FC<PageRemarkDialogProps> = ({
  draft,
  refetchDraftRequest,
}) => {
  const [review, setReview] = useState<string>('');
  const { translate } = useTranslate();

  const {
    handleRejectPageContent,
    updatePageContentMutation,
    rejectDraftModalDisclosure,
  } = useRejectContent(refetchDraftRequest);

  const handleRemarkChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = e => {
    setReview(e.target.value);
  };

  return (
    <>
      <Button variant='outline' onClick={rejectDraftModalDisclosure.onOpen}>
        {translate(TranslationKeys.reject)}
      </Button>
      <Modal {...rejectDraftModalDisclosure}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translate(TranslationKeys.rejectRemark)}</ModalHeader>
          <ModalBody>
            <VStack align='start' spacing='6'>
              <FormControl>
                <FormLabel>{translate(TranslationKeys.reviewRemark)}</FormLabel>
                <Textarea
                  placeholder={translate(TranslationKeys.letReviewerKnowRemark)}
                  value={review}
                  onChange={handleRemarkChange}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='blue'
              isLoading={updatePageContentMutation.isLoading}
              onClick={() => handleRejectPageContent(draft, review)}
            >
              {translate(TranslationKeys.submitPageReview)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
