import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import {
  joinAsrChatThreadRoom,
  leaveAsrChatThreadRoom,
  NewAsrChatMessage,
  onNewAsrChat,
  unsubscribeNewAsrChat,
} from 'src/apis/socket.io/messages.socket.io';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { User } from 'src/models';
import { ValueCallback } from 'src/types/common.type';

export const useNewAsrChatNotification = () => {
  const [newAsrChat, setNewAsrChat] = useState<NewAsrChatMessage>();
  const incomingAsrChatModalDisclosure = useDisclosure();

  const { currentUser } = useUserDetails();

  const handleTranscribeMessage = useCallback<
    ValueCallback<NewAsrChatMessage, User>
  >((newMessage, currentUser) => {
    if (currentUser?.id === newMessage.userId && newMessage.messageThreadId) {
      setNewAsrChat(newMessage);
      incomingAsrChatModalDisclosure.onOpen();
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      joinAsrChatThreadRoom(currentUser.id);
      return () => {
        leaveAsrChatThreadRoom(currentUser.id);
      };
    }
  }, [currentUser]);

  useEffect(() => {
    onNewAsrChat(value => handleTranscribeMessage(value, currentUser));

    return () => {
      unsubscribeNewAsrChat(value =>
        handleTranscribeMessage(value, currentUser),
      );
    };
  }, [currentUser]);

  return {
    newAsrChat,
    incomingAsrChatModalDisclosure,
  };
};
