import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import {
  subscribeNewIncomingAlert,
  unsubscribeNewIncomingAlert,
} from 'src/apis/socket.io/events/alerts.socket.io';
import { Alert } from 'src/models/Alerts.model';
import { SOSAlertDialog } from 'src/pages/home/components/SOSAlertDialog';

interface UserSOSAlertWrapperProps {
  children: React.ReactNode;
}

export const UserSOSAlertWrapper: React.FC<UserSOSAlertWrapperProps> = ({
  children,
}) => {
  const alertModalDisclosure = useDisclosure();

  const [alertInfo, setAlertInfo] = useState<Alert>();

  const handleIncomingALert = useCallback((alert: Alert) => {
    if (alert) {
      setAlertInfo(alert);
    }
  }, []);

  useEffect(() => {
    subscribeNewIncomingAlert(handleIncomingALert);
    return () => {
      unsubscribeNewIncomingAlert(handleIncomingALert);
    };
  });

  useEffect(() => {
    // if alert is present then we open alert modal
    if (alertInfo) {
      alertModalDisclosure.onOpen();
    }
  }, [alertInfo]);

  return (
    <>
      {children}
      {alertInfo && alertModalDisclosure.isOpen && (
        <SOSAlertDialog
          key={alertInfo.id}
          alertData={alertInfo}
          {...alertModalDisclosure}
        />
      )}
    </>
  );
};
