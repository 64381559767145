import { Page } from './Page.model';
import { User } from './user.model';

export enum PageDraftStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}

export interface PageDraft {
  id: number;
  draftContent: string;
  DraftedByUserId: number;
  draftedBy: User;
  deletedAt?: Date;
  createdAt: Date;
  PageId: number;
  page: Page;
  ReviewedByUserId?: number;
  reviewedBy?: User;
  reviewedAt?: Date;
  reviewRemarks?: string;
  status: PageDraftStatus;
}
