import { Avatar, Flex, Grid, Text } from '@chakra-ui/react';
import * as _ from 'lodash';
import { Fragment } from 'react';

import { CallSession } from 'src/models/CallSession.model';
import { time24Hour } from 'src/utils';

import { getDuration } from '../utils/call.util';

interface GroupCallTableProps {
  sessions: CallSession[];
}

export const GroupCallTable: React.FC<GroupCallTableProps> = ({ sessions }) => {
  return (
    <Grid gridTemplateColumns='1fr 1fr 1fr 1fr'>
      <Flex bgColor='blue.50'>
        <Text p='3' fontWeight='medium'>
          Member
        </Text>
      </Flex>
      <Flex bgColor='blue.50'>
        <Text p='3' fontWeight='medium'>
          Joined
        </Text>
      </Flex>
      <Flex bgColor='blue.50'>
        <Text p='3' fontWeight='medium'>
          Ended
        </Text>
      </Flex>
      <Flex bgColor='blue.50'>
        <Text p='3' fontWeight='medium'>
          Duration
        </Text>
      </Flex>
      {_.map(_.uniqBy(sessions, 'otherUsers.id'), (session: CallSession) => {
        const sessionWithJoinedAt = session.joinedAt;
        const sessionWithDisconnectedAt = session.disconnectedAt;

        return (
          <Fragment key={session.otherUsers.id}>
            <Flex justifyContent='normal' mt='1'>
              <Flex pl='3'>
                <Avatar name={session.otherUsers.fullName} size='xs' />
              </Flex>
              <Flex>
                <Text
                  fontWeight='normal'
                  color='gray.500'
                  pl='3'
                  textTransform='capitalize'
                >
                  {session.otherUsers.fullName.split(' ')[0]}
                </Text>
              </Flex>
            </Flex>
            <Flex mt='1'>
              <Text fontWeight='normal' color='gray.500'>
                {sessionWithJoinedAt &&
                  sessionWithDisconnectedAt &&
                  time24Hour(sessionWithJoinedAt)}
              </Text>
            </Flex>
            <Flex mt='1'>
              <Text fontWeight='normal' color='gray.500'>
                {sessionWithDisconnectedAt &&
                  sessionWithJoinedAt &&
                  time24Hour(sessionWithDisconnectedAt)}
              </Text>
            </Flex>
            <Flex mt='1' pl='6'>
              <Text fontWeight='normal' color='gray.500'>
                {session.joinedAt &&
                  session.disconnectedAt &&
                  getDuration(session.joinedAt, session.disconnectedAt)}
              </Text>
            </Flex>
          </Fragment>
        );
      })}
    </Grid>
  );
};
