import { ExternalNavigationLink } from './ExternalNavigationLink';
import { InternalNavigationLink } from './InternalNavigationLink';

interface NavigationLinkProps {
  url: string;
  isExternal: boolean;
}

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  url,
  isExternal,
  children,
}) => {
  return isExternal ? (
    <ExternalNavigationLink linkUrl={url}>{children}</ExternalNavigationLink>
  ) : (
    <InternalNavigationLink linkUrl={url}>{children}</InternalNavigationLink>
  );
};
