import { Center, Flex } from '@chakra-ui/react';
import * as _ from 'lodash';

import { CameraSwitchIcon } from 'src/components/Common/CameraSwitchIcon';
import {
  CALL_FOOTER_HEIGHT,
  ONE_MEMBER_IN_GROUP as ONE_JOINED_CALL_SESSION,
} from 'src/constants/group-call.constant';
import { maxWidth } from 'src/constants/max-width.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { CallSession } from 'src/models/CallSession.model';
import MicrophoneButton from 'src/pages/peer-call/components/footer/components/MicrophoneButton';
import SpeakerButton from 'src/pages/peer-call/components/footer/components/SpeakerButton';
import UserSelfVideo from 'src/pages/peer-call/components/footer/components/UserSelfVideo';
import VideoButton from 'src/pages/peer-call/components/footer/components/VideoButton';
import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

import EndGroupCallButton from '../body/components/EndCallButton';

const GroupCallFooter = () => {
  const { callSessions } = useCallSessionsContext();
  const { currentUser } = useUserDetails();
  const { switchCamera, isVideoMuted } = usePeerConnection();

  const isDisabled = !_.some(
    _.filter(
      callSessions,
      callSession =>
        currentUser &&
        (callSession.ReceiverId === currentUser.id ||
          callSession.CallerId === currentUser.id),
    ) as CallSession[],
    callSession => !!callSession.joinedAt,
  );

  return (
    <>
      {callSessions.length === ONE_JOINED_CALL_SESSION ? (
        <Center w='full' bottom='0' left='0'>
          <Center
            position='relative'
            bgColor='blue.500'
            w='full'
            h={CALL_FOOTER_HEIGHT}
            maxW={maxWidth}
          >
            <Flex justifyContent='space-around' w='full'>
              <SpeakerButton isSpeakerDisabled={isDisabled} />
              <VideoButton isVideoDisabled={isDisabled} />
              <MicrophoneButton isMicDisabled={isDisabled} />
              <CameraSwitchIcon
                switchCamera={switchCamera}
                iconFill='white'
                isDisabled={isVideoMuted}
                _hover={{ colorScheme: 'white' }}
              />
              <EndGroupCallButton />
            </Flex>
            <UserSelfVideo />
          </Center>
        </Center>
      ) : (
        <Center w='full'>
          <Center
            position='relative'
            bgColor='blue.500'
            w='full'
            h={CALL_FOOTER_HEIGHT}
          >
            <Flex justifyContent='space-around' w='full'>
              <SpeakerButton isSpeakerDisabled={isDisabled} />
              <VideoButton isVideoDisabled={isDisabled} />
              <MicrophoneButton isMicDisabled={isDisabled} />
              <CameraSwitchIcon
                switchCamera={switchCamera}
                iconFill='white'
                isDisabled={isVideoMuted}
                _hover={{ colorScheme: 'white' }}
              />
              <EndGroupCallButton />
            </Flex>
          </Center>
        </Center>
      )}
    </>
  );
};
export default GroupCallFooter;
