import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useMemo } from 'react';
import { FiEdit } from 'react-icons/fi';
import { MdGroup } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';

import UserAvatar from 'src/components/UserAvatar';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import usePageMembers from 'src/pages/page-members/hooks/usePageMembers';
import {
  DRAFT_REQUEST_PAGE_ROUTE,
  PAGE_CONTENT_PAGE_ROUTE,
  PAGE_MEMBERS_PAGE_ROUTE,
} from 'src/routes/routeList';
import { getUserDisplayName } from 'src/utils/user.utils';

import { PageContent } from './components/page-content';
import usePage from './hooks/usePage';
import usePageContent from './hooks/usePageContent';
import usePageParams from './hooks/usePageParams';
import { AppNavigationType } from 'src/types/navigation.type';

const BlogPage: React.FC = () => {
  const { id: pageId } = usePageParams();
  const { translate } = useTranslate();
  const { id: pageIdForLink }: { id: string } = useParams();

  const { page } = usePage();
  const { content } = usePageContent({ page });
  const { pageMembers } = usePageMembers({
    pageId,
  });
  const { currentUser } = useUserDetails();

  const currentUserMemberRecord = useMemo(
    () => _.find(pageMembers, member => member.UserId === currentUser?.id),
    [pageMembers, currentUser],
  );

  if (!page || !content) {
    return null;
  }

  return (
    <HeaderWrapper
      pageTitle={page.title}
      navigationType={AppNavigationType.back}
    >
      <VStack align='start' w='full' p='4' spacing='4'>
        <HStack w='full'>
          <HStack>
            <Heading fontSize='xl' noOfLines={1}>
              {page.title}
            </Heading>
            {currentUserMemberRecord?.canUpdate &&
              !currentUserMemberRecord?.canModerateUpdates && (
                <Link
                  to={DRAFT_REQUEST_PAGE_ROUTE.replace(':id', pageIdForLink)}
                >
                  <IconButton
                    colorScheme='blue'
                    variant='ghost'
                    size='sm'
                    aria-label='Edit page content'
                    icon={<Icon as={FiEdit} boxSize='5' />}
                  />
                </Link>
              )}
          </HStack>
          {currentUserMemberRecord?.canModerateUpdates && (
            <Link to={PAGE_CONTENT_PAGE_ROUTE.replace(':id', pageIdForLink)}>
              <IconButton
                colorScheme='blue'
                variant='ghost'
                size='sm'
                aria-label='Review and edit page content'
                icon={<Icon as={FiEdit} boxSize='5' />}
              />
            </Link>
          )}
        </HStack>
        {page.createdBy && (
          <Flex width='full'>
            <Flex justifyContent='left' flex='1' alignItems='center'>
              <UserAvatar user={page.createdBy} size='sm' mr='2' />
              <Text fontSize='sm'>
                {translate(TranslationKeys.by)}
                <Text as='span' ml='1.5' fontWeight='semibold'>
                  {getUserDisplayName(page.createdBy)}
                </Text>
              </Text>
            </Flex>
            <Flex justifyContent='right'>
              <Link to={PAGE_MEMBERS_PAGE_ROUTE.replace(':id', pageIdForLink)}>
                <IconButton
                  colorScheme='blue'
                  variant='ghost'
                  aria-label='view members of page'
                  icon={<Icon as={MdGroup} boxSize='8' />}
                  mx='4'
                >
                  {translate(TranslationKeys.viewMembers)}
                </IconButton>
              </Link>
            </Flex>
          </Flex>
        )}
        <Box w='full' h='0.5' bg='gray.300' />
        <PageContent pageId={pageId} deserializedPageContent={content} />
      </VStack>
    </HeaderWrapper>
  );
};

export default BlogPage;
