import { Progress } from '@chakra-ui/react';

interface UploadingProgressProps {
  progressValue: number;
}

export const UploadingProgress: React.FC<UploadingProgressProps> = ({
  progressValue,
}) => {
  if (progressValue <= 0) {
    return null;
  }

  return <Progress w='full' hasStripe value={progressValue} size='sm' />;
};
