import { Flex, Image } from '@chakra-ui/react';

interface PostCardCommentImageProps {
  imageUrl: string;
}

const PostCardCommentImage: React.FC<PostCardCommentImageProps> = ({
  imageUrl,
}) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <Flex p='3' mb='2' ml='4'>
      <Image src={imageUrl} objectFit='cover' />
    </Flex>
  );
};
export default PostCardCommentImage;
