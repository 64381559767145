import { Box, List } from '@chakra-ui/react';
import * as _ from 'lodash';
import { getUserDisplayName } from 'src/utils/user.utils';
import UserAvatar from '../../../components/UserAvatar';
import useResolveAlerts from '../hooks/useResolveAlerts';
import AlertItem from './AlertItems';

const ResolveAlertsPanel = () => {
  const { allResolveAlerts } = useResolveAlerts();

  const time24Hour = (date: Date) => {
    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  return (
    <Box position='relative'>
      <List paddingInline='0'>
        {_.chain(allResolveAlerts)
          .orderBy(({ createdAt }) => createdAt, 'desc')
          .value()
          .map(({ id, createdAt, raisedBy, alertMedias, textContent }) => (
            <AlertItem
              textContent={textContent}
              alertMedias={alertMedias}
              key={id}
              alertId={id}
              title={getUserDisplayName(raisedBy)}
              subtitle={time24Hour(createdAt)}
              avatarComponent={<UserAvatar user={raisedBy} />}
            />
          ))}
      </List>
    </Box>
  );
};

export default ResolveAlertsPanel;
