import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { updateUserLocationInMessageThread } from 'src/apis/message-thread-members.api';
import { useUserLocation } from 'src/hoc';
import { useHandsFreeOperation } from 'src/hoc/HandsFreeOperationProvider';
import {
  ASR_CHAT_PAGE_ROUTE,
  MESSAGE_THREAD_PARAM_NAME,
} from 'src/routes/routeList';

export const useUpdateUserLocationInMessageThread = () => {
  const { mutate: updateUserLocationInMessageThreadMutate } = useMutation(
    updateUserLocationInMessageThread,
  );
  const { hfoModelDisclosure } = useHandsFreeOperation();
  const { locationStream } = useUserLocation();
  const { push } = useHistory();

  const navigateAndCloseModal = (messageThreadId: number) => {
    push(
      ASR_CHAT_PAGE_ROUTE.replace(
        MESSAGE_THREAD_PARAM_NAME,
        messageThreadId.toString(),
      ),
    );
    hfoModelDisclosure.onClose();
  };

  const updateUserLocation = (messageThreadId: number) => {
    if (!messageThreadId) return;

    if (locationStream) {
      updateUserLocationInMessageThreadMutate(
        {
          messageThreadId,
          latitude: locationStream.latitude.toString(),
          longitude: locationStream.longitude.toString(),
        },
        {
          onSuccess: () => {
            navigateAndCloseModal(messageThreadId);
          },
        },
      );
    } else {
      navigateAndCloseModal(messageThreadId);
    }
  };

  return { updateUserLocation };
};
