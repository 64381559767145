import i18n from 'src/app/i18n';

/**
 * Custom hook for translation.
 * @returns An object containing the `changeLanguage` function and the `translate` function.
 * @example
 * const { changeLanguage, translate } = useTranslate();
 * changeLanguage('en');
 * translate('Hello'); // Returns the translated string for 'Hello' in the current language.
 */
export const useTranslate = () => {
  const { t, changeLanguage } = i18n;
  return { changeLanguage, translate: t };
};
