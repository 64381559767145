import { useEffect, useState } from 'react';
import usePage from 'src/pages/blogs/blog/hooks/usePage';
import { EditablePageContent } from '../types/EditablePageContent.type';

const usePageContentForEdit = () => {
  const { page, isLoading, refetch } = usePage();

  const [newContent, setNewContent] = useState<EditablePageContent>();
  const previousPageContent = JSON.parse(page?.content || '{}');

  useEffect(() => {
    if (page && page.content) {
      setNewContent(JSON.parse(page.content));
    }
  }, [page]);

  return {
    newContent,
    setNewContent,
    isContentLoading: isLoading,
    refetchPageContent: refetch,
    previousContent: previousPageContent,
  };
};

export default usePageContentForEdit;
