import { Button, ButtonGroup, Flex, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';

import Loader from 'src/components/loader/Loader';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { usePendingDraftRequests } from 'src/pages/blogs/blog/hooks/usePendingDraftRequests';

import { Editor } from './components/editor/Editor';
import { DraftedByUser } from './DraftedByUser';
import { useApproveContent } from './hooks/useApproveContent';
import { PageRemarkDialog } from './PageRemarkDialog';
import { parseDraftContent } from './utils';

export const ApprovePageContent = () => {
  const { translate } = useTranslate();
  const { pageDrafts, isLoading, refetch } = usePendingDraftRequests();

  const { handlePublishPageContent } = useApproveContent();

  if (isLoading) {
    return <Loader />;
  }

  if (_.isEmpty(pageDrafts)) {
    return (
      <Text textAlign='center'>
        {translate(TranslationKeys.noRequestsPending)}
      </Text>
    );
  }

  return (
    <VStack w='full' align='start'>
      {_.map(pageDrafts, draft => (
        <VStack
          w='full'
          p='4'
          rounded='md'
          border='1px solid'
          borderColor='gray.300'
          align='start'
          key={draft.id}
        >
          <DraftedByUser
            createdAt={draft.createdAt}
            draftedBy={draft?.draftedBy}
          />
          <Editor
            isReadonly
            editorKeyId={`${draft.id}-draft`}
            w='full'
            value={parseDraftContent(draft.draftContent)}
            contentPadding='0'
          />
          <Flex w='full' justifyContent='flex-end'>
            <ButtonGroup colorScheme='blue'>
              <PageRemarkDialog draft={draft} refetchDraftRequest={refetch} />
              <Button onClick={() => handlePublishPageContent(draft)}>
                {translate(TranslationKeys.approve)}
              </Button>
            </ButtonGroup>
          </Flex>
        </VStack>
      ))}
    </VStack>
  );
};
