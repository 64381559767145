import { MutableRefObject, memo } from 'react';
import { Tbody, Td } from '@chakra-ui/react';

import { listVariants } from 'src/constants/calls.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { ColorShaderMap } from 'src/utils';
import { Call } from 'src/models/Call.model';

import CallIcons from './CallIcons';
import { getColorForCallIcon, getDuration } from '../utils/call.util';

export interface CallHistoryItemProps {
  callerId: number;
  joinedAt: Date | null;
  subtitle: React.ReactNode;
  disconnectedAt: Date | null;
  callStatus: Call['callStatus'];
  callItemRef: MutableRefObject<HTMLTableSectionElement | null> | null;
}

const CallHistoryItem: React.FC<CallHistoryItemProps> = memo(
  ({
    callerId,
    joinedAt,
    subtitle,
    disconnectedAt,
    callStatus,
    callItemRef,
  }) => {
    // get current user
    const { currentUser } = useUserDetails();

    return (
      <Tbody
        variants={listVariants}
        borderBottom={'1px solid'}
        borderBottomColor={ColorShaderMap.gray[200]}
        py='2'
        px='2'
        ref={callItemRef}
      >
        <Td>{subtitle}</Td>

        <Td>
          {currentUser && (
            <CallIcons
              callerId={callerId}
              currentUser={currentUser}
              callStatus={callStatus}
              color={getColorForCallIcon(joinedAt)}
            />
          )}
        </Td>

        <Td>
          {joinedAt && disconnectedAt
            ? getDuration(joinedAt, disconnectedAt)
            : '-'}
        </Td>
      </Tbody>
    );
  },
);

export default CallHistoryItem;
