import { Button } from '@chakra-ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import {
  getIsLacsUnitIsCloud as getIsCloudUnit,
  login,
} from 'src/apis/auth.api';
import { ForgetPasswordModal } from 'src/components';
import { PasswordField } from 'src/components/form/PasswordField';
import RouteLink from 'src/components/RouteLink';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useToast } from 'src/hooks/use-toast';
import useAuthStore from 'src/hooks/useAuthStore';
import { useTranslate } from 'src/hooks/useTranslate';
import useUserRoom from 'src/hooks/useUserRoom';
import { HOME_PAGE_ROUTE, REGISTER_PAGE_ROUTE } from 'src/routes/routeList';
import { LoginDto, useLoginSchema } from 'src/schema';

const kLoginFormId = 'login-form';

const LoginForm: React.FC = () => {
  const { translate } = useTranslate();
  const loginFormSchema = useLoginSchema();
  const { showErrorToast } = useToast();
  const { joinRoom } = useUserRoom();
  const forgetPasswordModalDisclosure = useDisclosure();
  const { setAccessToken } = useAuthStore();
  const { refetchUser } = useUserDetails();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginFormSchema),
    shouldUnregister: false,
  });

  const { mutate: loginMutate } = useMutation(login);
  const { data: isCloudUnit } = useQuery('getIsCloudUnit', getIsCloudUnit);

  const submitLoginForm = async (loginPayload: LoginDto) => {
    loginMutate(loginPayload, {
      onSuccess: response => {
        setAccessToken(response.accessToken);
        refetchUser();
        joinRoom();
        // Redirect to home page on login so that call can be received without refreshing the page
        window.location.replace(HOME_PAGE_ROUTE);
      },
      onError: showErrorToast,
    });
  };

  return (
    <>
      <VStack
        spacing='8'
        as='form'
        onSubmit={handleSubmit(submitLoginForm)}
        id={kLoginFormId}
      >
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>{translate(TranslationKeys.email)}</FormLabel>
          <Input
            {...register('email', {
              setValueAs: (value: string) => value?.trim(),
            })}
          />
          {errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>

        <VStack w='full' align='start'>
          <PasswordField
            label={translate(TranslationKeys.enterPassword)}
            formControlProps={{
              isInvalid: !!errors.password,
            }}
            inputProps={register('password')}
          >
            {errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </PasswordField>
          {isCloudUnit && (
            <Button
              variant='link'
              colorScheme='blue'
              size='xs'
              alignSelf='end'
              onClick={() => forgetPasswordModalDisclosure.onOpen()}
            >
              {translate(TranslationKeys.forgotPassword)}
            </Button>
          )}
        </VStack>

        <Button
          w='100%'
          py='4'
          colorScheme='blue'
          type='submit'
          form={kLoginFormId}
        >
          {translate(TranslationKeys.login)}
        </Button>

        <HStack flexWrap='wrap' justifyContent='center'>
          <Text fontWeight='bold'>
            {translate(TranslationKeys.dontHaveAccount)}
          </Text>
          <RouteLink to={REGISTER_PAGE_ROUTE}>
            {translate(TranslationKeys.createAccount)}
          </RouteLink>
        </HStack>
      </VStack>
      <ForgetPasswordModal {...forgetPasswordModalDisclosure} />
    </>
  );
};

export default LoginForm;
