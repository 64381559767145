import _ from 'lodash';
import { useMutation } from 'react-query';

import { useUploadAlertMedia } from 'src/apis';
import { SOSMediaUpdateDto } from 'src/dto/SoS.dto';
import {
  useHandsFreeOperation,
  useTranscribeMessage,
} from 'src/hoc/HandsFreeOperationProvider';
import { useCommonAudioStream } from './useCommonAudioStream';

export const useAlertText = (onClose: () => void, alertId: number) => {
  const { setStartTime, transcribeMessage, setTranscribeMessage } =
    useTranscribeMessage();

  const { setIsListening } = useHandsFreeOperation();

  const {
    isRecording,
    stopRecording,
    endRecording,
    startRecording,
    resetAudioRecorder,
  } = useCommonAudioStream(setStartTime, setTranscribeMessage);

  const { updateAlertMedia } = useUploadAlertMedia();

  const updateSoSMediaMutation = useMutation((alertMedia: SOSMediaUpdateDto) =>
    updateAlertMedia(alertMedia),
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Do not allow input when recording
    if (isRecording) {
      return;
    }
    setTranscribeMessage(event.target.value);
  };

  const isValidTextContent = _.chain(transcribeMessage)
    .trim()
    .isEmpty()
    .value();

  const cleanUp = () => {
    endRecording();
    stopRecording();
    setIsListening(true);
    onClose();
  };

  const restartRecording = () => {
    setTranscribeMessage('');
    startRecording();
  };

  const handleSend = () => {
    updateSoSMediaMutation.mutate(
      {
        alertId: _.toString(alertId),
        textContent: transcribeMessage,
      },
      {
        onSuccess: cleanUp,
        onError: cleanUp,
      },
    );
  };

  return {
    transcribeMessage,
    handleSend,
    isValidTextContent,
    isRecording,
    restartRecording,
    cleanUp,
    handleInputChange,
    stopRecording,
    resetAudioRecorder,
  };
};
