import { Box, List, Text, VStack } from '@chakra-ui/react';
import * as _ from 'lodash';
import { MutableRefObject, useRef, useState } from 'react';

import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { CallSession } from 'src/models/CallSession.model';

import CallItem from '../components/CallItem';
import usePagination from '../hooks/usePagination';
import usePersonalCalls from '../hooks/usePersonalCalls';

interface PersonalCallsPanelProps {
  pageRef: MutableRefObject<HTMLDivElement | null>;
}

const PersonalCallsPanel: React.FC<PersonalCallsPanelProps> = ({ pageRef }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const { translate } = useTranslate();
  const {
    calls: personalCalls,
    hasMore,
    queryMetadata,
  } = usePersonalCalls(pageNumber);
  const [visibleItem, setVisibleItem] = useState<number | null>(null);
  const { currentUser } = useUserDetails();

  const time24Hour = (date: Date) => {
    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  const observerRef = useRef<HTMLDivElement | null>(null);
  const isFetchingData = useRef(false);
  const observerTargetIndex = useRef(0);

  usePagination({
    calls: personalCalls,
    observerTargetIndex,
    isLoading: queryMetadata.isLoading,
    isFetched: queryMetadata.isFetched,
    isFetchingData,
    pageRef,
    observerRef,
    hasMore,
    setPageNumber,
  });

  const handleVisibility = (id: number) => {
    setVisibleItem(prev => (prev === id ? null : id));
  };

  const selectCallerOrReceiverId = (session: CallSession) => {
    return session.CallerId === currentUser?.id
      ? session.ReceiverId
      : session.CallerId;
  };

  return (
    <Box position='relative'>
      <List spacing='4' paddingInline='0'>
        {_.isEmpty(personalCalls) && (
          <VStack mt='12'>
            <Text fontSize='lg' color='gray.500' fontWeight='semibold'>
              {translate(TranslationKeys.youHaveNoCallsYet)}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {translate(TranslationKeys.createACall)}
            </Text>
          </VStack>
        )}
        {/* render all latest items */}
        {_.chain(personalCalls)
          .uniqBy(({ session }) => selectCallerOrReceiverId(session))
          .value()
          .map(
            (
              {
                id,
                otherUser,
                session,
                createdAt,
                callStatus,
                hasMorePersonalCalls,
              },
              index,
            ) => {
              const isObserverTarget =
                index === observerTargetIndex.current && hasMore;

              return (
                <CallItem
                  onClick={() => handleVisibility(id)}
                  key={id}
                  callerId={session.CallerId}
                  receiverId={session.ReceiverId}
                  joinedAt={session.joinedAt}
                  callStatus={callStatus}
                  title={otherUser.fullName}
                  subtitle={time24Hour(createdAt)}
                  avatarComponent={<UserAvatar name={otherUser.fullName} />}
                  isVisible={visibleItem === id}
                  observerRef={isObserverTarget ? observerRef : null}
                  hasMorePersonalCalls={hasMorePersonalCalls}
                />
              );
            },
          )}
      </List>
    </Box>
  );
};

export default PersonalCallsPanel;
