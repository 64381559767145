import { Grid } from '@chakra-ui/react';

import { NavigationLink } from 'src/components/Navigation';
import useDashboardSectionLinks from '../../hooks/useDashboardSectionLinks';
import { BaseSection } from '../BaseSection';
import { TextSectionItem } from './TextSectionItem';
import { TextSectionProps } from './types/TextSection.props.types';

const TextSection: React.FC<TextSectionProps> = ({ dashboardSection }) => {
  const [dashboardSectionLinks] = useDashboardSectionLinks({
    dashboardSectionId: dashboardSection.id,
  });

  const body = (
    <Grid gap={4} mx='2'>
      {dashboardSectionLinks.map(link => (
        <NavigationLink
          key={link.id}
          url={link.linkUrl}
          isExternal={link.isExternal}
        >
          <TextSectionItem text={link.linkText} iconImgSrc={link.imageUrl} />
        </NavigationLink>
      ))}
    </Grid>
  );

  return <BaseSection title={dashboardSection.title} body={body} />;
};

export default TextSection;
