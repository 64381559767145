import { SimpleGrid, Text } from '@chakra-ui/react';
import _ from 'lodash';
import Loader from 'src/components/loader/Loader';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import HeaderWrapper from '../../hoc/HeaderWrapper';
import useFeeds from '../../hooks/apis/useFeeds';
import FeedCard from './FeedCard';

const EmptyFeeds = () => {
  const { translate } = useTranslate();
  return (
    <Text
      w='full'
      mt='8'
      textAlign='center'
      fontSize='xl'
      color='gray.500'
      fontWeight='semibold'
    >
      {translate(TranslationKeys.noFeedsYet)}
    </Text>
  );
};

const FeedsPage = () => {
  const { feeds, isLoadingFeeds } = useFeeds();
  const { translate } = useTranslate();

  const renderBody = () => {
    if (isLoadingFeeds) {
      return <Loader />;
    }

    if (_.isEmpty(feeds)) {
      return <EmptyFeeds />;
    }

    return _.map(feeds, feed => <FeedCard key={feed.id} feed={feed} />);
  };

  return (
    <HeaderWrapper pageTitle={translate(TranslationKeys.feeds)}>
      <SimpleGrid
        columns={1}
        spacing={4}
        py='6'
        px='4'
        mx='auto'
        justifyContent='center'
      >
        {renderBody()}
      </SimpleGrid>
    </HeaderWrapper>
  );
};

export default FeedsPage;
