import { Center, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { AddMembersIcon } from 'src/components/Common/AddMembersIcon';
import { CALL_HEADER_HEIGHT } from 'src/constants/group-call.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import useDialog from 'src/hooks/useDialog';
import { CallSession } from 'src/models/CallSession.model';
import SelectUserToCallModal from 'src/pages/calls/components/SelectUserForCall';
import CallDuration from 'src/pages/peer-call/components/header/components/call-duration';
import useCall from 'src/pages/peer-call/hooks/useCall';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';
import { getGroupCallNameOrImage } from 'src/utils/get-call-details';

const GroupCallHeader = () => {
  const { call } = useCall();
  const { callSessions } = useCallSessionsContext();
  const { currentUser } = useUserDetails();
  const [isTimerStarted, setIsTimerStarted] = useState<boolean>(true);
  const [session, setSession] = useState<CallSession>();

  const [
    isSelectUserToCallModalOpen,
    openSelectUserToCallModal,
    closeSelectUserToCallModal,
  ] = useDialog();

  const AllCallSessionOfHostUser = _.map(callSessions, session => {
    if (call?.HostUserId === session?.CallerId) {
      return session;
    }
  });

  const callSessionWithHost = _.head(AllCallSessionOfHostUser);

  const isCurrentUserHost = currentUser?.id === call?.HostUserId;

  useEffect(() => {
    if (callSessions) {
      const findSessionWithJoinedAt = callSessions.find(session => {
        if (session && session.joinedAt) {
          return session;
        }
        return undefined;
      });
      setSession(findSessionWithJoinedAt);
      setIsTimerStarted(false);
    }
  }, [callSessions, isTimerStarted]);

  const hasMaximumParticipants =
    _.map(callSessions, callSession => !callSession.disconnectedAt).length >= 3;

  return (
    <Center
      bgColor='blue.500'
      h={CALL_HEADER_HEIGHT}
      color='white'
      alignContent='center'
      flexDirection='column'
      position='relative'
    >
      <VStack>
        <Text fontSize='x-large' fontWeight='bold'>
          {call && getGroupCallNameOrImage(call, 'name')}
        </Text>

        {/* if the current user is host of the call then his call duration will be visible as soon as any of user that are in the group call is pick up the call */}
        {isCurrentUserHost && session && <CallDuration callSession={session} />}

        {/* if the current user is receiver then the call duration is visible as soon as he pickup the call */}
        {!isCurrentUserHost && callSessionWithHost && (
          <CallDuration callSession={callSessionWithHost} />
        )}
      </VStack>

      <AddMembersIcon
        position='absolute'
        right='6'
        openSelectUserToCallModal={openSelectUserToCallModal}
        iconFill='white'
        isDisabled={!session?.joinedAt || hasMaximumParticipants}
        _hover={{ colorScheme: 'white' }}
      />

      <SelectUserToCallModal
        isOpen={isSelectUserToCallModalOpen}
        onClose={closeSelectUserToCallModal}
        isGroupCall={true}
        isCallActive={true}
      />
    </Center>
  );
};
export default GroupCallHeader;
