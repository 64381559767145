import { Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { SOS_ALERT_DATE_FORMAT } from 'src/constants/sos-alert.constants';
import { Alert } from 'src/models/Alerts.model';
import { getUserFullName } from 'src/utils';

interface AlertResolvedByProps {
  alert: Alert;
}

export const AlertResolvedBy: React.FC<AlertResolvedByProps> = ({ alert }) => {
  const resolvedBy = alert?.resolvedBy;
  const resolvedAt = alert?.resolvedAt;

  return (
    <VStack
      width='full'
      align='start'
      border='1px solid'
      borderColor='gray.400'
      p='2'
      rounded='md'
      bg='gray.200'
      fontSize='lg'
    >
      <Text>
        <Text as='span' fontWeight='semibold'>
          Resolved By:
        </Text>{' '}
        {getUserFullName(resolvedBy)}
      </Text>
      <Text>
        <Text as='span' fontWeight='semibold'>
          Resolved At:
        </Text>{' '}
        {moment(resolvedAt).format(SOS_ALERT_DATE_FORMAT)}
      </Text>
    </VStack>
  );
};
