import { Flex, FormLabel, IconButton, Input } from '@chakra-ui/react';
import { BiCamera } from 'react-icons/bi';
import { FaUserCircle } from 'react-icons/fa';

interface UserWithoutAvatarProps {
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UserWithoutAvatar: React.FC<UserWithoutAvatarProps> = ({
  handleImageUpload,
}) => {
  return (
    <Flex
      justifyContent='center'
      py='8'
      w='full'
      bg='gray.100'
      position='relative'
    >
      <FaUserCircle fontSize='12rem' style={{ position: 'relative' }} />
      <FormLabel
        htmlFor='upload-button'
        m='0'
        position='relative'
        p='0'
        rounded='full'
      >
        <Input
          type='file'
          id='upload-button'
          accept='image/*'
          display='none'
          onChange={handleImageUpload}
        />
        <IconButton
          as='span'
          aria-label='image'
          rounded='full'
          colorScheme='blue'
          right='3'
          bottom='3'
          position='absolute'
        >
          <BiCamera />
        </IconButton>
      </FormLabel>
    </Flex>
  );
};
