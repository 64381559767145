import * as yup from 'yup';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

const GROUP_NAME_MIN_LENGTH = 3;
const GROUP_NAME_MAX_LENGTH = 30;

export const useGroupChatSchema = () => {
  const { translate } = useTranslate();

  const groupChatFormSchema = yup.object().shape({
    name: yup
      .string()
      .min(GROUP_NAME_MIN_LENGTH, translate(TranslationKeys.groupNameMinLength))
      .max(GROUP_NAME_MAX_LENGTH, translate(TranslationKeys.groupNameMaxLength))
      .required(translate(TranslationKeys.pleaseEnterGroupName)),
    description: yup.string().optional(),
  });

  return groupChatFormSchema;
};

export type GroupChatFormSchemaDto = yup.InferType<
  ReturnType<typeof useGroupChatSchema>
>;
