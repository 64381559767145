import _ from 'lodash';

import { axiosAsrInstance, axiosInstance } from 'src/config';
import { SpeechRecognitionApiKeys } from 'src/constants/speech-recognition.constant';
import { CreateRecordingDto } from 'src/dto/create-recording.dto';
import { NLPCommandExecutionResponse } from 'src/hoc/HandsFreeOperationProvider/hooks/use-execute-hfo-command';

import { getRequestData } from './utils/get-request-data.util';

type SendCommandToNlpServerDto = {
  UserId: number;
  language: string | undefined;
  text: string;
  accessToken: string | null;
  MessageThreadId?: number;
  longitude?: string;
  latitude?: string;
};

export const createRecording = async (newRecording: CreateRecordingDto) => {
  const formData = new FormData();
  formData.append(
    SpeechRecognitionApiKeys.messageThreadId,
    _.toString(newRecording.MessageThreadId),
  );
  formData.append(
    SpeechRecognitionApiKeys.userId,
    _.toString(newRecording.UserId),
  );
  formData.append(
    SpeechRecognitionApiKeys.timestamp,
    _.toString(newRecording.timestamp),
  );
  formData.append(
    SpeechRecognitionApiKeys.accessToken,
    _.toString(newRecording.token),
  );
  formData.append(
    SpeechRecognitionApiKeys.recordingStop,
    _.toString(newRecording.recStop),
  );
  formData.append(
    SpeechRecognitionApiKeys.language,
    _.toString(newRecording.language),
  );
  if (newRecording.file) {
    formData.append(SpeechRecognitionApiKeys.file, newRecording.file);
  }

  return getRequestData(axiosAsrInstance.post('/audio-stream', formData));
};

export const getTensorflowModelUrl = async () => {
  return getRequestData(axiosInstance.get('/speech-recognition/tfjs-url'));
};

export const sendCommandToNlpServer = async (
  payload: SendCommandToNlpServerDto,
) => {
  return getRequestData<NLPCommandExecutionResponse>(
    axiosInstance.post(
      '/speech-recognition/send-command-to-nlp-server',
      payload,
    ),
  );
};
