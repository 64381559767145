import { Center, Grid } from '@chakra-ui/react';
import { FaWifi } from 'react-icons/fa';
import { MdOutlineWifiOff } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { TopbarDropdownMenu } from 'src/components/TopbarDropdownMenu';
import UserAvatar from 'src/components/UserAvatar';
import { UserSearchBar } from 'src/components/UserSearchBar';
import { NAVBAR_HEIGHT } from 'src/constants';
import { maxWidth } from 'src/constants/max-width.constant';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useInternetConnection } from 'src/hooks/useInternetConnection';
import { MY_PROFILE_ROUTE } from 'src/routes/routeList';

export const HomePageNavbar = () => {
  const { currentUser } = useUserDetails();
  const { push } = useHistory();

  const { isInternetAvailable } = useInternetConnection();

  return (
    <Center w='full' h={NAVBAR_HEIGHT} zIndex='modal' top='0' position='fixed'>
      <Grid
        gap='1'
        shadow='md'
        h='full'
        w='full'
        pl='4'
        pr='2'
        maxW={maxWidth}
        templateColumns='7fr 0.75fr 0.75fr 0.4fr'
        alignItems='center'
        justifyItems='end'
      >
        <UserSearchBar />
        <UserAvatar
          user={currentUser}
          size='sm'
          cursor='pointer'
          onClick={() => push(MY_PROFILE_ROUTE)}
        />
        {isInternetAvailable ? (
          <FaWifi size='25' />
        ) : (
          <MdOutlineWifiOff size='25' />
        )}
        <TopbarDropdownMenu />
      </Grid>
    </Center>
  );
};
