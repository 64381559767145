import { Icon, StyleProps, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { NavigationLinksType } from 'src/pages/home/constants/navigation-link.constant';
import { MessagesButtonIcon } from './MessagesButton';

interface FooterNavigationButtonProps
  extends Pick<NavigationLinksType, 'icon' | 'label'> {
  path: string;
}

export const FooterNavigationButton: React.FC<FooterNavigationButtonProps> = ({
  label,
  icon,
  path,
}) => {
  const { translate } = useTranslate();
  const isCurrentPage = window.location.pathname === path;

  const navigationColor: Pick<StyleProps, 'bg' | 'color'> = isCurrentPage
    ? { bg: 'blue.500', color: 'white' }
    : { bg: 'gray.50', color: 'black' };

  const ButtonIcon =
    label === TranslationKeys.messages ? (
      <MessagesButtonIcon isCurrentPage={isCurrentPage} />
    ) : (
      <Icon
        w='full'
        color={isCurrentPage ? 'white' : 'black'}
        as={icon}
        boxSize='5'
      />
    );

  return (
    <VStack
      justify='center'
      spacing='1'
      w='full'
      h='full'
      as={Link}
      to={path}
      borderRight='2px solid'
      borderColor='gray.300'
      bg={navigationColor.bg}
      color={navigationColor.color}
      position='relative'
      _hover={{
        background: 'blue.500',
        color: 'white',
      }}
    >
      {ButtonIcon}
      <Text fontSize='xs' lineHeight='none' fontWeight='semibold'>
        {translate(label)}
      </Text>
    </VStack>
  );
};
