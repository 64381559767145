import {
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { GroupCallDot } from 'src/components/Common/GroupCallDot';
import {
  CALL_SESSION_FIRST_CHUNK_LENGTH,
  CALL_SESSION_REMAINING_CHUNK_LENGTH,
  INITIAL_TAB_INDEX,
} from 'src/constants/calls.constant';
import { CALL_PAGE_HEIGHT } from 'src/constants/group-call.constant';
import { CallSession } from 'src/models/CallSession.model';
import useCallSessions from 'src/pages/peer-call/hooks/useCallSessions';
import { getHeightForStreamComponent } from 'src/pages/peer-call/utils/call-session.utils';

import { GroupCallGridWrapper } from './GroupCallGrid';
import { ReceiverStreams } from './ReceiverStreams';
import { UserOwnStream } from './UserOwnStream';

interface GroupStreamContainerProps extends BoxProps {}

export const GroupStreamContainer: React.FC<GroupStreamContainerProps> = () => {
  const { callSessions } = useCallSessions();

  const [currentTab, setCurrentTab] = useState(INITIAL_TAB_INDEX);

  const heightRef = useRef<HTMLDivElement>(null);

  const childrenHeight = getHeightForStreamComponent(heightRef);

  // Dividing the call sessions into chunks where first chunk has 3 sessions and the rest have 4 sessions each cause at the first tab we have 3 peer streams and one user stream.
  // The rest of the tabs have 4 peer streams each.
  const callSessionChunks = useMemo(() => {
    // Making the first chunk of 3 sessions
    const firstChunk = _.take(callSessions, CALL_SESSION_FIRST_CHUNK_LENGTH);

    // Chunk the remaining sessions into groups of 4
    const remainingChunks = _.chunk(
      _.slice(callSessions, CALL_SESSION_FIRST_CHUNK_LENGTH),
      CALL_SESSION_REMAINING_CHUNK_LENGTH,
    );

    return [firstChunk, ...remainingChunks];
  }, [callSessions]);

  const swipeToChangeTabHandlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentTab(prevIndex =>
        Math.min(prevIndex + 1, callSessionChunks.length - 1),
      ),
    onSwipedRight: () => setCurrentTab(prevIndex => Math.max(prevIndex - 1, 0)),
    trackMouse: true,
  });

  return (
    <div {...swipeToChangeTabHandlers}>
      <Tabs
        index={currentTab}
        onChange={index => setCurrentTab(index)}
        ref={heightRef}
        variant='unstyled'
        position='relative'
      >
        <TabPanels>
          {_.map(callSessionChunks, (callSessionChunk, index) => {
            return (
              <TabPanel key={index} p='0'>
                <GroupCallGridWrapper
                  callSessionsChunk={callSessionChunk}
                  height={CALL_PAGE_HEIGHT}
                  isFirstTab={index === 0}
                >
                  {_.map(callSessionChunk, (session: CallSession) => (
                    <ReceiverStreams
                      session={session}
                      childrenHeight={childrenHeight}
                    />
                  ))}
                  {index === 0 && <UserOwnStream minH={childrenHeight} />}
                </GroupCallGridWrapper>
              </TabPanel>
            );
          })}
        </TabPanels>
        <TabList
          backgroundColor='blackAlpha.500'
          display={callSessionChunks.length > 1 ? 'block' : 'none'}
          position='absolute'
          bottom='2'
          left='50%'
          transform='translateX(-50%)'
          borderRadius='full'
        >
          <Flex display='flex' justifyContent='center' alignItems='center'>
            {_.map(callSessionChunks, (_, index) => (
              <Tab
                key={index}
                _focus={{
                  boxShadow: 'none',
                }}
                mx='-2'
              >
                <GroupCallDot isSelected={currentTab === index} />
              </Tab>
            ))}
          </Flex>
        </TabList>
      </Tabs>
    </div>
  );
};
