import { Box, Text } from '@chakra-ui/react';
import { User } from 'src/models';

interface MapEmptyStateProps {
  user: User;
}

export const MapEmptyState: React.FC<MapEmptyStateProps> = ({ user }) => {
  return (
    <Box zIndex='1010' top='0' w='full' h='full' position='absolute'>
      <Box h='full' opacity='0.7' bg='gray.600' />
      <Text
        w='full'
        position='absolute'
        top='45%'
        textAlign='center'
        fontWeight='semibold'
        color='white'
      >
        Location not available
      </Text>
    </Box>
  );
};
