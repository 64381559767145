import { Box } from '@chakra-ui/react';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { CallSession } from 'src/models/CallSession.model';
import { CallMicStatus } from 'src/pages/peer-call/components/body/components/CallMicStatus';
import CallUserImage from 'src/pages/peer-call/components/body/components/CallUserImage';
import PeerVideo from 'src/pages/peer-call/components/body/components/PeerVideo';
import { usePeerConnection } from 'src/pages/peer-call/context/usePeerConnection';
import { getOtherUserId } from 'src/pages/peer-call/utils/call-session.utils';

interface ReceiverStreamsProps {
  session: CallSession;
  childrenHeight: string;
}

export const ReceiverStreams: React.FC<ReceiverStreamsProps> = ({
  session,
  childrenHeight,
}) => {
  const { currentUser } = useUserDetails();
  const {
    peerStreams,
    peerVideoStatus,
    peerAudioStatus: peerMicStatus,
  } = usePeerConnection();

  const otherUserId =
    session && currentUser && getOtherUserId(session, currentUser.id);
  const stream = peerStreams[otherUserId!];
  const isPeerVideoEnabled = peerVideoStatus[otherUserId!];
  const isPeerMicEnabled = peerMicStatus[otherUserId!];

  return (
    <Box
      h='full'
      w='full'
      minH={childrenHeight}
      position='relative'
      key={session.id}
    >
      {stream ? (
        <PeerVideo
          key={session.id}
          stream={stream}
          name={session.otherUsers?.fullName}
          peerVideoStatus={isPeerVideoEnabled}
          session={session}
        />
      ) : (
        <CallUserImage
          key={otherUserId}
          name={session.otherUsers?.fullName}
          hasJoined={!!session?.joinedAt!}
        />
      )}
      <CallMicStatus
        isPeerMicEnabled={isPeerMicEnabled}
        name={session.otherUsers?.fullName}
      />
    </Box>
  );
};
