import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { NAVBAR_HEIGHT } from 'src/constants';
import { maxWidth } from 'src/constants/max-width.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { LOGIN_PAGE_ROUTE, REGISTER_PAGE_ROUTE } from 'src/routes/routeList';
import { AppNavigationType } from 'src/types/navigation.type';

import { TopbarDropdownMenu } from '../TopbarDropdownMenu';
import { FeedMapIcon } from './components/feed-map-icon';
import { NavbarUserSearchBar } from './components/user-search-bar';
import { UserSearchIcon } from './components/user-search-icon';
import { getNavbarLeftButton } from './utils/get-navbar-left-icon';

const PATHNAME_TO_EXCLUDE = [LOGIN_PAGE_ROUTE, REGISTER_PAGE_ROUTE];

interface TopbarProps {
  pageTitle: string;
  isFeedPostsPage?: boolean;
  feedId?: number;
  navigationType?: AppNavigationType;
  userSearchAllowed?: boolean;
}

export const Topbar: FC<TopbarProps> = ({
  pageTitle,
  isFeedPostsPage,
  feedId,
  navigationType,
  userSearchAllowed = true,
}) => {
  const { translate } = useTranslate();
  const { goBack, location } = useHistory();
  const { currentUser } = useUserDetails();

  const [isSearch, setIsSearch] = useState<boolean>(false);

  const toggleSearch = () => {
    setIsSearch(prev => !prev);
  };

  return (
    <Center
      minW='full'
      position='fixed'
      zIndex='modal'
      top='0'
      h={NAVBAR_HEIGHT}
    >
      <Flex
        bg='gray.50'
        w='full'
        h='full'
        justifyContent='space-between'
        alignItems='center'
        boxShadow='lg'
        borderTop='1px solid'
        borderColor='gray.200'
        px='2'
        zIndex='200'
        maxW={maxWidth}
      >
        <Box flex='1'>
          {getNavbarLeftButton(goBack, location.pathname, navigationType)}
        </Box>
        {isSearch ? (
          <NavbarUserSearchBar toggleSearch={toggleSearch} />
        ) : (
          <>
            <Text flex='2' fontWeight='bold' textAlign='center' noOfLines={1}>
              {pageTitle}
            </Text>
            <Flex flex='1' justifyContent='flex-end'>
              <UserSearchIcon
                toggleSearch={toggleSearch}
                userSearchAllowed={userSearchAllowed}
              />
              <FeedMapIcon feedId={feedId} isFeedPostsPage={isFeedPostsPage} />

              {/* don't show login button on the top bar when user is on the login or register page */}
              {!currentUser &&
                !_.includes(PATHNAME_TO_EXCLUDE, location.pathname) && (
                  <Link to={LOGIN_PAGE_ROUTE}>
                    <Button colorScheme='gray' size='sm'>
                      {translate(TranslationKeys.login)}
                    </Button>
                  </Link>
                )}
              <TopbarDropdownMenu />
            </Flex>
          </>
        )}
      </Flex>
    </Center>
  );
};
