import { useQuery } from 'react-query';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';

import { getAllGroupCalls } from 'src/apis/group-calls.api';
import { GroupCallResponse, GroupCalls } from 'src/models/GroupCall';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kGroupCallsQueryKey = 'groupCalls';

export const useGroupCallQueryClient = () => {
  return useArrayDataQuery<GroupCalls>({
    queryKey: kGroupCallsQueryKey,
  });
};

const useGroupCalls = (pageNumber: number) => {
  const [groupCalls, setGroupCalls] = useState<GroupCalls[]>([]);

  const { data, ...queryMetadata } = useQuery<GroupCallResponse>(
    [kGroupCallsQueryKey, pageNumber],
    () => getAllGroupCalls(pageNumber),
  );

  useEffect(() => {
    setGroupCalls(prevCalls => {
      if (data) {
        const { calls } = data;
        return [...prevCalls, ...calls];
      }
      return prevCalls;
    });
  }, [data]);

  const total = data?.total ?? 0;
  const pageSize = data?.pageSize ?? 0;
  const hasMore = total - pageSize * pageNumber > 0; // Determine if there are more calls to fetch

  return { calls: groupCalls, hasMore, queryMetadata };
};

export default useGroupCalls;
