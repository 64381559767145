import { useState } from 'react';

import { axiosInstance } from 'src/config';
import { CONVERT_TO_PERCENTAGE } from 'src/constants';
import { MediaMessageDto } from 'src/dto/messages.dto';

import { createMediaMessageApi } from '../utils/message.util';

export const useCreateMediaMessage = () => {
  const [progress, setProgress] = useState(0);

  const createMediaMessage = (
    messageThreadId: number,
    payload: MediaMessageDto,
  ) => {
    const { formData, url } = createMediaMessageApi(messageThreadId, payload);

    return axiosInstance.post<void>(url, formData, {
      onUploadProgress: progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * CONVERT_TO_PERCENTAGE,
        );
        setProgress(progress);
      },
    });
  };

  return { createMediaMessage, progress, setProgress };
};
