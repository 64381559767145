export const LayerStyles = {
  layerStyles: {
    ActiveRecordingAnimation: {
      cursor: 'pointer',
      padding: '2',
      bg: '#539ce0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60px',
      height: '60px',
    },
  },
};
