import { useCallback } from 'react';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { joinUserRoom } from '../apis/socket.io/users.socket.io';

const useUserRoom = () => {
  const { currentUser } = useUserDetails();

  const joinRoom = useCallback<() => boolean>(() => {
    if (currentUser?.id) {
      joinUserRoom(currentUser.id);
      return true;
    }

    return false;
  }, [currentUser?.id]);

  return { joinRoom };
};

export default useUserRoom;
