import { Box } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { AlertMediaInformation } from './AlertMediaInformation';

interface TextAlertMediaProps {
  alertContent: string | undefined;
}

export const TextAlertMedia: React.FC<TextAlertMediaProps> = ({
  alertContent,
}) => {
  const { translate } = useTranslate();
  if (!alertContent) {
    return null;
  }

  return (
    <AlertMediaInformation title={translate(TranslationKeys.text)}>
      <Box width='full' height='auto' background='#F4F7FE' p='2'>
        {alertContent}
      </Box>
    </AlertMediaInformation>
  );
};
