import { BoxProps, HStack, Text, VStack } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import React from 'react';

import { TextAudio } from 'src/apis/socket.io/messages.socket.io';
import { MessageSpokenIcon } from 'src/assets';

import { Message } from '../../../models/Message.model';
import MediaMessage from './message-box/MediaMessage';
import MessageStatus from './MessageStatus';

export interface MessageBoxProps extends BoxProps {
  message: Message;
  textAudio?: TextAudio;
}

const time24Hour = (date: Date) => {
  return Intl.DateTimeFormat('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const MessageBox: React.FC<MessageBoxProps> = ({
  message,
  textAudio,
  ...props
}) => {
  const isMediaMessage = message.messageMedias.length > 0;

  return (
    <VStack
      spacing='4'
      borderRadius='8'
      bg='blue.500'
      color='white'
      p='2'
      fontSize='sm'
      alignItems='baseline'
      justifyContent='start'
      minW='20%'
      {...props}
    >
      {!isMediaMessage && <Text wordBreak='break-word'>{message.text}</Text>}
      {isMediaMessage && (
        <MediaMessage
          message={message}
          mediaBoxProps={{ minW: '44', maxH: '60', objectFit: 'cover' }}
        />
      )}
      <HStack alignSelf='end' align='end'>
        {textAudio && message.id === textAudio.messageId && (
          <Icon as={MessageSpokenIcon} boxSize='4' />
        )}
        <Text fontSize='xs' color='gray.500'>
          {time24Hour(message.messageAt)}
        </Text>
        <MessageStatus message={message} />
      </HStack>
    </VStack>
  );
};

export default MessageBox;
