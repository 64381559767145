import {
  ComponentWithAs,
  Grid,
  GridItem,
  GridItemProps,
  Icon,
  IconProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FC, useState } from 'react';

import { CameraIcon } from 'src/assets';
import { TextIcon } from 'src/assets/icons/Text-icon';
import { VideoIcon } from 'src/assets/icons/video-icon';
import { VoiceIcon } from 'src/assets/icons/voice-icon';
import { AudioDialog, VideoDialog } from 'src/components';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import CameraDialog from 'src/pages/chat/components/CameraDialog';

import { AlertTextDialog } from './AlertTextDialog';
import { SOSMediaPreviewDialog } from './SosMediaPreviewDialog';

interface SOsInformationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  alertId: Alert['id'];
}

export const SosInformationDialog: FC<SOsInformationDialogProps> = ({
  isOpen,
  onClose,
  alertId,
}) => {
  const { translate } = useTranslate();
  const [mediaToPreview, setMediaToPreview] = useState<
    Blob[] | File[] | FileList
  >([]);

  const photoModalDisclosure = useDisclosure();
  const textModalDisclosure = useDisclosure();
  const audioModalDisclosure = useDisclosure();
  const previewModalDisclosure = useDisclosure();
  const videoModalDisclosure = useDisclosure();

  const handleOnImageCapture: BlobCallback = blob => {
    if (blob) {
      setMediaToPreview([blob]);
      photoModalDisclosure.onClose();
      previewModalDisclosure.onOpen();
    }
  };

  const onPhotoIconClick = () => {
    onClose();
    photoModalDisclosure.onOpen();
  };

  const onAudioIconClick = () => {
    onClose();
    audioModalDisclosure.onOpen();
  };

  const onVideoIconClick = () => {
    onClose();
    videoModalDisclosure.onOpen();
  };

  const closeMediaPreview = () => {
    setMediaToPreview([]);
    previewModalDisclosure.onClose();
  };

  const onTextIconClick = () => {
    onClose();
    textModalDisclosure.onOpen();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width='80' py='2'>
          <ModalCloseButton _focus={{}} />
          <ModalHeader>{translate(TranslationKeys.addMoreInfo)}</ModalHeader>
          <ModalBody alignItems='center'>
            <Grid templateColumns='repeat(2, 2fr)' gap='4'>
              <InformationIcons
                onClick={onPhotoIconClick}
                icon={CameraIcon}
                title={translate(TranslationKeys.photo)}
                bg='#FFE6E2'
              />
              <InformationIcons
                onClick={onVideoIconClick}
                icon={VideoIcon}
                title={translate(TranslationKeys.video)}
                bg='#E3F8FA'
              />
              <InformationIcons
                onClick={onAudioIconClick}
                icon={VoiceIcon}
                title={translate(TranslationKeys.voice)}
                bg='#F5E6FE'
              />
              <InformationIcons
                onClick={onTextIconClick}
                icon={TextIcon}
                title={translate(TranslationKeys.text)}
                bg='#FFF0E5'
              />
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>

      {previewModalDisclosure.isOpen && (
        <SOSMediaPreviewDialog
          media={mediaToPreview}
          alertId={alertId}
          {...previewModalDisclosure}
          onClose={closeMediaPreview}
        />
      )}
      {photoModalDisclosure.isOpen && (
        <CameraDialog
          {...photoModalDisclosure}
          onImageCapture={handleOnImageCapture}
        />
      )}
      {textModalDisclosure.isOpen && (
        <AlertTextDialog alertId={alertId} {...textModalDisclosure} />
      )}
      {audioModalDisclosure.isOpen && (
        <AudioDialog {...audioModalDisclosure} alertId={alertId} />
      )}
      {videoModalDisclosure.isOpen && (
        <VideoDialog {...videoModalDisclosure} alertId={alertId} />
      )}
    </>
  );
};

interface InformationIconsProps {
  onClick: () => void;
  icon: ComponentWithAs<'svg', IconProps>;
  title: string;
  bg: GridItemProps['bg'];
}

const InformationIcons: React.FC<InformationIconsProps> = ({
  onClick,
  icon,
  title,
  bg,
}) => {
  return (
    <GridItem bg={bg} borderRadius='10' onClick={onClick} cursor='pointer'>
      <VStack spacing='none' py='3'>
        <Icon as={icon} boxSize='10' />
        <Text fontWeight='semibold'>{title}</Text>
      </VStack>
    </GridItem>
  );
};
