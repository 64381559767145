import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { reviewPage } from 'src/apis/pages.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { PageDraftReviewDto } from 'src/dto/pages.dto';
import { useTranslate } from 'src/hooks/useTranslate';
import { PageDraft, PageDraftStatus } from 'src/models/pageDraft';

export const useApproveContent = () => {
  const showToast = useToast();
  const history = useHistory();
  const { translate } = useTranslate();

  const updatePageContentMutation = useMutation<
    void,
    AxiosError,
    PageDraftReviewDto
  >(reviewPage, {
    onSuccess: () => {
      history.goBack();
      showToast({
        title: translate(TranslationKeys.pageContentApproved),
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: translate(TranslationKeys.errorApprovingPageContent),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handlePublishPageContent = (draft: PageDraft) => {
    if (!draft) {
      return;
    }
    updatePageContentMutation.mutate({
      status: PageDraftStatus.approved,
      PageId: draft.PageId,
      id: draft.id,
    });
  };

  return { handlePublishPageContent };
};
