import {
  Icon,
  Image,
  ImageProps,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdBrokenImage } from 'react-icons/md';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { FeedModel } from '../models';

type FeedCoverImageProps = StackProps &
  ImageProps & {
    coverImageUrl: FeedModel['coverImageUrl'];
  };

const FeedCoverImage: React.FC<FeedCoverImageProps> = ({
  coverImageUrl,
  ...props
}) => {
  const { translate } = useTranslate();
  return coverImageUrl ? (
    <Image src={coverImageUrl} minH='52' w='full' {...props} />
  ) : (
    <VStack
      bg='gray.400'
      color='whiteAlpha.800'
      minH='52'
      w='full'
      justifyContent='center'
      {...props}
    >
      <Icon as={MdBrokenImage} boxSize='14' />
      <Text>{translate(TranslationKeys.noCoverImageFeed)}</Text>
    </VStack>
  );
};

export default FeedCoverImage;
