import { Box, Heading } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Page } from '../../../../models/Page.model';
import { deserializePageContent } from '../../utils/page-content.utils';

import BlogCardTextContent from './components/BlogCardTextContent';
import BlogCardThumbnail from './components/BlogCardThumbnail';

interface BlogPageCardProps {
  page: Page;
}

const BlogPageCard: React.FC<BlogPageCardProps> = ({ page }) => {
  const deserializedContent = useMemo(
    () => deserializePageContent(page.content),
    [page.content],
  );

  return (
    <Box
      as={Link}
      to={`/pages/${page.id}`}
      w='full'
      borderWidth='1px'
      borderStyle='solid'
      borderColor='gray.400'
      bg='white'
      minH='24'
      p='2'
      rounded='lg'
      h='fit-content'
    >
      <Heading fontSize='x-large' py='2'>
        {page.title}
      </Heading>
      <BlogCardThumbnail deserializedContent={deserializedContent} />
      <BlogCardTextContent deserializedContent={deserializedContent} />
    </Box>
  );
};

export default BlogPageCard;
