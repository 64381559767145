import { Box } from '@chakra-ui/react';
import Slider, { Settings as CarouselSettings } from 'react-slick';

import CarouselArrow from 'src/components/CarouselArrow';
import { NavigationLink } from 'src/components/Navigation';
import { CAROUSEL_SETTINGS } from 'src/constants';
import ImageCard from '../../ImageCard';
import { ImageSliderCarouselProps } from '../types';

export const ImageSliderCarousel: React.FC<ImageSliderCarouselProps> = ({
  imgSrcList,
}) => {
  const settings: CarouselSettings = {
    ...CAROUSEL_SETTINGS,
    nextArrow: <CarouselArrow direction='next' />,
    prevArrow: <CarouselArrow direction='prev' />,
  };

  return (
    <Box mx={{ base: '2', sm: '6' }}>
      <Slider {...settings}>
        {imgSrcList.map(image => (
          <NavigationLink
            key={image.src}
            url={image.linkUrl!}
            isExternal={image.externalLink!}
          >
            <ImageCard {...image} imageProps={{ maxH: '52' }} />
          </NavigationLink>
        ))}
      </Slider>
    </Box>
  );
};
