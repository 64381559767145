import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useMutation } from 'react-query';

import { deleteComment } from 'src/apis/comment.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { Post } from 'src/models/Post.model';
import { EntityType } from 'src/models/View.model';

import useComments from './hooks/useComments';

interface DeleteCommentModalProps {
  isOpen: boolean;
  onClose: () => void;
  commentId: number;
  post: Post;
}

export const DeleteCommentModal: FC<DeleteCommentModalProps> = ({
  isOpen,
  onClose,
  commentId,
  post,
}) => {
  const { translate } = useTranslate();
  const { refetch } = useComments(post.id, EntityType.post);

  const deleteCommentMutation = useMutation(
    (commentId: number) => deleteComment(commentId),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const deletePostComment = () => {
    deleteCommentMutation.mutate(commentId);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Flex>
            <Text>{translate(TranslationKeys.deleteComment)} </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex>
            <Button
              loadingText={`${translate(TranslationKeys.updating)}...`}
              colorScheme='red'
              type='submit'
              mx='1'
              onClick={deletePostComment}
            >
              {translate(TranslationKeys.delete)}
            </Button>
            <Button
              ml='1'
              variant='outline'
              colorScheme='red'
              onClick={onClose}
            >
              {translate(TranslationKeys.cancel)}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
