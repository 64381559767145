import { useQuery } from 'react-query';
import { getResolvedAlerts } from 'src/apis/alerts.api';
import { Alert } from 'src/models/Alerts.model';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kResolveAlertsQueryKey = 'resolvedAlerts';

export const useAlertsQueryClient = () => {
  return useArrayDataQuery<Alert>({
    queryKey: kResolveAlertsQueryKey,
  });
};

const useResolveAlerts = () => {
  const { data, ...queryMetadata } = useQuery<Alert[]>(
    kResolveAlertsQueryKey,
    getResolvedAlerts,
  );
  const allResolveAlerts = data || [];

  return { allResolveAlerts, queryMetadata };
};

export default useResolveAlerts;
