import { createContext, useContext } from 'react';

interface UserMediaContextType {
  localStream: MediaStream | null;
}

export const UserMediaContext = createContext<UserMediaContextType>({
  localStream: null,
});

export const useUserMedia = () => {
  const context = useContext(UserMediaContext);
  if (!context) {
    throw new Error('useUserMedia must be used within a UserMediaProvider');
  }
  return context;
};
