import { Text } from '@chakra-ui/react';
import _ from 'lodash';

import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { AlertMedia } from 'src/models/Alerts.model';

import { AlertMediaInformation } from './AlertMediaInformation';

interface VideoAlertMediaProps {
  alertMedia: AlertMedia[] | undefined;
}

export const VideoAlertMedia: React.FC<VideoAlertMediaProps> = ({
  alertMedia,
}) => {
  const { translate } = useTranslate();
  const videoMedia = _.filter(alertMedia, media =>
    media.type.startsWith(ALERT_MEDIA_TYPES.video),
  );

  if (_.isEmpty(videoMedia)) {
    return null;
  }

  return (
    <AlertMediaInformation title={translate(TranslationKeys.video)}>
      {_.map(videoMedia, media => {
        return (
          <>
            <video style={{ width: '100%' }} controls playsInline>
              {/* start the video from 1 millisecond so the video preview is visible for all the devices */}
              <source src={`${media.mediaUrl}#t=0.001`} type={media.type} />
            </video>
            <Text>{media.caption}</Text>
          </>
        );
      })}
    </AlertMediaInformation>
  );
};
