export const kNewMessageEvent = 'messages:new_message';
export const kTypingEvent = 'messages:typing';
export const kStoppedTypingEvent = 'messages:stopped_typing';
export const kStatusUpdateEvent = 'messages:status_updated';
export const kMultipleStatusUpdateEvent = 'messages:status_updated_multiple';
export const kTranscribeMessage = 'message:transcribe-message';
export const kTextToSpeechEvent = 'message:text-to-speech';
export const kHFOTranscribeMessage = 'hfo:message';
export const kJoinHFOThreadEvent = 'hfo:join';
export const kLeaveHFOThreadEvent = 'hfo:leave';
export const kJoinAsrChatThreadEvent = 'asr-chat:join';
export const kLeaveAsrChatThreadEvent = 'asr-chat:leave';
export const kNewAsrChatEvent = 'asr-chat:new';
