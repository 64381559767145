import {
  Center,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { Comment } from 'src/models/Comment.model';
import { convertToCardDate, getUserDisplayName } from 'src/utils';
import PostCardCommentImage from '../PostCardCommentImage';

interface PostCommentCardProps {
  openReviewDialog: (comment?: Comment) => void;
  openDeleteDialog: () => void;
  comment: Comment;
  setCommentIdToDelete: (commentId: number) => void;
}

export const PostCommentCard: FC<PostCommentCardProps> = ({
  openReviewDialog,
  openDeleteDialog,
  comment,
  setCommentIdToDelete,
}) => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();

  const isCurrentUserComment = comment?.UserId === currentUser?.id;

  const commentImageUrl = comment.imageUrl;

  return (
    <>
      <Flex bgColor='blue.50' flexDir='column'>
        <Flex m='1.5'>
          <Flex m='2'>
            <UserAvatar size='sm' user={comment.user} />
          </Flex>
          <Flex flexDir='column'>
            <Text fontWeight='bold' fontSize='sm'>
              {comment.user && getUserDisplayName(comment.user)}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {convertToCardDate(comment.updatedAt)}
            </Text>
          </Flex>
          <Flex>
            <Flex pl={{ base: '9rem', sm: '18rem' }}>
              {isCurrentUserComment && (
                <Menu autoSelect={false}>
                  <MenuButton>
                    <Icon as={IoEllipsisHorizontal} boxSize='6' />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => openReviewDialog(comment)}>
                      {translate(TranslationKeys.editComment)}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setCommentIdToDelete(comment.id);
                        openDeleteDialog();
                      }}
                    >
                      <Text color='red.500'>
                        {translate(TranslationKeys.deleteComment)}
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex mb='2' ml='7'>
          <Text fontSize='md' mt='-2'>
            {comment.description}
          </Text>
        </Flex>
        <PostCardCommentImage imageUrl={commentImageUrl} />
        <Center borderColor='gray.400'>
          <Divider orientation='horizontal' />
        </Center>
      </Flex>
    </>
  );
};
