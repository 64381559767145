import { createIcon } from '@chakra-ui/react';

export const CameraIcon = createIcon({
  displayName: 'camera icon',
  viewBox: '0 0 45 45',
  defaultProps: {
    width: '45',
    height: '45',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  path: [
    <>
      <circle cx='22.0889' cy='22.4099' r='22' fill='#FC573B' />
      <path
        d='M32.5184 15.0349H28.7611L28.3236 13.7224C28.1128 13.0851 27.7062 12.5306 27.1618 12.1378C26.6174 11.7451 25.963 11.5341 25.2917 11.5349H19.886C19.2149 11.5344 18.5607 11.7455 18.0166 12.1383C17.4724 12.531 17.0659 13.0853 16.855 13.7224L16.4166 15.0349H12.6594C11.8122 15.0358 10.9999 15.3728 10.4008 15.9719C9.80176 16.5709 9.46479 17.3832 9.46387 18.2304V31.0929C9.46572 31.9395 9.80309 32.7509 10.4021 33.3492C11.001 33.9475 11.8128 34.284 12.6594 34.2849H32.5219C33.3685 34.2831 34.1798 33.9457 34.7781 33.3467C35.3765 32.7477 35.7129 31.936 35.7139 31.0894V18.2269C35.712 17.3803 35.3746 16.569 34.7757 15.9706C34.1767 15.3723 33.365 15.0358 32.5184 15.0349ZM33.9639 31.0894C33.9634 31.4726 33.811 31.84 33.54 32.111C33.269 32.382 32.9016 32.5345 32.5184 32.5349H12.6594C12.2761 32.5345 11.9087 32.382 11.6378 32.111C11.3668 31.84 11.2143 31.4726 11.2139 31.0894V18.2269C11.2153 17.8443 11.3681 17.4778 11.639 17.2076C11.9099 16.9373 12.2767 16.7854 12.6594 16.7849H17.0475C17.2311 16.7848 17.4099 16.7269 17.5588 16.6195C17.7077 16.5121 17.819 16.3606 17.877 16.1864L18.5149 14.2728C18.6107 13.985 18.7947 13.7346 19.0408 13.5573C19.2869 13.38 19.5826 13.2847 19.886 13.2849H25.2917C25.5952 13.2846 25.8911 13.38 26.1373 13.5575C26.3834 13.735 26.5673 13.9856 26.6629 14.2737L27.3007 16.1864C27.3587 16.3606 27.4701 16.5121 27.6189 16.6195C27.7678 16.7269 27.9467 16.7848 28.1302 16.7849H32.5184C32.9016 16.7854 33.269 16.9378 33.54 17.2088C33.811 17.4798 33.9634 17.8472 33.9639 18.2304V31.0894Z'
        fill='#EEEEEE'
      />
      <path
        d='M22.5889 17.6599C21.2909 17.6599 20.0221 18.0448 18.9429 18.7659C17.8637 19.487 17.0226 20.5119 16.5259 21.7111C16.0292 22.9102 15.8993 24.2297 16.1525 25.5027C16.4057 26.7757 17.0307 27.945 17.9485 28.8628C18.8663 29.7806 20.0356 30.4056 21.3086 30.6588C22.5816 30.912 23.9011 30.7821 25.1002 30.2854C26.2994 29.7887 27.3243 28.9475 28.0454 27.8683C28.7665 26.7891 29.1514 25.5204 29.1514 24.2224C29.1495 22.4825 28.4575 20.8144 27.2272 19.5841C25.9969 18.3538 24.3288 17.6618 22.5889 17.6599ZM22.5889 29.0349C21.637 29.0349 20.7066 28.7527 19.9152 28.2239C19.1238 27.6951 18.5069 26.9434 18.1427 26.0641C17.7785 25.1847 17.6831 24.2171 17.8688 23.2835C18.0545 22.35 18.5129 21.4925 19.1859 20.8195C19.859 20.1464 20.7165 19.6881 21.65 19.5024C22.5835 19.3167 23.5512 19.412 24.4305 19.7762C25.3099 20.1405 26.0615 20.7573 26.5903 21.5487C27.1191 22.3401 27.4014 23.2706 27.4014 24.2224C27.4 25.4983 26.8925 26.7216 25.9903 27.6238C25.0881 28.526 23.8648 29.0335 22.5889 29.0349Z'
        fill='#EEEEEE'
      />
      <path
        d='M31.3389 20.2849C31.8221 20.2849 32.2139 19.8932 32.2139 19.4099C32.2139 18.9267 31.8221 18.5349 31.3389 18.5349C30.8556 18.5349 30.4639 18.9267 30.4639 19.4099C30.4639 19.8932 30.8556 20.2849 31.3389 20.2849Z'
        fill='#EEEEEE'
      />
    </>,
  ],
});
