import { Icon, IconButton } from '@chakra-ui/react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { MapViewIcon } from 'src/assets';
import { FEED_POSTS_MAP_VIEW } from 'src/routes/routeList';

interface FeedMapIconProps {
  isFeedPostsPage: boolean | undefined;
  feedId: number | undefined;
}

export const FeedMapIcon: React.FC<FeedMapIconProps> = ({
  isFeedPostsPage,
  feedId,
}) => {
  const FEED_MAPS_URL = FEED_POSTS_MAP_VIEW.replace(':id', _.toString(feedId));

  if (!isFeedPostsPage || !feedId) {
    return null;
  }

  return (
    <Link to={FEED_MAPS_URL}>
      <IconButton
        aria-label='posts-view'
        icon={<Icon as={MapViewIcon} boxSize='6' />}
        size='sm'
        _focus={{ boxShadow: 'none' }}
        _hover={{ background: 'none' }}
        variant='ghost'
        pr='6px'
      />
    </Link>
  );
};
