import { useState } from 'react';
import { axiosInstance } from 'src/config';
import { CONVERT_TO_PERCENTAGE } from 'src/constants';
import { Post } from 'src/models/Post.model';
import { NewPostType } from 'src/types/post.type';
import { createPostApi } from '../posts.api';

export const useCreatePost = () => {
  const [progress, setProgress] = useState(0);

  const createPost = async (newPost: NewPostType, feedId: string) => {
    const { formData, url } = createPostApi(newPost, feedId);

    const { data } = await axiosInstance.post<Post>(url, formData, {
      onUploadProgress: progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * CONVERT_TO_PERCENTAGE,
        );
        setProgress(progress);
      },
    });

    return data;
  };

  return { createPost, progress, setProgress };
};
