import { Icon, IconButton } from '@chakra-ui/react';
import { IoCallOutline } from 'react-icons/io5';

import useNewCall from 'src/hooks/useNewCall';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';

interface PersonalCallButtonProps {
  personalMessageThread: PersonalMessageThread;
}

const PersonalCallButton: React.FC<PersonalCallButtonProps> = ({
  personalMessageThread: { recipient },
}) => {
  const { makePersonalCall } = useNewCall();

  const handleClick = () => {
    makePersonalCall({ ReceiverId: recipient.id });
  };

  return (
    <IconButton
      aria-label={`Call ${recipient.firstName}`}
      icon={<Icon as={IoCallOutline} boxSize='6' />}
      onClick={handleClick}
      variant='ghost'
    />
  );
};

export default PersonalCallButton;
