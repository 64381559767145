import { useQuery, UseQueryResult } from 'react-query';

import { getUserPageDrafts } from 'src/apis/pages.api';
import { Page } from 'src/models/Page.model';
import { PageDraft } from 'src/models/pageDraft';

import usePageParams from './usePageParams';

const getPendingDraftsForPageQueryKey = (id: Page['id']) => [
  `getUserPageDrafts-${id}`,
  id,
];

type UsePageReturn = Omit<UseQueryResult, 'data'> & {
  pageDrafts: PageDraft[] | undefined;
};

export const usePageDraftsForUser = (): UsePageReturn => {
  const { id } = usePageParams();
  const { data, ...queryMetadata } = useQuery(
    getPendingDraftsForPageQueryKey(id),
    () => getUserPageDrafts(id),
    { enabled: !!id },
  );

  return { pageDrafts: data, ...queryMetadata };
};
