import { Box, Input, RadioProps, useRadio } from '@chakra-ui/react';
import { FC } from 'react';

interface RadioCardProps extends RadioProps {}

export const RadioCard: FC<RadioCardProps> = props => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  return (
    <Box as='label'>
      <Input {...getInputProps()} hidden />
      <Box
        {...getCheckboxProps()}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        px={3}
        fontWeight='normal'
      >
        {props.children}
      </Box>
    </Box>
  );
};
