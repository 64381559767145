import { Text, Image } from '@chakra-ui/react';
import _ from 'lodash';
import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { AlertMedia } from 'src/models/Alerts.model';
import { AlertMediaInformation } from './AlertMediaInformation';

interface ImageAlertMediaProps {
  alertMedia: AlertMedia[] | undefined;
}

export const ImageAlertMedia: React.FC<ImageAlertMediaProps> = ({
  alertMedia,
}) => {
  const { translate } = useTranslate();
  const imageMedia = _.filter(alertMedia, media =>
    media.type.startsWith(ALERT_MEDIA_TYPES.image),
  );

  if (_.isEmpty(imageMedia)) {
    return null;
  }

  return (
    <AlertMediaInformation title={translate(TranslationKeys.image)}>
      {_.map(imageMedia, media => {
        return (
          <>
            <Image src={media.mediaUrl} />
            <Text>{media.caption}</Text>
          </>
        );
      })}
    </AlertMediaInformation>
  );
};
