import { createIcon } from '@chakra-ui/react';

export const WalkiesTalkieIcon = createIcon({
  displayName: 'WalkiesTalkieIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='150 20 250 350'
      xmlSpace='preserve'
    >
      <g transform='scale(0.5) rotate(-45) translate(-150 400)'>
        <g>
          <path
            d='M505.152,44.717c-4.427-4.426-10.308-6.862-16.562-6.862s-12.137,2.438-16.562,6.862L359.781,156.965
			c-1.38-0.941-3.019-1.459-4.713-1.459c-2.218,0-4.345,0.881-5.916,2.45l-31.055,31.055l-37.239-37.239l11.05-11.05
			c11.738-11.741,11.738-30.844,0-42.584c-11.741-11.74-30.844-11.74-42.584,0L237.4,110.062c-4.094-2.186-8.687-3.346-13.459-3.346
			c-7.649,0-14.839,2.979-20.247,8.387L8.387,310.411C2.978,315.819,0,323.011,0,330.658c0,7.648,2.979,14.839,8.387,20.247
			l114.867,114.867c5.582,5.582,12.915,8.374,20.247,8.374c7.333,0,14.665-2.79,20.247-8.374l144.577-144.577l9.471,9.471
			c1.633,1.633,3.774,2.45,5.914,2.45s4.282-0.816,5.916-2.45l44.816-44.815c1.568-1.568,2.449-3.696,2.449-5.914
			s-0.881-4.346-2.45-5.914l-10.344-10.344c2.186-4.094,3.346-8.688,3.346-13.459c0-6.793-2.354-13.221-6.668-18.363l31.139-31.139
			c2.892-2.892,3.218-7.371,0.99-10.628L505.152,77.839C514.283,68.707,514.283,53.849,505.152,44.717z M261.155,109.97
			c5.216-5.217,13.707-5.217,18.923-0.001c5.219,5.217,5.219,13.708,0,18.926l-11.049,11.05l-18.924-18.924L261.155,109.97z
			 M151.918,453.943c-4.642,4.64-12.194,4.641-16.835,0L20.216,339.075c-2.248-2.247-3.486-5.236-3.486-8.417
			c0-3.181,1.238-6.17,3.486-8.418l195.308-195.307c2.248-2.248,5.237-3.486,8.417-3.486c3.18,0,6.17,1.238,8.418,3.486
			L347.226,241.8c0.001,0,0.001,0,0.001,0c2.248,2.248,3.486,5.237,3.486,8.417c0,3.18-1.238,6.17-3.488,8.418L151.918,453.943z
			 M323.712,312.923l-3.557-3.556l32.986-32.986l3.557,3.557L323.712,312.923z M349.027,219.941l-19.101-19.101l25.141-25.142
			l19.102,19.101L349.027,219.941z M493.322,66.01L381.18,178.152l-9.463-9.463L483.859,56.546c1.266-1.265,2.945-1.962,4.731-1.962
			c1.786,0,3.465,0.697,4.731,1.963C495.932,59.156,495.932,63.402,493.322,66.01z'
          />
        </g>
      </g>
      <g transform='scale(0.5) rotate(-45) translate(-150 400)'>
        <g>
          <path
            d='M304.002,242.713l-72.555-72.555c-3.267-3.268-8.563-3.267-11.829,0c-3.267,3.267-3.267,8.563,0,11.829l72.554,72.555
			c1.634,1.634,3.774,2.45,5.916,2.45c2.139,0,4.28-0.816,5.914-2.45C307.269,251.275,307.269,245.978,304.002,242.713z'
          />
        </g>
      </g>
      <g transform='scale(0.5) rotate(-45) translate(-150 400)'>
        <g>
          <path
            d='M283.098,263.618l-72.557-72.555c-3.267-3.267-8.563-3.267-11.829,0c-3.267,3.267-3.267,8.563,0,11.829l72.555,72.555
			c1.634,1.633,3.774,2.45,5.916,2.45c2.14,0,4.282-0.816,5.916-2.45C286.365,272.18,286.365,266.883,283.098,263.618z'
          />
        </g>
      </g>
      <g transform='scale(0.5) rotate(-45) translate(-150 400)'>
        <g>
          <path
            d='M262.191,284.524l-72.556-72.555c-3.267-3.267-8.563-3.267-11.829,0c-3.267,3.267-3.267,8.563,0,11.829l72.555,72.555
			c1.633,1.633,3.774,2.45,5.914,2.45s4.282-0.816,5.916-2.45C265.458,293.086,265.458,287.789,262.191,284.524z'
          />
        </g>
      </g>
    </svg>
  ),
});
