import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallUnreachable,
  unsubscribeCallUnreachable,
} from 'src/apis/socket.io/call-sessions.socket.io';
import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';

const useCallUnreachableUpdate = () => {
  const { updatePartial } = useCallSessionsContext();

  const handleCallUnreachable = useCallback<CallSessionHandler>(updatePartial, [
    updatePartial,
  ]);

  useEffect(() => {
    subscribeCallUnreachable(handleCallUnreachable);

    return () => {
      unsubscribeCallUnreachable(handleCallUnreachable);
    };
  }, [handleCallUnreachable]);
};

export default useCallUnreachableUpdate;
