import { Box, Flex, IconButton, Image, Text } from '@chakra-ui/react';
import { FaUserCircle } from 'react-icons/fa';
import { MdCall, MdMessage } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useChat } from 'src/hooks/use-chat';
import useNewCall from 'src/hooks/useNewCall';
import { getUserDetails } from 'src/apis/users.api';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { User } from 'src/models';
import { useTranslate } from 'src/hooks/useTranslate';
import { TranslationKeys } from 'src/constants/translation-keys';
import { AppNavigationType } from 'src/types/navigation.type';

const UserProfilePage = () => {
  const { translate } = useTranslate();
  const { userId }: { userId: string } = useParams();
  const { data: user } = useQuery<User | undefined>(
    ['getUserDetails', userId],
    () => getUserDetails(userId),
  );

  const { startChat } = useChat(+userId);
  const { makePersonalCall } = useNewCall();

  return (
    <HeaderWrapper
      key={userId}
      pageTitle={translate(TranslationKeys.profile)}
      navigationType={AppNavigationType.back}
    >
      <Box bg='gray.200'>
        <Flex position='relative'>
          {user?.avatarUrl ? (
            <Image
              src={user?.avatarUrl}
              boxSize='100%'
              maxH='64'
              objectFit='cover'
            />
          ) : (
            <Flex justifyContent='center' bg='gray.100' py='8' w='full'>
              <FaUserCircle fontSize='12rem' />
            </Flex>
          )}
          <Text
            pos='absolute'
            bottom='0'
            color='gray.600'
            fontWeight='bold'
            fontSize='2xl'
            left='2'
          >
            {user?.firstName}&nbsp;{user?.lastName}
          </Text>
        </Flex>
        <Box
          my='2'
          borderY='1px solid'
          borderColor='gray.300'
          px='2'
          bg='white'
        >
          <Text color='blue.500' fontSize='lg'>
            {translate(TranslationKeys.connect)}
          </Text>
          <Flex my='2'>
            <IconButton
              onClick={startChat}
              aria-label='message-call'
              colorScheme='blue'
              mx='1'
            >
              <MdMessage fontSize='24' />
            </IconButton>
            {user && (
              <IconButton
                onClick={() => makePersonalCall({ ReceiverId: user.id })}
                aria-label='call'
                colorScheme='blue'
                mx='1'
              >
                <MdCall fontSize='24' />
              </IconButton>
            )}
          </Flex>
        </Box>
        <Box
          my='2'
          borderY='1px solid'
          borderColor='gray.300'
          px='2'
          bg='white'
        >
          <Flex fontSize='lg'>
            <Text color='blue.500'>{`${translate(
              TranslationKeys.email,
            )} & ${translate(TranslationKeys.phoneNumber)}`}</Text>
          </Flex>
          <Text>{user?.email}</Text>
          <Text>{user?.phoneNumber}</Text>
        </Box>

        <Box
          my='2'
          borderY='1px solid'
          borderColor='gray.300'
          px='2'
          bg='white'
        >
          <Text color='blue.500' fontSize='lg'>
            {translate(TranslationKeys.address)}
          </Text>
          <Flex>{user?.address1 ? user?.address1 : <span>-</span>}</Flex>
          <Flex>{user?.address2 ? user?.address2 : <span>-</span>}</Flex>
          <Text>{user?.city}</Text>
          <Text>{user?.state}</Text>
          <Text>{user?.country}</Text>
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

export default UserProfilePage;
