import { Box, Flex, HStack, StackProps } from '@chakra-ui/layout';
import { Button, Icon, Link, Text } from '@chakra-ui/react';
import { AiOutlineEye } from 'react-icons/ai';
import { Link as RouterLink } from 'react-router-dom';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import UserAvatar from '../../../components/UserAvatar';
import useDialog from '../../../hooks/useDialog';
import { Post } from '../../../models/Post.model';
import {
  MY_PROFILE_ROUTE,
  USER_PROFILE_ROUTE,
} from '../../../routes/routeList';
import { getUserDisplayName } from '../../../utils/user.utils';
import PostApprovalStatusBadge from './PostApprovalStatusBadge';
import PostReviewPreview from './PostReviewPreview';

interface PostCardHeaderProps extends StackProps {
  post: Post;
  showPostApprovalStatus?: boolean;
}

const PostCardHeader: React.FC<PostCardHeaderProps> = ({
  post,
  showPostApprovalStatus,
  ...props
}) => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();
  const isPostByCurrentUser = post.CreatedByUserId === currentUser?.id;

  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog] =
    useDialog();

  const getPostCreatorLink = (): string => {
    if (isPostByCurrentUser) {
      return MY_PROFILE_ROUTE;
    }
    return USER_PROFILE_ROUTE.replace(':userId', `${post.CreatedByUserId}`);
  };

  const getUpdatedTimeOfPost = () => {
    return Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(post.updatedAt));
  };

  return (
    <HStack {...props} px='2'>
      {(isPostByCurrentUser && (
        <RouterLink to={getPostCreatorLink()}>
          <UserAvatar size='sm' user={post.createdBy} />
        </RouterLink>
      )) || <UserAvatar size='sm' user={post.createdBy} />}
      <Flex flexDir='column' my='2' mx='1'>
        {(isPostByCurrentUser && (
          <Link
            as={RouterLink}
            to={getPostCreatorLink()}
            fontSize='md'
            fontWeight='700'
          >
            {post.createdBy && getUserDisplayName(post.createdBy)}
          </Link>
        )) || (
          <Text fontSize='md' fontWeight='700'>
            {post.createdBy && getUserDisplayName(post.createdBy)}
          </Text>
        )}
        <Flex justifyContent='space-between' justify='center'>
          <Flex>
            <Text fontSize='md'>{getUpdatedTimeOfPost()}</Text>
          </Flex>
          {post.views && post.views.length > 0 && (
            <Flex ml='3' align='center'>
              <Icon as={AiOutlineEye} boxSize='5' />
              <Text fontSize='xs' p='1'>
                {post.views.length} {translate(TranslationKeys.viewCount)}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      {showPostApprovalStatus && isPostByCurrentUser && (
        <>
          <Box alignSelf='start'>
            <Button
              variant='link'
              isDisabled={post.isApproved === null}
              cursor={post.isApproved === null ? 'default' : 'pointer'}
              // Since this is a badge, we don't want to show it as disabled
              // which changes badge style
              _disabled={{}}
              onClick={openPreviewDialog}
            >
              <PostApprovalStatusBadge post={post} />
            </Button>
          </Box>
          <PostReviewPreview
            post={post}
            isOpen={isPreviewDialogOpen}
            onClose={closePreviewDialog}
          />
        </>
      )}
    </HStack>
  );
};

export default PostCardHeader;
