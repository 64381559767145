import {
  Button,
  FormErrorMessage,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { register as registerUser } from 'src/apis/auth.api';
import ErrorMessage from 'src/components/form/ErrorMessage';
import { PasswordField } from 'src/components/form/PasswordField';
import TextField from 'src/components/form/TextField';
import RouteLink from 'src/components/RouteLink';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';
import { LOGIN_PAGE_ROUTE } from 'src/routes/routeList';
import { RegisterDto, useRegisterSchema } from 'src/schema';

const kRegisterFormId = 'register-form';

const RegisterForm: React.FC = () => {
  const { translate } = useTranslate();
  const registerFormSchema = useRegisterSchema();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerFormSchema),
    shouldUnregister: false,
  });

  const history = useHistory();
  const { showToast, showErrorToast } = useToast();
  const { mutate: registerMutate, isLoading } = useMutation(registerUser);

  const submitRegisterForm = (registerPayload: RegisterDto) => {
    registerMutate(registerPayload, {
      onSuccess: () => {
        history.replace(LOGIN_PAGE_ROUTE);
        showToast({
          title: 'You have successfully created an account',
          status: 'success',
        });
      },
      onError: showErrorToast,
    });
  };

  return (
    <VStack
      spacing='4'
      as='form'
      onSubmit={handleSubmit(submitRegisterForm)}
      id={kRegisterFormId}
    >
      <TextField
        formControlProps={{ isInvalid: !!errors.firstName }}
        inputProps={{
          ...register('firstName', {
            setValueAs: (value: string) => value?.trim(),
          }),
          placeholder: translate(TranslationKeys.firstNamePlaceholder),
        }}
        label={translate(TranslationKeys.firstName)}
      >
        <ErrorMessage error={errors.firstName} />
      </TextField>

      <TextField
        formControlProps={{ isInvalid: !!errors.lastName }}
        inputProps={{
          ...register('lastName', {
            setValueAs: (value: string) => value?.trim(),
          }),
          placeholder: translate(TranslationKeys.lastNamePlaceholder),
        }}
        label={translate(TranslationKeys.lastName)}
      >
        <ErrorMessage error={errors.lastName} />
      </TextField>

      <TextField
        formControlProps={{ isInvalid: !!errors.email }}
        inputProps={{
          ...register('email', {
            setValueAs: (value: string) => value?.trim(),
          }),
          placeholder: translate(TranslationKeys.emailPlaceholder),
        }}
        label={translate(TranslationKeys.email)}
      >
        <ErrorMessage error={errors.email} />
      </TextField>

      <PasswordField
        label={translate(TranslationKeys.enterPassword)}
        formControlProps={{ isInvalid: !!errors.password }}
        inputProps={register('password')}
      >
        {errors.password && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
      </PasswordField>

      <PasswordField
        label={translate(TranslationKeys.confirmPassword)}
        formControlProps={{
          isInvalid: !!errors.confirmPassword,
        }}
        inputProps={register('confirmPassword')}
      >
        {errors.confirmPassword && (
          <FormErrorMessage>{errors.confirmPassword.message}</FormErrorMessage>
        )}
      </PasswordField>

      <Button
        w='100%'
        py='4'
        colorScheme='blue'
        type='submit'
        form={kRegisterFormId}
        isLoading={isLoading}
      >
        {translate(TranslationKeys.createAccount)}
      </Button>
      <HStack>
        <Text fontWeight='bold'>
          {translate(TranslationKeys.alreadyHaveAccount)}
        </Text>

        <RouteLink to={LOGIN_PAGE_ROUTE}>
          {translate(TranslationKeys.login)}
        </RouteLink>
      </HStack>
    </VStack>
  );
};

export default RegisterForm;
