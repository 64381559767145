import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { sendForgetPasswordMail } from 'src/apis/auth.api';
import { useToast } from 'src/hooks/use-toast';
import { ForgetPasswordDto, useForgetPasswordSchema } from 'src/schema';

interface ForgetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ForgetPasswordModal: React.FC<ForgetPasswordModalProps> = ({
  isOpen,
  onClose,
}) => {
  const forgetPasswordSchema = useForgetPasswordSchema();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(forgetPasswordSchema),
    mode: 'onChange',
  });

  const { showErrorToast, showToast } = useToast();

  const { mutate: sendForgetPasswordMailMutate, isLoading } = useMutation(
    sendForgetPasswordMail,
  );

  const onSubmit = (payload: ForgetPasswordDto) => {
    sendForgetPasswordMailMutate(payload, {
      onSuccess: () => {
        showToast({
          title:
            'Reset password link sent to your email. Please check your email.',
          status: 'success',
        });
        closeFormAndReset();
      },
      onError: showErrorToast,
    });
  };

  const closeFormAndReset = () => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeFormAndReset}>
      <ModalOverlay />
      <ModalContent as='form' onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Forget Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={errors.email}>
            <FormLabel>Email</FormLabel>
            <Input {...register('email')} />
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant='outline'
            colorScheme='blue'
            mr={3}
            onClick={closeFormAndReset}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            colorScheme='blue'
            isDisabled={!isValid}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
