import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { resetPassword } from 'src/apis/auth.api';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useToast } from 'src/hooks/use-toast';
import { useResetPasswordToken } from 'src/hooks/useResetPasswordToken';
import { LOGIN_PAGE_ROUTE } from 'src/routes/routeList';
import {
  ResetPasswordDto,
  useResetPasswordSchema,
} from 'src/schema/reset-password.schema';

const ResetPassword = () => {
  const resetPasswordSchema = useResetPasswordSchema();
  const history = useHistory();
  const { showToast, showErrorToast } = useToast();
  const { resetPasswordToken, removeResetPasswordToken } =
    useResetPasswordToken();
  const { mutate: resetPasswordMutate, isLoading } = useMutation(resetPassword);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = (payload: ResetPasswordDto) => {
    if (!resetPasswordToken) {
      showToast({
        title: 'Something went wrong. Please try again.',
        status: 'error',
      });
      history.replace(LOGIN_PAGE_ROUTE);
      return;
    }
    resetPasswordMutate(payload, {
      onSuccess: () => {
        showToast({
          title: 'Password Reset Successful',
          status: 'success',
        });
        removeResetPasswordToken();
        history.replace(LOGIN_PAGE_ROUTE);
      },
      onError: showErrorToast,
    });
  };

  return (
    <HeaderWrapper pageTitle='Reset Password'>
      <Container mt='10'>
        <Text
          align='center'
          mb='20'
          lineHeight='shorter'
          fontWeight='bold'
          color='blue.700'
          fontSize='4xl'
        >
          Reset Password
        </Text>
        <VStack
          spacing='6'
          w='full'
          as='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl isInvalid={errors.password}>
            <FormLabel>New Password</FormLabel>
            <Input type='password' {...register('password')} />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.confirmPassword}>
            <FormLabel>Confirm New Password</FormLabel>
            <Input type='password' {...register('confirmPassword')} />
            <FormErrorMessage>
              {errors.confirmPassword?.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            w='100%'
            py='4'
            colorScheme='blue'
            type='submit'
            isLoading={isLoading}
          >
            Update Password
          </Button>
        </VStack>
      </Container>
    </HeaderWrapper>
  );
};

export default ResetPassword;
