import { Container, Text } from '@chakra-ui/react';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import LoginForm from './components/LoginForm';

const LoginPage = () => {
  const { translate } = useTranslate();

  return (
    <HeaderWrapper
      pageTitle={translate(TranslationKeys.login)}
      bodyContainerProps={{ pb: '10' }}
    >
      <Container mt='10'>
        <Text
          align='center'
          mb='20'
          lineHeight='shorter'
          fontWeight='bold'
          color='blue.700'
          fontSize='4xl'
        >
          {translate(TranslationKeys.login)}
        </Text>
        <LoginForm />
      </Container>
    </HeaderWrapper>
  );
};

export default LoginPage;
