import * as yup from 'yup';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

export const useInsertMediaUrlFormSchema = () => {
  const { translate } = useTranslate();

  const insertMediaUrlFormSchema = yup.object().shape({
    mediaUrl: yup
      .string()
      .url(translate(TranslationKeys.pleaseEnterValidUrl))
      .required(translate(TranslationKeys.pleaseEnterUrl)),
  });

  return insertMediaUrlFormSchema;
};

export type InsertMediaUrlFormSchemaDto = yup.InferType<
  ReturnType<typeof useInsertMediaUrlFormSchema>
>;
