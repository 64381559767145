import { Grid } from '@chakra-ui/react';

import { NavigationLink } from 'src/components/Navigation';
import useDashboardSectionLinks from '../../hooks/useDashboardSectionLinks';
import { BaseSection } from '../BaseSection';
import { IconSectionItem } from './IconSectionItem';
import { IconSectionProps } from './types';

const IconSection: React.FC<IconSectionProps> = ({ dashboardSection }) => {
  const [dashboardSectionLinks] = useDashboardSectionLinks({
    dashboardSectionId: dashboardSection.id,
  });

  const body = (
    <Grid
      templateColumns={{ base: 'repeat(3, 1fr)', sm: 'repeat(4, 1fr)' }}
      gap={8}
      justifyContent='space-between'
    >
      {dashboardSectionLinks.map(link => (
        <NavigationLink
          url={link.linkUrl}
          key={link.id}
          isExternal={link.isExternal}
        >
          <IconSectionItem src={link.imageUrl} caption={link.linkText} />
        </NavigationLink>
      ))}
    </Grid>
  );

  return <BaseSection gap={6} title={dashboardSection.title} body={body} />;
};

export default IconSection;
