import { Avatar, List } from '@chakra-ui/react';
import _ from 'lodash';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { GroupMessageThread } from 'src/models/GroupMessageThread.model';

import MessageItem from '../../components/MessageItem';
import getLatestMessageText from '../../utils/getLatestMessage.util';
import getMessageThreadDate from '../../utils/getMessageThreadDate.util';
import EmptyGroupChats from '../components/EmptyGroupChats';

interface AseGroupMessagePanelProps {
  asrGroupMessageThread: GroupMessageThread[];
  goToAsrChat: (messageThreadId: number) => void;
}

export const AsrGroupMessagePanel: React.FC<AseGroupMessagePanelProps> = ({
  asrGroupMessageThread,
  goToAsrChat,
}) => {
  const { translate } = useTranslate();

  return (
    <List spacing='4' paddingInline='0'>
      {_.isEmpty(asrGroupMessageThread) ? (
        <EmptyGroupChats showTips={false} />
      ) : (
        _.map(
          asrGroupMessageThread,
          ({ id, group, createdAt, latestMessage }) => (
            <MessageItem
              key={id}
              title={group.name}
              subtitle={getLatestMessageText(
                latestMessage,
                translate(TranslationKeys.beginConversation),
              )}
              topRightInfo={getMessageThreadDate({
                createdAt,
                latestMessage,
              })}
              unreadMessageCount={0}
              avatarComponent={
                <Avatar name={group.name} src={group.imageUrl} />
              }
              onClick={() => goToAsrChat(id)}
            />
          ),
        )
      )}
    </List>
  );
};
