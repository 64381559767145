import { Button, ButtonGroup, Flex, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useState } from 'react';
import { TranslationKeys } from 'src/constants/translation-keys';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { getPageContentRendererId } from 'src/pages/blogs/blog/components/page-content/utils/getPageContentRendererId.util';
import usePageParams from 'src/pages/blogs/blog/hooks/usePageParams';
import usePageMembers from 'src/pages/page-members/hooks/usePageMembers';

import usePageContentForEdit from '../../hooks/usePageContentForEdit';
import usePageContentForReview from '../../hooks/usePageContentForReview';
import { EditablePageContent } from '../../types/EditablePageContent.type';
import { Editor } from '../editor/Editor';

export const DraftPageEditContent: React.FC = () => {
  const { translate } = useTranslate();
  const { id, id: pageId } = usePageParams();
  const [draft, setDraft] = useState<EditablePageContent>();
  const [isReadonly, setIsReadonly] = useState(false);

  const { handleReviewPageContent, reviewPageContentMutation } =
    usePageContentForReview({ id });
  const { newContent } = usePageContentForEdit();
  const { pageMembers } = usePageMembers({
    pageId,
  });
  const { currentUser } = useUserDetails();

  const currentUserMemberRecord = _.find(
    pageMembers,
    member => member.UserId === currentUser?.id,
  );

  const toggleReadonly = () => setIsReadonly(prev => !prev);

  const isPublishingReviewChanges = reviewPageContentMutation.isLoading;

  return (
    <VStack w='full' align='start' overflowX='hidden'>
      {currentUserMemberRecord?.canUpdate && (
        <Editor
          isReadonly={isReadonly}
          editorKeyId={getPageContentRendererId(id)}
          w='full'
          value={newContent}
          onChange={setDraft}
        />
      )}
      <Flex justify='flex-end'>
        <ButtonGroup colorScheme='blue' alignSelf='end'>
          <Button onClick={toggleReadonly} variant='outline'>
            {isReadonly
              ? translate(TranslationKeys.continueEditing)
              : translate(TranslationKeys.previewChanges)}
          </Button>
          {currentUserMemberRecord?.canUpdate && (
            <Button
              onClick={() => handleReviewPageContent(draft)}
              disabled={isPublishingReviewChanges}
              isLoading={isPublishingReviewChanges}
              loadingText={translate(TranslationKeys.reviewRequest)}
            >
              {translate(TranslationKeys.reviewRequest)}
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </VStack>
  );
};

export default DraftPageEditContent;
