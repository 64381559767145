import { Text, TextProps } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { CallSession } from 'src/models/CallSession.model';

import {
  everySecond,
  getDateDiffSecondsFromNow,
  secondsToDuration,
} from './utils/call-duration.utils';

interface CallDurationProps extends TextProps {
  callSession: CallSession;
}

const CallDuration: React.FC<CallDurationProps> = ({
  callSession,
  ...props
}) => {
  const [callDurationTimer, setCallDurationTimer] = useState('');

  const updateCallDuration = useCallback(() => {
    if (!callSession?.joinedAt) {
      return;
    }

    const diffSeconds = getDateDiffSecondsFromNow(
      new Date(callSession.joinedAt),
    );
    setCallDurationTimer(secondsToDuration(diffSeconds));
  }, [callSession]);

  useEffect(() => {
    if (!callSession?.joinedAt) {
      return;
    }

    const callDurationInterval = everySecond(updateCallDuration);

    return () => {
      clearInterval(callDurationInterval);
    };
  }, [updateCallDuration, callSession]);

  return <Text {...props}>{callDurationTimer}</Text>;
};

export default CallDuration;
