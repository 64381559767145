import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import { createRecording } from 'src/apis/speech-recognition-api';
import { audioConstraints } from 'src/constants/calls.constant';
import {
  AUDIO_RECORDER_CHUNK_TIME_SLICE_MS,
  AUDIO_RECORDER_CHUNK_TYPE,
  AUDIO_RECORDER_INITIAL_CHUNK_SIZE,
} from 'src/constants/speech-recognition.constant';
import { CreateRecordingDto } from 'src/dto/create-recording.dto';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider/hands-free-operation-setting.provider';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { TRANSCRIBE_LANGUAGE } from 'src/pages/messages/constants/language.constant';
import { getAccessToken } from 'src/providers/auth-store.provider';

export const useCommonAudioStream = (
  setStartTime: React.Dispatch<React.SetStateAction<number>>,
  setTranscribeMessage: React.Dispatch<React.SetStateAction<string>>,
  messageThreadId?: number,
  selectedLanguage?: TRANSCRIBE_LANGUAGE,
  isListeningASR: boolean = true,
) => {
  const accessToken = getAccessToken();
  const { currentUser } = useUserDetails();
  const { transcribeLanguage } = useHandsFreeOperationSetting();

  const { mutate: createRecordingMutate } = useMutation(createRecording);

  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder>();
  const [audioChunks, setAudioChunks] = useState<
    {
      stream: Blob;
      timestamp: number;
    }[]
  >([]);

  const asrAudioLanguage = selectedLanguage ?? transcribeLanguage;

  const isRecording = useMemo(
    () => audioRecorder?.state === 'recording',
    [audioRecorder?.state],
  );

  // Initialize audio stream and recorder
  const initAudioRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        audioConstraints,
      );
      const recorder = new MediaRecorder(stream);
      setAudioRecorder(recorder);
    } catch (error) {
      console.error('Error initializing audio recording:', error);
    }
  };

  const createRecordingPayload: CreateRecordingDto = {
    UserId: currentUser?.id,
    recStop: 'true',
    token: accessToken,
    language: asrAudioLanguage,
  };

  const recordingPayload: CreateRecordingDto = messageThreadId
    ? { MessageThreadId: messageThreadId, ...createRecordingPayload }
    : createRecordingPayload;

  const startRecorderDataCapture = () => {
    if (audioRecorder && audioRecorder.state !== 'recording') {
      audioRecorder.start(AUDIO_RECORDER_CHUNK_TIME_SLICE_MS);
      setStartTime(Date.now());
      audioRecorder.ondataavailable = event => {
        if (event.data.size > AUDIO_RECORDER_INITIAL_CHUNK_SIZE) {
          setAudioChunks(prevChunks => [
            ...prevChunks,
            { stream: event.data, timestamp: Date.now() },
          ]);
        }
      };
    }
  };

  const stopRecording = () => {
    resetAudioRecorder();
    audioRecorder?.stream.getTracks().forEach(track => track.stop());
  };

  const resetAudioRecorder = (onRecordingEnd?: () => void) => {
    if (audioRecorder && audioRecorder.state === 'recording') {
      audioRecorder.stop();
      endRecording(onRecordingEnd);
    }
  };

  const sendAudioChunks = (chunks: { stream: Blob; timestamp: number }[]) => {
    if (chunks.length === 0) {
      return;
    }

    const blob = new Blob(
      _.map(chunks, chunk => chunk.stream),
      { type: AUDIO_RECORDER_CHUNK_TYPE },
    );

    if (currentUser && accessToken) {
      createRecordingMutate({
        ...recordingPayload,
        timestamp: Date.now(),
        language: asrAudioLanguage,
        recStop: 'false',
        file: blob,
      });
    }
  };

  const endRecording = (callbackOnSuccess?: () => void) => {
    if (currentUser && accessToken) {
      createRecordingMutate(
        {
          ...recordingPayload,
          timestamp: Date.now(),
          language: asrAudioLanguage,
        },
        {
          onSuccess: () => {
            setAudioChunks([]);
            callbackOnSuccess?.();
          },
        },
      );
    }
  };

  const startRecording = (callbackOnSuccess?: () => void) => {
    if (currentUser && accessToken) {
      createRecordingMutate(
        {
          ...recordingPayload,
          timestamp: Date.now(),
          language: asrAudioLanguage,
        },
        {
          onSuccess: () => {
            setAudioChunks([]);
            setTranscribeMessage('');
            startRecorderDataCapture();
            callbackOnSuccess?.();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (isRecording) {
      sendAudioChunks(audioChunks);
    }
  }, [audioChunks, isRecording]);

  useEffect(() => {
    if (audioRecorder) {
      startRecording();
    }

    return () => {
      if (audioRecorder && audioRecorder.state === 'recording') {
        stopRecording();
      }
    };
  }, [audioRecorder]);

  useEffect(() => {
    if (!isListeningASR) {
      return;
    }
    initAudioRecording();

    return () => {
      stopRecording();
    };
  }, [isListeningASR]);

  return {
    isRecording,
    startRecording,
    stopRecording,
    resetAudioRecorder,
    endRecording,
    audioRecorder,
  };
};
